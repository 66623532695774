import React from 'react';

import FieldErrorMsg from '+containers/Dashboard/Shared/FormError';
import NumericInput from '+containers/Dashboard/Shared/NumericInput';
import { ILimitConfigFields, IMerchantInfo } from '+types';

import { riskLevels } from '../../../constants/common';

const SpendingLimit = ({ getFieldProps, isDefault, currency, values, setFieldValue, touched, errors, handleBlur }: ILimitConfigFields) => {
  const isInvalid = (key: keyof IMerchantInfo) => touched[key] && errors[key];

  return (
    <fieldset className="fade-in mt-1">
      <div>
        <label htmlFor="risk-level">Risk level</label>
        <select className="form-control" id="risk-level" {...getFieldProps('risk_level')} disabled={isDefault}>
          {riskLevels.map(level => (
            <option value={level.value} key={level.value}>
              {level.label}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label htmlFor="max-limit-per-trxn">Maximum limit per transaction ({currency})</label>
        <NumericInput
          id="max-limit-per-trxn"
          name="max_limit_per_trxn"
          value={values.max_limit_per_trxn}
          onValueChange={values => setFieldValue('max_limit_per_trxn', values.value)}
          onBlur={handleBlur}
          disabled={isDefault}
          data-state={isInvalid('max_limit_per_trxn') ? 'invalid' : ''}
        />
        <FieldErrorMsg touched={Boolean(touched.max_limit_per_trxn)} error={errors.max_limit_per_trxn} id="max-limit-per-trxn" />
      </div>

      <div>
        <label htmlFor="daily-trxn-cap">Daily transaction cap ({currency})</label>
        <NumericInput
          name="daily_trxn_cap"
          id="daily-trxn-cap"
          value={values.daily_trxn_cap}
          onValueChange={values => setFieldValue('daily_trxn_cap', values.value)}
          onBlur={handleBlur}
          disabled={isDefault}
          data-state={isInvalid('daily_trxn_cap') ? 'invalid' : ''}
        />
        <FieldErrorMsg touched={Boolean(touched.daily_trxn_cap)} error={errors.daily_trxn_cap} id="daily_trxn_cap" />
      </div>

      <div>
        <label htmlFor="monthly-trxn-cap">Monthly transaction cap ({currency})</label>
        <NumericInput
          name="monthly_trxn_cap"
          id="monthly-trxn-cap"
          value={values.monthly_trxn_cap}
          onValueChange={values => setFieldValue('monthly_trxn_cap', values.value)}
          onBlur={handleBlur}
          disabled={isDefault}
          data-state={isInvalid('monthly_trxn_cap') ? 'invalid' : ''}
        />
        <FieldErrorMsg touched={Boolean(touched.monthly_trxn_cap)} error={errors.monthly_trxn_cap} id="monthly-trxn-cap" />
      </div>
    </fieldset>
  );
};

export default SpendingLimit;

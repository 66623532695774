import React, { useMemo, useState } from 'react';
import { useMutation } from 'react-query';

import Modal from '+containers/Dashboard/Shared/Modal';
import { useFeedbackHandler } from '+hooks';
import APIRequest from '+services/api-services';
import { BillingType, ErrorT, KAASBillingFeesDataType, KAASFeesDataType, KAASFeeType } from '+types';
import { capitalizeFirst, identityCountries, logError } from '+utils';

import {
  checkIfModified,
  hasDifferencesWithInitial,
  transformFeesData,
  transformFeesDataForReset,
  transformFeesDataForSave
} from '../../helpers/configurationHelper';
import ConfigurationContent from './ModalContent/Configuration';
import ConfirmationContent from './ModalContent/Confirmation';
import ResetContent from './ModalContent/ResetContent';

import './index.scss';

const api = new APIRequest(process.env.REACT_APP_MIDDLEWARE_API_BASE);

export default function ConfigurationFeeModal({
  close,
  fees,
  id,
  region,
  refetch,
  defaultFee,
  merchantName,
  configType,
  identityTitles
}: {
  close: () => void;
  fees: KAASFeesDataType;
  id?: string;
  region: string;
  refetch?: () => void;
  defaultFee?: KAASFeesDataType;
  merchantName?: string;
  configType: 'default' | 'merchant';
  identityTitles: { [key: string]: string };
}) {
  const initialFeesData = transformFeesData(fees, defaultFee as KAASFeesDataType, configType, identityTitles);
  const [feesData, setFeesData] = useState<KAASBillingFeesDataType>(initialFeesData);
  const [configStage, setConfigStage] = useState<'configuration' | 'confirmation' | 'reset'>('configuration');
  const [vatInclusive, setVatInclusive] = useState(fees?.billing?.vat_inclusive || defaultFee?.billing?.vat_inclusive || false);
  const [selectedCurrency, setSelectedCurrency] = useState(fees?.billing?.currency || defaultFee?.billing?.currency || 'NGN');
  const { feedbackInit } = useFeedbackHandler();
  const [dataReset, setDataReset] = useState(false);
  const country = identityCountries?.find(i => i.value === region)?.label;
  const configModified = checkIfModified(feesData);
  const [resetFeesData, setResetFeesData] = useState<KAASBillingFeesDataType>({ kyc: [], kyb: [] });
  const hasDifferences = useMemo(
    () =>
      hasDifferencesWithInitial(
        {
          currency: fees?.billing?.currency || defaultFee?.billing?.currency,
          vat_inclusive: Boolean(fees?.billing?.vat_inclusive),
          ...initialFeesData
        },
        { currency: selectedCurrency, vat_inclusive: Boolean(vatInclusive), ...feesData }
      ),
    [fees?.billing?.currency, fees?.billing?.vat_inclusive, initialFeesData, selectedCurrency, vatInclusive, feesData]
  );

  const getModifiedFees = () => {
    const kyc = (feesData as KAASBillingFeesDataType).kyc.filter(fee => fee.modified);
    const kyb = (feesData as KAASBillingFeesDataType).kyb.filter(fee => fee.modified);
    return { kyc, kyb };
  };

  const modifiedFees = getModifiedFees();

  const updateFees = useMutation((data: BillingType) => api.updateBillingFees(id || 'default', data, region), {
    onError: (error: ErrorT) => {
      logError(error);
      feedbackInit({ message: error.response?.data.message, componentLevel: true, type: 'danger' });
    },
    onSuccess: () => {
      feedbackInit({ message: `Fees configured successfully`, componentLevel: true, type: 'success' });
      refetch?.();
    }
  });

  const handleFeeChange = (section: 'kyc' | 'kyb', title: string, newFee: number | undefined) => {
    setFeesData(prevState => {
      const updatedSection = prevState[section].map(fee => {
        if (fee.title === title) {
          return { ...fee, newFee: newFee };
        }
        return fee;
      });

      return { ...prevState, [section]: updatedSection };
    });
  };

  const addToResetFees = (section: 'kyc' | 'kyb', e: KAASFeeType, add: boolean) => {
    setResetFeesData(prevState => {
      if (add) {
        return { ...prevState, [section]: [...prevState[section], e] };
      }
      return { ...prevState, [section]: prevState[section].filter(fee => fee.key !== e.key) };
    });
  };

  const renderContent = (stage: 'confirmation' | 'configuration' | 'reset') => {
    switch (stage) {
      case 'confirmation':
        return <ConfirmationContent type={dataReset ? 'reset' : 'configuration'} />;
      case 'reset':
        return <ResetContent feesData={modifiedFees} defaultFee={defaultFee as KAASFeesDataType} setSelectedFees={addToResetFees} />;
      case 'configuration':
      default:
        return (
          <ConfigurationContent
            vatInclusive={vatInclusive}
            setVatInclusive={setVatInclusive}
            type={configType}
            selectedCurrency={selectedCurrency}
            setSelectedCurrency={setSelectedCurrency}
            configModified={configModified}
            resetToDefaultFees={() => {
              setDataReset(true);
              setConfigStage('reset');
            }}
            feesData={feesData}
            handleFeeChange={(section, title, newFee) => handleFeeChange(section, title, newFee)}
          />
        );
    }
  };

  const confHeading = merchantName
    ? `${capitalizeFirst(merchantName)} Identity Service Fees in ${country}`
    : `Fees for Identity Service in ${country}`;

  const getModalSize = () => {
    switch (configStage) {
      case 'reset':
      case 'configuration':
        return 'lg';
      default:
        return 'sm';
    }
  };

  const getModalDescription = () => {
    switch (configStage) {
      case 'configuration':
        return 'These are the fees for the different verification use cases that are available within this region. Enter an amount in the ‘New Fee’ column to change a fee, or leave it empty to maintain the current fee.';
      case 'reset':
        return 'Resetting will revert your custom fees to the system default for the selected verification type and ID types. This action cannot be undone.';
      default:
        return 'Please confirm that you want to save the changes you have made to this configuration. Saved changes will be applied immediately.';
    }
  };

  const getSecondButtonText = () => {
    switch (configStage) {
      case 'configuration':
        return 'Save Fees';
      case 'reset':
        return 'Reset Fees';
      default:
        return 'Yes, Confirm';
    }
  };

  const getHeading = () => {
    switch (configStage) {
      case 'reset':
        return 'Reset Fees to Default';
      case 'confirmation':
        return 'Confirm Changes';
      case 'configuration':
      default:
        return confHeading;
    }
  };

  const getSecondButtonDisabledState = () => {
    switch (configStage) {
      case 'configuration':
        return !hasDifferences;
      case 'reset':
        return resetFeesData.kyc.length <= 0 && resetFeesData.kyb.length <= 0;
      default:
        return undefined;
    }
  };

  const handleSecondBtnAction = (type: 'configuration' | 'confirmation' | 'reset') => {
    if (['configuration', 'reset'].includes(type)) {
      return () => setConfigStage('confirmation');
    }

    if (dataReset) {
      const transformedData = transformFeesDataForReset(resetFeesData);
      return async () => await updateFees.mutateAsync(transformedData);
    }

    const transformedData = transformFeesDataForSave(feesData, selectedCurrency, vatInclusive, configType);
    return async () => await updateFees.mutateAsync(transformedData);
  };

  const configurationModal = (type: 'configuration' | 'confirmation' | 'reset') => {
    const content = {
      heading: getHeading(),
      description: getModalDescription(),
      content: renderContent(type),
      firstButtonText: type === 'reset' ? 'Back' : 'Cancel',
      firstButtonAction:
        type === 'reset'
          ? () => {
              setDataReset(false);
              setConfigStage('configuration');
            }
          : undefined,
      secondButtonText: getSecondButtonText(),
      secondButtonAction: handleSecondBtnAction(type),
      headerBottomBorder: true,
      completedHeading: `Saved!`,
      completedDescription: dataReset
        ? 'You have successfully replaced this merchant’s custom fees with default fees.'
        : `You have successfully saved changes to this configuration.`,
      secondaryCompletedModal: type === 'confirmation',
      secondButtonActionIsTerminal: type === 'confirmation',
      secondButtonDisable: getSecondButtonDisabledState(),
      equalFooterBtn: type === 'confirmation' ? true : undefined
    };

    return content;
  };

  return <Modal size={getModalSize()} {...configurationModal(configStage)} close={close} isScrollable />;
}

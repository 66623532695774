import React from 'react';

import FieldErrorMsg from '+containers/Dashboard/Shared/FormError';

import '../../index.scss';

interface IOverlayInput extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  id: string;
  error?: string;
  showError?: boolean;
  touched: boolean;
  overlayText?: string;
}

export default function OverlayInput({ className, overlayText, id, error, touched, showError = true, ...props }: IOverlayInput) {
  return (
    <div className="overlay-input__wrapper--outer">
      <div className="overlay-input__wrapper--inner">
        <input {...props} className={`form-control ${className}`} data-state={touched && error ? 'invalid' : ''} />
        <span className="overlay-input__overlay">{overlayText}</span>
      </div>
      {showError ? <FieldErrorMsg id={id} error={error || ''} touched={touched} /> : null}
    </div>
  );
}

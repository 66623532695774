/* eslint-disable react/no-children-prop */
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ChargebackSummary from './Chargeback/ChargebackSummary/ChargebackSummary';
import ChargeBackDetails from './Chargeback/components/ChargeBackDetails';
import MultipleChargeback from './Chargeback/MultipleChargeback';
import DisputesHistory from './DisputesHistory';
import RefundsDetails from './Refunds/RefundsDetails';

import './index.scss';

export default function Disputes() {
  return (
    <div className="checkouts-container dispute-container">
      <Switch>
        <Route exact path="/dashboard/disputes" component={DisputesHistory} />
        <Route path="/dashboard/disputes/chargeback/add" component={MultipleChargeback} />
        <Route path="/dashboard/disputes/chargeback/summary" component={ChargebackSummary} />
        <Route path="/dashboard/disputes/refunds/:id">
          <RefundsDetails />
        </Route>
        <Route path="/dashboard/disputes/chargebacks/:id">
          <ChargeBackDetails />
        </Route>
      </Switch>
    </div>
  );
}

import {
  AddMerchantModalStepType,
  CardIssuanceDefaultLimitsResponseType,
  IFeeProps,
  IMerchantInfo,
  IPlan,
  MerchantInfoFormikBagType,
  TransformedDefaultPlansConfigType
} from '+types';
import { formatAmount, formatWithCommas } from '+utils';

import { customerCardMonthlyPaymentValues, reservedCardMonthlyPaymentValues } from '../constants/common';

export const descriptionContext = {
  shared: {
    'issued-cards': 'issued cards',
    'reserved-cards': 'RVCs.'
  },
  init: {
    'issued-cards': 'issued cards',
    'reserved-cards': 'reserved cards.'
  },
  confirm_submission: {
    'issued-cards': 'card issuance',
    'reserved-cards': 'RVC'
  }
};

export const initializeValues = ({
  defaultLimitsConfig,
  defaultPlansConfig,
  category
}: {
  defaultLimitsConfig: CardIssuanceDefaultLimitsResponseType['customer'];
  defaultPlansConfig: TransformedDefaultPlansConfigType['customer'];
  category: 'reserved-cards' | 'issued-cards';
}) => {
  const spendingLimit = defaultLimitsConfig.risk_level.medium_risk.spending_limit;
  const fundingLimit = defaultLimitsConfig.risk_level.medium_risk.funding_limit;
  const pcidssLevel = category === 'issued-cards' ? 'level_2' : 'level_0';
  const pcidssLevelLimit = defaultLimitsConfig.pcidss_level[pcidssLevel];
  const fee = defaultPlansConfig.find(config => config.name === 'startup')?.fee;

  const monthlyPaymentValue =
    category === 'issued-cards' ? customerCardMonthlyPaymentValues[0]['value'] : reservedCardMonthlyPaymentValues[0]['value'];

  return {
    merchant: { name: '', kora_id: 0 },
    risk_level: 'medium_risk',
    max_limit_per_trxn: String(spendingLimit.per_transaction_max),
    daily_trxn_cap: String(spendingLimit.daily_max),
    monthly_trxn_cap: String(spendingLimit.monthly_max),
    pci_dss_level: pcidssLevel,
    subscription_plan: 'startup',
    total_payment_value: '9000',
    max_daily_funding: String(fundingLimit.daily_max),
    max_monthly_funding: String(fundingLimit.monthly_max),
    max_quarterly_funding: String(fundingLimit.quarterly_max),
    trxn_count: String(pcidssLevelLimit?.yearly_transaction_count),
    monthly_card_limit: String(defaultPlansConfig[0]?.cardLimit),
    yearly_issued_cards: String(pcidssLevelLimit?.yearly_issued_cards),
    subscription_fee: fee?.subscription as IFeeProps,
    issuance_fee: fee?.issuance as IFeeProps,
    cross_currency_fee: fee?.cross_currency as IFeeProps,
    chargeback_fee: fee?.chargeback as IFeeProps,
    funding_fee: fee?.funding as IFeeProps,
    withdrawal_fee: fee?.withdrawal as IFeeProps,
    monthly_payment_value: monthlyPaymentValue
  } satisfies IMerchantInfo;
};

export const validatePerStep = ({
  values,
  stage,
  limitConfig
}: {
  values: IMerchantInfo;
  stage: AddMerchantModalStepType;
  limitConfig: 'custom' | 'default';
}) => {
  const errors: MerchantInfoFormikBagType['errors'] = {};

  if (stage === 'init') {
    if (values.merchant.name === '' || String(values.merchant.kora_id) === '') errors.merchant = 'Merchant name is required';
    if (values.total_payment_value === '') errors.total_payment_value = 'TPV is required';
  }

  if (stage === 'set_spending_limit' && limitConfig === 'custom') {
    if (values.max_limit_per_trxn === '') errors.max_limit_per_trxn = 'Max limit per transaction is required';
    if (values.daily_trxn_cap === '') errors.daily_trxn_cap = 'Daily transaction cap is required';
    if (values.monthly_trxn_cap === '') errors.monthly_trxn_cap = 'Monthly transaction cap is required';
  }

  if (stage === 'set_funding_limit' && limitConfig === 'custom') {
    if (values.max_daily_funding === '') errors.max_daily_funding = 'Max daily funding amount is required';
    if (values.max_monthly_funding === '') errors.max_monthly_funding = 'Max monthly funding amount is required';
    if (values.max_quarterly_funding === '') errors.max_quarterly_funding = 'Max quarterly funding amount is required';
  }

  if (stage === 'set_pci_dss_limit' && limitConfig === 'custom') {
    if (values.trxn_count === '') errors.trxn_count = 'Transaction count is required';
    if (values.yearly_issued_cards === '') errors.yearly_issued_cards = 'Number of issuable cards is required';
  }

  if (stage === 'set_subscription_fees' && limitConfig === 'custom') {
    if (values.yearly_issued_cards === '') errors.yearly_issued_cards = 'Number of issuable cards is required';
    if (values.subscription_plan === '') errors.subscription_plan = 'Subscription plan is required';
    if (String(values.subscription_fee?.amount) === '') errors.subscription_fee = 'Subscription fee is required';
    if (String(values.issuance_fee?.amount) === '') errors.issuance_fee = 'Card issuing fee is required';
    if (String(values.cross_currency_fee?.amount) === '') errors.cross_currency_fee = 'Cross currency fee is required';
    if (String(values.chargeback_fee?.amount) === '') errors.chargeback_fee = 'Cross currency fee is required';
  }
  return errors;
};

export const formatAmountWithDelimiters = (value: unknown) => formatWithCommas(formatAmount(String(value)));

export const transformDefaultPlansConfig = (plans: IPlan[]) => {
  return plans.reduce<TransformedDefaultPlansConfigType>(
    (prev, next) => {
      return {
        reserved: [
          ...prev.reserved,
          {
            name: next.name,
            cardLimit: next.monthly_card_limit,
            fee: { ...next.fee.reserved }
          }
        ],
        customer: [
          ...prev.customer,
          {
            name: next.name,
            cardLimit: next.monthly_card_limit,
            fee: { ...next.fee.customer }
          }
        ]
      };
    },
    { reserved: [], customer: [] }
  );
};

import React from 'react';

import Badge from '+containers/Dashboard/Shared/Badge';
import Typography from '+containers/Dashboard/Shared/Typography';
import { useReducerState, useSetUserAccess } from '+hooks';
import { CurrencyType, modalState, productCategoriesType, ProductConfigType } from '+types';
import { availableProductCategories, formatProductConfigStatus, isAllowed, productMapping } from '+utils';

import MerchantCurrencyModal from '../../MerchantsTable/components/MerchantCurrencyModal';
import ProductCategories from '../components/ProductCategories';

import './index.scss';

type ProductsPropsType<T> = {
  config: T;
  label: string;
  type: productCategoriesType;
  currency: CurrencyType;
  merchantId: string;
  merchantsStatus: boolean;
};

const Products = ({ config, type, label, currency, merchantId, merchantsStatus }: ProductsPropsType<ProductConfigType>) => {
  const userAccess = useSetUserAccess();
  const [state, setState] = useReducerState<{ activeModal: modalState }>({
    activeModal: null
  });

  return (
    <div className="payins-container">
      <div className="payins-container__first">
        <div>
          <span>
            <Typography variant="h2">{label}</Typography>
            <Badge status={formatProductConfigStatus(String(!merchantsStatus ? false : config?.enabled))} />
          </span>
          <Typography className="mt-3" variant="subtitle2">
            {`Here you can find the ${label} products for this merchant. You can modify their limits and payment channels configuration.`}
          </Typography>
        </div>

        <div>
          {merchantsStatus && (
            <>
              <p>See KYB </p>
              {isAllowed(userAccess, ['transaction_config_details.update']) && (
                <button
                  type="button"
                  className="btn btn-secondary --enable-btn"
                  onClick={() => {
                    setState({ activeModal: config?.enabled ? 'disable-product' : 'enable-product' });
                  }}
                  title={`${config?.enabled ? 'Disable' : 'Enable'} ${availableProductCategories[type]} for this merchant`}
                >
                  {`${config?.enabled ? 'Disable' : 'Enable'} ${label} for this merchant`}
                </button>
              )}
            </>
          )}
        </div>
      </div>
      <ProductCategories config={config} type={type} currency={currency} merchantId={merchantId} merchantsStatus={merchantsStatus} />
      {state.activeModal && (
        <MerchantCurrencyModal
          activeModal={state.activeModal}
          setActiveModal={() => setState({ activeModal: null })}
          currency={currency}
          merchantId={merchantId}
          productType={productMapping[type]}
          hideButton
        />
      )}
    </div>
  );
};

export default Products;

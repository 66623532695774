import React from 'react';

import Badge from '+containers/Dashboard/Shared/Badge';
import Typography from '+containers/Dashboard/Shared/Typography';
import Icon from '+shared/Icons';
import { CurrencyType } from '+types';
import { switchTrxnMessage } from '+utils';

interface IMerchantId {
  name: string;
  hasAccess: boolean;
  currency: CurrencyType;
  id: number;
  plan: string;
}

const MerchantId = ({ name, hasAccess = false, currency, id, plan = 'enterprise' }: IMerchantId) => {
  return (
    <div className="__left">
      <div>
        <span>
          <Icon name="office" />
        </span>
        <p>Merchant</p>
      </div>
      <div>
        <Typography variant="h1">{name}</Typography>
        <Badge status={hasAccess ? 'enabled' : 'disabled_access'}>
          {currency ?? 'Not Available'} {switchTrxnMessage[hasAccess ? 'enabled' : 'disabled_access']?.name}
        </Badge>
      </div>
      <Typography className="id-and-plan" variant="subtitle2" as="div">
        <p>{`ID: ${id}`}</p>
        <span />
        <p className="text-capitalize">{plan}</p>
      </Typography>
    </div>
  );
};

export default MerchantId;

/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';

import ToolTip from '+containers/Dashboard/Shared/Tooltip';
import useFeedbackHandler from '+hooks/useFeedbackHandler';
import useQueryStorage from '+hooks/useQueryStorage';
import useSetUserAccess from '+hooks/useSetUserAccess';
import APIRequest from '+services/api-services';
import APIServiceError from '+services/error-services';
import CurrencyPicker from '+shared/CurrencyPicker';
import EditFeesContent from '+shared/EditFeesComponent';
import HoverMenu from '+shared/HoverMenu';
import LoadingPlaceholder from '+shared/LoadingPlaceHolder';
import Modal, { IModalProps } from '+shared/Modal';
import NewFeesContent from '+shared/NewFeesComponent';
import queryKeys from '+store/constants/queryKeys';
import { CurrencyFeesDataType, ICurrency, IError, IFeeDetails, IFeeItem, IFees, IValue } from '+types';
import { capitalize, defaultScrollToTop, formatAmount, isAllowed, logError } from '+utils';

import close from '+assets/img/dashboard/close-red.svg';
import deleted from '+assets/img/dashboard/deleted.svg';
import edit from '+assets/img/dashboard/edit.svg';
import InfoIcon from '+assets/img/dashboard/question-mark-gray.svg';

import './index.scss';

const api = new APIRequest();

export default function DefaultFees() {
  const userAccess = useSetUserAccess();

  const queryClient = useQueryClient();
  const { feedbackInit, closeFeedback } = useFeedbackHandler();
  const { getQueryData } = useQueryStorage();
  const currencies = getQueryData(queryKeys.currencies) as CurrencyFeesDataType;

  const [values, setValues] = useState<Partial<IValue>>();
  const [fees, setFees] = useState<IFees>({});
  const [disabled, setDisabled] = useState(true);
  const [state, setState] = useState<{ modalVisible: boolean; type?: string }>({
    modalVisible: false,
    type: 'delete'
  });

  const [activeCurrency, setActiveCurrency] = useState('NGN');
  const {
    data,
    refetch: refetchDefaultFees,
    isLoading
  } = useQuery(['DEFAULT_MERCHANT_FEES'], () => api.getDefaultFees(), {
    staleTime: 30 * 60 * 1000,
    keepPreviousData: true,
    onError: () => {
      feedbackInit({
        message: 'There has been an error fetching the default fees.',
        type: 'danger'
      });
    }
  });

  const updateDefaultFees = useMutation(({ info }: { info: IFeeItem | IFeeItem[] }) => api.updateDefaultFees(info), {
    onError: error => {
      if (error instanceof APIServiceError) {
        throw error;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['DEFAULT_MERCHANT_FEES']);
    }
  });

  const updateDefaultFee = (info: IFeeItem) => {
    return updateDefaultFees.mutateAsync({ info });
  };

  const feesData = data?.data;
  const currencyFeesData = fees?.[activeCurrency];

  useEffect(() => {
    const newFees: IFees = Object.keys(feesData || {}).reduce((allFees, currency) => {
      const currencyFees = feesData[currency];
      if (currencyFees.charge) {
        currencyFees.charge = currencyFees.charge.map((fee: IFeeItem) => {
          fee.definition = fee.definition.map(def => {
            def.id = Math.floor(1000 + Math.random() * 9000);
            return def;
          });
          return fee;
        });
      }
      if (currencyFees.disburse) {
        currencyFees.disburse = currencyFees.disburse.map((fee: IFeeItem) => {
          fee.definition = fee.definition.map(def => {
            def.id = Math.floor(1000 + Math.random() * 9000);
            return def;
          });
          return fee;
        });
      }
      return {
        ...allFees,
        [currency]: currencyFees
      };
    }, {});
    setFees(newFees);
    if (feesData && !newFees?.[activeCurrency]) {
      setActiveCurrency(Object.keys(newFees)[0]);
    }
  }, [feesData]);

  const addFee = async () => {
    const getAvailableFeeWithCurrencyAndKind = feesData?.[values?.currency as string]?.[values?.kind as string];
    const getAvailableFeeWithTransactionType =
      getAvailableFeeWithCurrencyAndKind?.filter((f: IFeeItem) => f.item === values?.trxnType) || [];
    const apiFee =
      getAvailableFeeWithTransactionType.length > 0
        ? getAvailableFeeWithTransactionType?.[0]?.definition.map((e: IFeeDetails) => ({
            cap: e.cap,
            type: e.type,
            value: e.value,
            vat_inclusive: e.vat_inclusive,
            scheme: e.scheme,
            default: e.default,
            range: e.range
          }))
        : [];

    const feeData = {
      item: values?.trxnType,
      type: values?.kind,
      currency: values?.currency,
      definition: [
        ...apiFee,
        {
          cap: values?.cap,
          type: values?.type,
          value: values?.type === 'flat' ? values?.amount : values?.percent,
          vat_inclusive: ['GBP', 'EUR', 'USD'].includes(activeCurrency) ? true : values?.vat,
          range: [values?.minimum, values?.maximum],
          ...(values?.scheme === 'ALL' ? {} : { scheme: values?.scheme }),
          default: values?.default
        }
      ]
    };
    try {
      await updateDefaultFee(feeData as IFeeItem);
      await refetchDefaultFees();
      feedbackInit({
        title: '',
        message: 'Fee rule added successfully',
        type: 'success'
      });
    } catch (error) {
      logError(error);
      const message = (error as IError)?.response?.data?.message;
      feedbackInit({
        title: '',
        message: !message?.includes('request') ? message : 'This fee rule cannot be added right now',
        type: 'danger',
        componentLevel: true
      });
      throw error;
    }
  };

  const editDefaultFee = async () => {
    const feeKind = currencyFeesData?.[values?.kind as keyof typeof currencyFeesData].filter(kind => kind.item === values?.trxnType) || [];
    const newFee = feeKind.map(f => {
      const tempFees = f.definition.filter(detail => detail.id !== values?.feeId);
      const modifiedTempFees = tempFees.map(e => ({
        cap: e.cap,
        type: e.type,
        value: e.value,
        vat_inclusive: e.vat_inclusive,
        range: e.range,
        scheme: e.scheme,
        default: e.default
      }));

      const tempData = {
        item: f.item,
        type: f.type,
        currency: activeCurrency,
        definition: [
          ...modifiedTempFees,
          {
            cap: values?.cap,
            type: values?.type,
            value: values?.type === 'flat' ? values?.amount : values?.percent,
            vat_inclusive: values?.vat,
            range: [values?.minimum, values?.maximum],
            ...(values?.scheme === 'ALL' ? {} : { scheme: values?.scheme }),
            default: values?.default
          }
        ]
      };
      return tempData;
    });

    try {
      await updateDefaultFee(newFee?.[0] as IFeeItem);
      await refetchDefaultFees();
    } catch (error) {
      logError(error);
      const message = (error as IError)?.response?.data?.message;
      feedbackInit({
        title: '',
        message: !message?.includes('request') ? message : 'The default fees rule cannot be edited right now. Please try again',
        type: 'danger',
        componentLevel: true
      });
      throw error;
    }
  };

  const deleteFee = async () => {
    const feeKind = currencyFeesData?.[values?.kind as keyof typeof currencyFeesData].filter(kind => kind.item === values?.trxnType);
    const newFee = feeKind.map(f => {
      const tempFees = f.definition.filter(detail => detail.id !== values?.feeId);
      const modifiedTempFees = tempFees.map(e => ({
        cap: e.cap,
        type: e.type,
        value: e.value,
        vat_inclusive: e.vat_inclusive,
        range: e.range,
        scheme: e.scheme,
        default: e.default
      }));
      if (modifiedTempFees.length !== 0) {
        const tempData = {
          item: f.item,
          type: f.type,
          definition: modifiedTempFees,
          currency: values?.currency
        };
        return tempData;
      }
      return false;
    });

    if (!newFee) {
      setState({ modalVisible: false });
      defaultScrollToTop();
      feedbackInit({
        title: '',
        message: 'A fee rule must exist for this kind of transaction',
        type: 'danger'
      });
      setTimeout(() => closeFeedback(), 2000);
    } else {
      try {
        await updateDefaultFee(newFee?.[0] as IFeeItem);
        await refetchDefaultFees();
      } catch (error) {
        logError(error);
        defaultScrollToTop();
        feedbackInit({
          title: '',
          message: 'The default fees cannot be deleted right now. Please try again',
          type: 'danger'
        });
        throw error;
      }
    }
  };

  const switchFeesModal = (type: string | undefined) => {
    let content;
    switch (type) {
      case 'new':
        content = {
          heading: 'Add a fee rule',
          description: `Fee rules define the amount charged as fees for any transaction. A fee rule would apply to transactions within a specific currencies, payment method and transaction range.`,
          content: (
            <NewFeesContent
              setDisabled={value => setDisabled(value)}
              setValues={v => setValues(prevValues => ({ ...prevValues, ...v }))}
              currencies={currencies as ICurrency[]}
              activeCurrency={activeCurrency}
            />
          ),
          secondButtonText: 'Save',
          size: 'md',
          maxHeight: '75vh',
          secondButtonAction: addFee,
          secondButtonDisable: disabled,
          completedHeading: 'Done',
          completedDescription: 'The fee rule has been successfully added.',
          secondaryCompletedModal: true
        };
        break;
      case 'edit':
        content = {
          heading: 'Edit fee rule',
          description: `Editing this fee rule will update the fees on future transactions that fall within this range for all merchant's transaction where the following conditions are applicable.`,
          content: (
            <EditFeesContent
              setDisabled={value => setDisabled(value)}
              setValues={v => setValues(prevValues => ({ ...prevValues, ...v }))}
              oldValue={values?.oldValue as IFeeDetails}
              activeCurrency={activeCurrency}
            />
          ),
          secondButtonText: 'Update',
          size: 'md',
          secondButtonAction: editDefaultFee,
          secondButtonDisable: disabled,
          completedHeading: 'Done',
          completedDescription: 'The fee rule has been successfully updated',
          secondaryCompletedModal: true
        };
        break;

      default:
        content = {
          themeColor: '#e65252',
          heading: 'Delete fee rule?',
          description: (
            <>
              Are you absolutely sure that you want to <strong>delete</strong> this fee rule? This action cannot be undone.
            </>
          ),
          secondButtonText: 'Yes, Delete',
          secondaryButtonColor: '#F32345',
          size: 'sm',
          secondButtonAction: deleteFee,
          completedHeading: 'Deleted',
          completedImage: deleted,
          completedDescription: 'The fee rule has been deleted.',
          secondaryCompletedModal: true
        };
        break;
    }
    return {
      visible: state.modalVisible,
      close: () => {
        setState({ ...state, modalVisible: false });
        setDisabled(true);
      },
      ...content
    };
  };

  const collectionFees = () => {
    const payinsRulesInfo = {
      bank_transfer: {
        title: 'Bank Transfers',
        description: 'These are the rules for the fees charged to the merchant when their customer chooses to pay with bank transfer.'
      },
      card: {
        title: 'Card Payments',
        description: 'These are the rules for the fees charged to the merchant when their customer chooses to pay with a debit/credit card.'
      },
      reserved_bank_account: {
        title: 'Reserved Bank Account',
        description:
          'These are the rules for the fees charged to the merchant when their customer chooses to pay through the reserved bank account assigned to them.'
      },
      mobile_money: {
        title: 'Mobile Money',
        description:
          'These are the rules for the fees charged to the merchant when their customer chooses to pay through a mobile money provider.'
      },
      virtual_bank_account: {
        title: 'VBA',
        description:
          'These are the rules for the fees charged to the merchant when their customer chooses to pay through the virtual account assigned to them.'
      },
      pay_with_bank: {
        title: 'Pay with Bank (Direct debit)',
        description:
          'These are the rules for the fees charged to the merchant when their customer chooses to pay through a direct debit on their bank account.'
      }
    };

    if (isLoading) return <LoadingPlaceholder content={5} type="table" background="#f5f6f6" />;

    if (!currencyFeesData?.charge && !isLoading) return null;

    return (
      <section className="merchants-fees__payins-w grey-section">
        <p className="grey-section__title">Fee Rules for Pay-ins</p>
        <div className="merchants-fees__payins grey-section__subsection">
          {currencyFeesData.charge?.map((fee, index) => (
            <React.Fragment key={index}>
              {payinsRulesInfo?.[fee.item as keyof typeof payinsRulesInfo] && (
                <div className="rule-container grey-section__subsection-group">
                  <div className="fee-item grey-section__subsection-item">
                    <div>
                      <p>{payinsRulesInfo[fee.item as keyof typeof payinsRulesInfo].title || ''}</p>
                      <p>{payinsRulesInfo[fee.item as keyof typeof payinsRulesInfo].description || ''}</p>
                    </div>
                  </div>
                  <div className="rule-group">
                    {fee.definition.map((feeDetails, index: number) => (
                      <div className="rule-box" key={feeDetails.id || index}>
                        <div className="rule-details">
                          <p>
                            {'A '}
                            <span className="--detail-green">
                              {feeDetails.type === 'flat' && capitalize(`${feeDetails.type} fee`)}
                              {feeDetails.type === 'percent' && capitalize(`${feeDetails.type}age fee`)}
                            </span>
                            {' of '}
                            <span className="--detail-green">
                              {feeDetails.type === 'flat'
                                ? `${activeCurrency} ${formatAmount(feeDetails.total_fee)} `
                                : `${formatAmount(feeDetails.total_fee)}% `}
                            </span>
                            {feeDetails.cap > 0 && (
                              <>
                                capped at{' '}
                                <span className="--detail-grey">
                                  {activeCurrency} {formatAmount(feeDetails.cap)}
                                </span>
                              </>
                            )}
                            {' is charged for transactions between '}
                            <span className="--detail-grey">{`${activeCurrency} ${formatAmount(feeDetails.range[0])}`}</span>
                            {' and '}
                            <span className="--detail-grey">{`${activeCurrency} ${formatAmount(feeDetails.range[1])}`}</span>
                            {feeDetails.scheme && (
                              <span>
                                {' '}
                                for <strong>{feeDetails.scheme}</strong> transfers
                              </span>
                            )}{' '}
                            {feeDetails.default && (
                              <ToolTip message="This is the default fee for this payment channel" type="Default-fees" image={InfoIcon} />
                            )}
                          </p>

                          {isAllowed(userAccess, ['default_fee_config.update']) && (
                            <HoverMenu
                              title={`${activeCurrency} ${formatAmount(feeDetails.range[0])} to ${activeCurrency} ${formatAmount(
                                feeDetails.range[1]
                              )}`}
                            >
                              <li>
                                <button
                                  type="button"
                                  className="btn btn--link"
                                  onClick={() => {
                                    setState({ modalVisible: true, type: 'edit' });
                                    setValues({
                                      kind: 'charge',
                                      trxnType: fee.item,
                                      feeId: feeDetails.id as number,
                                      currency: activeCurrency,
                                      oldValue: feeDetails
                                    });
                                  }}
                                >
                                  <img src={edit} alt="edit icon" aria-hidden />
                                  <span className="ml-2">Edit</span>
                                </button>
                              </li>
                              <li>
                                <button
                                  className="text-danger btn btn--link"
                                  type="button"
                                  onClick={() => {
                                    setState({ modalVisible: true, type: 'del' });
                                    setValues({
                                      kind: 'charge',
                                      trxnType: fee.item,
                                      feeId: feeDetails.id as number,
                                      currency: activeCurrency
                                    });
                                  }}
                                >
                                  <img src={close} alt="close icon" aria-hidden />
                                  <span className="ml-2">Delete</span>
                                </button>
                              </li>
                            </HoverMenu>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      </section>
    );
  };

  const disbursementFees = () => {
    const payoutsRulesInfo = {
      bank_account: {
        title: 'Bank Payouts',
        description: 'These are the rules for the fees charged to the merchant when they make payouts to bank accounts.'
      },
      mobile_money: {
        title: 'Mobile Money',
        description:
          'These are the rules for the fees charged to the merchant when they pay their customer through a mobile money provider.'
      }
    };

    if (isLoading) return <LoadingPlaceholder content={5} type="table" background="#f5f6f6" />;

    if (!currencyFeesData?.disburse && !isLoading) return null;

    return (
      <section className="merchants-fees__payouts-w grey-section">
        <p className="grey-section__title">Fee Rules for Payouts</p>
        <div className="merchants-fees__payouts grey-section__subsection">
          {currencyFeesData.disburse?.map(fee => (
            <React.Fragment key={fee.item}>
              {payoutsRulesInfo?.[fee.item as keyof typeof payoutsRulesInfo] && (
                <div className="rule-container grey-section__subsection-group">
                  <div className="fee-item grey-section__subsection-item">
                    <div>
                      <p>{payoutsRulesInfo[fee.item as keyof typeof payoutsRulesInfo].title}</p>
                      <p>{payoutsRulesInfo[fee.item as keyof typeof payoutsRulesInfo].description}</p>
                    </div>
                  </div>
                  <div className="rule-group">
                    {fee.definition.map(feeDetails => (
                      <div className="rule-box" key={feeDetails.id}>
                        <div className="rule-details">
                          <p>
                            {'A '}
                            <span className="--detail-green">
                              {feeDetails.type === 'flat' && capitalize(`${feeDetails.type} fee`)}
                              {feeDetails.type === 'percent' && capitalize(`${feeDetails.type}age fee`)}
                              {feeDetails.type === 'percentFlat' && capitalize(`Percentage + Flat Fee`)}
                            </span>
                            {' of '}
                            <span className="--detail-green">
                              {feeDetails.type === 'flat'
                                ? `${activeCurrency} ${formatAmount(feeDetails.total_fee)} `
                                : `${formatAmount(feeDetails.total_fee)}% `}
                            </span>
                            {feeDetails.cap > 0 && (
                              <>
                                capped at{' '}
                                <span className="--detail-grey">
                                  {activeCurrency} {formatAmount(feeDetails.cap)}
                                </span>
                              </>
                            )}
                            {feeDetails.scheme && (
                              <span>
                                {' '}
                                for <strong>{feeDetails.scheme}</strong> transfers
                              </span>
                            )}{' '}
                            {feeDetails.default ? (
                              <ToolTip message="This is the default fee for this payment channel" type="Default-fees" image={InfoIcon} />
                            ) : null}
                            {' is charged for transactions between '}
                            <span className="--detail-grey">{`${activeCurrency} ${formatAmount(feeDetails.range[0])}`}</span>
                            {' and '}
                            <span className="--detail-grey">{`${activeCurrency} ${formatAmount(feeDetails.range[1])}`}</span>
                          </p>

                          {isAllowed(userAccess, ['default_fee_config.update']) && (
                            <HoverMenu
                              title={`${activeCurrency} ${formatAmount(feeDetails.range[0])} to ${activeCurrency} ${formatAmount(
                                feeDetails.range[1]
                              )}`}
                            >
                              <li>
                                <button
                                  type="button"
                                  className="btn btn--link"
                                  onClick={() => {
                                    setState({ modalVisible: true, type: 'edit' });
                                    setValues({
                                      kind: 'disburse',
                                      trxnType: fee.item,
                                      feeId: feeDetails.id as number,
                                      currency: activeCurrency,
                                      oldValue: feeDetails
                                    });
                                  }}
                                >
                                  <img src={edit} alt="edit icon" aria-hidden />
                                  <span className="ml-2">Edit</span>
                                </button>
                              </li>
                              <li>
                                <button
                                  className="text-danger btn btn--link"
                                  type="button"
                                  onClick={() => {
                                    setState({ modalVisible: true, type: 'del' });
                                    setValues({
                                      kind: 'disburse',
                                      trxnType: fee.item,
                                      feeId: feeDetails.id as number,
                                      currency: activeCurrency
                                    });
                                  }}
                                >
                                  <img src={close} alt="close icon" aria-hidden />
                                  <span className="ml-2">Delete</span>
                                </button>
                              </li>
                            </HoverMenu>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      </section>
    );
  };

  return (
    <div className="element-box">
      <div className="fees-heading-box">
        <div className="fees-heading-content">
          <h5 className="form-header">Default Transaction Fees</h5>
          <div className="form-desc" style={{ border: 'none', marginBottom: '0' }}>
            This is where you configure the default fees for all merchants. If you need to change the fees for a specific merchant, simply
            go to the &apos;Fees&apos; tab in the details page of that <Link to="/dashboard/merchants">merchant</Link>. Note that all fees
            have VAT implications.
          </div>
        </div>
        {isAllowed(userAccess, ['default_fee_config.update']) && (
          <div className="fees-heading-buttons --default">
            <button
              className="btn btn-success"
              type="button"
              style={{ height: 'max-content' }}
              onClick={() => {
                setState({ modalVisible: true, type: 'new' });
              }}
            >
              <i className="os-icon os-icon-plus" />
              <span>Add Fee Rule</span>
            </button>
          </div>
        )}
      </div>

      {Object.keys(feesData || {}).length > 0 && (
        <CurrencyPicker
          options={Object.keys(feesData)}
          onChange={value => {
            setActiveCurrency(value);
          }}
          className="merchants-fees__currency-toggle"
          activeCurrency={activeCurrency}
          label={<strong>Showing fees for:</strong>}
          id="default-merchants-fees__currency-switch"
        />
      )}

      {collectionFees()}
      {disbursementFees()}
      <Modal isScrollable {...(switchFeesModal(state.type) as IModalProps)} />
    </div>
  );
}

/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { FormikErrors, FormikProps, FormikTouched, useFormik } from 'formik';

import { useFeedbackHandler, useSearchQuery } from '+hooks';
import APIRequest from '+services/api-services';
import Modal, { IModalProps } from '+shared/Modal';
import {
  CardIssuanceUpdateLimits,
  CategoryType,
  CurrencyType,
  EditActionType,
  PartialIModalPropsType,
  PCIDSSLevelResponseType,
  RiskLevelResponseType
} from '+types';
import { logError } from '+utils';

import EditCardFundingLimits from './EditCardFundingLimits';
import EditCardSpendingLimits from './EditCardSpendingLimits';
import EditLimitsByPCIDSS from './EditLimitsByPCIDSS';
import { formValidationFn } from './editVirtualCardLimitsModalHelpers';

type ConfirmEditActionType = 'confirm_save_pci_dss_limit' | 'confirm_save_funding_limit' | 'confirm_save_spending_limit';

type StepType = EditActionType | ConfirmEditActionType;

type FormValuesType = RiskLevelResponseType | PCIDSSLevelResponseType;

const api = new APIRequest();

const EditVirtualCardLimitsModal = ({
  action,
  onClose,
  category,
  defaultValues,
  userType,
  refchLimits
}: {
  action: EditActionType;
  onClose: () => void;
  category: CategoryType;
  defaultValues: FormValuesType;
  userType: 'reserved' | 'customer';
  refchLimits: () => void;
}) => {
  const [step, setStep] = useState<StepType>(action);
  const [isChecked, setChecked] = useState(false);
  const { feedbackInit } = useFeedbackHandler();
  const searchQuery = useSearchQuery();
  const currency = (searchQuery.value?.currency as CurrencyType) ?? 'USD';

  const handleCheckboxChange = (e: { target: { checked: boolean | ((prevState: boolean) => boolean) } }) => {
    setChecked(e.target.checked);
  };

  const { mutateAsync: mutateCustomizePCIDSSLevel } = useMutation((payload: CardIssuanceUpdateLimits) => api.updateLevelLimits(payload), {
    onSuccess: () => {
      refchLimits();
    },
    onError: error => {
      if (error) {
        logError(error);
        feedbackInit({
          message: error?.response?.data?.message || 'Action was unsuccessful',
          type: 'danger'
        });
      }
    }
  });

  const handleMutateCustomizePCIDSSLevel = async () => {
    const formValues = formik.values as any;

    let payload: CardIssuanceUpdateLimits;

    switch (step) {
      case 'confirm_save_funding_limit':
        payload = {
          currency,
          card_category: userType,
          funding_limits: Object.keys(formValues).map((levelKey: string) => {
            const levelData = formValues[levelKey]['funding_limit'];
            return {
              level: levelKey,
              data: {
                daily_max: levelData.daily_max,
                monthly_max: levelData.monthly_max,
                quarterly_max: levelData.quarterly_max
              }
            };
          })
        };
        break;

      case 'confirm_save_spending_limit':
        payload = {
          currency,
          card_category: userType,
          spending_limits: Object.keys(formValues).map((levelKey: string) => {
            const levelData = formValues[levelKey]['spending_limit'];
            return {
              level: levelKey,
              data: {
                daily_max: levelData.daily_max,
                monthly_max: levelData.monthly_max,
                per_transaction_max: levelData.per_transaction_max
              }
            };
          })
        };
        break;

      case 'confirm_save_pci_dss_limit':
        payload = {
          currency,
          card_category: userType,
          pcidss_level_limits: Object.keys(formValues).map((levelKey: string) => {
            const levelData = formValues[levelKey];
            return {
              level: levelKey,
              data: {
                yearly_issued_cards: levelData.yearly_issued_cards,
                yearly_transaction_count: levelData.yearly_transaction_count
              }
            };
          })
        };
        break;

      default:
        return;
    }
    await mutateCustomizePCIDSSLevel(payload);
  };

  const formik = useFormik({
    initialValues: defaultValues,
    validate: formValidationFn,
    enableReinitialize: true,
    onSubmit: async () => {
      switch (step) {
        case 'edit_pci_dss_limit':
          setStep('confirm_save_pci_dss_limit');
          break;
        case 'edit_funding_limit':
          setStep('confirm_save_funding_limit');
          break;
        case 'edit_spending_limit':
          setStep('confirm_save_spending_limit');
          break;
        case 'confirm_save_funding_limit':
          await handleMutateCustomizePCIDSSLevel();
          break;
        case 'confirm_save_spending_limit':
          await handleMutateCustomizePCIDSSLevel();
          break;
        case 'confirm_save_pci_dss_limit':
        default:
          await handleMutateCustomizePCIDSSLevel();
          break;
      }
    }
  });

  const gotoPrev = () => {
    switch (step) {
      case 'confirm_save_spending_limit':
        setStep('edit_spending_limit');
        break;
      case 'confirm_save_funding_limit':
        setStep('edit_funding_limit');
        break;
      case 'confirm_save_pci_dss_limit':
        setStep('edit_pci_dss_limit');
        break;
      case 'edit_pci_dss_limit':
      case 'edit_funding_limit':
      case 'edit_spending_limit':
      default:
        onClose();
        break;
    }
  };

  const getStepProps = () => {
    let stepProps: PartialIModalPropsType;

    const sharedProps: PartialIModalPropsType = {
      close: () => {
        onClose();
      },
      firstButtonAction: gotoPrev,
      secondButtonAction: formik.submitForm,
      secondButtonActionIsTerminal: false,
      secondaryCompletedModal: true,
      secondButtonDisable: !isChecked,
      size: 'lg',
      completedDescription: 'You have successfully made changes to this configuration.',
      description: 'You can edit these product limits by clicking into the input fields.'
    };

    switch (step) {
      case 'edit_pci_dss_limit':
        stepProps = {
          heading:
            category === 'issued-cards' ? 'Edit Limits based on PCI DSS level for Virtual Cards' : 'Edit Card and Transaction Count Limits',
          content: (
            <EditLimitsByPCIDSS
              category={category}
              isReservedCategory={category === 'reserved-cards'}
              onBlur={formik.handleBlur}
              values={formik.values as PCIDSSLevelResponseType}
              errors={formik.errors as FormikErrors<PCIDSSLevelResponseType>}
              touched={formik.touched as FormikTouched<PCIDSSLevelResponseType>}
              setFieldValue={formik.setFieldValue as FormikProps<PCIDSSLevelResponseType>['setFieldValue']}
              handleCheckboxChange={handleCheckboxChange}
            />
          )
        };
        break;
      case 'edit_funding_limit':
        stepProps = {
          heading: 'Edit Card Funding Limit for Virtual Cards',
          content: (
            <EditCardFundingLimits
              onBlur={formik.handleBlur}
              values={formik.values as RiskLevelResponseType}
              currency={currency}
              errors={formik.errors as FormikErrors<RiskLevelResponseType>}
              touched={formik.touched as FormikTouched<RiskLevelResponseType>}
              setFieldValue={formik.setFieldValue as FormikProps<RiskLevelResponseType>['setFieldValue']}
              handleCheckboxChange={handleCheckboxChange}
            />
          )
        };
        break;
      case 'edit_spending_limit':
        stepProps = {
          heading: 'Edit Card Spending Limit for Virtual Cards',
          content: (
            <EditCardSpendingLimits
              onBlur={formik.handleBlur}
              values={formik.values as RiskLevelResponseType}
              currency={currency}
              errors={formik.errors as FormikErrors<RiskLevelResponseType>}
              touched={formik.touched as FormikTouched<RiskLevelResponseType>}
              setFieldValue={formik.setFieldValue as FormikProps<RiskLevelResponseType>['setFieldValue']}
              handleCheckboxChange={handleCheckboxChange}
            />
          )
        };
        break;
      case 'confirm_save_pci_dss_limit':
      case 'confirm_save_funding_limit':
      case 'confirm_save_spending_limit':
      default:
        stepProps = {
          heading: 'Confirm modification',
          description: 'Kindly confirm that you want to apply the modification(s) to the configuration. They will take effect immediately.',
          firstButtonText: 'Back',
          secondButtonText: 'Yes, Confirm',
          secondButtonActionIsTerminal: true,
          size: 'sm'
        };
        break;
    }
    return { ...sharedProps, ...stepProps };
  };

  return <Modal {...(getStepProps() as IModalProps)} />;
};

export default EditVirtualCardLimitsModal;

import React from 'react';
import * as Flags from 'country-flag-icons/react/3x2';

import { CheckboxType } from '+types';

import CustomSwitch from '../CustomSwitch';

const CountrySwitch = ({
  country,
  onChange,
  value,
  disabled
}: {
  country: CheckboxType;
  value: boolean;
  onChange: (country: string) => void;
  disabled?: boolean;
}) => {
  const Flag = Flags[country.value.toUpperCase() as keyof typeof Flags];
  return (
    <div className="d-flex align-items-center country-switch">
      <label className="country-switch__label" htmlFor="customSwitch1">
        <span className="country-switch__flag">{Flag && <Flag data-testid={`flag-${country.value}`} />}</span> {country.label}
      </label>
      <CustomSwitch value={value} id={country.value} onChange={() => onChange(country.value)} disabled={disabled} />
    </div>
  );
};

export default CountrySwitch;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { cloneElement, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import { useGetBanks, useGetBulkActions, useGetCountries, useGetCurrencies, useNotifications, usePageHeader, usePermission } from '+hooks';
import useFeedbackHandler from '+hooks/useFeedbackHandler';
import APIRequest from '+services/api-services';
import setupPusher from '+services/pusher';
import { Storage } from '+services/storage-services';
import FeedbackHandler from '+shared/FeedBackHandler';
import useBulkActionStore from '+store/zustand';
import { history } from '+utils';
import useStore from '+zustandStore';

import AccessDenial from './AccessDenial';
import Notifications from './Notifications';
import { matchDashboardComponents } from './Shared/data/dashboardRoutes';

import defaultUserLogo from '+assets/img/dashboard/default-user-avatar.png';
import logo from '+assets/theme/img/korapay-circled-logo.png';
import kpyLogoWhiteSmall from '+assets/theme/img/kpy-logo-white-small.png';
import NotificationSvg from '+assets/img/dashboard/notification.svg';

import './index.scss';

const authBaseURL = process.env.REACT_APP_MIDDLEWARE_API_BASE;
const authAPIRequest = new APIRequest(authBaseURL);

interface IDashboardComponentProps {
  location: {
    pathname: string;
  };
}
function DashboardComponent({ location }: IDashboardComponentProps) {
  const bulkInfo = useBulkActionStore(state => state.bulkInfo);
  const setBulkInfo = useBulkActionStore(state => state.setBulkInfo);
  const setCompletedAction = useBulkActionStore(state => state.setCompletedAction);
  const homeRoutes = useRef([]);
  const transactionRoutes = useRef([]);
  const toolsRoutes = useRef([]);
  const accountsRoutes = useRef([]);
  const apiRoutes = useRef([]);
  const ungroupedRoutes = useRef([]);
  const urlPath = useLocation();
  const { pathname } = useLocation();

  const heading = usePageHeader();
  const redirectTo = sessionStorage.getItem('redirectExtLink');

  const id = pathname?.split('/').pop();
  const detailsPaths = [
    '/dashboard/payouts/',
    '/dashboard/pay-ins/',
    '/dashboard/disputes/',
    '/dashboard/card-issuance/chargebacks',
    '/dashboard/bulk-payouts/',
    '/dashboard/partners-balance/transaction/'
  ];
  const isDetailsPath = detailsPaths.some(path => pathname.includes(path));

  const { logout } = authAPIRequest;

  useGetBanks(Storage);
  useGetCountries(Storage);
  useGetCurrencies(Storage);
  useGetBulkActions(Storage);
  const {
    profile: { avatar, email }
  } = useStore(state => state);
  const { data = { componentLevel: false, isActive: false } } = useFeedbackHandler();
  const { componentLevel, isActive } = data;
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const {
    showNotification,
    notifications,
    isNotificationUnread,
    notificationCount,
    notificationSettings,
    handleNotificationRefetch,
    handleShowNotification
  } = useNotifications();

  const [routes, router] = usePermission(matchDashboardComponents);

  const getExportDownload = () => {
    const isExportDownload = urlPath.pathname.includes('/dashboard/files');
    if (isExportDownload) {
      const downloadUrl = urlPath.search;
      sessionStorage.setItem('EXPORT_LINK', downloadUrl);
    }
  };

  const isBulkAction = (action, payload) => {
    return (
      action.type === payload.type &&
      action.references.length === payload.references.length &&
      action.references.every(ref => payload.references.includes(ref)) &&
      action.type === 'in_progress'
    );
  };

  useEffect(() => {
    getExportDownload();
  }, []);

  useEffect(() => {
    const URL_CONSTANTS = '/dashboard';
    const urlPaths = {
      payins: 'pay-ins',
      payouts: 'payouts',
      settlements: 'settlements',
      'admin_card-issuance_transaction_history': 'card-issuance',
      balance_history: 'merchants',
      processor_reports: 'reports',
      merchant_account_statement: 'merchants'
    };
    const exportLink = sessionStorage.getItem('EXPORT_LINK');
    const params = new URLSearchParams(exportLink);
    const exportType = params.get('type');
    const path = urlPaths[exportType];
    if (path) {
      history.push(`${URL_CONSTANTS}/${path}`);
    } else if (redirectTo && redirectTo !== '/') {
      history.push(redirectTo);
    }
  }, []);

  useEffect(() => {
    let menuTimer;
    if (!Storage.checkAuthentication()) {
      logout();
    } else {
      // INIT MOBILE MENU TRIGGER BUTTON
      const openMenu = () => {
        $('.menu-mobile .menu-and-user').slideDown(200, 'swing');
        setIsMobileMenuOpen(true);
      };

      const closeMenu = () => {
        $('.menu-mobile .menu-and-user').slideUp(200, 'swing', function () {
          // Prevent menu from disappearing on tablet
          menuTimer = setTimeout(() => {
            $('.menu-mobile .menu-and-user').removeAttr('style');
          }, 300);
        });
        setIsMobileMenuOpen(false);
      };

      $('.mobile-menu-trigger').on('click', () => {
        if (isMobileMenuOpen) {
          closeMenu();
        }
        openMenu();
        return false;
      });

      $('.menu-mobile .menu-and-user li a').on('click', closeMenu);
    }

    return () => {
      clearTimeout(menuTimer);
      $('.mobile-menu-trigger').off();
      $('.menu-mobile .menu-and-user li a').off();
    };
  }, [isMobileMenuOpen]);

  const overflowTabletMenu = () => {
    const overflowPages = ['webhooks'];
    return overflowPages.find(page => location.pathname.includes(page));
  };

  // eslint-disable-next-line consistent-return
  const handleMenuGroup = (menuGroup, groupRoute, route, actualRoute) => {
    if (menuGroup.includes(actualRoute)) {
      if (menuGroup.includes('analytics') || menuGroup.includes('settings')) {
        groupRoute.current.push(route);
        return groupRoute.current?.reverse();
      }
      return groupRoute.current.push(route);
    }
  };

  useLayoutEffect(() => {
    toolsRoutes.current = [];
    transactionRoutes.current = [];
    accountsRoutes.current = [];
    apiRoutes.current = [];
    ungroupedRoutes.current = [];
    homeRoutes.current = [];

    if (routes?.base?.length > 0) {
      const routeBase = routes?.base;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < routeBase.length; i++) {
        const route = routeBase[i];
        const actualRoute = route.props.children.props.to.pathname.replace('/dashboard/', '');
        handleMenuGroup(['home'], homeRoutes, route, actualRoute);
        handleMenuGroup(['analytics', 'merchants'], ungroupedRoutes, route, actualRoute);
        handleMenuGroup(
          ['virtual-accounts', 'card-issuance', 'product-config', 'bulk-payouts', 'partners-balance', 'reports', 'identity'],
          toolsRoutes,
          route,
          actualRoute
        );
        handleMenuGroup(
          ['pay-ins', 'payouts', 'settlements', 'payment-reversals', 'settlement-payouts', 'disputes', 'currency-exchange', 'paused'],
          transactionRoutes,
          route,
          actualRoute
        );
        handleMenuGroup(['audit-logs', 'settings', 'users', 'utilities'], accountsRoutes, route, actualRoute);
        handleMenuGroup(['webhooks'], apiRoutes, route, actualRoute);
      }
    }
  }, [routes]);

  const pusherUpdate = (type, data) => {
    if (type === 'delayed_processes') {
      const delayedProccesslist = [];
      delayedProccesslist.push(data);
      sessionStorage.setItem('delayedProcesses', JSON.stringify(delayedProccesslist));
    } else {
      const payload = { ...data.payload, status: type === 'processing' ? 'in_progress' : 'completed' };
      const mergeBulkInfo = [...(bulkInfo?.data ?? []), payload];
      const bulkInfoData = type === 'processing' ? mergeBulkInfo : mergeBulkInfo.filter(action => !isBulkAction(action, payload));
      setBulkInfo({ ...bulkInfo, data: bulkInfoData });
      if (type === 'completed') setCompletedAction(payload.type);
    }
  };

  useEffect(() => {
    setupPusher('bulk_actions', 'start_processing', 'finish_processing', 'delayed_processes', pusherUpdate);
  }, []);

  return (
    <main className="menu-position-side menu-side-left full-screen with-content-panel" data-testid="main-menu">
      <div className="all-wrapper with-side-panel solid-bg-all">
        <div className="layout-w">
          <div className={`menu-mobile menu-activated-on-click color-scheme-dark ${overflowTabletMenu() ? 'overflow' : ''}`}>
            <div className="mm-logo-buttons-w">
              <a className="mm-logo" href="/">
                <img src={kpyLogoWhiteSmall} alt="korapay logo" />
                <span>Dashboard</span>
              </a>
              <div className="mm-buttons">
                {/**  ------------------- START - User avatar and menu in secondary top menu  ------------------- */}

                {/**  ------------------- END - User avatar and menu in secondary top menu  ------------------- */}
                <div className="mobile-menu-trigger">
                  <div className="os-icon os-icon-hamburger-menu-1" />
                </div>
              </div>
            </div>
            <div className="menu-and-user">
              {/* <!---------------- START - Mobile Menu List -------------------- */}
              <ul className="main-menu">
                {homeRoutes.current?.map((toolsRoute, key) => cloneElement(toolsRoute, { key }))}
                {ungroupedRoutes.current?.map((toolsRoute, key) => cloneElement(toolsRoute, { key }))}

                {toolsRoutes.current?.map((toolsRoute, key) => cloneElement(toolsRoute, { key }))}

                {transactionRoutes.current?.map((transactionRoute, key) => cloneElement(transactionRoute, { key }))}

                {apiRoutes.current?.map((apiRoute, key) => cloneElement(apiRoute, { key }))}

                {accountsRoutes.current?.map((accountsRoute, key) => cloneElement(accountsRoute, { key }))}
              </ul>
              {/* <!---------------- END - Mobile Menu List -------------------- */}
            </div>
          </div>
          {/** ------------------- START - Main Menu ------------------ */}
          <div className="menu-w color-scheme-light color-style-transparent menu-position-side menu-side-left menu-layout-compact sub-menu-style-over sub-menu-color-bright selected-menu-color-light menu-activated-on-hover menu-has-selected-link">
            <div className="logged-user-w avatar-inline">
              <div className="logged-user-i">
                <div className="avatar-w">
                  <img alt="" src={logo} />
                </div>
                <div className="logged-user-info-w">
                  <div className="logged-user-name">Korapay Technologies</div>
                  <div className="logged-user-role">Admin</div>
                </div>
              </div>
            </div>
            <ul className="main-menu">
              {homeRoutes.current?.map((toolsRoute, key) => cloneElement(toolsRoute, { key }))}
              {ungroupedRoutes.current?.map((toolsRoute, key) => cloneElement(toolsRoute, { key }))}
              {toolsRoutes.current.length > 0 && (
                <li className="sub-header" data-testid="sub-header">
                  <span data-testid="tools">Tools</span>
                </li>
              )}
              {toolsRoutes.current?.map((toolsRoute, key) => cloneElement(toolsRoute, { key }))}
              {transactionRoutes.current.length > 0 && (
                <li className="sub-header" data-testid="sub-header">
                  <span>Transactions</span>
                </li>
              )}
              {transactionRoutes.current?.map((transactionRoute, key) => cloneElement(transactionRoute, { key }))}
              {apiRoutes.current.length > 0 && (
                <li className="sub-header" data-testid="sub-header">
                  <span>APIS</span>
                </li>
              )}
              {apiRoutes.current?.map((apiRoute, key) => cloneElement(apiRoute, { key }))}
              {accountsRoutes.current.length > 0 && (
                <>
                  <li className="sub-header" data-testid="sub-header">
                    <span>Account</span>
                  </li>
                  {accountsRoutes.current?.map((accountsRoute, key) => cloneElement(accountsRoute, { key }))}
                </>
              )}
            </ul>
          </div>
          {/** ------------------- END - Main Menu ------------------ */}

          <div className="content-w" style={{ minHeight: '100vh' }}>
            {/** ------------------- START - TOP BAR ------------------ */}
            <div className="top-bar color-scheme-transparent">
              <div className="page-heading">
                <span className="page-heading-text">{heading}</span>
                {isDetailsPath && <p className="transaction_id">{id?.toUpperCase()}</p>}
              </div>
              {/** ------------------- START - TOP MENU CONTROLS ------------------ */}
              <div className="top-menu-controls">
                <button className="notifications" onClick={() => handleShowNotification(true)} type="button">
                  <div className="notification-container">
                    <img src={NotificationSvg} alt="notification-icon" data-testid="notification-icon" />
                    {isNotificationUnread && <div className="notification-dot" />}
                  </div>
                </button>
                <Notifications
                  isVisible={showNotification}
                  setIsVisible={handleShowNotification}
                  content={notifications}
                  settings={notificationSettings}
                  handleRefetch={handleNotificationRefetch}
                  isLoading={false}
                  notificationCount={notificationCount}
                />
                {/**  ------------------- START - User avatar and menu in secondary top menu  ------------------- */}
                <div className="logged-user-w">
                  <div className="logged-user-i">
                    <div className="avatar-w">
                      <img alt="" src={logo} />
                    </div>
                    <div className="logged-user-menu color-style-bright">
                      <div className="logged-user-avatar-info">
                        <div className="avatar-w">
                          <img alt="" src={avatar || defaultUserLogo} />
                        </div>
                        <div className="logged-user-info-w">
                          <div className="logged-user-role">{email}</div>
                          <div className="logged-user-role">Administrator</div>
                        </div>
                      </div>
                      <div className="bg-icon">
                        <i className="os-icon os-icon-user-male-circle2" />
                      </div>
                      <ul>
                        <li>
                          <a role="button" tabIndex={-42} onClick={() => logout()} onKeyDown={() => logout()}>
                            <i className="os-icon os-icon-signs-11" />
                            <span>Logout</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/**  ------------------- END - User avatar and menu in secondary top menu  ------------------- */}
              </div>
              {/** ------------------- END - TOP MENU CONTROLS ------------------ */}
            </div>
            {/** ------------------- END - TOP BAR ------------------ */}
            {!componentLevel && <FeedbackHandler />}
            {isActive && !componentLevel && (
              <>
                <br />
                <br />
              </>
            )}
            <Switch>
              {router.map((route, key) => cloneElement(route, { key }))}
              <Route path="*">
                <AccessDenial />
              </Route>
            </Switch>
          </div>
        </div>
        <div className="display-type" />
      </div>
    </main>
  );
}

export default React.memo(DashboardComponent);

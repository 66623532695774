import React from 'react';

import { history, identityCountries } from '+utils';

import Sidebar from '.';

export default function IndividualSidebar({ region, id, enabledCountries }: { region: string, id: string, enabledCountries?: string[] }) {
  const getSidebarItems = () => {
    return identityCountries?.map(country => {
      return {
        name: country.label,
        action: () => history.push(`/dashboard/identity/configuration/individual/${id}/individual?region=${country.value}`),
      }
    });
  };
  const getActiveItem = () => identityCountries.find(country => country.value === region)?.label;
  return <Sidebar className={'id-individual-sidebar'} items={getSidebarItems()} activeItem={getActiveItem()} />;
}

import React, { useLayoutEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Route, Switch } from 'react-router-dom';
import { AxiosError } from 'axios';

import { useFeedbackHandler, useSearchQuery, useSetUserAccess } from '+hooks';
import APIRequest from '+services/api-services';
import { IdentityTabsT } from '+types';
import { isAllowed, logError } from '+utils';

import Tabs from './components/Tabs';
import Configuration from './Configuration';
import GeneralHome from './Configuration/ConfigureUseCases/General';
import General from './Configuration/ConfigureUseCases/General/GeneralConfigUsecases';
import Individual from './Configuration/ConfigureUseCases/Individual';
import Merchants from './Merchants';
import MerchantDetails from './Merchants/MerchantDetails';
import Overview from './Overview';
import Requests from './Requests';
import RequestDetails from './Requests/RequestDetails';
import Verification from './Verification';
import VerificationEventDetails from './Verification/VerificationEventDetails';

import './index.scss';

const api = new APIRequest(process.env.REACT_APP_MIDDLEWARE_API_BASE);

export default function Identity() {
  const userAccess = useSetUserAccess();
  const { feedbackInit } = useFeedbackHandler();
  const searchQuery = useSearchQuery();
  const [tabs, setTabs] = useState([{ name: 'Overview', key: 'overview' }]);
  const addTab = (tab: IdentityTabsT) => {
    if (tabs.some(t => t.key === tab.key)) return;
    setTabs(prev => [...prev, tab]);
  };
  const activeTab = searchQuery.value.tab || 'overview';
  const canViewMerchants = isAllowed(userAccess, ['identity_merchants.view']);
  const canViewVerification = isAllowed(userAccess, ['identity_verifications.view']);
  const canViewRequests = isAllowed(userAccess, ['identity_requests.view']);

  useLayoutEffect(() => {
    if (canViewMerchants) {
      addTab({ name: 'Merchants', key: 'merchants' });
    }
    if (canViewVerification) {
      addTab({ name: 'Verifications', key: 'verifications' });
    }
    if (canViewRequests) {
      addTab({ name: 'Requests', key: 'requests' });
    }
    addTab({ name: 'Configuration', key: 'configuration' });
  }, []);

  const { data } = useQuery(`IDENTITY_ACCESS_COUNT`, () => api.getIdentityAccessRequestCount('pending'), {
    onError: (e: AxiosError) => {
      logError(e);
      feedbackInit({
        message: "There has been an error getting this merchant's information",
        type: 'danger'
      });
    },
    enabled: !!canViewRequests
  });

  const requestCount = data?.data?.count || 0;

  return (
    <Switch>
      <Route
        path="/dashboard/identity"
        exact
        render={() => (
          <div className="content-i">
            <div className="content-box">
              <Tabs activeTab={activeTab} tabs={tabs} searchQuery={searchQuery} tabsCount={[{ key: 'requests', count: requestCount }]} />
              <div>
                {activeTab === 'overview' && <Overview />}
                {activeTab === 'merchants' && canViewMerchants && <Merchants />}
                {activeTab === 'verifications' && canViewMerchants && <Verification />}
                {activeTab === 'requests' && canViewRequests && <Requests />}
                {activeTab === 'configuration' && <Configuration />}
              </div>
            </div>
          </div>
        )}
      />
      <Route path="/dashboard/identity/merchants/:id" component={MerchantDetails} />
      <Route path="/dashboard/identity/verifications/:id" component={VerificationEventDetails} />
      <Route path="/dashboard/identity/requests/:id" component={RequestDetails} />
      <Route path="/dashboard/identity/configuration/general/:category" component={General} />
      <Route path="/dashboard/identity/configuration/general" component={GeneralHome} />
      <Route path="/dashboard/identity/configuration/individual/:id" component={Individual} />
    </Switch>
  );
}

import React from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import HeroSection from '+containers/Dashboard/Identity/components/HeroSection';
import PageBackButton from '+containers/Dashboard/Identity/components/PageBackButton';
import Tabs from '+containers/Dashboard/Identity/components/Tabs';
import { useSearchQuery } from '+hooks';
import APIRequest from '+services/api-services';
import { history } from '+utils';

import './index.scss';

const api = new APIRequest(process.env.REACT_APP_MIDDLEWARE_API_BASE);

const tabs = [
  { name: 'Nigeria', key: 'ng' },
  { name: 'Ghana', key: 'gh' },
  { name: 'Kenya', key: 'ke' },
  { name: 'South Africa', key: 'za' }
];

const categories = [
  {
    name: 'For Individuals (KYC)',
    description: "Manage how merchants verify individual customers' identities, ensuring secure and compliant verification processes.",
    key: 'kyc'
  },
  {
    name: 'For Businesses (KYB)',
    description: 'Manage how merchants verify the identities of businesses, streamlining compliance and risk management workflows.',
    disabled: true,
    key: 'kyb'
  }
];

function Category({
  name,
  description,
  disabled,
  categoryKey,
  region
}: {
  name: string;
  description: string;
  disabled?: boolean;
  categoryKey: string;
  region: string;
}) {
  return (
    <div className={`id-category ${disabled ? 'disabled' : ''}`} aria-disabled={disabled}>
      <h3 className="id-category__header">{name}</h3>
      <p className={`id-category__desc ${disabled ? 'font-italic' : ''}`}>
        {disabled
          ? `${categoryKey?.toUpperCase()} verification is currently unavailable
for this country.`
          : description}
      </p>
      {!disabled && (
        <Link className="btn id-category__btn" to={`/dashboard/identity/configuration/general/${categoryKey}?region=${region}`}>
          <i className="os-icon os-icon-settings" /> Configure Use Case
        </Link>
      )}
    </div>
  );
}

function CategoryList({ region, idTypeAccessStatus }: { region: string; idTypeAccessStatus: { kyb: boolean; kyc: boolean } }) {
  return (
    <div className="row">
      <div className="col-sm-12 id-categories">
        {categories.map(category => (
          <Category
            name={category.name}
            description={category.description}
            disabled={idTypeAccessStatus[category.key as keyof typeof idTypeAccessStatus] === undefined}
            categoryKey={category.key}
            region={region}
          />
        ))}
      </div>
    </div>
  );
}

export default function GeneralHome() {
  const searchQuery = useSearchQuery();
  const activeTab = searchQuery.value.tab || 'ng';
  const getActiveTabName = () => tabs.find(tab => tab.key === activeTab)?.name || '';
  const {
    data: merchantFees,
    isFetching: isFetchingMerchantFees,
    refetch: refetchMerchantFees
  } = useQuery(`IDENTITY_DEFAULT_FEES`, () => api.getIdentityDefaultFees(), {});

  const idTypeAccessStatus = {
    kyb: merchantFees?.data?.setting?.country?.[activeTab]?.access?.kyb?.enabled,
    kyc: merchantFees?.data?.setting?.country?.[activeTab]?.access?.kyc?.enabled
  };

  return (
    <div className="icu">
      <div className="content-i">
        <div className="content-box">
          <PageBackButton onClick={() => history.push('/dashboard/identity?tab=configuration')} label="Back to configuration" />
          <Tabs activeTab={activeTab} tabs={tabs} searchQuery={searchQuery} />
          <HeroSection
            title={`Manage Identity Service for ${getActiveTabName()}`}
            description={`You can configure how the Identity service works for you. Set which use cases are available in ${getActiveTabName()} for individual and business verification checks.`}
          />
          <CategoryList region={activeTab} idTypeAccessStatus={idTypeAccessStatus} />
        </div>
      </div>
    </div>
  );
}

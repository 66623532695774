import React from 'react';

import FieldErrorMsg from '+containers/Dashboard/Shared/FormError';
import { ILimitsByPCIDSS } from '+types';
import { formatWithCommas, stripNonNumeric } from '+utils';

import { pciDSSLevelMap } from '../../helpers/holderDetailsHelpers';

const LimitsByPCIDSS = ({ onBlur, errors, values, touched, setFieldValue, getFieldProps }: ILimitsByPCIDSS) => {
  return (
    <div className="fade-in">
      <fieldset className="mt-0">
        <div>
          <label className="mt-0" htmlFor="mpcil">
            Merchant&apos;s PCI Level
          </label>
          <select id="mpcil" {...getFieldProps('pciDssLevel')} className="form-control" disabled>
            {Object.entries(pciDSSLevelMap).map(([key, value]) => (
              <option key={key} value={key}>
                {value}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label htmlFor="tcl">Transaction Count limit</label>
          <input
            id="tcl"
            className="form-control"
            data-state={touched.yearly_transaction_count && errors.yearly_transaction_count ? 'invalid' : ''}
            aria-labelledby="err-tcl"
            name="yearly_transaction_count"
            value={formatWithCommas(values.yearly_transaction_count)}
            onChange={e => setFieldValue('yearly_transaction_count', stripNonNumeric(e.target.value))}
            onBlur={onBlur}
          />
          <FieldErrorMsg touched={Boolean(touched.yearly_transaction_count)} error={errors.yearly_transaction_count ?? ''} id="tcl" />
        </div>

        <div>
          <label htmlFor="nic">No. of Issuable Cards</label>
          <input
            id="nic"
            className="form-control"
            data-state={touched.yearly_issued_cards && errors.yearly_issued_cards ? 'invalid' : ''}
            aria-labelledby="err-nic"
            name="yearly_issued_cards"
            value={formatWithCommas(values.yearly_issued_cards)}
            onChange={e => setFieldValue('yearly_issued_cards', stripNonNumeric(e.target.value))}
            onBlur={onBlur}
          />
          <FieldErrorMsg touched={Boolean(touched.yearly_issued_cards)} error={errors.yearly_issued_cards ?? ''} id="nic" />
        </div>
      </fieldset>

      <div className="disclaimer mt-3">Note: Modifications you make here will be applied immediately after you save them.</div>
    </div>
  );
};

export default LimitsByPCIDSS;

import React from 'react';

import { useGetWhiteListeIP } from '+hooks';
import Table from '+shared/Table';

import whiteListedIPAddresses from './whiteListedIpHelper';

const WhiteListedIP = () => {
  const { isLoading, data } = useGetWhiteListeIP();
  return (
    <div className="nav-content active">
      <div className="element-box">
        <div className="element-box-heading" style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '2rem' }}>
          <div className="heading-box-mmd">
            <h4 className="form-header">Whitelisted IP History</h4>
            <div className="ip-form-desc-text no-underline mb-2">
              View history of all IP addresses that have been added merchant whitelist.
            </div>
          </div>
        </div>
        <div className="element-box-tp">
          <Table
            annotation="Whitelisted IPs"
            renderFields
            data={data}
            loading={isLoading}
            className={whiteListedIPAddresses.className}
            rowKey={whiteListedIPAddresses.rowKey}
            tableHeadings={['IPs', 'Description', 'Date and Time']}
            filterHasAdvancedFilter={false}
            filterShowExport={false}
            showDateFilter={false}
            tableWrapperClassName="table-responsive table-wrapper general-merchant-table-wrapper"
            type="whitelisted-ip"
            filterKeywordPlaceholder="Search IP"
            emptyStateHeading={whiteListedIPAddresses.emptyStateHeading}
            emptyStateMessage={whiteListedIPAddresses.emptyStateMessage}
            totalItems={data.length}
          >
            {whiteListedIPAddresses.fields}
          </Table>
        </div>
      </div>
    </div>
  );
};

export default WhiteListedIP;

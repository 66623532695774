import React from 'react';

import PayoutReversalsTab from '../../../PayOut/components/PayoutReversals';
import ChargebacksTab from './ChargebacksTab';
import RefundsTab from './RefundsTab';
import ReversalsTab from './ReversalsTab';

import InfoIcon from '+assets/img/dashboard/information-button.svg';

type TReversalTabs = {
  activeDisputeTab: string;
  setActiveDisputeTab: any;
  setModalOpen: any;
  reversalsControls: any;
  currency: any;
  loadingDisputes: any;
  refetchDisputes: any;
  payoutReversal: any;
  refetchTransaction: any;
  data: any;
};

const ReversalTabs = ({
  activeDisputeTab,
  setModalOpen,
  reversalsControls,
  setActiveDisputeTab,
  currency,
  loadingDisputes,
  refetchDisputes,
  payoutReversal,
  refetchTransaction,
  data
}: TReversalTabs) => {
  const sectionTabs = [
    { label: 'Refunds', key: 'refunds' },
    { label: 'Reversals', key: 'reversals' },
    { label: 'Chargebacks', key: 'chargebacks' }
  ];
  return (
    <li>
      <p className="section-heading refund-heading mt-5" id="section-title">
        Refunds, Reversals & Chargebacks
        <button type="button" onClick={() => setModalOpen(true)}>
          Learn more
          <img src={InfoIcon} alt="info" />
        </button>
      </p>
      <div className="rversal-rfund-cback">
        <div className="reversals-tabs-list" id="reversals-tabs-list">
          <div className="os-tabs-controls os-tabs-complex">
            <ul className="nav nav-tabs">
              {sectionTabs.map(({ label, key }) => {
                const count = reversalsControls.data?.[key]?.length;
                return (
                  <li className={`nav-item ${key === activeDisputeTab && 'active'}`} key={key}>
                    <button
                      id="tab"
                      type="button"
                      aria-expanded="false"
                      className={key === activeDisputeTab ? 'nav-link active' : 'nav-link'}
                      data-toggle="tab"
                      onClick={() => setActiveDisputeTab(key)}
                    >
                      <div>
                        <span className="tab-label">{label}</span>
                        {count ? <span style={{ marginLeft: '3px', fontWeight: '400' }}>({count})</span> : null}
                      </div>
                      <span className={`tab-icon ${key === activeDisputeTab && 'active'}`} />
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <span className="render-tabs">
          {activeDisputeTab === 'refunds' && (
            <RefundsTab
              data={reversalsControls.data?.refunds || []}
              currency={currency}
              isLoading={loadingDisputes}
              isError={reversalsControls.isReversalError}
              refetch={refetchDisputes}
            />
          )}
          {activeDisputeTab === 'reversals' && !payoutReversal && (
            <ReversalsTab
              data={reversalsControls.data?.reversals || []}
              currency={currency}
              isLoading={loadingDisputes}
              isError={reversalsControls.isReversalError}
              refetch={refetchDisputes}
            />
          )}
          {activeDisputeTab === 'chargebacks' && (
            <ChargebacksTab
              data={reversalsControls.data?.chargebacks || []}
              currency={currency}
              isLoading={loadingDisputes}
              isError={reversalsControls.isReversalError}
              refetch={refetchDisputes}
            />
          )}
          {activeDisputeTab === 'reversals' && payoutReversal && (
            <PayoutReversalsTab
              reversals={reversalsControls.data?.reversals || []}
              refetchTransaction={refetchTransaction}
              transaction={data}
            />
          )}
        </span>
      </div>
    </li>
  );
};

export default ReversalTabs;

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Fragment, ReactElement, useEffect, useReducer, useState } from 'react';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { useGetRoles, useSearchQuery, useSetUserAccess } from '+hooks';
import { CurrencyType, FileFormatType, filterButton, IDateRange, moduleType, ParamType } from '+types';
import {
  capitalizeFirst,
  capitalizeRemovedash,
  cleanInput,
  durationMonth,
  filteredOutObjectProperty,
  getPresentDate,
  isAllowed,
  lowercaseRemovedash
} from '+utils';

import AdvanceExportModal from './AdvanceExportModal';
import AdvanceFilterModal from './AdvanceFilterModal';
import {
  AccessStatusOptions,
  configOptions,
  filterData,
  FilterTypes,
  getActionButton,
  options,
  paymentMethods,
  rightOptions
} from './data/Filter';
import SecondaryFilter from './SecondaryFilter';

import arrowRight from '+assets/img/dashboard/arrow-right.svg';
import calendar from '+assets/img/dashboard/calendar.svg';
import search from '+assets/img/dashboard/search-thin.svg';

import 'react-datepicker/dist/react-datepicker.css';
import './index.scss';

dayjs.extend(customParseFormat);

/**
 * @description
 * This is a filter component that makes use of useSearchQuery to set filtered
 * queries to the URL
 *
 *
 * @remarks
 * The handleFilterQuery is now optional, you only need it when you want to get
 * filter queries without setting them to the URL
 */

const defaultState = {
  isFiltered: false,
  firstload: true,
  openExport: false,
  clear: false,
  openFilter: false,
  showLoadLastQuery: false,
  dateFrom: '',
  dateTo: '',
  disableDatePicker: false,
  keyword: '',
  reference: '',
  settlementReference: '',
  settlementPayoutReference: '',
  amount: '',
  roleId: '',
  roleCategory: '',
  paymentMethod: '',
  selectedConfig: '',
  currencyAccessStatus: '',
  selectedRequestTypes: '',
  status: '',
  dateCreated: '',
  tier: '',
  accountType: '',
  merchantId: '',
  tierLevelId: ''
};

const showClearButtonKeys = [
  'status',
  'reference',
  'keyword',
  'dateFrom',
  'dateTo',
  'amount',
  'roleId',
  'roleCategory',
  'paymentMethod',
  'amount',
  'selectedConfig',
  'currencyAccessStatus',
  'merchantId',
  'dateCreatedTo',
  'dateCreatedFrom',
  'cardHolderName',
  'transactionId',
  'cardNumberEnding',
  'chargebackId',
  'merchant',
  'customerName',
  'customerEmail',
  'processorReference',
  'transactionId',
  'transactionReference',
  'phoneNumber',
  'mobileNumber',
  'mobileNetwork',
  'accountNumber',
  'accountName',
  'stan',
  'lastFourDigits',
  'processor',
  'payment_method',
  'channel',
  'dateCompletedFrom',
  'dateCompletedTo',
  'uniqueReferenceSubfilter',
  'uniqueReference',
  'type',
  'cardAcceptorName',
  'cardHolderName',
  'datePaidFrom',
  'datePaidTo',
  'settledTo',
  'settlementCategory',
  'merchantName',
  'expectedSettlementDateFrom',
  'expectedSettlementDateTo',
  'settlementMethod',
  'amountCollected',
  'amountSettled',
  'tierLevelId',
  'initiator_email',
  'processor_reference',
  'session_id',
  'limit',
  'email'
];

const hideClearBtnKeys = ['paused-payments'];
const secondaryFilterModules = ['pay-in', 'pay-outs', 'partners_balance', 'paused-payments', 'merchant-team-members'];

interface IFilterProps {
  defaultStatus?: string;
  activeCurrency: string;
  handleFilterQuery?: (data: ParamType) => void;
  totalCount?: number;
  annotation?: string;
  hasAdvancedFilter?: boolean;
  filterName?: string;
  amountPlaceholder?: string;
  keywordPlaceholder?: string;
  QueryIDPlaceholder?: string;
  showDateFilter?: boolean;
  filterOptions?: { label: string; value: string | number }[];
  type: moduleType;
  hasBasicFilter: boolean;
  showExport?: boolean;
  exportAction?: (format: FileFormatType, close: () => void, fieldsToExport: string | string[]) => void;
  dateRange?: boolean;
  defaultExport?: boolean;
  moreFilterBtn?: ReactElement;
  loading?: boolean;
  exportModalHeaderBottomBorder?: boolean;
  exportModalScrollable?: boolean;
}

const MIN_DATE = new Date('01-01-2016');
const MAX_DATE = new Date();

const defaultDateFrom = getPresentDate().dateFrom;
const defaultDateTo = getPresentDate().dateTo;

function FilterComponent({
  type,
  exportAction,
  handleFilterQuery,
  keywordPlaceholder = '',
  QueryIDPlaceholder = '',
  totalCount = 0,
  defaultStatus = '',
  showExport = true,
  activeCurrency,
  hasAdvancedFilter = true,
  filterName,
  amountPlaceholder = '',
  annotation = 'Transaction(s)',
  dateRange = false,
  defaultExport = true,
  showDateFilter = true,
  hasBasicFilter = true,
  filterOptions = [],
  loading = false,
  moreFilterBtn,
  exportModalHeaderBottomBorder = false,
  exportModalScrollable = false
}: IFilterProps): JSX.Element {
  const userAccess = useSetUserAccess();
  const searchQuery = useSearchQuery();
  const { roleData } = useGetRoles(type);
  const count = searchQuery.value.totalItems ?? totalCount;
  const [isDisabled, setIsDisabled] = useState(false);

  const doesNotUseSearchQuery = ![
    'settlements',
    'users',
    'roles',
    'invited_users',
    'reversals',
    'refunds',
    'issued-cards',
    'cards-transactions',
    'card-details-transactions',
    'issuance-chargebacks',
    'fixed-virtual-accounts',
    'account-holders',
    'upgrade-requests',
    'chargebacks',
    'settlement',
    'product-config',
    'balance_funding',
    'issuing-merchants',
    'issuing_history',
    'bulk-payouts',
    'bulk-transactions',
    'billing_history',
    'verification-events',
    'identity-merchants',
    'identity-requests',
    'identity-billing',
    'merchant-team-members',
    'product-config-access-requesters',
    'whitelisted-ip'
  ].includes(type);
  const hideFilterForm = !['merchant-balance-history', 'settlements-details'].includes(type);

  const [state, setState] = useReducer(
    (prev: typeof defaultState, next: Partial<typeof defaultState>): typeof defaultState => {
      if (next?.clear) {
        searchQuery.clearAll(['tab', 'subTab', 'kycTab', 'currency', 'activeCurrency']);
        return { ...defaultState, clear: true };
      }
      return { ...prev, ...next, clear: false };
    },
    { ...defaultState, status: defaultStatus || '' }
  );
  const actionButtons = getActionButton(type, annotation, setState, hasAdvancedFilter, showExport);

  const setDefaultDate = () => {
    if (type) {
      if (['settlements-payouts'].includes(type)) {
        setState({ dateTo: defaultDateTo, dateFrom: defaultDateFrom });
      }
      if (['settlements'].includes(type)) {
        setState({ dateTo: durationMonth(3)[1], dateFrom: durationMonth(3)[0] });
      }
    }
  };

  useEffect(() => {
    setDefaultDate();
  }, [type]);

  const switchID = () => {
    switch (type) {
      case 'users':
      case 'merchant':
      case 'invited_users':
      case 'roles':
      case 'payment-preferences':
        return 'merchantId';
      case 'settlements':
        return 'settlementReference';
      case 'settlements-payouts':
        return 'settlementPayoutReference';
      case 'cards-transactions':
        return 'transactionId';
      default:
        return 'reference';
    }
  };

  const rolesOptions = {
    users: roleData,
    roles: [
      { value: '', label: 'Select a role' },
      { value: 'system', label: 'System Role' },
      { value: 'custom', label: 'Custom Role' }
    ]
  };

  const getFilterName = () => {
    if (filterName && type.includes('product-config')) return `${count} ${filterName}`;
    if (type === 'merchant-balance-history') return `All transactions (${count})`;
    if (type === 'partners_balance') return `All ${annotation} (${count})`;
    if (filterName) return `${filterName}`;
    return `${count} ${getTotalText()} ${state.isFiltered ? '(filtered results)' : ''}`;
  };
  const getTotalText = (): string => {
    if (['pay-ins', 'pay-outs', 'refunds', 'vba-transactions'].includes(type)) return 'Transactions';
    if (['users', 'invited_users'].includes(type)) return 'Users';
    if (['whitelisted-ip'].includes(type)) return 'WhiteListed IPs';
    return capitalizeFirst(annotation);
  };

  useEffect(() => {
    const filterKeys = Object.keys(searchQuery.value);
    const showKeys = !hideClearBtnKeys.includes(filterName || '') && showClearButtonKeys.some(key => filterKeys.indexOf(key) !== -1);
    if (filterKeys.length > 0 && showKeys) {
      setState({ isFiltered: true, ...searchQuery.value });
    } else {
      setState({ ...defaultState });
      setDefaultDate();
    }
  }, [searchQuery.value]);

  useEffect(() => {
    if (state.clear || state.isFiltered) {
      setState({ ...defaultState, isFiltered: false, openFilter: false, disableDatePicker: false, status: '' });
      setDefaultDate();
    }
  }, [state.clear]);

  useEffect(() => {
    setIsDisabled(loading || count === 0);
  }, [loading, count]);

  const executeFilter = () => {
    const filteredState = filteredOutObjectProperty(state, [
      'isFiltered',
      'firstload',
      'openExport',
      'openFilter',
      'showLoadLastQuery',
      'disableDatePicker',
      'dateCreated',
      'dateCreatedTo',
      'dateCreatedFrom',
      'dateCreatedRange',
      'clear',
      'tab',
      'selectData',
      'startingAfter',
      'endingBefore',
      'currency'
    ]);

    const finalFilteredState = {
      ...filteredState,
      page: '1',
      startingAfter: '',
      endingBefore: '',
      totalItems: ''
    };

    if (handleFilterQuery) {
      handleFilterQuery(finalFilteredState);
    } else {
      searchQuery.setQuery(finalFilteredState);
    }

    setState({ isFiltered: true, openFilter: false, disableDatePicker: true });
  };

  const isDateRange = !['fixed-virtual-accounts'].includes(type);
  const leftOptions = filterOptions.length === 0 ? rolesOptions : { ...rolesOptions, merchant: filterOptions };

  const handleLeftOption = (value: string) => {
    switch (type) {
      case 'roles':
        setState({ roleCategory: value });
        break;
      case 'merchant':
        setState({ tierLevelId: value });
        break;
      default:
        setState({ roleId: value });
        break;
    }
  };

  const handleLeftOptionValue = () => {
    switch (type) {
      case 'roles':
        return state.roleCategory;
      case 'merchant':
        return state.tierLevelId;
      default:
        return state.roleId;
    }
  };

  return (
    <>
      {showExport && exportAction && (
        <AdvanceExportModal
          close={() => setState({ openExport: false })}
          openExport={state.openExport}
          exportAction={exportAction}
          type={type}
          dateRange={dateRange}
          defaultExport={defaultExport}
          heading={type?.includes('users') ? 'Export Users' : `Export ${capitalizeRemovedash(type)}`}
          description={`Choose how you would like to export these ${type?.includes('users') ? 'users' : capitalizeRemovedash(type)}`}
          activeCurrency={activeCurrency as CurrencyType}
          exportModalHeaderBottomBorder={exportModalHeaderBottomBorder}
          exportModalScrollable={exportModalScrollable}
        />
      )}
      <AdvanceFilterModal
        type={type}
        visible={state.openFilter}
        close={() => setState({ openFilter: false })}
        heading={type?.includes('users') ? 'Filter Users' : `Filter ${capitalizeRemovedash(type)}`}
        description={`Find more ${
          type?.includes('users') ? 'users' : lowercaseRemovedash(type)
        } using any of the following advanced filter controls.`}
        filterData={filterData[type as FilterTypes]}
        loadedData={searchQuery.value}
        activeCurrency={activeCurrency}
        handleFilterQuery={data => searchQuery.setQuery({ ...data } as Record<string, string>, true)}
        queryKeyword={String(state.keyword || searchQuery.value.keyword)}
        setQueryKeyword={value => setState({ keyword: cleanInput(value) })}
        queryAmount={String(state.amount || searchQuery.value.amount)}
        setQueryAmount={value => setState({ amount: value })}
        filterIsCleared={state.clear}
      />

      <div className="basic-filter">
        {/* Filter controls (on Top) */}
        <div className="element-actions-tab mt-0">
          <div>
            <span>{getFilterName()}</span>
            {state.isFiltered && (
              <>
                <span className="divider-sm" />
                <button type="button" onClick={() => setState({ clear: true })}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none" viewBox="0 0 21 20">
                    <path
                      fill="#AABDCE"
                      d="M3.426 2.926c3.902-3.9 10.247-3.9 14.149 0 3.9 3.901 3.9 10.248 0 14.15A9.976 9.976 0 0110.5 20a9.975 9.975 0 01-7.074-2.924c-3.901-3.902-3.901-10.249 0-14.15zM6.374 12.95a.833.833 0 101.179 1.178L10.5 11.18l2.946 2.948a.835.835 0 001.18-1.18l-2.947-2.946 2.947-2.948a.833.833 0 10-1.179-1.179L10.5 8.822 7.553 5.874a.833.833 0 10-1.18 1.18L9.322 10l-2.947 2.948z"
                    />
                  </svg>
                  &nbsp;
                  <span>Clear</span>
                </button>
              </>
            )}
          </div>

          <div>
            {moreFilterBtn}
            {actionButtons.map((button: filterButton, index: number) => (
              <Fragment key={button?.text}>
                <button
                  type="button"
                  hidden={button?.permissionsArray ? !isAllowed(userAccess, button.permissionsArray || []) : !button?.show}
                  onClick={button?.action}
                  data-testid={button?.dataTestId}
                  disabled={isDisabled}
                  className={isDisabled ? 'button-disabled' : ''}
                >
                  {button?.image}
                  &nbsp;
                  <span>{button?.text}</span>
                </button>
                {actionButtons.length > 1 && index !== 1 && <span className="divider-sm" />}
              </Fragment>
            ))}
            {state.showLoadLastQuery && <LoadLastUsedQueryButton setState={setState} />}
          </div>
        </div>
        {/** @dev TODO: Update these sections to be dynamic and read from the data file */}
        {hideFilterForm && (
          <div className="element-search-box filter-section" id="filtertransactions">
            <form
              onSubmit={e => {
                if (e) e.preventDefault();
                executeFilter();
              }}
              onKeyDown={e => e.key === 'Enter' && executeFilter()}
              className={`element-search-content filter-body w-100 ${type === 'paused-payments' ? 'paused-payments-filter-body' : ''}`}
            >
              {Object.keys(options || {}).includes(type) && type !== 'paused-payments' && (
                <div className="form-group filter-object filter-object-ssm">
                  <select
                    aria-label="status"
                    name="type"
                    className="form-control"
                    disabled={type === 'settlements'}
                    onChange={e => setState({ status: e.target.value })}
                    value={state.status}
                    style={{ paddingRight: '30px' }}
                  >
                    {options[type as keyof typeof options].map(item => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              {secondaryFilterModules.includes(type) && hasBasicFilter && (
                <SecondaryFilter
                  selectedDate={{ startDate: state.dateFrom || '', endDate: state.dateTo || '' }}
                  onDateChange={(value: IDateRange) => setState({ dateFrom: value.startDate, dateTo: value.endDate })}
                  handleSearch={(value: Record<string, unknown>) => setState(value)}
                  type={type as Extract<typeof type, 'pay-in' | 'pay-outs' | 'paused-payments'>}
                  state={state}
                />
              )}
              {!secondaryFilterModules.includes(type) && hasBasicFilter && (
                <>
                  {Object.keys(leftOptions).includes(type) && (
                    <div className="form-group filter-object filter-object-ssm">
                      <select
                        aria-label="role"
                        name="type"
                        className="form-control"
                        onChange={e => handleLeftOption(e.target.value)}
                        value={handleLeftOptionValue()}
                      >
                        {leftOptions[type as keyof typeof leftOptions].map(item => (
                          <option key={item.value} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  {doesNotUseSearchQuery && (
                    <div className="form-group filter-object filter-object-ssm">
                      <input
                        aria-label="transaction-id"
                        name="transaction-id"
                        className="form-control"
                        type={['merchant', 'payment-preferences'].includes(type) ? 'number' : 'search'}
                        placeholder={QueryIDPlaceholder}
                        value={String(state[switchID() as keyof typeof defaultState])}
                        onChange={e => setState({ [switchID()]: cleanInput(e.target.value) })}
                      />
                    </div>
                  )}
                  <div
                    className={`form-group position-relative filter-object ${
                      filterOptions.length === 0 ? `filter-object-${type === 'reversals' ? 'xxl' : 'xl'}` : ''
                    } flex-grow-1 w-auto --no-max-width --search-container`}
                  >
                    <img src={search} alt="search icon" aria-hidden aria-label="search" />
                    <input
                      id="search"
                      type="search"
                      className="form-control"
                      placeholder={keywordPlaceholder || 'Search...'}
                      value={state.keyword}
                      onChange={e => setState({ keyword: cleanInput(e.target.value) })}
                    />
                  </div>
                  {Object.keys(rightOptions || {}).includes(type) && (
                    <div className="form-group filter-object filter-object-ssm">
                      <select
                        aria-label="status"
                        name="type"
                        className="form-control"
                        onChange={e => setState({ status: e.target.value })}
                        value={state.status}
                      >
                        {rightOptions[type as keyof typeof rightOptions].map(item => (
                          <option key={item.value} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  {Object.keys(configOptions || {}).includes(type) && (
                    <div className="form-group filter-object filter-object-ssm">
                      <select
                        aria-label="role"
                        name="type"
                        className="form-control"
                        onChange={e => {
                          setState({ selectedConfig: e.target.value });
                        }}
                        value={state.selectedConfig}
                      >
                        {configOptions[type as keyof typeof configOptions].map(item => (
                          <option key={item.value} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  {Object.keys(AccessStatusOptions).includes(type) && (
                    <div className="form-group filter-object filter-object-ssm">
                      <select
                        aria-label="role"
                        name="type"
                        className="form-control"
                        onChange={e => {
                          setState({ currencyAccessStatus: e.target.value });
                        }}
                        value={state.currencyAccessStatus}
                      >
                        {AccessStatusOptions[type as keyof typeof AccessStatusOptions].map(item => (
                          <option key={item.value} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  {type === 'refunds' && (
                    <div className="form-group filter-object w-auto --search-container --no-max-width filter-object-ssm">
                      <img src={search} alt="search icon" aria-hidden aria-label="search" />
                      <input
                        id="amount"
                        type="search"
                        className="form-control"
                        placeholder={amountPlaceholder || 'Search...'}
                        value={state.amount}
                        onChange={e => setState({ amount: e.target.value })}
                      />
                    </div>
                  )}
                  {type === 'settlements' && (
                    <div className="form-group filter-object filter-object-ssm w-auto --no-max-width">
                      <select
                        name="type"
                        aria-label="method"
                        className="form-control"
                        onChange={e => setState({ paymentMethod: e.target.value })}
                        value={state.paymentMethod}
                        style={{ minWidth: '200px' }}
                      >
                        <option value="">Select method</option>
                        {Object.keys(paymentMethods[activeCurrency as keyof typeof paymentMethods] || {})?.map(paymentMethod => (
                          <option key={paymentMethod} value={paymentMethod}>
                            {
                              paymentMethods[activeCurrency as keyof typeof paymentMethods]?.[
                                paymentMethod as keyof (typeof paymentMethods)[keyof typeof paymentMethods]
                              ]
                            }
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  {showDateFilter && (
                    <div
                      className={`form-group filter-object ${isDateRange ? 'd-flex filter-object-sm w-auto' : 'filter-object-ssm'}`}
                      style={{ '--calendar-image': `url(${isDateRange && calendar})` } as React.CSSProperties}
                      id="filtertransactions"
                    >
                      <DatePicker
                        selected={state.dateFrom ? new Date(state.dateFrom) : undefined}
                        dateFormat="dd-MM-yyyy"
                        popperPlacement="bottom-end"
                        className="form-control date-select pl-4  rounded-left"
                        onChange={date => setState({ dateFrom: date ? dayjs(dayjs(date)).format('YYYY-MM-DD') : defaultDateFrom })}
                        placeholderText={isDateRange ? 'From' : 'Date Created'}
                        calendarClassName="custom-datepicker"
                        minDate={MIN_DATE}
                        maxDate={MAX_DATE}
                      />
                      {isDateRange && (
                        <DatePicker
                          selected={state.dateTo ? new Date(state.dateTo) : undefined}
                          dateFormat="dd-MM-yyyy"
                          popperPlacement="bottom-end"
                          className="form-control date-select pl-4 rounded-right"
                          minDate={state.dateFrom ? new Date(state.dateFrom) : undefined}
                          onChange={date => setState({ dateTo: date ? dayjs(dayjs(date)).format('YYYY-MM-DD') : defaultDateTo })}
                          placeholderText="To"
                          calendarClassName="custom-datepicker"
                          maxDate={MAX_DATE}
                        />
                      )}
                    </div>
                  )}
                </>
              )}
              <button
                type="submit"
                aria-label="filter transactions"
                className="filter__button"
                aria-controls={showDateFilter ? 'filtertransactions' : undefined}
              >
                <img src={arrowRight} alt="arrow right icon" aria-hidden />
              </button>
            </form>
          </div>
        )}
      </div>
    </>
  );
}

const LoadLastUsedQueryButton = ({ setState }: { setState: (value: Record<string, boolean>) => void }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="loadquery">
      <button title="reset" onClick={() => setOpen(!open)} type="button" aria-label="reload">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" fill="none" viewBox="0 0 25 24">
          <g clipPath="url(#clip0_5865_20775)">
            <path
              fill="#2376F3"
              fillRule="evenodd"
              d="M12.5 24a12 12 0 100-24.002A12 12 0 0012.5 24zM9.443 9.724a1.333 1.333 0 10-1.886 1.885l4 4a1.333 1.333 0 001.886 0l4-4a1.334 1.334 0 00-1.886-1.885L12.5 12.781 9.443 9.724z"
              clipRule="evenodd"
            />
          </g>
          <defs>
            <clipPath id="clip0_5865_20775">
              <path fill="#fff" d="M0 0H24V24H0z" transform="translate(.5)" />
            </clipPath>
          </defs>
        </svg>
      </button>
      {open && (
        <>
          <div className="loadquery_marker" />
          <ul className="loadquery_container">
            <li
              aria-hidden="true"
              onClick={() => {
                setState({ showLoadLastQuery: false });
                setOpen(false);
              }}
              className="loadquery_container-item"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path fill="#2376F3" d="M12 8v5h5v-2h-3V8h-2z" />
                <path
                  fill="#2376F3"
                  d="M21.292 8.497a9.004 9.004 0 00-6.478-5.314 9.09 9.09 0 00-3.626 0 8.965 8.965 0 00-4.552 2.453A8.963 8.963 0 004 12l.001.025H2L5 16l3-3.975H6.001L6 12a6.957 6.957 0 011.195-3.913 7.066 7.066 0 011.891-1.892 7.003 7.003 0 0110.772 4.391 7.118 7.118 0 010 2.824 6.937 6.937 0 01-1.054 2.503 7.057 7.057 0 01-3.079 2.537 6.976 6.976 0 01-1.313.408 7.117 7.117 0 01-2.823 0 6.956 6.956 0 01-2.501-1.053 7.066 7.066 0 01-1.037-.855l-1.414 1.414A8.984 8.984 0 0013 21a9.05 9.05 0 003.503-.707 9.009 9.009 0 004.789-11.796z"
                />
              </svg>
              &emsp;
              <span style={{ fontSize: 14, cursor: 'pointer' }}>Load last used filter</span>
            </li>
          </ul>
        </>
      )}
    </div>
  );
};

export default FilterComponent;

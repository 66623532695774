import React from 'react';

import { getDate, getTime } from '+utils';

import './index.scss';

const whiteListedIPAddresses = {
  annotation: 'IP addresses',
  className: '--whitelisted-ip-address',
  emptyStateHeading: 'No IP Addresses yet',
  emptyStateMessage: 'There are no IP addresses',
  rowKey: 'ip_address',
  fields: (address: Record<string, unknown>) => ({
    data: {
      IP_address: <span className="">{address?.ip_address as string}</span>,
      description: <span className="text-gray-500">{address?.description as string}</span>,
      created_at: (
        <span style={{ margin: 0, padding: 0 }}>
          {getDate(String(address?.created_at))}&nbsp;<span style={{ color: '#A9AFBC' }}>{getTime(String(address?.created_at))}</span>
        </span>
      )
    }
  })
};

export default whiteListedIPAddresses;

import React, { ChangeEvent } from 'react';
import { FormikErrors, FormikProps, FormikTouched } from 'formik';

import FieldErrorMsg from '+containers/Dashboard/Shared/FormError';
import Icon from '+shared/Icons';
import { CategoryType, PCIDSSLevelResponseType } from '+types';
import { formatWithCommas, stripNonNumeric } from '+utils';

import { pciDssLevelHeadings } from '../../constants/edit-virtual-card-limits-modal';

const EditLimitsByPCIDSS = ({
  values,
  onBlur,
  errors,
  touched,
  setFieldValue,
  handleCheckboxChange,
  isReservedCategory
}: {
  category: CategoryType;
  isReservedCategory: boolean;
  values: PCIDSSLevelResponseType;
  onBlur: React.ChangeEventHandler;
  errors: FormikErrors<PCIDSSLevelResponseType>;
  touched: FormikTouched<PCIDSSLevelResponseType>;
  setFieldValue: FormikProps<PCIDSSLevelResponseType>['setFieldValue'];
  handleCheckboxChange: (e: ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <div className="edit-vc-limits-form">
      <table
        style={{
          ['--num-of-rows' as string]: isReservedCategory ? 2 : 4
        }}
        className="edit-vc-limits-table"
      >
        <thead>
          <tr>
            <th hidden={isReservedCategory}>
              <span className="invisible">placeholder text</span>
            </th>
            <th className="limit-title">
              <label htmlFor="tc">Transaction count</label>
            </th>
            <th className="limit-title">
              <label htmlFor="noic">Number of issuable cards</label>
            </th>
          </tr>
        </thead>

        <tbody className={isReservedCategory ? 'one-col' : 'five-cols'}>
          {pciDssLevelHeadings.map(heading => (
            <tr key={heading.value} hidden={isReservedCategory && heading.value !== 'level_0'}>
              <th scope="col" key={heading.value} hidden={isReservedCategory}>
                Level {heading.label}
              </th>
              <td>
                <span className="sr-only" id={`level-${heading.label}-tc`}>
                  level {heading.label} transaction count
                </span>
                <input
                  id="tc"
                  className="form-control"
                  data-state={`${
                    touched?.[heading.value]?.yearly_transaction_count && errors?.[heading.value]?.yearly_transaction_count ? 'invalid' : ''
                  }`}
                  aria-labelledby={`level-${heading.label}-tc`}
                  name={`${heading.value}.yearly_transaction_count`}
                  value={
                    values?.[heading.value]?.yearly_transaction_count === 'limitless'
                      ? 'limitless'
                      : formatWithCommas(values?.[heading.value]?.yearly_transaction_count)
                  }
                  onChange={e => setFieldValue(`${heading.value}.yearly_transaction_count`, stripNonNumeric(e.target.value))}
                  onBlur={onBlur}
                />
                <FieldErrorMsg
                  error={errors?.[heading.value]?.yearly_transaction_count}
                  id="tc"
                  touched={Boolean(touched?.[heading.value]?.yearly_transaction_count)}
                />
              </td>
              <td>
                <span className="sr-only" id={`level-${heading.label}-noic`}>
                  level {heading.label} issuable cards
                </span>
                <input
                  id="noic"
                  className="form-control"
                  data-state={`${
                    touched?.[heading.value]?.yearly_issued_cards && errors?.[heading.value]?.yearly_issued_cards ? 'invalid' : ''
                  }`}
                  aria-labelledby={`level-${heading.label}-noic`}
                  name={`${heading.value}.yearly_issued_cards`}
                  value={formatWithCommas(values?.[heading.value]?.yearly_issued_cards)}
                  onChange={e => setFieldValue(`${heading.value}.yearly_issued_cards`, stripNonNumeric(e.target.value))}
                  onBlur={onBlur}
                />
                <FieldErrorMsg
                  error={errors?.[heading.value]?.yearly_issued_cards}
                  id="noic"
                  touched={Boolean(touched?.[heading.value]?.yearly_issued_cards)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="confirmation-disclaimer">
        <Icon width={15} height={15} fill="#2376f3" name="infoSolid" />
        <p className="copy">Important: The changes you have made here will affect all merchants under the default configuration</p>
      </div>

      <div className="confirmation-check">
        <label htmlFor="confirm-action">Yes, I understand the implications of this action</label>
        <input id="confirm-action" type="checkbox" onChange={handleCheckboxChange} />
      </div>
    </div>
  );
};

export default EditLimitsByPCIDSS;

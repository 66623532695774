/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import Icon from '+containers/Dashboard/Shared/Icons';
import Typography from '+containers/Dashboard/Shared/Typography';
import { useFeedbackHandler, useSetUserAccess } from '+hooks';
import APIRequest from '+services/api-services';
import Modal, { IModalProps } from '+shared/Modal';
import {
  EditDetailsCardPropsType,
  IError,
  TransactionLimitType,
  updateProductConfigData,
  updateProductConfigDataTypeWithoutEnabled,
  UpdateVbaCountDataType,
  VbaCountsDataType,
  VbaCountType
} from '+types';
import {
  capitalize,
  capitalizeAllCharacters,
  capitalizeRemovedash,
  cleanInput,
  formatAmount,
  isAllowed,
  logError,
  productMapping
} from '+utils';

import { conditionToDisplayBanner, getHeaderLabel } from '../ProductConfigHelper';
import IncreasedVbaCountForm, { TVbaIncreaseFormFields, VBA_LIMIT_MAX_COUNT } from './IncreaseVbaCountForm';
import ProductConfigBanner from './ProductConfigBanner';
import VbaLimitDetails from './VbaLimitDetailsContent';

import './index.scss';

const apiRequest = new APIRequest();
const payinChannels = {
  api: 'API',
  web: 'Dashboard',
  modal: 'Checkout'
} as const;

const payoutChannels = {
  api: 'API',
  web: 'Dashboard'
} as const;

type ModalType = 'transaction_limit' | 'channels' | 'all' | 'vba_count' | null;
type channelsType = keyof typeof payinChannels | keyof typeof payoutChannels;

const MIN_WORD_COUNT = 5;

const limitsLabel = {
  min: 'Minimum limit per transaction',
  max: 'Maximum limit per transaction'
};

const radioLabel = {
  default: 'for only merchants under the default configuration',
  all_merchants: 'for all merchants',
  custom_merchants: 'for only custom merchants'
} as const;

const configurationMode = {
  default: 'default',
  custom_merchants: 'custom',
  all_merchants: 'default and custom'
} as const;

type radioLabelType = keyof typeof radioLabel;
type limitsLabelType = keyof typeof limitsLabel;

const EditDetailsCard = ({
  title,
  content,
  type,
  category,
  merchantId,
  paymentMethod,
  currency,
  disableEdit
}: EditDetailsCardPropsType) => {
  const queryClient = useQueryClient();
  const userAccess = useSetUserAccess();
  const { feedbackInit, closeFeedback } = useFeedbackHandler();
  const [modal, setModal] = useState<ModalType>(null);
  const [selected, setSelected] = useState<radioLabelType | null>(null);
  const [selectedChannels, setSelectedChannels] = useState<string[]>([]);
  const [consent, setConsent] = useState(false);
  const [limits, setLimits] = useState({
    min: 0,
    max: 0
  });
  const [vbaCountsData, setVbaCountsData] = useState<VbaCountsDataType>({
    num: 0,
    reason: ''
  });
  const selectedMode = configurationMode[selected as keyof typeof configurationMode];
  useEffect(() => {
    const max = +limits.max;
    const min = +limits.min;
    if (max <= min) {
      feedbackInit({
        message: 'Minimum transaction limit must be less than maximum transaction limit',
        componentLevel: true,
        type: 'warning',
        isClosable: false
      });
    } else if (max > min) {
      closeFeedback();
    }
  }, [limits.max, limits.min]);

  const isMerchant = merchantId ? `merchant’s` : '';

  const description = {
    channels: `Here you can find the checkout and API products for this ${isMerchant} ${capitalizeRemovedash(
      paymentMethod ?? type
    )} configuration. You can modify these payment channels configuration here.`,
    transaction_limit: `Here you can find the limits for this ${isMerchant} ${capitalizeRemovedash(
      paymentMethod ?? type
    )} configuration. You can modify these limits configuration here.`,
    can_send_to_any_merchant: `Here you can find merchant's config to send payment to other merchants through this product.`,
    vba_count: 'Here you can find the count of all VBAs and how they are been used. You can increase the VBA count here.'
  };

  useEffect(() => {
    if (Array.isArray(content) && content.length > 0 && modal === 'channels') {
      setSelectedChannels(prev => [...prev, ...content]);
    }
  }, [content, modal]);

  useEffect(() => {
    if (!Array.isArray(content) && modal === 'transaction_limit') {
      if ('max' in content && 'min' in content) {
        setLimits({
          min: content.min,
          max: content.max
        });
      }
    }
  }, [modal]);

  const updateProductConfiguration = useMutation((value: updateProductConfigData) => apiRequest.updateProductConfiguration(value), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${currency}_PRODUCT`, merchantId, currency, category]);
      queryClient.invalidateQueries([`${currency}_PRODUCTS`, currency, `${category?.toLowerCase()}`]);
      queryClient.invalidateQueries([`${currency}_PRODUCTS`, currency, `${category.toLowerCase()}`, paymentMethod]);
      queryClient.invalidateQueries([`${currency}_PRODUCTS`, currency]);
      queryClient.invalidateQueries(`${currency}_MERCHANTS`);
    },
    onError: error => {
      logError(error);
      feedbackInit({
        message: `${(error as IError)?.response?.data?.message || "There has been an error updating merchant's configuration"}`,
        type: 'danger',
        componentLevel: true
      });
    }
  });
  const updateVbaCount = useMutation((value: UpdateVbaCountDataType) => apiRequest.updateVbaCountService(value, merchantId || ''), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${currency}_PRODUCT`, merchantId, currency, category]);
      queryClient.invalidateQueries([`${currency}_PRODUCTS`, currency, `${category?.toLowerCase()}`]);
      queryClient.invalidateQueries([`${currency}_PRODUCTS`, currency, `${category.toLowerCase()}`, paymentMethod]);
      queryClient.invalidateQueries([`${currency}_PRODUCTS`, currency]);
      queryClient.invalidateQueries(`${currency}_MERCHANTS`);
    },
    onError: error => {
      logError(error);
      feedbackInit({
        message: `${(error as IError)?.response?.data?.message || 'There has been an error increasing VBA count'}`,
        type: 'danger',
        componentLevel: true
      });
    }
  });

  const allAccessContent = () => {
    return (
      <div className="currency-modal__content">
        <div className="radio_container">
          {['default', 'all_merchants', 'custom_merchants'].map(item => {
            return (
              <label key={item}>
                <input checked={item === selected} type="radio" onChange={() => setSelected(item as radioLabelType)} />
                {`Edit ${radioLabel[item as radioLabelType]}`}
              </label>
            );
          })}
        </div>
      </div>
    );
  };
  const removeChannel = (current: channelsType) => {
    if (category === 'pay-ins') {
      if (['card', 'mobile_money', 'bank_transfer'].includes(paymentMethod)) {
        if (!['api', 'modal'].includes(current)) return true;
      } else if (['pay_with_bank'].includes(paymentMethod)) {
        if (['ZAR'].includes(currency)) {
          if (!['modal', 'api'].includes(current)) return true;
        } else if (!['modal'].includes(current)) return true;
      } else if (['disbursement_wallet'].includes(paymentMethod)) {
        if (!['web'].includes(current)) return true;
      } else if (['virtual_bank_account'].includes(paymentMethod)) {
        if (!['api'].includes(current)) return true;
      }
    } else if (['bank_transfer', 'bank_account', 'bulk_bank_account', 'mobile_money'].includes(paymentMethod)) {
      if (!['api', 'web'].includes(current)) return true;
    } else if (['disbursement_wallet'].includes(paymentMethod)) {
      if (!['web'].includes(current)) return true;
    }
    return false;
  };

  const channels = category === 'payouts' ? payoutChannels : payinChannels;
  const channelContent = () => {
    const handleSelection = (value: channelsType) => {
      if (selectedChannels.includes(value)) {
        setSelectedChannels(selectedChannels.filter(item => item !== value));
      } else {
        setSelectedChannels([...selectedChannels, value]);
      }
    };

    return (
      <div className="currency-modal__content">
        <div className="radio_container --channels">
          {Object.entries(channels).map(([key, value]) => {
            return (
              <label key={key} className="channel-item">
                <input
                  checked={selectedChannels.includes(key)}
                  type="checkbox"
                  onChange={() => handleSelection(key as channelsType)}
                  disabled={removeChannel(key as channelsType)}
                  style={{
                    cursor: removeChannel(key as channelsType) ? 'not-allowed' : 'pointer'
                  }}
                />
                {key === 'api' ? capitalizeAllCharacters(value) : capitalize(value)}
              </label>
            );
          })}
        </div>
        <div className="info-wrapper --channel-info">
          <Icon name="infoRounded" fill="#2376F3" height={16} width={16} />
          <p className="enable">
            <span>Important:</span>{' '}
            {`The changes you have made here will affect ${
              merchantId ? 'this merchant.' : `all merchants under the ${selectedMode} configuration.`
            }`}
          </p>
        </div>
        <label className="prod-consent-wrapper">
          <input checked={consent} type="checkbox" onChange={() => setConsent(!consent)} />
          <span>Yes, I understand the implications of this action</span>
        </label>
      </div>
    );
  };
  const editVbaCountContent = () => {
    const handleVbaLimitFormChange = (value: string, mode: TVbaIncreaseFormFields) => {
      if (mode === 'reason') {
        setVbaCountsData({
          ...vbaCountsData,
          reason: value
        });
      }
      if (mode === 'counts') {
        setVbaCountsData({
          ...vbaCountsData,
          num: Number(value)
        });
      }
    };

    return (
      <IncreasedVbaCountForm
        content={content as VbaCountType}
        vbaCountsData={vbaCountsData}
        handleFormChange={handleVbaLimitFormChange}
        consent={consent}
        setConsent={setConsent}
      />
    );
  };
  const limitsContent = () => {
    const handleChange = (item: limitsLabelType, value: string) => {
      setLimits({
        ...limits,
        [item]: value
      });
    };
    return (
      <div className="currency-modal__content">
        <div className="radio_container --channels">
          {['min', 'max'].map(item => {
            return (
              <div key={item} className="channel-input">
                <span>{limitsLabel[item as limitsLabelType]}</span>
                <input
                  value={limits[item as limitsLabelType]}
                  placeholder={`Enter ${item} value`}
                  type="number"
                  onChange={e => handleChange(item as limitsLabelType, cleanInput(e.target.value) as string)}
                />
              </div>
            );
          })}
        </div>
        <div className="info-wrapper --channel-info">
          <Icon name="infoRounded" fill="#2376F3" height={16} width={16} />
          <p className="enable">
            <span>Important:</span>{' '}
            {`The changes you have made here will affect ${
              merchantId ? 'this merchant.' : `all merchants under the ${selectedMode} configuration`
            }`}
          </p>
        </div>
        <label className="prod-consent-wrapper">
          <input checked={consent} type="checkbox" onChange={() => setConsent(!consent)} />
          <span>Yes, I understand the implications of this action</span>
        </label>
      </div>
    );
  };

  const modalDetails = (types: ModalType) => {
    let contents;
    switch (types) {
      case 'all':
        contents = {
          heading:
            title === 'transaction_limit'
              ? `Edit ‘Limits’ for ${currency} ${capitalizeRemovedash(paymentMethod)}`
              : 'Edit channels product config',
          description: 'Please select an option to continue',
          secondButtonText: 'Continue',
          content: allAccessContent(),
          secondButtonDisable: !selected,
          secondButtonActionIsTerminal: false,
          secondButtonAction: () => {
            if (title === 'transaction_limit') {
              setModal('transaction_limit');
            } else {
              setModal('channels');
            }
          }
        };
        break;
      case 'transaction_limit':
        contents = {
          heading: `Edit ‘Limits’ for ${currency} ${capitalizeRemovedash(paymentMethod)}`,
          description: 'You can edit the limits for this product category by clicking into the input fields. ',
          secondButtonText: 'Confirm & Edit',
          completedDescription: 'You have made changes to the payment channels under this payment category.',
          content: limitsContent(),
          secondButtonDisable: !(limits.max && limits.min && consent) || limits.max <= limits.min,
          secondButtonAction: async () => {
            return updateProductConfiguration.mutateAsync({
              currency,
              payment_type: productMapping[category],
              payment_method: paymentMethod,
              type: selected || 'single_merchant',
              data: {
                enabled: true,
                transaction_limit: {
                  min: limits.min,
                  max: limits.max
                }
              },
              account_id: merchantId
            });
          }
        };
        break;
      case 'channels':
        contents = {
          heading: 'Edit channels product config',
          description: 'Please select the payment channels products you would like to enable under this payment category',
          secondButtonText: 'Confirm & Edit',
          content: channelContent(),
          completedDescription: 'You have made changes to the payment channels under this payment category.',
          secondButtonDisable: !(selectedChannels.length && consent),
          secondButtonAction: async () => {
            return updateProductConfiguration.mutateAsync({
              currency,
              payment_type: productMapping[category],
              payment_method: paymentMethod,
              type: selected || 'single_merchant',
              data: {
                enabled: true,
                channels: selectedChannels
              },
              account_id: merchantId
            });
          }
        };
        break;
      case 'vba_count':
        contents = {
          heading: `Edit VBA Count`,
          description: 'You can edit the number of assigned VBAs for this merchant by clicking into the input fields.',
          secondButtonText: 'Confirm and Update',
          completedDescription: 'You have successfully increased the number of assigned VBAs',
          completedHeading: 'VBA Increased',
          content: editVbaCountContent(),
          secondButtonDisable: !(
            consent &&
            vbaCountsData.num > 0 &&
            vbaCountsData.reason.length > MIN_WORD_COUNT &&
            vbaCountsData.reason.length < VBA_LIMIT_MAX_COUNT
          ),
          secondButtonAction: async () => {
            return updateVbaCount.mutateAsync({
              count: vbaCountsData.num || 0,
              reason: vbaCountsData.reason || '',
              currency
            });
          }
        };
        break;
      default:
        contents = {};
    }

    return {
      close: () => {
        setModal(null);
        setSelected(null);
        setSelectedChannels([]);
        setConsent(false);
        setLimits({
          min: 0,
          max: 0
        });
        setVbaCountsData({
          num: 0,
          reason: ''
        });
      },
      secondaryCompletedModal: true,
      ...contents
    };
  };

  const editDetailsContent = (value: updateProductConfigDataTypeWithoutEnabled) => {
    switch (value) {
      case 'channels':
        return Object.entries(channels).map(([key, values]) => {
          if (removeChannel(key as channelsType)) return null;
          return (
            <div key={key} className="menu">
              <p>{`Payment via ${values}`}:</p>{' '}
              <span>{[...new Set(content as channelsType[])].includes(key as channelsType) ? 'Enabled' : 'Disabled'}</span>
            </div>
          );
        });
      case 'transaction_limit':
        return Object.entries(content as TransactionLimitType).map(([key, values]) => (
          <div key={key} className="menu">
            <p>{limitsLabel[key as limitsLabelType]}:</p> <span>{formatAmount(Number(values))}</span>
          </div>
        ));
      case 'vba_count':
        return <VbaLimitDetails content={content as VbaCountType} />;
      default:
        return null;
    }
  };
  return (
    <>
      <div className="editable-card">
        <Typography variant="h4" className="editable-card__header">
          {getHeaderLabel(title)}
        </Typography>
        {conditionToDisplayBanner(title, content) ? <ProductConfigBanner type="warning" mode={title} /> : null}
        <div className="editable-card__body">
          <div className="first-section">
            <p className="card-title">{description[title]}</p>
            {isAllowed(userAccess, ['transaction_config_details.update']) && disableEdit && (
              <span
                role="button"
                className="card-action"
                onClick={() => setModal((!merchantId ? 'all' : title) as ModalType)}
                onKeyDown={() => setModal((!merchantId ? 'all' : title) as ModalType)}
                tabIndex={0}
              >
                Edit
              </span>
            )}
          </div>
          <div className="second-section">{editDetailsContent(title as updateProductConfigDataTypeWithoutEnabled)}</div>
        </div>
      </div>

      <Modal
        visible={!!modal}
        size="md"
        completedModalSize="base"
        equalFooterBtn
        showCompleteActionText
        {...(modalDetails(modal as ModalType) as IModalProps)}
      />
    </>
  );
};

export default EditDetailsCard;

import React from 'react';

import { ActionButtonType, CurrencyType, IPayOutDetails, SummaryItemType } from '+types';
import {
  capitalize,
  capitalizeFirst,
  capitalizeRemovedash,
  daysfromToday,
  formatAmount,
  getDate,
  getDateAndTime,
  getTime,
  history,
  isAllowed,
  switchBank,
  switchChannel,
  switchCurrency,
  switchStatus,
  truncateString
} from '+utils';

import Copyable from '../../Shared/Copyable';
import Icon from '../../Shared/Icons';
import ToolTip from '../../Shared/Tooltip';

import mobileIconPNG from '+assets/img/dashboard/mobile.png';
import InfoIcon from '+assets/img/dashboard/information-button.svg';
import mobileIconSVG from '+assets/img/dashboard/mobile.svg';

import './index.scss';

interface IGenerateMoreDetailFromProps {
  data: IPayOutDetails['data'];
  triggerReasonForFailureModal: () => void;
  state: {
    clickedTransactionIds?: string[];
  };
  updateTransactionStatusModalState: (state: boolean, data: IPayOutDetails['data']) => void;
  userAccess: { [key: string]: boolean };
}

interface IActionButton {
  data: IPayOutDetails['data'];
  triggerReversalInitiateModal: () => void;
}

export function generateSummaryFrom(data: IPayOutDetails['data']): Array<SummaryItemType> {
  return [
    {
      label: (
        <div className="summary-label-item">
          <span>Net</span>
          <ToolTip type="net_amount" image={InfoIcon} message={<>This is the amount less fees</>} />
        </div>
      ),
      value: `${formatAmount(data?.amount_paid)} ${data?.currency}`
    },
    {
      label: 'Fee',
      value: `${formatAmount(parseFloat(data?.fee || '0') + parseFloat(data?.vat || '0'))} ${data?.currency}`
    },
    {
      label: 'Date Completed',
      value: data?.completed_at ? `${getDate(data.completed_at)}, ${getTime(data.completed_at)}` : 'Not Available'
    },
    {
      label: 'Merchant',
      value: data?.account?.name ? (
        <button type="button" className="merchant-name" onClick={() => history.push(`/dashboard/merchant/${data.merchant_id}`)}>
          {data?.account?.name}
        </button>
      ) : (
        'Not Available'
      )
    },
    {
      label: 'Description',
      value: `${data?.channel === 'web' ? 'Balance Top Up' : data?.narration || 'Not Available'}`
    }
  ];
}

export function getPayoutActionButtons({ data, triggerReversalInitiateModal }: IActionButton): Array<ActionButtonType> {
  return [
    {
      children: data?.payout_reversal?.id ? 'Reversal Initiated' : 'Reverse Payout',
      onClick: triggerReversalInitiateModal,
      variant: 'light-blue',
      disabled: !!data?.payout_reversal,
      iconAfter: <i className="os-icon os-icon-corner-up-left" />
    }
  ];
}

export function generateMoreDetailFrom({
  data,
  triggerReasonForFailureModal,
  state,
  updateTransactionStatusModalState,
  userAccess
}: IGenerateMoreDetailFromProps): Array<SummaryItemType> {
  const authExpiryText = () => {
    const diff = daysfromToday(data?.auth_data?.pre_auth_expiry);

    if (diff.includes('in')) return `${diff.split(' ').slice(1).join(' ').split(',').join(' ')} remaining`;
    if (diff.includes('ago')) return 'Expired';
    return diff;
  };

  return [
    {
      label: 'Status',
      value: (
        <>
          <span
            className={`status-pill smaller ${data?.status === 'requires_auth' ? 'yellow' : switchStatus(data?.status)}`}
            style={{ margin: '0 7px 5px 0' }}
          />
          {capitalize(data?.status === 'requires_auth' ? 'Pending (Requires Authorization)' : data?.status || 'Not Available')}
          {data?.status === 'processing' &&
            ['mobile_money', 'bank_account'].includes(data?.payment_destination_type) &&
            !state.clickedTransactionIds?.includes(data?.processor_reference) &&
            isAllowed(userAccess, ['payouts_status.update']) && (
              <button
                aria-label="settings"
                onClick={e => {
                  e.stopPropagation();
                  updateTransactionStatusModalState(true, data);
                }}
                type="button"
                className="status-update-icon"
              >
                <Icon name="settings" />
              </button>
            )}
          {state.clickedTransactionIds?.includes(data?.processor_reference) && data.status === 'processing' && (
            <ToolTip
              type=""
              message={
                <p>A status update request has been made on this transaction. Please refresh after a few minutes to see new status.</p>
              }
              centered
            >
              <span className="rotate">
                <Icon name="loading" />
              </span>
            </ToolTip>
          )}
        </>
      )
    },
    ...(['expired', 'failed'].includes(data?.status)
      ? [
          {
            label: `Reason for ${data?.status === 'expired' ? 'Expiry' : 'Failure'}`,
            value: (
              <>
                {data?.message}
                <button className="btn btn-link" onClick={triggerReasonForFailureModal}>
                  View all reasons
                </button>
              </>
            )
          }
        ]
      : []),
    {
      label: 'Amount Paid',
      value: `${formatAmount(data?.amount)} ${data?.currency}`
    },
    {
      label: 'Currency Charged',
      value: switchCurrency[data?.currency as keyof typeof switchCurrency] || 'Not Available'
    },
    {
      label: 'Korapay ID',
      value: data?.unique_reference ? (
        <Copyable text={data?.unique_reference} textModifier={text => truncateString(text, 8)} />
      ) : (
        'Not available'
      )
    },
    {
      label: 'Channel',
      value: switchChannel(data?.channel)
    },
    {
      label: 'Transaction Type',
      value: data?.channel === 'web' ? 'Withdrawal' : 'Payout'
    },
    {
      label: 'Processor',
      value: capitalizeRemovedash(data?.processor) || 'Not available'
    },
    {
      label: 'Processor ID',
      value: data?.processor_reference || 'Not available'
    },
    {
      label: 'Fee Bearer',
      value: data?.merchant_bears_cost ? 'Merchant' : 'Customer'
    },
    ...(data?.meta?.receipt ? [{ label: 'RNN', value: <Copyable text={data.meta.receipt} /> }] : []),
    ...(data?.trace_id ? [{ label: 'Trace ID', value: data.trace_id }] : []),
    ...(data?.meta?.stan ? [{ label: 'STAN', value: <Copyable text={data.meta.stan} /> }] : []),

    {
      label: 'Date Created',
      value: data?.created_at ? `${getDate(data?.created_at)}, ${getTime(data?.created_at)}` : 'Not Available'
    },
    {
      label: 'Date Completed',
      value: data?.completed_at ? `${getDate(data?.completed_at)}, ${getTime(data?.completed_at)}` : 'Not Available'
    },
    {
      label: 'Payment Reversals Type',
      value: data?.payment_reversals_type || 'Not Available'
    },
    ...(data?.auth_data?.pre_auth_expiry
      ? [
          {
            label: 'Authorization Expiry',
            value: (
              <>
                `${getDateAndTime(data?.auth_data.pre_auth_expiry)} `
                <i style={{ color: '#A9AFBC', fontStyle: 'italic' }}>({authExpiryText()})</i>
              </>
            )
          }
        ]
      : []),
    {
      label: 'Transaction ID',
      value: <Copyable text={data?.reference} />
    }
  ];
}

export function generateReceipientInfoFrom(data: IPayOutDetails['data']): Array<SummaryItemType> {
  return [
    {
      label: 'Payment Method',
      value: capitalizeRemovedash(data?.destination?.type) || 'Not Available'
    },
    ...(['bank_account', 'wallet'].includes(data?.payment_destination_type)
      ? [
          {
            label: data?.destination?.details?.bank_name ? (
              <div className="detail-item">
                <img id="bank-icon" src={switchBank.default} alt="bank icon" style={{ width: '14px' }} />
                <span style={{ display: 'inline', marginLeft: 8 }}>{capitalize(data?.destination.details.bank_name)}</span>
              </div>
            ) : (
              'Account Number'
            ),
            value: data?.destination?.details?.account_number || 'Not Available'
          }
        ]
      : []),
    ...(data?.destination?.type === 'mobile_money'
      ? [
          {
            label: (
              <span>
                <img id="phone-icon" src={mobileIconSVG} alt="phone icon" srcSet={mobileIconPNG} height={16} style={{ marginRight: 6 }} />
                <span>{data?.destination?.details?.mobile_name || 'Operator Information Unavailable'}</span>
              </span>
            ),
            value: data?.destination?.details?.mobile_number || 'Operator Information Unavailable'
          }
        ]
      : []),
    {
      label: 'Recipient',
      value: capitalize(data?.destination?.details?.account_name || data?.payment?.customer?.name || 'Not Available')
    },
    {
      label: 'Email',
      value: data?.payment?.customer?.email || 'Not provided'
    }
  ];
}

export const summaryGenerators = {
  refunds: (item: any, currency: CurrencyType) => [],
  chargebacks: (item: any, currency: CurrencyType) => [],
  reversals: (item: any, currency: CurrencyType) => [
    { label: 'Reason for Reversal', value: item.reversal_reason || 'Not Available' },
    {
      label: 'Reversal Status',
      value: <span style={{ color: switchStatus(item.status) }}>{capitalizeRemovedash(item.status || 'Not Available')}</span>
    },
    {
      label: 'Date Approved',
      value: item.completed_at ? `${getDate(item.completed_at)}, ${getTime(item.completed_at)}` : '-- | --'
    }
  ]
};

export function generateRemittanceInfoFrom(data: IPayOutDetails['data']): Array<SummaryItemType> {
  return [
    {
      label: 'Sender Name',
      value: capitalize(data?.remittance_data?.sender_name) || 'Not Available'
    },
    {
      label: 'Sender of fund',
      value: capitalizeFirst(data?.remittance_data?.source_of_funds) || 'Not Available'
    },
    {
      label: 'Sender phone number',
      value: data?.remittance_data?.sender_phone_number || 'Not Available'
    },
    ...(data?.remittance_data?.sender_nationality
      ? [
          {
            label: 'Sender Nationality',
            value: data?.remittance_data?.sender_nationality
          }
        ]
      : []),
    ...(data?.remittance_data?.sender_country_iso
      ? [
          {
            label: 'Sender Country ISO',
            value: data?.remittance_data?.sender_country_iso
          }
        ]
      : []),
    ...(data?.remittance_data?.sender_service_provider_name
      ? [
          {
            label: 'Sender Service Provider Name',
            value: data?.remittance_data?.sender_service_provider_name
          }
        ]
      : []),
    ...(data?.remittance_data?.destination_phone_number
      ? [
          {
            label: 'Destination Phone Number',
            value: data?.remittance_data?.destination_phone_number
          }
        ]
      : []),
    ...(data?.remittance_data?.sender_id_type
      ? [
          {
            label: 'Sender ID Type',
            value: capitalize(data?.remittance_data?.sender_id_type)
          }
        ]
      : []),
    ...(data?.remittance_data?.sender_id_number
      ? [
          {
            label: 'Sender ID Number',
            value: data?.remittance_data?.sender_id_number
          }
        ]
      : []),
    ...(data?.remittance_data?.remittance_purpose
      ? [
          {
            label: 'Remittance Purpose',
            value: capitalizeFirst(data?.remittance_data?.remittance_purpose)
          }
        ]
      : []),
    ...(data?.remittance_data?.sender_occupation
      ? [
          {
            label: 'Sender Occupation',
            value: data?.remittance_data?.sender_occupation
          }
        ]
      : [])
  ];
}

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useMutation } from 'react-query';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';

import { CustomDateRangeCalendar } from '+containers/Dashboard/Shared/CustomDateRangeCalendar';
import Icon from '+containers/Dashboard/Shared/Icons';
import { useFeedbackHandler, useReducerState } from '+hooks';
import APIRequest from '+services/api-services';
import Modal, { IModalProps } from '+shared/Modal';
import {
  generateBalanceStatementModalStages,
  GenerateBalanceStatementModalType,
  GenerateBalanceStatementPayLoadType,
  generateBalanceStatementPeriodOptions,
  GenerateBalanceStatementPeriodType,
  IAllDateTimeProps,
  IDateRange,
  IError,
  IGenerateBalanceStatementForm,
  IGenerateBalanceStatementModalProps
} from '+types';
import { capitalizeRemovedash, logError } from '+utils';

import './generateBalanceStatement.scss';
import './index.scss';

const apiRequest = new APIRequest();
const intialSelectedDate: IDateRange = {
  startDate: '',
  endDate: ''
};
dayjs.extend(customParseFormat);
dayjs.extend(utc);
export const GenerateBalanceStatementModal = ({ isVisible, onClose, currency, merchantId }: IGenerateBalanceStatementModalProps) => {
  const { feedbackInit } = useFeedbackHandler();
  const [state, setState] = useReducerState<IGenerateBalanceStatementForm>({
    periodSelected: '',
    isOpen: false,
    modal: generateBalanceStatementModalStages.generate_balance_statement,
    start_date: '',
    end_date: '',
    showCalendarIcon: false
  });
  const generateMerchantStatement = useMutation(
    (payLoad: GenerateBalanceStatementPayLoadType) => apiRequest.generateMerchantBalanceStatement({ merchantId, payLoad }),
    {
      onError: (error: IError) => {
        logError(error);
        feedbackInit({
          message: `${(error as IError)?.response?.data?.message || 'There has been an error generating your account statement.'}`,
          type: 'danger',
          componentLevel: true
        });
      }
    }
  );
  const handleSelect = (value: GenerateBalanceStatementPeriodType) => {
    if (value === 'custom_range') {
      setState({
        periodSelected: capitalizeRemovedash(value),
        isOpen: false,
        modal: generateBalanceStatementModalStages.custom_date_range,
        showCalendarIcon: true
      });
    } else {
      setState({
        periodSelected: capitalizeRemovedash(value),
        isOpen: false,
        start_date: '',
        end_date: '',
        showCalendarIcon: false
      });
    }
  };
  const onDateTimeRangeFormat = (data: IAllDateTimeProps) => {
    if (data && data.startDate && data.endDate) {
      const formatDateTime = `${dayjs(`${data.startDate}`).format('DD/MM/YYYY')} - ${dayjs(`${data.endDate} `).format('DD/MM/YYYY')}`;
      setState({
        periodSelected: formatDateTime,
        isOpen: false,
        start_date: data.startDate,
        end_date: data.endDate
      });
    }
  };
  const formatStatementPeriod = (dateOption: string): GenerateBalanceStatementPayLoadType | undefined => {
    const currentDate = dayjs().format('YYYY-MM-DD');
    const formatedDateObj = {
      dateFrom: '',
      dateTo: '',
      currency
    };
    switch (dateOption) {
      case 'Last 7 Days':
        formatedDateObj['dateFrom'] = dayjs().subtract(7, 'day').format('YYYY-MM-DD');
        formatedDateObj['dateTo'] = currentDate;
        return formatedDateObj;
      case 'Last 1 Month':
        formatedDateObj['dateFrom'] = dayjs().subtract(1, 'month').format('YYYY-MM-DD');
        formatedDateObj['dateTo'] = currentDate;
        return formatedDateObj;
      case 'Last 3 Months':
        formatedDateObj['dateFrom'] = dayjs().subtract(3, 'month').format('YYYY-MM-DD');
        formatedDateObj['dateTo'] = currentDate;
        return formatedDateObj;
      default:
        formatedDateObj['dateFrom'] = state.start_date;
        formatedDateObj['dateTo'] = state.end_date;
        return formatedDateObj;
    }
  };
  const generateBalanceStatementContent = () => {
    return (
      <>
        <label htmlFor="select-period" className="generate-statment-label">
          Select Period
        </label>
        <div className="form-group generate-statement-dropdown-wrapper">
          <input
            id="select-period"
            type="text"
            value={state.periodSelected}
            placeholder="Select Period"
            readOnly
            aria-haspopup="listbox"
            aria-expanded={state.isOpen ? 'true' : 'false'}
            aria-controls="period-options"
            role="combobox"
            onClick={() => setState({ isOpen: !state.isOpen })}
            className={`balance-dropdown-input ${
              state.showCalendarIcon ? 'generate-balance-padding-with-calendar-icon' : 'generate-balance-padding-without-calendar-icon'
            }`}
          />
          {state.showCalendarIcon && (
            <div className="generate-calendar-icon">
              <Icon name="calendarIcon" aria-hidden="true" />
            </div>
          )}
          <div
            className="generate-dropdown-icon"
            onClick={() => setState({ isOpen: !state.isOpen })}
            role="button"
            aria-label="Toggle dropdown"
            tabIndex={0}
            onKeyDown={e => (e.key === 'Enter' || e.key === ' ') && setState({ isOpen: !state.isOpen })}
          >
            <Icon name="arrowDown" />
          </div>
          {state.isOpen && (
            <ul
              id="period-options"
              className="generate-balance-dropdown-list"
              role="listbox"
              aria-labelledby="select-period"
              data-testid="select-period-dropdown"
            >
              {generateBalanceStatementPeriodOptions.map((option, index) => (
                <li
                  key={option.value}
                  id={`option-${index}`}
                  role="option"
                  tabIndex={0}
                  onClick={() => handleSelect(option.value as GenerateBalanceStatementPeriodType)}
                  onKeyDown={e => (e.key === 'Enter' || e.key === ' ') && handleSelect(option.value as GenerateBalanceStatementPeriodType)}
                  className="generate-balance-dropdown-item"
                  data-testid={`select-option-${index}`}
                >
                  {option.label}
                </li>
              ))}
            </ul>
          )}
        </div>
      </>
    );
  };
  const onDateTimeRangeModalClose = () => {
    setState({
      isOpen: false,
      modal: generateBalanceStatementModalStages.generate_balance_statement
    });
  };
  const modalDetails = (types: GenerateBalanceStatementModalType) => {
    let contents;
    switch (types) {
      case generateBalanceStatementModalStages.generate_balance_statement:
        contents = {
          heading: `Generate Account Statement`,
          description: (
            <p>
              Select a timeframe to generate the statement. You’ll receive an email linked to your <strong>Kora</strong> account.
            </p>
          ),
          secondButtonText: 'Generate',
          completedDescription: 'Account statement generated ! Check your Kora email for the PDF.',
          completedHeading: 'Done',
          content: generateBalanceStatementContent(),
          secondButtonDisable: !state.periodSelected,
          secondButtonAction: async () => {
            const payLoad = formatStatementPeriod(state.periodSelected);
            if (payLoad) {
              return generateMerchantStatement.mutateAsync(payLoad);
            }
          }
        };
        break;
      default:
        contents = {};
    }

    return {
      close: () => {
        onClose();
        setState({
          periodSelected: '',
          isOpen: false,
          start_date: '',
          end_date: '',
          showCalendarIcon: false
        });
      },
      secondaryCompletedModal: true,
      ...contents
    };
  };

  return (
    <>
      <CustomDateRangeCalendar
        visible={state.modal === generateBalanceStatementModalStages.custom_date_range}
        close={onDateTimeRangeModalClose}
        selectedDate={intialSelectedDate}
        setSelectedDateRange={value => {
          if (value && value?.allData) {
            onDateTimeRangeFormat(value.allData);
          }
        }}
      />
      <Modal
        visible={isVisible && state.modal === generateBalanceStatementModalStages.generate_balance_statement}
        size="md"
        completedModalSize="base"
        headerBottomBorder
        showCompleteActionText
        {...(modalDetails(state.modal as GenerateBalanceStatementModalType) as IModalProps)}
      />
    </>
  );
};

import React from 'react';

import RiskIndicator from '+dashboard/ProductConfig/components/RiskIndicator';
import {
  CardIssuanceDefaultLimitsResponseType,
  IssuingMerchantDetailsResponseType,
  MerchantFundingLimitsType,
  MerchantPCIDSSLimitsType,
  MerchantSpendingLimitsType,
  PCIDSSLevelType
} from '+types';
import { formatAmount, formatWithCommas, getDateAndTime } from '+utils';

export const riskLevelMap = {
  low_risk: 'Low Risk',
  medium_risk: 'Medium Risk',
  above_average_risk: 'Semi-high Risk',
  high_risk: 'High Risk'
} as const;

export const pciDSSLevelMap = {
  level_4: 'Level 4',
  level_3: 'Level 3',
  level_2: 'Level 2',
  level_1: 'Level 1',
  level_0: 'Level 0'
} as const;

export const formatPCIDSSLimits = (data: CardIssuanceDefaultLimitsResponseType['customer']['pcidss_level']) => {
  if (!data) return [];
  return Object.keys(data).map(key => ({
    name: key.replace('level_', 'Level '),
    data: [
      formatWithCommas(data[key as PCIDSSLevelType].yearly_transaction_count),
      formatWithCommas(data[key as PCIDSSLevelType].yearly_issued_cards)
    ]
  }));
};

export const formatFundingLimits = (data: CardIssuanceDefaultLimitsResponseType['customer']['risk_level']) => {
  if (!data) return [];
  return [
    {
      name: 'Low risk',
      data: [
        `$${formatAmount(data.low_risk.funding_limit.daily_max)}`,
        `$${formatAmount(data.low_risk.funding_limit.monthly_max)}`,
        `$${formatAmount(data.low_risk.funding_limit.quarterly_max)}`
      ]
    },
    {
      name: 'Medium risk',
      data: [
        `$${formatAmount(data.medium_risk.funding_limit.daily_max)}`,
        `$${formatAmount(data.medium_risk.funding_limit.monthly_max)}`,
        `$${formatAmount(data.medium_risk.funding_limit.quarterly_max)}`
      ]
    },
    {
      name: 'Semi-high risk',
      data: [
        `$${formatAmount(data.above_average_risk.funding_limit.daily_max)}`,
        `$${formatAmount(data.above_average_risk.funding_limit.monthly_max)}`,
        `$${formatAmount(data.above_average_risk.funding_limit.quarterly_max)}`
      ]
    },
    {
      name: 'High risk',
      data: [
        `$${formatAmount(data.high_risk.funding_limit.daily_max)}`,
        `$${formatAmount(data.high_risk.funding_limit.monthly_max)}`,
        `$${formatAmount(data.high_risk.funding_limit.quarterly_max)}`
      ]
    }
  ];
};

export const formatSpendingLimits = (data: CardIssuanceDefaultLimitsResponseType['customer']['risk_level']) => {
  if (!data) return [];
  return [
    {
      name: 'Low risk',
      data: [
        `$${formatAmount(data.low_risk.spending_limit.per_transaction_max)}`,
        `$${formatAmount(data.low_risk.spending_limit.daily_max)}`,
        `$${formatAmount(data.low_risk.spending_limit.monthly_max)}`
      ]
    },
    {
      name: 'Medium risk',
      data: [
        `$${formatAmount(data.medium_risk.spending_limit.per_transaction_max)}`,
        `$${formatAmount(data.medium_risk.spending_limit.daily_max)}`,
        `$${formatAmount(data.medium_risk.spending_limit.monthly_max)}`
      ]
    },
    {
      name: 'Semi-high risk',
      data: [
        `$${formatAmount(data.above_average_risk.spending_limit.per_transaction_max)}`,
        `$${formatAmount(data.above_average_risk.spending_limit.daily_max)}`,
        `$${formatAmount(data.above_average_risk.spending_limit.monthly_max)}`
      ]
    },
    {
      name: 'High risk',
      data: [
        `$${formatAmount(data.high_risk.spending_limit.per_transaction_max)}`,
        `$${formatAmount(data.high_risk.spending_limit.daily_max)}`,
        `$${formatAmount(data.high_risk.spending_limit.monthly_max)}`
      ]
    }
  ];
};

export const formatMerchantPCIDSSLimits = (data: MerchantPCIDSSLimitsType) => {
  if (!data) return [];
  return [formatWithCommas(data.yearly_transaction_count), formatWithCommas(data.yearly_issued_cards)];
};

export const formatMerchantFundingLimits = (data: MerchantFundingLimitsType) => {
  if (!data) return [];
  return [`$${formatAmount(data.daily_max)}`, `$${formatAmount(data.monthly_max)}`, `$${formatAmount(data.quarterly_max)}`];
};

export const formatMerchantSpendingLimits = (data: MerchantSpendingLimitsType) => {
  if (!data) return [];
  return [`$${formatAmount(data.per_transaction_max)}`, `$${formatAmount(data.daily_max)}`, `$${formatAmount(data.monthly_max)}`];
};

export const formatSummary = (data: IssuingMerchantDetailsResponseType) => {
  if (!data) return [];
  return [
    { label: 'Available Card Issuing Balance', value: formatAmount(data.wallet_balance) + ' USD' },
    { label: 'USD Transaction Count', value: data.transactions_count },
    { label: 'PCI DSS Level', value: data.pci_dss_level?.replace('level_', 'Level ') },
    { label: 'Risk Rating', value: <RiskIndicator riskLevel={data.risk_level} /> },
    { label: 'Access Requested On', value: getDateAndTime(data.date_created) }
  ];
};

export const customerCardMonthlyPaymentOptions = [
  { value: 'LessThan50K', label: '$50,000 and Below' },
  { value: 'From50KTo100K', label: 'Between $50,000 - $100,000' },
  { value: 'From100KTo500K', label: 'Between $100,000 - $500,000' },
  { value: 'From500KTo1M', label: 'Between $500,000 - $1,000,000' },
  { value: 'Above1M', label: 'Above $1,000,000' }
];

export const spendingLimitMap = {
  per_transaction_max: 'Maximum limit per transaction (USD)',
  daily_max: 'Daily transaction cap (USD)',
  monthly_max: 'Monthly transaction cap (USD)'
};

export const fundingLimitMap = {
  daily_max: 'Maximum daily funding (USD)',
  monthly_max: 'Maximum monthly funding (USD)',
  quarterly_max: 'Maximum quarterly funding (USD)'
};

export const pcidsssMap = {
  yearly_transaction_count: 'Transaction Count',
  yearly_issued_cards: 'Number of Issuable Cards'
};

import React from 'react';

import Accordion from '+containers/Dashboard/Shared/Accordion';
import CustomCheckbox from '+containers/Dashboard/Shared/CustomCheckbox';
import ToolTip from '+containers/Dashboard/Shared/Tooltip';
import { KAASBillingFeesDataType } from '+types';

import Alert from '../../Alert';
import ConfigurationFee from '../ConfigurationFee';
import ConfigurationFeeItem from '../ConfigurationFeeItem';
import FeesCurrencyInput from '../FeesCurrencyInput';

import InfoIcon from '+assets/img/dashboard/question-mark-gray.svg';

const ConfigurationContent = ({
  vatInclusive,
  setVatInclusive,
  type,
  selectedCurrency,
  setSelectedCurrency,
  configModified,
  resetToDefaultFees,
  feesData,
  handleFeeChange
}: {
  vatInclusive?: boolean;
  setVatInclusive: (e: boolean) => void;
  type: string;
  selectedCurrency: string;
  setSelectedCurrency: (e: string) => void;
  configModified: boolean;
  resetToDefaultFees: () => void;
  feesData: KAASBillingFeesDataType;
  handleFeeChange: (type: 'kyb' | 'kyc', title: string, newFee: number | undefined) => void;
}) => {
  const selfie = feesData?.kyc.find(fee => fee.key === 'selfie_validation');
  return (
    <div>
      {type !== 'merchant' && (
        <div className={`mt-3 ${type !== 'merchant' ? 'mb-2' : 'mb-4'}`}>
          <CustomCheckbox
            className="identity-check"
            text={
              <>
                Apply VAT to fees{' '}
                <ToolTip
                  type="info"
                  message="Check this box if Value Added Tax (VAT) has been considered and included in the fees entered below."
                  image={InfoIcon}
                  className="mb-1"
                />
              </>
            }
            checked={vatInclusive}
            onChange={e => setVatInclusive(e as boolean)}
            disabled={type === 'merchant'}
          />

          {vatInclusive && <span className="icf__vat-info">Current VAT percentage: 7.5%</span>}
        </div>
      )}
      {type !== 'merchant' && <FeesCurrencyInput currency={selectedCurrency} onChange={e => setSelectedCurrency(e)} />}
      {configModified ? (
        <Alert
          text="This configuration has been modified"
          hideIcon
          actionBtn={{
            text: 'Reset to default',
            action: resetToDefaultFees
          }}
          size="sm"
        />
      ) : null}
      <Accordion
        title="Verifications for Individuals (KYC)"
        className="icf__accordion"
        showToggle
        open
        content={
          <div>
            <ConfigurationFee title="ID Lookup" type="KYC">
              {feesData?.kyc
                ?.filter(fee => fee.key !== 'selfie_validation')
                .map(fee => (
                  <ConfigurationFeeItem
                    key={fee.key}
                    title={fee.title}
                    currentFee={fee.currentFee}
                    newFee={fee.newFee}
                    currency={fee.currency}
                    onChangeFee={newFee => handleFeeChange('kyc', fee.title, newFee)}
                    newCurrency={selectedCurrency}
                    modified={fee.modified}
                  />
                ))}
            </ConfigurationFee>
            {selfie && (
              <ConfigurationFee title="Facial Matching" type="KYC">
                <ConfigurationFeeItem
                  key={selfie.key}
                  title={selfie.title}
                  currentFee={selfie.currentFee}
                  newFee={selfie.newFee}
                  currency={selfie.currency}
                  onChangeFee={newFee => handleFeeChange('kyc', selfie.title, newFee)}
                  newCurrency={selectedCurrency}
                  modified={selfie.modified}
                  tooltip="This fee covers the cost of validating a supported ID for facial matching purposes."
                />
              </ConfigurationFee>
            )}
          </div>
        }
      />
      <Accordion
        title="Verifications for Businesses (KYB)"
        className="icf__accordion"
        showToggle
        content={
          <div>
            <ConfigurationFee title="Business Identity" type="KYB">
              {feesData.kyb.map(fee => (
                <ConfigurationFeeItem
                  key={fee.key}
                  title={fee.title}
                  currentFee={fee.currentFee}
                  newFee={fee.newFee}
                  currency={fee.currency}
                  newCurrency={selectedCurrency}
                  onChangeFee={newFee => handleFeeChange('kyb', fee.title, newFee)}
                  modified={fee.modified}
                />
              ))}
            </ConfigurationFee>
          </div>
        }
      />
    </div>
  );
};

export default ConfigurationContent;

/* eslint-disable react/no-array-index-key */
import React from 'react';

type ColumnWithNameType = { name?: string; data: Array<React.ReactNode | number> };

interface ILimitsInfo {
  cta?: string;
  columnwiseData: Array<ColumnWithNameType> | Array<React.ReactNode>;
  onLimitEdit: () => void;
  rowHeaders: Array<React.ReactNode | null>;
  subtitle: string;
  title: string;
  disabled?: boolean;
}

const isArrayOfObjects = (arr: Array<ColumnWithNameType> | Array<React.ReactNode>): arr is Array<ColumnWithNameType> => {
  if (!Array.isArray(arr) || arr.length === 0) return false;
  const firstItem = arr[0];
  return (
    typeof firstItem === 'object' && firstItem !== null && 'data' in firstItem && Array.isArray((firstItem as ColumnWithNameType).data)
  );
};

const LimitsInfoPanel = ({ cta = 'Edit', onLimitEdit, title, subtitle, rowHeaders, columnwiseData, disabled }: ILimitsInfo) => {
  const columnwiseDataIsArrayOfObjects = isArrayOfObjects(columnwiseData);

  return (
    <div role="region" aria-labelledby={title} className="limits-info-comp">
      <div className="d-flex justify-between align-items-baseline top-section">
        <div className="title-block">
          <p className="title">{title}</p>
          <p className="subtitle">{subtitle}</p>
        </div>

        <button onClick={onLimitEdit} type="button" className="btn btn-link edit-limit-cta" disabled={disabled}>
          {cta}
        </button>
      </div>
      <table
        className="limits-info-panel bg-transparent"
        style={{
          ['--num-of-rows' as string]: rowHeaders.length
        }}
      >
        <caption className="sr-only" id={title}>
          {title}
        </caption>
        <thead>
          <tr>
            {rowHeaders.map((rowHeader, index) => (
              <th className="font-weight-bold" key={index}>
                {rowHeader}
              </th>
            ))}
          </tr>
        </thead>
        <tbody
          style={{
            ['--num-of-columns' as string]: columnwiseDataIsArrayOfObjects ? columnwiseData.length : 1,
            ['--text-align' as string]: columnwiseDataIsArrayOfObjects ? '' : 'end'
          }}
        >
          {columnwiseDataIsArrayOfObjects ? (
            columnwiseData?.map((column, colIndex) => (
              <tr key={`row-${colIndex}`}>
                <th scope="row" className="font-weight-bold text-capitalize">
                  {column?.name || <span className="invisible">placeholder text</span>}
                </th>
                {column?.data.map((item, datumIndex) => (
                  <td key={`data-row-${colIndex}-${datumIndex}`}>{item ?? '--'}</td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <th scope="row" className="font-weight-bold text-capitalize">
                <span className="invisible">placeholder text</span>
              </th>
              {columnwiseData?.map((column, index) => (
                <td key={index}>{column || '--'}</td>
              ))}
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default LimitsInfoPanel;

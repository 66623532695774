import React, { useState } from 'react';

import Accordion from '+containers/Dashboard/Shared/Accordion';
import EmptyStateComponent from '+containers/Dashboard/Shared/EmptyState';
import Modal from '+containers/Dashboard/Shared/Modal';
import { capitalizeFirst, capitalizeRemovedash, getDateAndTime } from '+utils';

import './FailedPayoutReasonsModal.scss';

interface IFailedPayoutReasonsProps {
  visible: boolean;
  close: () => void;
  transactions: {
    [key: string]: {
      message: string;
      attempt_number: number;
      date: string;
    }[];
  };
}

const FailedPayoutReasons: React.FC<IFailedPayoutReasonsProps> = ({ visible, close, transactions }) => {
  const { processor_messages: failureReasons, transaction_date: date } = transactions || {};
  const [openAccordion, setOpenAccordion] = useState<number | null>(0);

  const renderProcessorMessages = () => {
    if (!failureReasons || Object.keys(failureReasons).length === 0) {
      return <EmptyStateComponent message="No processor message(s) available for this transaction" />;
    }
    return Object.entries(failureReasons)?.map(([processor, messages], index) => {
      const reason = Array.isArray(messages) ? messages : [];

      return (
        <Accordion
          showToggle={true}
          open={openAccordion === index}
          title={capitalizeRemovedash(processor)}
          className="identity__accordion"
          onClick={() => {
            setOpenAccordion(openAccordion === index ? null : index);
          }}
          content={
            <div className="processor_message_container">
              {reason.length > 0 ? (
                reason.map(({ message }, index) => (
                  <div className="ivr-modal__content__row" key={`${processor}_${index}`}>
                    <div className="ivr-modal__content__row__label">
                      <div>{capitalizeFirst(message)}</div>
                    </div>
                    <div className="ivr-modal__content__row__value">
                      <div>{getDateAndTime(reason[index]?.date)}</div>
                    </div>
                  </div>
                ))
              ) : (
                <div>No messages available for this processor.</div>
              )}
            </div>
          }
          key={processor}
        />
      );
    });
  };

  return (
    <Modal
      visible={visible}
      size="md"
      heading="Reason for failure"
      description="These are reasons why the transaction failed from our processors"
      content={renderProcessorMessages()}
      secondaryCompletedModal
      completedActionText=""
      close={close}
      secondButtonText="Done"
      secondButtonAction={close}
      hideSecondButton
      firstButtonStyles={{
        width: 'auto',
        backgroundColor: '#EAF2FE',
        color: '#333'
      }}
      firstButtonText="Close"
      isScrollable
      headerBottomBorder
    />
  );
};

export default FailedPayoutReasons;

/* eslint-disable react/jsx-props-no-spreading */
import React, { LegacyRef, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { useClickOutside, useReducerState } from '+hooks';
import { IDateFilterProps } from '+types';

import { CustomDateRangeCalendar, formatDate } from './CustomDateRangeCalendar';

import calendar from '+assets/img/dashboard/calendar.svg';

import 'react-calendar/dist/Calendar.css';
import './SecondaryFilter.scss';

dayjs.extend(customParseFormat);

const dateOptions = ['Today', 'Last 7 days', 'Last 30 days', 'Last 90 days', 'All Time', 'Custom Range'] as const;

interface IDateFilterState {
  type: (typeof dateOptions)[number] | string;
  visible: boolean;
  startDate?: string;
  endDate?: string;
  startTime?: string;
  endTime?: string;
}

const DateFilter = (props: IDateFilterProps) => {
  const { selectedDate, onDateChange, tableType } = props;

  const [updateKey, setUpdateKey] = useState(0);

  const forceUpdate = () => setUpdateKey(prev => prev + 1);

  const getDateType = (startDate: string, endDate: string) => {
    if (!startDate && !endDate) return null;
    if (dayjs().diff(dayjs(startDate), 'day') === 0 && !startDate.includes(':')) return 'Today';
    if (dayjs().diff(dayjs(startDate), 'day') === 7) return 'Last 7 days';
    if (dayjs().diff(dayjs(startDate), 'day') === 30) return 'Last 30 days';
    if (dayjs().diff(dayjs(startDate), 'day') === 90) return 'Last 90 days';
    if (startDate === 'all_time' && endDate === 'all_time') return 'All Time';
    return `${formatDate(startDate)} ${state?.startTime} - ${formatDate(endDate)} ${state?.endTime}`;
  };

  const [state, setState] = useReducerState<IDateFilterState>({
    type: dateOptions[0],
    visible: false,
    startDate: selectedDate.startDate,
    endDate: selectedDate.endDate,
    startTime: '',
    endTime: ''
  });

  useEffect(() => {
    setState({ type: getDateType(selectedDate.startDate, selectedDate.endDate) ?? dateOptions[0] });
  }, [updateKey, selectedDate.startDate, selectedDate.endDate]);

  useEffect(() => {
    if (props.isCleared) {
      setState({ type: dateOptions[0], startDate: '', endDate: '' });
      onDateChange({ startDate: '', endDate: '' });
    }
  }, [props.isCleared]);

  const wrapperRef = useClickOutside(() => {
    setState({ ...state, visible: false });
  });

  const handleSelectDateOption = (dateOption: (typeof dateOptions)[number]) => {
    const currentDate = dayjs().format('YYYY-MM-DD');
    switch (dateOption) {
      case 'Today':
        setState({ type: dateOption, endDate: currentDate, startDate: currentDate, visible: false });
        onDateChange({ startDate: currentDate, endDate: currentDate });
        break;
      case 'Last 7 days':
        setState({
          type: dateOption,
          endDate: currentDate,
          startDate: dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
          visible: false
        });
        onDateChange({
          startDate: dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
          endDate: currentDate
        });
        break;
      case 'Last 30 days':
        setState({
          type: dateOption,
          endDate: currentDate,
          startDate: dayjs().subtract(30, 'day').format('YYYY-MM-DD'),
          visible: false
        });
        onDateChange({
          startDate: dayjs().subtract(30, 'day').format('YYYY-MM-DD'),
          endDate: currentDate
        });
        break;
      case 'Last 90 days':
        setState({
          type: dateOption,
          endDate: currentDate,
          startDate: dayjs().subtract(90, 'day').format('YYYY-MM-DD'),
          visible: false
        });
        onDateChange({
          startDate: dayjs().subtract(90, 'day').format('YYYY-MM-DD'),
          endDate: currentDate
        });
        break;
      case 'All Time':
        setState({ type: dateOption, startDate: 'all_time', endDate: 'all_time', visible: false });
        onDateChange({ startDate: 'all_time', endDate: 'all_time' });
        break;
      case 'Custom Range':
        setState({ type: dateOption, visible: false });
        break;
      default:
        break;
    }
  };

  function convertTo24HourFormat(
    timeStr: string,
    isEndTime: boolean,
    value: {
      startDate: string;
      endDate: string;
    }
  ): string | undefined {
    const timeRegex = /^(\d{1,2}):(\d{2})\s?(AM|PM)$/i;
    const match = timeStr.match(timeRegex);

    const seconds = isEndTime ? '59' : '00';

    if (!match) {
      throw new Error('Invalid time format. Please use "hh:mm AM/PM" format.');
    }

    const [_, hours, minutes, period] = match;
    let hoursNum = parseInt(hours, 10);

    if (period.toUpperCase() === 'PM' && hoursNum !== 12) {
      hoursNum += 12;
    } else if (period.toUpperCase() === 'AM' && hoursNum === 12) {
      hoursNum = 0;
    }

    const formattedHours = hoursNum.toString().padStart(2, '0');

    return value?.startDate === value?.endDate ? ` ${formattedHours}:${minutes}:${seconds}` : '';
  }

  return (
    <div ref={wrapperRef as LegacyRef<HTMLDivElement>} className="secondary-datefilter">
      <span
        className="form-group filter-object filter-object-xxl flex-grow-1 w-auto --no-max-width --search-container"
        role="presentation"
        onClick={() => setState({ visible: !state.visible })}
        onKeyDown={() => setState({ visible: !state.visible })}
      >
        <img src={calendar} alt="calendar icon" aria-hidden aria-label="calendar" />
        <input
          title="dateFilter"
          data-testid="dateFilter"
          aria-label="dateFilter"
          name="dateFilter"
          className="form-control"
          type="text"
          value={state.type}
          readOnly
        />
        <i className="os-icon os-icon-arrow-down2" />
      </span>
      {state.visible && (
        <section className="secondary-datefilter--option element-box ">
          <ul>
            {dateOptions.map(dateOption => (
              <li
                key={dateOption}
                role="presentation"
                onClick={() => handleSelectDateOption(dateOption)}
                onKeyDown={() => handleSelectDateOption(dateOption)}
              >
                <span>{dateOption}</span>
              </li>
            ))}
          </ul>
        </section>
      )}
      <CustomDateRangeCalendar
        visible={state.type === 'Custom Range'}
        close={() => setState({ ...state, type: '' })}
        selectedDate={selectedDate}
        setSelectedDateRange={value => {
          setState({ ...state, ...value, startTime: value?.allData?.startTime, endTime: value?.allData?.endTime });
          onDateChange({
            startDate: `${value.startDate}${
              value?.allData?.startTime &&
              convertTo24HourFormat(value?.allData?.startTime + ' ' + value?.allData?.meridiemStart, false, value)
            }`,
            endDate: `${value.endDate}${
              value?.allData?.endTime && convertTo24HourFormat(value?.allData?.endTime + ' ' + value?.allData?.meridiemEnd, true, value)
            }`,
            startTime: value?.allData?.startTime,
            endTime: value?.allData?.endTime
          });
          forceUpdate();
        }}
        tableType={tableType}
        isCleared={props.isCleared}
      />
    </div>
  );
};

export default DateFilter;

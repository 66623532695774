import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';

import AccessDenial from '+containers/Dashboard/AccessDenial';
import LoadingPlaceholder from '+containers/Dashboard/Shared/LoadingPlaceHolder';
import { useSetUserAccess } from '+hooks';
import APIRequest from '+services/api-services';
import useProductConfigCardIssuanceStore from '+store/productConfigCardIssuanceStore';
import {
  CardIssuanceDefaultLimitsResponseType,
  CategoryType,
  CurrencyType,
  EditActionType,
  PCIDSSLevelResponseType,
  RiskLevelResponseType
} from '+types';
import { formatWithCommas, isAllowed, logError } from '+utils';

import { formatFundingLimits, formatPCIDSSLimits, formatSpendingLimits } from '../helpers/holderDetailsHelpers';
import EditVirtualCardLimitsModal from './EditVirtualCardLimitsModal';
import LimitsInfoPanel from './LimitsInfoPanel';

type PayloadType = RiskLevelResponseType | PCIDSSLevelResponseType;

interface IStateType {
  action: EditActionType | null;
  isOpen: boolean;
  payload: PayloadType | null;
}

const api = new APIRequest();

const DefaultConfiguration = () => {
  const { currency } = useParams<{
    currency: CurrencyType;
  }>();
  const [editLimit, setEditLimit] = useState<IStateType>({ action: null, isOpen: false, payload: null });
  const setDefaultConfig = useProductConfigCardIssuanceStore(store => store.setDefaultConfig);
  const location = useLocation();
  const segments = location.pathname.split('/');
  const cardCategory = segments[segments.length - 1] as CategoryType;
  const userType: 'customer' | 'reserved' = cardCategory === 'issued-cards' ? 'customer' : 'reserved';
  const userAccess = useSetUserAccess();

  const handleEditLimitModalVisibility = ({ action, isOpen, payload }: IStateType) => {
    setEditLimit({ action, isOpen, payload });
  };

  const {
    data: defaultLimits,
    isFetching: defaultLimitsIsFetching,
    refetch: refchLimits
  } = useQuery<CardIssuanceDefaultLimitsResponseType>(['DEFAULT_LIMITS'], () => api.getDefaultLimits(currency), {
    onSuccess: data => setDefaultConfig(data),
    onError: error => logError(error),
    enabled: !!isAllowed(userAccess, ['card_issuance_limits.view'])
  });

  const updateIsDisabled = !isAllowed(userAccess, ['card_issuance_limits.update']);

  if (!isAllowed(userAccess, ['card_issuance_limits.view'])) return <AccessDenial />;

  return (
    <>
      <div className="default-configuration default-configuration--card-issuance">
        <h6 className="font-weight-bolder">Virtual Card Limits</h6>

        <div className="stack-md mt-3">
          {defaultLimitsIsFetching && (
            <>
              <LoadingPlaceholder type="text" content={4} />
              <LoadingPlaceholder type="text" content={4} />
              <LoadingPlaceholder type="text" content={4} />
            </>
          )}
          {defaultLimits && (
            <>
              {userType === 'customer' ? (
                <LimitsInfoPanel
                  disabled={updateIsDisabled}
                  columnwiseData={formatPCIDSSLimits(defaultLimits?.[userType].pcidss_level)}
                  cta="Edit Default Limits"
                  onLimitEdit={() =>
                    handleEditLimitModalVisibility({
                      action: 'edit_pci_dss_limit',
                      isOpen: true,
                      payload: { ...defaultLimits?.[userType].pcidss_level }
                    })
                  }
                  rowHeaders={['Merchant PCI Level', 'Transaction Count Limit', 'No. of Issuable Cards']}
                  subtitle="These are the pci dss limit configuration for this product"
                  title="PCI DSS Limits"
                />
              ) : (
                <LimitsInfoPanel
                  disabled={updateIsDisabled}
                  columnwiseData={[
                    formatWithCommas(defaultLimits?.[userType].pcidss_level.level_0.yearly_transaction_count),
                    formatWithCommas(defaultLimits?.[userType].pcidss_level.level_0.yearly_issued_cards)
                  ]}
                  cta="Edit Default Limits"
                  onLimitEdit={() =>
                    handleEditLimitModalVisibility({
                      action: 'edit_pci_dss_limit',
                      isOpen: true,
                      payload: {
                        ...defaultLimits?.[userType].pcidss_level
                      }
                    })
                  }
                  rowHeaders={[null, 'Transaction Count Limit', 'No. of Issuable Cards']}
                  subtitle="These are the card and transaction count limit configuration for this product"
                  title="Card and Transaction Count Limits"
                />
              )}
              <LimitsInfoPanel
                disabled={updateIsDisabled}
                cta="Edit Default Limits"
                columnwiseData={formatFundingLimits(defaultLimits?.[userType].risk_level)}
                onLimitEdit={() =>
                  handleEditLimitModalVisibility({
                    action: 'edit_funding_limit',
                    isOpen: true,
                    payload: { ...defaultLimits?.[userType].risk_level }
                  })
                }
                rowHeaders={[
                  'Merchant Risk Level',
                  'Max. funding limit per day',
                  'Max. funding limit per month',
                  'Max. funding limit per quarter'
                ]}
                subtitle="These are the card funding limit configuration for this product"
                title="Card Funding Limits"
              />

              <LimitsInfoPanel
                disabled={updateIsDisabled}
                columnwiseData={formatSpendingLimits(defaultLimits?.[userType].risk_level)}
                cta="Edit Default Limits"
                onLimitEdit={() =>
                  handleEditLimitModalVisibility({
                    action: 'edit_spending_limit',
                    isOpen: true,
                    payload: { ...defaultLimits?.[userType].risk_level }
                  })
                }
                rowHeaders={['Merchant Risk Level', 'Max. limit per transaction', 'Daily transaction cap', 'Monthly transaction cap']}
                subtitle="These are the card spending limit configuration for this product"
                title="Card Spending Limits"
              />
            </>
          )}
        </div>
      </div>

      {editLimit.isOpen ? (
        <EditVirtualCardLimitsModal
          defaultValues={editLimit.payload as PayloadType}
          action={editLimit.action as EditActionType}
          onClose={() => handleEditLimitModalVisibility({ action: null, isOpen: false, payload: null })}
          category={cardCategory}
          userType={userType}
          refchLimits={refchLimits}
        />
      ) : null}
    </>
  );
};

export default DefaultConfiguration;

import React from 'react';
import { Link } from 'react-router-dom';

import { CurrencyType, productCategoriesType, ProductConfigType } from '+types';

import './index.scss';

const data = {
  bank_transfer: {
    name: 'Bank Transfer (DVBA)',
    description: 'This houses the bank transfers via dynamic bank account products'
  },
  virtual_bank_account: {
    name: 'Virtual Bank Account',
    description: 'This houses the virtual bank account pay-ins products'
  },
  card: {
    name: 'Debit Card Payments',
    description: 'This houses the debit card payments pay-ins products'
  },
  mobile_money: {
    name: 'Mobile Money',
    description: 'This houses the mobile money pay-ins products'
  },
  pay_with_bank: {
    name: 'Pay with Bank',
    description: 'This houses the pay with bank pay-ins products'
  },
  disbursement_wallet: {
    name: 'Wallet',
    description: 'This houses the wallet pay-ins products'
  },
  reserved_bank_account: {
    name: 'RBA',
    description: 'This houses the Reserved bank account pay-ins products'
  },
  bulk_bank_account: {
    name: 'Bulk Bank Account',
    description: 'This houses the bulk bank account pay-out products'
  },
  bank_account: {
    name: 'Bank Account',
    description: 'This houses the bank account pay-out products'
  },
  'reserved-cards': {
    name: 'Reserved Cards',
    description: 'This product allows businesses to issue reserved virtual cards for payments, and manage these reserved virtual cards.'
  },
  'issued-cards': {
    name: 'Issued Cards',
    description: 'This product allows merchants to issue virtual cards for payments, and manage these issued virtual cards.'
  }
} as const;

type dataType = keyof typeof data;

const ProductCards = ({
  currency,
  config,
  product
}: {
  currency: CurrencyType;
  config: ProductConfigType;
  product: productCategoriesType;
}) => {
  return (
    <div className="product-cards --container">
      <div className="product-cards --content">
        {config &&
          product !== 'card-issuance' &&
          Object.keys(config).map(key => {
            if (['enabled', 'can_send_to_any_merchant', 'transaction_limit'].includes(key)) return null;
            return (
              <div key={key} className="product-card">
                <p className="product-card__header">{data[key as dataType]?.name}</p>
                <p className="product-card__description">{data[key as dataType]?.description}</p>
                {config.enabled && (
                  <div className="product-card__action">
                    <span className="icon-w">
                      <span className="os-icon os-icon-ui-46" />
                    </span>
                    <span>
                      <Link to={`/dashboard/product-config/${currency}/${product}/${key}`}>Configure</Link>
                    </span>
                  </div>
                )}
              </div>
            );
          })}

        {product === 'card-issuance'
          ? Object.keys(config).map(key => {
              return (
                <div key={key} className="product-card">
                  <p className="product-card__header">{data[key as dataType]?.name}</p>
                  <p className="product-card__description">{data[key as dataType]?.description}</p>
                  <div className="product-card__action">
                    <span className="icon-w">
                      <span className="os-icon os-icon-ui-46" />
                    </span>
                    <span>
                      <Link to={`/dashboard/product-config/${currency}/${product}/${key}`}>Configure</Link>
                    </span>
                  </div>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default ProductCards;

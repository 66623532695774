import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import SectionInfo from '+dashboard/ProductConfig/components/SectionInfo';
import { useFeedbackHandler, useSearchQuery, useSetUserAccess } from '+hooks';
import APIRequest from '+services/api-services';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '+shared/Tabs';
import useProductConfigCardIssuanceStore from '+store/productConfigCardIssuanceStore';
import {
  CategoryType,
  CurrencyType,
  IPlan,
  ProductDetailsTabKeyType,
  ProductDetailsTabType,
  TransformedDefaultPlansConfigType
} from '+types';
import { capitalizeRemovedash, history, isAllowed } from '+utils';

import AddMerchantModal from './components/AddMerchantModal';
import DefaultConfiguration from './components/DefaultConfiguration';
import HoldersTable from './components/HoldersTable';
import RequestersTable from './components/RequestersTable';
import { transformDefaultPlansConfig } from './helpers/addMerchantModalHelpers';

import './index.scss';

const api = new APIRequest();

interface ICardIssuanceProductDetails {
  currency: CurrencyType;
  method: 'reserved-cards' | 'issued-cards';
}

const tabsList: ProductDetailsTabType = [
  { label: 'Default Configuration', key: 'defaults' },
  { label: 'Merchants with Access', key: 'holders' },
  { label: 'Access Requests', key: 'requesters' }
];

const CardIssuanceProductDetails = ({ currency, method }: ICardIssuanceProductDetails) => {
  const { feedbackInit } = useFeedbackHandler();
  const userAccess = useSetUserAccess();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { feature } = useParams<{ feature: CategoryType }>();
  const searchQuery = useSearchQuery();
  const defaultConfig = useProductConfigCardIssuanceStore(store => store.defaultConfig);
  const access = useProductConfigCardIssuanceStore(store => store.access);

  const userType: 'customer' | 'reserved' = method === 'issued-cards' ? 'customer' : 'reserved';
  const tab = searchQuery.value?.tab ?? tabsList[0].key;

  const tabPanelsList: Record<ProductDetailsTabKeyType, JSX.Element> = {
    defaults: <DefaultConfiguration />,
    holders: <HoldersTable feature={feature} />,
    requesters: <RequestersTable feature={feature} />
  };

  const { data: defaultPlansConfig } = useQuery<Array<IPlan>, unknown, TransformedDefaultPlansConfigType>(
    ['ISSUING_MERCHANT_PLANS'],
    () => api.fetchIssuingMerchantPlans(),
    {
      onError: () => {
        feedbackInit({
          message: 'There has been an error fetching the merchant plans',
          type: 'danger'
        });
      },
      select: data => transformDefaultPlansConfig(data),
      enabled: !!isAllowed(userAccess, ['card_issuance_plan_subscription.update'])
    }
  );

  return (
    <div className="content-i">
      <div className="content-box">
        <div className="row">
          <button type="button" className="btn btn-link mb-2" onClick={() => history.goBack()}>
            <i className="os-icon os-icon-arrow-left7" />
            <span>Back to Card Issuance Products</span>
          </button>
        </div>
        <div className="payins-container my-4">
          <div className="switcher">
            <SectionInfo
              title={`${capitalizeRemovedash(method)} [${currency}]`}
              description="Here, you’ll find the general configuration of this product and other product channels under it. You can make modifications here."
              status={Boolean(userType === 'reserved' ? access.reservedIsEnabled : access.issuedIsEnabled)}
            />

            {userType === 'customer' && (
              <div className="d-flex align-items-center justify-content-end">
                <button
                  disabled={!isAllowed(userAccess, ['card_issuance_merchant.create'])}
                  type="button"
                  className="btn btn-primary"
                  onClick={() => setModalIsOpen(true)}
                  title="more"
                  data-variant="cta-add-merchant"
                >
                  <span className="os-icon os-icon-plus" />
                  <span>Add Merchant</span>
                </button>
              </div>
            )}
          </div>
        </div>

        <Tabs style={{ marginTop: '2.5rem' }} defaultValue={tab} onChange={tabVal => searchQuery.setQuery({ tab: tabVal }, true)}>
          <TabList className="nav nav-tabs">
            {tabsList.map(({ key, label }) => {
              return (
                <Tab value={key} key={key}>
                  {label}
                </Tab>
              );
            })}
          </TabList>
          <TabPanels>
            {tabsList.map(({ key }) => (
              <TabPanel value={key} key={key}>
                {tabPanelsList[key]}
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>

        {modalIsOpen ? (
          <AddMerchantModal
            merchantClass="new"
            defaultLimitsConfig={defaultConfig[userType]}
            onClose={() => setModalIsOpen(false)}
            category={method}
            defaultPlansConfig={defaultPlansConfig ? defaultPlansConfig[userType] : ([] as TransformedDefaultPlansConfigType['customer'])}
          />
        ) : null}
      </div>
    </div>
  );
};

export default CardIssuanceProductDetails;

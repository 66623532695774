/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { NumericFormatProps } from 'react-number-format';

import FieldErrorMsg from './FieldErrorMsg';
import NumericInput from './NumericInput';

import './InputWithSuffix.scss';

interface IInputWithSuffix extends NumericFormatProps {
  suffix: string;
  className?: string;
  id: string;
  error?: string;
  touched?: boolean;
}

const InputWithSuffix = ({ suffix, id, error = '', touched = false, ...props }: IInputWithSuffix) => {
  return (
    <div className="input-with-suffix">
      <div className="input-wrapper">
        <NumericInput {...props} id={id} data-state={touched && error ? 'invalid' : ''} />
        <span>{suffix}</span>
      </div>

      <FieldErrorMsg touched={Boolean(touched)} id={`err-${id}`} error={String(error)} />
    </div>
  );
};

export default InputWithSuffix;

import React, { useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import LoadingPlaceholder from '+containers/Dashboard/Shared/LoadingPlaceHolder';
import { useFeedbackHandler, useSearchQuery } from '+hooks';
import APIRequest from '+services/api-services';
import { ErrorT, KAASFeesDataType, KAASFeeType, MerchantIDTypeAccessAPIPayloadType } from '+types';
import { history, identityCountries, logError } from '+utils';

import ConfigurationConfirmationModal from '../../../components/ConfigurationConfirmationModal';
import HeroSection from '../../../components/HeroSection';
import NavigationBlocker from '../../../components/NavigationBlocker';
import PageBackButton from '../../../components/PageBackButton';
import UseCases from '../../../components/UseCases';
import { checkIfUseCasesChanged, convertToObject, convertUseCasesToObject, getCountry, transformFeesData } from '../../../helpers/configurationHelper';

const categoryName = {
  kyc: 'Individuals (KYC)',
  kyb: 'Businesses (KYB)'
};

const api = new APIRequest(process.env.REACT_APP_MIDDLEWARE_API_BASE);

export default function GeneralConfigUsecases() {
  const [openSaveModal, setOpenSaveModal] = React.useState(false);
  const category = useParams<{ category: string }>().category;
  const searchQuery = useSearchQuery();
  const { feedbackInit } = useFeedbackHandler();
  const region = searchQuery.value.region || 'ng';
  const regionName = identityCountries.find(c => c.value === region)?.label;

  const {
    data: merchantFees,
    isFetching: isFetchingMerchantFees,
    refetch: refetchMerchantFees
  } = useQuery(`IDENTITY_DEFAULT_FEES`, () => api.getIdentityDefaultFees(), {});

  const updateAccess = useMutation((data: MerchantIDTypeAccessAPIPayloadType) => api.grantGlobalIDTypeAccess(region, data), {
    onError: (error: ErrorT) => {
      logError(error);
      feedbackInit({ message: error.response?.data.message, componentLevel: true, type: 'danger' });
    },
    onSuccess: () => {
      feedbackInit({ message: `Fees configured successfully`, componentLevel: true, type: 'success' });
      refetchMerchantFees();
    }
  });

  const settings = merchantFees?.data?.setting?.country?.[region];
  const useCasesData = transformFeesData(
    settings,
    {} as KAASFeesDataType,
    'default',
    convertToObject(merchantFees?.data?.identities || []),
    true
  );
  const categoryInitialState = {
    kyb: settings?.access?.kyb?.enabled,
    kyc: settings?.access?.kyc?.enabled
  } as { kyb: boolean; kyc: boolean };

  const [useCasesState, setUseCasesState] = React.useState(useCasesData);
  const [categoryState, setCategoryState] = React.useState(categoryInitialState);
  const updateUseCases = (useCase: KAASFeeType, section: 'kyb' | 'kyc') => {
    setUseCasesState(prev => {
      return {
        ...prev,
        [section]: prev[section].map(u => {
          if (u.key === useCase.key) {
            return useCase;
          }
          return u;
        })
      };
    });
  };

  const updateCategoryState = (state: boolean) => {
    setCategoryState(prev => {
      return {
        ...prev,
        [category]: state
      };
    });
  };

  const useCasesChanged = checkIfUseCasesChanged(useCasesState, useCasesData, category, categoryState, categoryInitialState);

  const handleSave = async () => {
    const useCases = useCasesState[category as keyof typeof useCasesData];
    const useCasesObject = convertUseCasesToObject(useCases, categoryState, category as 'kyb' | 'kyc');
    await updateAccess.mutateAsync(useCasesObject);
  };

  useEffect(() => {
    if (merchantFees) {
      const settings = merchantFees?.data?.setting?.country[region];
      const useCasesData = transformFeesData(
        settings,
        {} as KAASFeesDataType,
        'default',
        convertToObject(merchantFees?.data?.identities || []),
        true
      );
      const categoryInitialState = {
        kyb: settings?.access?.kyb?.enabled,
        kyc: settings?.access?.kyc?.enabled
      };
      setUseCasesState(useCasesData);
      setCategoryState(categoryInitialState);
    }
  }, [merchantFees, region]);

  const idTypeAccessStatus = {
    kyb: merchantFees?.data?.setting?.country?.[region]?.access?.kyb?.enabled,
    kyc: merchantFees?.data?.setting?.country?.[region]?.access?.kyc?.enabled
  };

  return (
    <div className="identity-configuration">
      <NavigationBlocker blockNavigation={useCasesChanged} />
      <div className="content-i">
        <div className="content-box">
          {isFetchingMerchantFees ? (
            <div className="row">
              <div className="col-sm-12">
                <LoadingPlaceholder type="text" content={1} section={4} />
              </div>
            </div>
          ) : (
            <>
              <PageBackButton
                onClick={() => history.push(`/dashboard/identity/configuration/general?tab=${region}`)}
                label={`Back to Identity Service for ${regionName}`}
                dropdownList={[
                  {
                    name: 'For Individuals (KYC)',
                    action: () => history.push(`/dashboard/identity/configuration/general/kyc?region=${region}`),
                    disabled: idTypeAccessStatus.kyc === undefined
                  },
                  {
                    name: 'For Businesses (KYB)',
                    action: () => history.push(`/dashboard/identity/configuration/general/kyb?region=${region}`),
                    disabled: idTypeAccessStatus.kyb === undefined
                  }
                ]}
                activeMenu={`For ${categoryName?.[category as keyof typeof categoryName]}`}
              />

              <HeroSection
                title={`${categoryName?.[category as keyof typeof categoryName]} Verification for ${regionName}`}
                description={`You can configure how the Identity service works for you. Set which use cases are available in ${getCountry(region)} for ${
                  category === 'kyb' ? 'business' : 'individual'
                } verification checks.`}
                button={{
                  name: `Save Changes`,
                  disabled: !useCasesChanged || updateAccess.isLoading,
                  action: () => {
                    setOpenSaveModal(true);
                  },
                  color: 'success',
                  icon: <i className="os-icon os-icon-save" />
                }}
              />
              <UseCases
                updateUseCases={updateUseCases}
                useCaseOptions={useCasesState[category as keyof typeof useCasesData]}
                category={category as 'kyc' | 'kyb'}
                updateSectionState={updateCategoryState}
                sectionState={categoryState[category as keyof typeof categoryState]}
                region={region}
              />
            </>
          )}
        </div>
      </div>
      <ConfigurationConfirmationModal openSaveModal={openSaveModal} handleClick={handleSave} close={() => setOpenSaveModal(false)} />
    </div>
  );
}

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import RiskIndicator from '+containers/Dashboard/ProductConfig/components/RiskIndicator';
import { useFeedbackHandler, useSearchQuery, useSetUserAccess } from '+hooks';
import APIRequest from '+services/api-services';
import Table from '+shared/Table';
import { AllIssuanceMerchantsResponseType, CategoryType, CurrencyType, productType } from '+types';
import {
  APIDownload,
  capitalize,
  capitalizeRemovedash,
  filteredOutObjectProperty,
  formatProductConfigStatus,
  getDate,
  getTime,
  isAllowed,
  logError,
  queriesParams,
  renameObjectKeys,
  switchStatus
} from '+utils';

const api = new APIRequest();

const HoldersTable = ({ feature }: { feature: CategoryType }) => {
  const { currency } = useParams<{ currency: CurrencyType }>();
  const cardType = feature === 'issued-cards' ? 'customer' : 'reserved';
  const { feedbackInit } = useFeedbackHandler();
  const searchQuery = useSearchQuery();
  const userAccess = useSetUserAccess();

  const page = searchQuery.value.page || 1;
  const limit = searchQuery.value.limit || 10;
  const keyword = searchQuery.value.keyword || undefined;
  const selectedConfig = searchQuery.value.selectedConfig || undefined;
  const status = searchQuery.value.status || ['active', 'suspended'];
  const filteredSortingParams = filteredOutObjectProperty(searchQuery.value, [
    queriesParams.tab,
    queriesParams.page,
    queriesParams.limit,
    queriesParams.status,
    queriesParams.keyword,
    queriesParams.selectedConfig
  ]);

  const sortingParams = {
    limit,
    page,
    keyword: keyword,
    ...(selectedConfig ? { configuration: typeof selectedConfig === 'string' ? [selectedConfig] : selectedConfig } : {}),
    ...(status ? { status: typeof status === 'string' ? [status] : status } : {}),
    ...renameObjectKeys(filteredSortingParams, { dateFrom: 'dateCreatedFrom', dateTo: 'dateCreatedTo' })
  };

  const { data: allIssuanceMerchantsData, isLoading: isLoadingAllIssuanceMerchantsData } = useQuery<{
    data: AllIssuanceMerchantsResponseType;
    paging: { page_size: number; total_items: number };
  }>(
    ['ISSUING_MERCHANTS', searchQuery.value],
    () => api.fetchAllAccessRequestMerchants(currency, 'virtual', cardType, page, limit, sortingParams),
    {
      onError: () => {
        feedbackInit({
          message: 'There has been an error fetching merchants with access',
          type: 'danger'
        });
      },
      enabled: !!isAllowed(userAccess, ['card_issuance_access_request.view'])
    }
  );

  const exportAllIssuanceMerchants = async (exportFormat: 'csv' | 'xlsx', close: () => void, exportFields: Array<string>) => {
    try {
      const res = await api.fetchAllAccessRequestMerchants(
        currency,
        'virtual',
        cardType,
        page,
        limit,
        sortingParams,
        true,
        exportFormat,
        exportFields
      );

      const type = exportFormat === 'csv' ? 'csv' : 'xlsx';
      APIDownload(res, `All Issuance Merchants at ${getDate(Date.now())}`, type);
      feedbackInit({
        title: 'Export Successful',
        message: <> - Successfully exported</>,
        type: 'success'
      });
      close();
    } catch (error) {
      logError(error);
      feedbackInit({
        title: 'Export Failed',
        message: `There has been an error downloading your Issuance Merchant list file`,
        type: 'danger',
        componentLevel: true
      });
    }
  };

  const tableProps = {
    className: 'issuance-merchants-access-table',
    emptyStateHeading: 'No Merchants yet',
    annotations: 'Merchant(s)',
    data: allIssuanceMerchantsData?.data || [],
    emptyStateMessage: 'There are no Merchants',
    rowUrl: `/dashboard/product-config/${currency}/card-issuance/${feature}/holders`,
    rowKey: 'merchant_reference',
    rowUrlQuery: `?tab=${cardType === 'customer' ? 'issued_cards' : 'reserved_cards'}`,
    fields: (each: AllIssuanceMerchantsResponseType[number]) => ({
      style: { backgroundColor: each.limit_config_type === 'custom' ? '#FFF7ED' : 'transparent' },
      data: {
        Merchant: <span id="merchant-name">{capitalize(each.name)}</span>,
        'Subscription Plan': each.subscription_plan,
        Risk_Level: <RiskIndicator riskLevel={each.risk_level} />,
        Currency_Status: (
          <>
            <span className={`status-pill smaller ${switchStatus(formatProductConfigStatus(each.status))}`} />
            <span>{capitalizeRemovedash(formatProductConfigStatus(each.status))}</span>
          </>
        ),
        Configuration_Type: <span className="lighter">{capitalize(each.limit_config_type)}</span>,
        Date_Added: (
          <>
            <span>{getDate(each.merchant_created_at)}</span>
            <span className="annotation" style={{ marginLeft: '5px' }}>
              {getTime(each.merchant_created_at)}
            </span>
          </>
        )
      }
    })
  };

  return (
    <div className="table-section">
      <Table
        annotation={tableProps.annotations}
        className={tableProps.className}
        data={tableProps.data}
        tableHeadings={['Merchant', 'Subscription Plan', 'Risk Level', 'Status', 'Configuration Type', 'Date Added']}
        loading={isLoadingAllIssuanceMerchantsData}
        isRowClickable
        renderFields
        hasPagination
        rowURL={tableProps.rowUrl}
        rowKey={tableProps?.rowKey}
        rowUrlQuery={tableProps?.rowUrlQuery}
        pageSize={allIssuanceMerchantsData?.paging?.page_size}
        totalItems={allIssuanceMerchantsData?.paging?.total_items}
        current={parseInt(page + '', 10)}
        filterKeywordPlaceholder="Search merchant..."
        tableWrapperClassName="table-responsive table-wrapper"
        emptyStateHeading={tableProps?.emptyStateHeading || ''}
        emptyStateMessage={tableProps.emptyStateMessage || ''}
        actionFn={currentPage => searchQuery.setQuery({ page: String(currentPage) })}
        limitAction={currentLimit => searchQuery.setQuery({ limit: String(currentLimit) })}
        filterHasAdvancedFilter={false}
        filterName={`merchants have access to ${currency} ${feature === 'issued-cards' ? 'Issued' : 'Reserved'} Cards`}
        type="product-config"
        filterExportAction={exportAllIssuanceMerchants}
        filterShowExport
      >
        {tableProps.fields}
      </Table>
    </div>
  );
};

export default HoldersTable;

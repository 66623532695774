/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useFormik } from 'formik';

import FieldErrorMsg from '+containers/Dashboard/Shared/FormError';
import Icon from '+containers/Dashboard/Shared/Icons';
import Modal, { IModalProps } from '+containers/Dashboard/Shared/Modal';
import { useFeedbackHandler } from '+hooks';
import APIRequest from '+services/api-services';
import { IssuingPartnerType, PartnerSwitchingPayloadType, SwitchProviderPaylodType } from '+types';
import { logError } from '+utils';

import { issuingPartners, reasonsForPartnerSwitching } from '../constants/common';

type StepType = 'change_partner' | 'confirm_change';

const apiRequest = new APIRequest();

function PartnerSwitchingModal({
  onClose,
  cardCategory,
  currentPartner,
  payload,
  onToggleSuccess
}: {
  onClose: () => void;
  cardCategory: 'customer' | 'reserved';
  currentPartner: IssuingPartnerType;
  payload: PartnerSwitchingPayloadType;
  onToggleSuccess: () => void;
}) {
  const [step, setStep] = useState<StepType>('change_partner');
  const isReserved = cardCategory === 'reserved';
  const issuingPartnerOptions = generateIssuingPartnerOptions();
  const { feedbackInit, closeFeedback } = useFeedbackHandler();

  const switchProvider = useMutation(
    (requestPayload: SwitchProviderPaylodType) => apiRequest.switchProvider(payload.merchantReference, requestPayload),
    {
      onSuccess: data => {
        feedbackInit({ message: data?.message, type: 'success' });
      },
      onError: error => {
        logError(error);
        feedbackInit({
          message: error.response?.data?.message || 'Action was unsuccessful',
          type: 'danger',
          componentLevel: true
        });
      },
      onSettled: () => {
        setTimeout(() => {
          closeFeedback();
        }, 5000);
      }
    }
  );

  const formik = useFormik({
    initialValues: {
      issuing_partner: currentPartner,
      reason: '',
      reasonDescription: ''
    },
    validate: formValues => {
      const errors: Partial<Record<keyof typeof formValues, string>> = {};

      if (!formValues.reason) errors.reason = 'Please provide a reason for the switch';

      if (formValues.reason === 'others') {
        if (!formValues.reasonDescription) errors.reasonDescription = 'Please provide a description';

        if (formValues.reasonDescription.length < 3)
          errors.reasonDescription = 'Description too short, please provide a longer description';

        if (!formValues.reasonDescription.length) errors.reasonDescription = 'Please provide a description';
      }
      return errors;
    },
    onSubmit: async () => {
      switch (step) {
        case 'change_partner':
          setStep('confirm_change');
          break;
        case 'confirm_change':
          await handleSwitchProvider();
          break;
        default:
          break;
      }
    }
  });

  const goBackOneStep = () => {
    switch (step) {
      case 'confirm_change':
        setStep('change_partner');
        break;
      case 'change_partner':
      default:
        onClose();
        break;
    }
  };
  const isInvalid = (key: keyof typeof formik.values) => formik.touched[key] && formik.errors[key];

  const handleSwitchProvider = async () =>
    await switchProvider.mutateAsync({
      reason: formik.values.reason,
      card_category: cardCategory,
      provider: formik.values.issuing_partner,
      previous_provider: currentPartner
    });

  const stepProps: Record<StepType, Partial<IModalProps>> = {
    change_partner: {
      heading: 'Change issuing partner for this merchant',
      description: `You are about to change the issuing partner for ${payload.merchantName}. Switching partners may impact how their ${
        isReserved ? 'RVCs' : 'customer cards'
      } are issued, managed, and any ongoing card operations.`,
      content: (
        <fieldset className="mt-0">
          <div>
            <label htmlFor="issuingPartners" className="mt-0">
              Select issuing partner
            </label>
            <select id="issuingPartners" className="form-control" {...formik.getFieldProps('issuing_partner')}>
              {issuingPartnerOptions.map(partner => (
                <option value={partner.value} key={partner.value}>
                  {partner.label}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label htmlFor="reasonForChanging">Reason for changing</label>
            <select
              {...formik.getFieldProps('reason')}
              id="reasonForChanging"
              className="form-control"
              data-state={isInvalid('reason') ? 'invalid' : ''}
            >
              <option>--Select a reason--</option>
              {reasonsForPartnerSwitching.map(reason => (
                <option value={reason.value} key={reason.value}>
                  {reason.label}
                </option>
              ))}
            </select>
            <FieldErrorMsg touched={Boolean(formik.touched.reason)} error={formik.errors.reason} id="reasonForChanging" />
          </div>
          {formik.values.reason === 'others' && (
            <div className="fade-in">
              <label htmlFor="reasonDescription">Tell us why you want to change issuing partner for this merchant</label>
              <textarea
                rows={5}
                value={formik.values.reasonDescription}
                // onChange={e => formik.setFieldValue('reasonDescription', sanitizeText(e.target.value))}
                onChange={formik.handleChange}
                id="reasonDescription"
                className="form-control"
                data-state={isInvalid('reasonDescription') ? 'invalid' : ''}
                maxLength={50}
              />
              <FieldErrorMsg
                touched={Boolean(formik.touched.reasonDescription)}
                error={formik.errors.reasonDescription}
                id="reasonDescription"
              />
            </div>
          )}
        </fieldset>
      ),
      secondButtonText: 'Confirm and change',
      size: 'md',
      headerBottomBorder: true,
      secondButtonAction: formik.submitForm,
      secondButtonActionIsTerminal: false
    },
    confirm_change: {
      heading: 'Confirm change of issuing partner',
      description: `Kindly confirm that you want to proceed with changing the ${
        isReserved ? 'RVCs' : 'customer cards'
      } issuing partner for ${payload.merchantName}.`,
      equalFooterBtn: true,
      content: (
        <div className="info">
          <Icon name="infoSolid" fill="#2376f3" />
          <span>
            Note that pending activities will continue to be handled by the current partner, while the new partner will manage new card
            activities.
          </span>
        </div>
      ),
      secondButtonText: 'Yes, Confirm',
      firstButtonText: 'Back',
      secondButtonActionIsTerminal: true,
      size: 'sm',
      secondaryCompletedModal: true,
      completedDescription: `You have successfully changed the ${isReserved ? 'RVCs' : 'customer cards'} issuing partner for ${
        payload.merchantName
      } from ${issuingPartners[currentPartner]} to ${issuingPartners[formik.values.issuing_partner as IssuingPartnerType]}.`
    }
  };

  return (
    <Modal
      {...(stepProps[step] as IModalProps)}
      close={() => {
        if (switchProvider.isSuccess) onToggleSuccess();
        onClose();
      }}
      firstButtonAction={goBackOneStep}
      secondButtonAction={formik.submitForm}
    />
  );
}

export default PartnerSwitchingModal;

const generateIssuingPartnerOptions = () => {
  return Object.entries(issuingPartners).map(([key, value]) => ({ label: value, value: key }));
};

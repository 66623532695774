import { create } from 'zustand';
import { combine, createJSONStorage, devtools, persist } from 'zustand/middleware';

import { CardIssuanceDefaultLimitsResponseType, ProductConfigSettingsType } from '+types';
import { StoreStorage } from '+utils';

type InitialStateType = {
  access: {
    enabled?: boolean;
    reservedIsEnabled?: boolean;
    issuedIsEnabled?: boolean;
  };
  defaultConfig: CardIssuanceDefaultLimitsResponseType;
};

type StateSetterType = {
  setDefaultConfig: (data: CardIssuanceDefaultLimitsResponseType) => void;
  setAccessStatus: (data?: ProductConfigSettingsType) => void;
};

const useProductConfigCardIssuanceStore = create(
  devtools(
    persist(
      combine<InitialStateType, StateSetterType>(
        {
          access: { enabled: false, reservedIsEnabled: false, issuedIsEnabled: false },
          defaultConfig: {
            customer: {} as CardIssuanceDefaultLimitsResponseType['customer'],
            reserved: {} as CardIssuanceDefaultLimitsResponseType['reserved']
          }
        },
        set => ({
          setDefaultConfig: data => {
            set(state => ({
              ...state,
              defaultConfig: {
                ...state.defaultConfig,
                ...data
              }
            }));
          },
          setAccessStatus: data => {
            set(state => ({
              ...state,
              access: {
                ...state.access,
                enabled: data?.setting?.card_issuance?.enabled ?? false,
                reservedIsEnabled: data?.setting?.card_issuance?.virtual?.reserved?.enabled ?? false,
                issuedIsEnabled: data?.setting?.card_issuance?.virtual?.enabled ?? false
              }
            }));
          }
        })
      ),
      {
        name: 'productConfigCardIssuanceStore',
        storage: createJSONStorage(() => StoreStorage)
      }
    )
  )
);

export default useProductConfigCardIssuanceStore;

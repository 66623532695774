import React, { Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';

import Copyable from '+shared/Copyable';
import { ActionButtonType, DisputeRefundStatusType, IRefundDetails } from '+types';
import {
  capitalize,
  capitalizeRemovedash,
  formatAmount,
  formatPAN,
  getDate,
  getTime,
  isAllowed,
  payinMethodMapping,
  switchBank,
  switchCard,
  switchCurrency,
  truncateString
} from '+utils';

import cardIcon from '+assets/img/dashboard/card-icon.svg';
import walletIcon from '+assets/img/dashboard/wallet.svg';

export const statusToShowActionsButtonRefund: DisputeRefundStatusType[] = ['manual', 'failed'];
export const getBankImageByName = (name: string): string => {
  return switchBank[name as keyof typeof switchBank] || switchBank.default;
};
export const generateHeaderSummary = (data: IRefundDetails) => {
  return [
    {
      label: 'Refund Amount',
      value: data?.amount ? formatAmount(data?.amount) : 'Not Available'
    },
    {
      label: 'Date Created',
      value: `${getDate(data?.created_at)}, ${getTime(data?.created_at)}`
    },
    { label: 'Merchant', value: data?.account?.name },
    {
      label: 'Amount Paid',
      value: data?.amount ? formatAmount(data?.payment_source?.amount) : 'Not Available'
    },
    {
      label: 'Transaction ID',
      value: (
        <>
          <div>
            <Copyable text={data?.payment?.reference.toUpperCase?.()} textModifier={text => truncateString(text, 18)} />
          </div>
          <Link to={`/dashboard/pay-ins/${data?.payment_source?.reference}`} className="btn" data-redirectto="original-transaction">
            See original transaction
            <i className="os-icon os-icon-arrow-up-right icon-after ml-2" />
          </Link>
        </>
      )
    }
  ];
};
export function getActionButtons({
  status,
  permissions,
  setManageRefundsModalOpen
}: {
  status: DisputeRefundStatusType;
  permissions: Record<string, boolean>;
  setManageRefundsModalOpen: Dispatch<SetStateAction<boolean>>;
}): Array<ActionButtonType> {
  const result: ActionButtonType[] = [];
  if (statusToShowActionsButtonRefund.includes(status) && isAllowed(permissions, ['refunds.update'])) {
    result.push({
      children: (
        <>
          <i className="os-icon os-icon-settings" />
          <span>Manage Refund</span>
        </>
      ),
      variant: 'primary',
      onClick: () => setManageRefundsModalOpen(true)
    });
  }
  return result;
}

export const generateRefundDetailsSummary = (data: IRefundDetails) => {
  return [
    {
      label: 'Refund Amount',
      value: `${formatAmount(data?.amount)}`
    },
    {
      label: 'Currency Charged',
      value: `${switchCurrency[data?.currency as keyof typeof switchCurrency]}`
    },
    {
      label: 'Channel',
      value: `${capitalizeRemovedash(data?.channel as unknown as string) || 'Not Available'}`
    },
    {
      label: 'Refund Destination',
      value: `${capitalize(data?.destination) || 'Not Available'}`
    },
    {
      label: 'Date Created',
      value: data?.created_at ? `${getDate(data?.created_at)}, ${getTime(data?.created_at)}` : 'Not available'
    },
    {
      label: 'Date Completed',
      value: data?.completed_at ? `${getDate(data?.completed_at)}, ${getTime(data?.completed_at)}` : 'Not available'
    },
    {
      label: 'Processor',
      value: `${capitalizeRemovedash(data?.payment_source?.processor) || 'Not available'}`
    },
    {
      label: 'Processor Reference',
      value: `${data?.payment_source?.processor_reference || 'Not available'}`
    },
    { label: 'Reason For Refund', value: `${capitalizeRemovedash(data?.reversal_reason) || 'Not Available'}` }
  ];
};
export const generatePayerDetailsSummary = (data: IRefundDetails) => {
  return [
    {
      label: 'Payment Method',
      value:
        data?.payment_source?.payment_source_type === 'payment_reversal'
          ? 'Payment Reversal'
          : payinMethodMapping[data?.source?.type as keyof typeof payinMethodMapping] || 'Not Available'
    },
    data && data?.source?.type && ['bank_transfer', 'pay_with_bank'].includes(data?.source?.type || '')
      ? {
          label: 'Bank',
          value: (
            <>
              <img
                id="bank-icon"
                src={getBankImageByName(data?.source?.details?.bank_slug)}
                alt="bank icon"
                style={{ marginLeft: data?.source?.details?.bank_name ? 0 : '' }}
              />
              <span style={{ display: 'inline', marginLeft: 8, color: '#414f5f' }}>
                {capitalize(data?.source?.details?.bank_name || 'No Bank Information Available')}
              </span>
            </>
          )
        }
      : [],
    ...(data?.source?.type === 'wallet'
      ? [
          {
            label: 'Wallet',
            value: (
              <>
                <span>
                  <img id="wallet-icon" src={walletIcon} alt="wallet icon" />
                  <span style={{ display: 'inline', marginLeft: '0.5rem' }}>Korapay wallet</span>
                </span>
                <span>{data?.payment?.customer?.email || 'Not provided'}</span>
              </>
            )
          }
        ]
      : []),
    ...(data?.source?.type === 'card'
      ? [
          {
            label: (
              <>
                <span>
                  <img
                    src={switchCard[data?.source?.details?.card_type as keyof typeof switchCard]}
                    className="card-logo"
                    alt="card-logo"
                    style={{ width: '1.5rem', marginRight: '8px' }}
                  />
                  <span style={{ display: 'inline' }}>{capitalize(data?.source?.details?.card_type)}</span>
                </span>
              </>
            ),
            value: data?.source?.details?.masked_pan ? (
              <>
                <span>{formatPAN(data?.source.details.masked_pan)}</span>
              </>
            ) : (
              <span>
                <img src={cardIcon} className="card-logo" alt="card-icon" />
                <span style={{ display: 'inline' }}>No card information available</span>
              </span>
            )
          }
        ]
      : []),
    ...(['bank_transfer', 'pay_with_bank', 'wallet', 'card'].includes(data?.source?.type)
      ? [
          {
            label: 'Account Number',
            value: data?.source?.details?.account_number || 'Not Available'
          }
        ]
      : []),
    {
      label: 'Customer Name',
      value: (
        <>
          {data?.source?.type === 'bank_transfer' && <span>{capitalize(data?.source?.details?.account_name || 'Not Available')}</span>}
          {(['card', 'wallet', 'pay_with_bank', 'mobile_money', 'payment_reversal'].includes(data?.source?.type) ||
            data?.payment_source.payment_source_type === 'payment_reversal') && (
            <span>{capitalize(data?.payment?.customer?.name || 'Not Available')}</span>
          )}
        </>
      )
    },
    {
      label: 'Email',
      value: data?.payment?.customer?.email ?? 'Not provided'
    },
    ...(data?.metadata?.trace_id ? [{ label: 'Trace id', value: data?.metadata?.trace_id }] : [])
  ];
};

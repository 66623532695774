import React, { ChangeEvent } from 'react';
import { FormikErrors, FormikProps, FormikTouched } from 'formik';

import FieldErrorMsg from '+containers/Dashboard/Shared/FormError';
import NumericInput from '+containers/Dashboard/Shared/NumericInput';
import Icon from '+shared/Icons';
import { CurrencyType, RiskLevelResponseType } from '+types';

import { riskLevelHeadings } from '../../constants/edit-virtual-card-limits-modal';

const EditCardFundingLimits = ({
  currency,
  onBlur,
  values,
  errors,
  touched,
  setFieldValue,
  handleCheckboxChange
}: {
  currency: CurrencyType;
  onBlur: React.ChangeEventHandler;
  values: RiskLevelResponseType;
  touched: FormikTouched<RiskLevelResponseType>;
  errors: FormikErrors<RiskLevelResponseType>;
  setFieldValue: FormikProps<RiskLevelResponseType>['setFieldValue'];
  handleCheckboxChange: (e: ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <div className="edit-vc-limits-form">
      <table className="edit-vc-limits-table">
        <thead>
          <tr>
            <th>
              <span className="invisible">placeholder text</span>
            </th>
            <th className="limit-title">
              <label htmlFor="mflpd">Max. funding limit per day ({currency})</label>
            </th>
            <th className="limit-title">
              <label htmlFor="mflpm">Max. funding limit per month ({currency})</label>
            </th>
            <th className="limit-title">
              <label htmlFor="mflpq">Max. funding limit per quarter ({currency})</label>
            </th>
          </tr>
        </thead>

        <tbody>
          {riskLevelHeadings.map(heading => (
            <tr key={heading.value}>
              <th scope="col" key={heading.value}>
                {heading.label} Risk
              </th>
              <td>
                <span className="sr-only" id={`level-${heading.label}-mflpd`}>
                  level {heading.label} Max. funding limit per day
                </span>
                <NumericInput
                  aria-labelledby={`level-${heading.label}-mflpd`}
                  data-state={`${
                    touched?.[heading.value]?.funding_limit?.daily_max && errors?.[heading.value]?.funding_limit?.daily_max ? 'invalid' : ''
                  }`}
                  id="mflpd"
                  name={`${heading.value}.funding_limit.daily_max`}
                  onBlur={onBlur}
                  onValueChange={values => setFieldValue(`${heading.value}.funding_limit.daily_max`, values.value)}
                  value={values?.[heading.value].funding_limit.daily_max}
                />
                <FieldErrorMsg
                  error={errors?.[heading.value]?.funding_limit?.daily_max}
                  id="mflpd"
                  touched={Boolean(touched?.[heading.value]?.funding_limit?.daily_max)}
                />
              </td>
              <td>
                <span className="sr-only" id={`level-${heading.label}-mflpm`}>
                  level {heading.label} Max. funding limit per month
                </span>
                <NumericInput
                  aria-labelledby={`level-${heading.label}-mflpm`}
                  data-state={`${
                    touched?.[heading.value]?.funding_limit?.monthly_max && errors?.[heading.value]?.funding_limit?.monthly_max
                      ? 'invalid'
                      : ''
                  }`}
                  id="mflpm"
                  name={`${heading.value}.funding_limit.monthly_max`}
                  onBlur={onBlur}
                  onValueChange={values => setFieldValue(`${heading.value}.funding_limit.monthly_max`, values.value)}
                  value={values?.[heading.value].funding_limit.monthly_max}
                />
                <FieldErrorMsg
                  error={errors?.[heading.value]?.funding_limit?.monthly_max}
                  id="mflpm"
                  touched={Boolean(touched?.[heading.value]?.funding_limit?.monthly_max)}
                />
              </td>
              <td>
                <span className="sr-only" id={`level-${heading.label}-mflpq`}>
                  level {heading.label} Max. funding limit per quarter
                </span>
                <NumericInput
                  aria-labelledby={`level-${heading.label}-mflpq`}
                  data-state={`${
                    touched?.[heading.value]?.funding_limit?.quarterly_max && errors?.[heading.value]?.funding_limit?.quarterly_max
                      ? 'invalid'
                      : ''
                  }`}
                  id="mflpq"
                  name={`${heading.value}.funding_limit.quarterly_max`}
                  onBlur={onBlur}
                  onValueChange={values => setFieldValue(`${heading.value}.funding_limit.quarterly_max`, values.value)}
                  value={values?.[heading.value].funding_limit.quarterly_max}
                />
                <FieldErrorMsg
                  error={errors?.[heading.value]?.funding_limit?.quarterly_max}
                  id="mflpm"
                  touched={Boolean(touched?.[heading.value]?.funding_limit?.quarterly_max)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="confirmation-disclaimer">
        <Icon width={15} height={15} fill="#2376f3" name="infoSolid" />
        <p className="copy">Important: The changes you have made here will affect all merchants under the default configuration</p>
      </div>

      <div className="confirmation-check">
        <label htmlFor="confirm-action">Yes, I understand the implications of this action</label>
        <input id="confirm-action" type="checkbox" onChange={handleCheckboxChange} />
      </div>
    </div>
  );
};

export default EditCardFundingLimits;

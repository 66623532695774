import React from 'react';

import FieldErrorMsg from '+containers/Dashboard/Shared/FormError';
import NumericInput from '+containers/Dashboard/Shared/NumericInput';
import { ICardFundingLimits } from '+types';

import { riskLevelMap } from '../../helpers/holderDetailsHelpers';
import ModificationDisclaimer from './ModificationDisclaimer';

const CardFundingLimits = ({ currency, onBlur, errors, touched, values, setFieldValue, getFieldProps }: ICardFundingLimits) => {
  return (
    <div className="fade-in">
      <fieldset className="mt-1">
        <div>
          <label htmlFor="mrl" className="mt-0">
            Merchant&apos;s Risk level
          </label>
          <select id="mrl" {...getFieldProps('riskLevel')} className="form-control" aria-labelledby="err-mrl" disabled>
            {Object.entries(riskLevelMap).map(([key, value]) => (
              <option key={key} value={key}>
                {value}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label htmlFor="mflpd">Max. funding limit per day ({currency})</label>
          <NumericInput
            aria-labelledby="err-mflpd"
            className="form-control"
            data-state={touched.daily_max && errors.daily_max ? 'invalid' : ''}
            id="mflpd"
            name="daily_max"
            onBlur={onBlur}
            onValueChange={values => setFieldValue('daily_max', values.value)}
            value={values.daily_max}
          />
          <FieldErrorMsg id="mflpd" touched={Boolean(touched.daily_max)} error={errors.daily_max ?? ''} />
        </div>

        <div>
          <label htmlFor="mflpm">Max. funding limit per month ({currency})</label>
          <NumericInput
            aria-labelledby="err-mflpm"
            className="form-control"
            data-state={touched.monthly_max && errors.monthly_max ? 'invalid' : ''}
            id="mflpm"
            name="monthly_max"
            onBlur={onBlur}
            onValueChange={values => setFieldValue('monthly_max', values.value)}
            value={values.monthly_max}
          />
          <FieldErrorMsg id="mflpm" touched={Boolean(touched.monthly_max)} error={errors.monthly_max ?? ''} />
        </div>

        <div>
          <label htmlFor="mflpq">Max. funding limit per quarter ({currency})</label>
          <NumericInput
            aria-labelledby="err-mflpq"
            className="form-control"
            data-state={touched.quarterly_max && errors.quarterly_max ? 'invalid' : ''}
            id="mflpq"
            name="quarterly_max"
            onBlur={onBlur}
            onValueChange={values => setFieldValue('quarterly_max', values.value)}
            value={values.quarterly_max}
          />
          <FieldErrorMsg id="mflpq" touched={Boolean(touched.quarterly_max)} error={errors.quarterly_max ?? ''} />
        </div>
      </fieldset>

      <ModificationDisclaimer />
    </div>
  );
};

export default CardFundingLimits;

import { PCIDSSLevelResponseType, RiskLevelResponseType } from '+types';

type PartialWithExtractedKeys<T> = Partial<Record<keyof T, unknown>>;

type FormValuesType = RiskLevelResponseType | PCIDSSLevelResponseType;

const isEmpty = (value: unknown) => String(value).trim() === '';

export const formValidationFn = (formValues: FormValuesType) => {
  const errors: PartialWithExtractedKeys<RiskLevelResponseType | PCIDSSLevelResponseType> = {};

  // Low risk errors
  if ((formValues as RiskLevelResponseType).low_risk) {
    const lowRiskErrors: PartialWithExtractedKeys<RiskLevelResponseType['low_risk']> = {};
    const fundingErrors: PartialWithExtractedKeys<RiskLevelResponseType['low_risk']['funding_limit']> = {};
    const spendingErrors: PartialWithExtractedKeys<RiskLevelResponseType['low_risk']['spending_limit']> = {};

    // Funding limit errors
    if (isEmpty((formValues as RiskLevelResponseType).low_risk?.funding_limit.daily_max)) fundingErrors.daily_max = 'Invalid value';

    if (isEmpty((formValues as RiskLevelResponseType).low_risk?.funding_limit.monthly_max)) fundingErrors.monthly_max = 'Invalid value';

    if (isEmpty((formValues as RiskLevelResponseType).low_risk?.funding_limit.quarterly_max)) fundingErrors.quarterly_max = 'Invalid value';

    if (Object.keys(fundingErrors).length) lowRiskErrors.funding_limit = fundingErrors;

    // Spending limit errors
    if (isEmpty((formValues as RiskLevelResponseType).low_risk?.spending_limit.daily_max)) spendingErrors.daily_max = 'Invalid value';

    if (isEmpty((formValues as RiskLevelResponseType).low_risk?.spending_limit.monthly_max)) spendingErrors.monthly_max = 'Invalid value';

    if (isEmpty((formValues as RiskLevelResponseType).low_risk?.spending_limit.per_transaction_max))
      spendingErrors.per_transaction_max = 'Invalid value';

    if (Object.keys(spendingErrors).length) lowRiskErrors.spending_limit = spendingErrors;

    if (Object.keys(lowRiskErrors).length) (errors as PartialWithExtractedKeys<RiskLevelResponseType>).low_risk = lowRiskErrors;
  }

  // Medium risk errors

  if ((formValues as RiskLevelResponseType).medium_risk) {
    const mediumRiskErrors: PartialWithExtractedKeys<RiskLevelResponseType['medium_risk']> = {};
    const fundingErrors: PartialWithExtractedKeys<RiskLevelResponseType['medium_risk']['funding_limit']> = {};
    const spendingErrors: PartialWithExtractedKeys<RiskLevelResponseType['medium_risk']['spending_limit']> = {};

    // Funding limit errors
    if (isEmpty((formValues as RiskLevelResponseType).medium_risk?.funding_limit.daily_max)) fundingErrors.daily_max = 'Invalid value';

    if (isEmpty((formValues as RiskLevelResponseType).medium_risk?.funding_limit.monthly_max)) fundingErrors.monthly_max = 'Invalid value';

    if (isEmpty((formValues as RiskLevelResponseType).medium_risk?.funding_limit.quarterly_max))
      fundingErrors.quarterly_max = 'Invalid value';

    if (Object.keys(fundingErrors).length) mediumRiskErrors.funding_limit = fundingErrors;

    // Spending limit errors
    if (isEmpty((formValues as RiskLevelResponseType).medium_risk?.spending_limit.daily_max)) spendingErrors.daily_max = 'Invalid value';

    if (isEmpty((formValues as RiskLevelResponseType).medium_risk?.spending_limit.monthly_max))
      spendingErrors.monthly_max = 'Invalid value';

    if (isEmpty((formValues as RiskLevelResponseType).medium_risk?.spending_limit.per_transaction_max))
      spendingErrors.per_transaction_max = 'Invalid value';

    if (Object.keys(spendingErrors).length) mediumRiskErrors.spending_limit = spendingErrors;

    if (Object.keys(mediumRiskErrors).length) (errors as PartialWithExtractedKeys<RiskLevelResponseType>).medium_risk = mediumRiskErrors;
  }

  // Above average risk errors
  if ((formValues as RiskLevelResponseType).above_average_risk) {
    const aboveAverageRiskErrors: PartialWithExtractedKeys<RiskLevelResponseType['above_average_risk']> = {};
    const fundingErrors: PartialWithExtractedKeys<RiskLevelResponseType['above_average_risk']['funding_limit']> = {};
    const spendingErrors: PartialWithExtractedKeys<RiskLevelResponseType['above_average_risk']['spending_limit']> = {};

    // Funding limit errors
    if (isEmpty((formValues as RiskLevelResponseType).above_average_risk?.funding_limit.daily_max))
      fundingErrors.daily_max = 'Invalid value';

    if (isEmpty((formValues as RiskLevelResponseType).above_average_risk?.funding_limit.monthly_max))
      fundingErrors.monthly_max = 'Invalid value';

    if (isEmpty((formValues as RiskLevelResponseType).above_average_risk?.funding_limit.quarterly_max))
      fundingErrors.quarterly_max = 'Invalid value';

    if (Object.keys(fundingErrors).length) aboveAverageRiskErrors.funding_limit = fundingErrors;

    // Spending limit errors
    if (isEmpty((formValues as RiskLevelResponseType).above_average_risk?.spending_limit.daily_max))
      spendingErrors.daily_max = 'Invalid value';

    if (isEmpty((formValues as RiskLevelResponseType).above_average_risk?.spending_limit.monthly_max))
      spendingErrors.monthly_max = 'Invalid value';

    if (isEmpty((formValues as RiskLevelResponseType).above_average_risk?.spending_limit.per_transaction_max))
      spendingErrors.per_transaction_max = 'Invalid value';

    if (Object.keys(spendingErrors).length) aboveAverageRiskErrors.spending_limit = spendingErrors;

    if (Object.keys(aboveAverageRiskErrors).length)
      (errors as PartialWithExtractedKeys<RiskLevelResponseType>).above_average_risk = aboveAverageRiskErrors;
  }

  // High risk errors
  if ((formValues as RiskLevelResponseType).high_risk) {
    const highRiskErrors: PartialWithExtractedKeys<RiskLevelResponseType['high_risk']> = {};
    const fundingErrors: PartialWithExtractedKeys<RiskLevelResponseType['high_risk']['funding_limit']> = {};
    const spendingErrors: PartialWithExtractedKeys<RiskLevelResponseType['high_risk']['spending_limit']> = {};

    // Funding limit errors
    if (isEmpty((formValues as RiskLevelResponseType).high_risk?.funding_limit.daily_max)) fundingErrors.daily_max = 'Invalid value';

    if (isEmpty((formValues as RiskLevelResponseType).high_risk?.funding_limit.monthly_max)) fundingErrors.monthly_max = 'Invalid value';

    if (isEmpty((formValues as RiskLevelResponseType).high_risk?.funding_limit.quarterly_max))
      fundingErrors.quarterly_max = 'Invalid value';

    if (Object.keys(fundingErrors).length) highRiskErrors.funding_limit = fundingErrors;

    // Spending limit errors
    if (isEmpty((formValues as RiskLevelResponseType).high_risk?.spending_limit.daily_max)) spendingErrors.daily_max = 'Invalid value';

    if (isEmpty((formValues as RiskLevelResponseType).high_risk?.spending_limit.monthly_max)) spendingErrors.monthly_max = 'Invalid value';

    if (isEmpty((formValues as RiskLevelResponseType).high_risk?.spending_limit.per_transaction_max))
      spendingErrors.per_transaction_max = 'Invalid value';

    if (Object.keys(spendingErrors).length) highRiskErrors.spending_limit = spendingErrors;

    if (Object.keys(highRiskErrors).length) (errors as PartialWithExtractedKeys<RiskLevelResponseType>).high_risk = highRiskErrors;
  }

  // PCIDSS errors
  if ((formValues as PCIDSSLevelResponseType).level_0) {
    const level0Errors: PartialWithExtractedKeys<PCIDSSLevelResponseType['level_0']> = {};
    if (isEmpty((formValues as PCIDSSLevelResponseType).level_0?.yearly_issued_cards)) level0Errors.yearly_issued_cards = 'Invalid value';

    if (isEmpty((formValues as PCIDSSLevelResponseType).level_0?.yearly_transaction_count))
      level0Errors.yearly_transaction_count = 'Invalid value';

    if (Number((formValues as PCIDSSLevelResponseType).level_0?.yearly_issued_cards) < 1)
      level0Errors.yearly_issued_cards = 'Value must be > 0';

    if (Number((formValues as PCIDSSLevelResponseType).level_0?.yearly_transaction_count) < 1)
      level0Errors.yearly_transaction_count = 'Value must be > 0';

    if (Object.keys(level0Errors).length) (errors as PartialWithExtractedKeys<PCIDSSLevelResponseType>).level_0 = level0Errors;
  }

  if ((formValues as PCIDSSLevelResponseType).level_1) {
    const level1Errors: PartialWithExtractedKeys<PCIDSSLevelResponseType['level_1']> = {};
    if (isEmpty((formValues as PCIDSSLevelResponseType).level_1?.yearly_issued_cards)) level1Errors.yearly_issued_cards = 'Invalid value';

    if (isEmpty((formValues as PCIDSSLevelResponseType).level_1?.yearly_transaction_count))
      level1Errors.yearly_transaction_count = 'Invalid value';

    if (Number((formValues as PCIDSSLevelResponseType).level_1?.yearly_issued_cards) < 1)
      level1Errors.yearly_issued_cards = 'Value must be > 0';

    if (Number((formValues as PCIDSSLevelResponseType).level_1?.yearly_transaction_count) < 1)
      level1Errors.yearly_transaction_count = 'Value must be > 0';

    if (Object.keys(level1Errors).length) (errors as PartialWithExtractedKeys<PCIDSSLevelResponseType>).level_1 = level1Errors;
  }

  if ((formValues as PCIDSSLevelResponseType).level_2) {
    const level2Errors: PartialWithExtractedKeys<PCIDSSLevelResponseType['level_2']> = {};
    if (isEmpty((formValues as PCIDSSLevelResponseType).level_2?.yearly_issued_cards)) level2Errors.yearly_issued_cards = 'Invalid value';

    if (isEmpty((formValues as PCIDSSLevelResponseType).level_2?.yearly_transaction_count))
      level2Errors.yearly_transaction_count = 'Invalid value';

    if (Number((formValues as PCIDSSLevelResponseType).level_2?.yearly_issued_cards) < 1)
      level2Errors.yearly_issued_cards = 'Value must be > 0';

    if (Number((formValues as PCIDSSLevelResponseType).level_2?.yearly_transaction_count) < 1)
      level2Errors.yearly_transaction_count = 'Value must be > 0';

    if (Object.keys(level2Errors).length) (errors as PartialWithExtractedKeys<PCIDSSLevelResponseType>).level_2 = level2Errors;
  }

  if ((formValues as PCIDSSLevelResponseType).level_3) {
    const level3Errors: PartialWithExtractedKeys<PCIDSSLevelResponseType['level_3']> = {};
    if (isEmpty((formValues as PCIDSSLevelResponseType).level_3?.yearly_issued_cards)) level3Errors.yearly_issued_cards = 'Invalid value';

    if (isEmpty((formValues as PCIDSSLevelResponseType).level_3?.yearly_transaction_count))
      level3Errors.yearly_transaction_count = 'Invalid value';

    if (Number((formValues as PCIDSSLevelResponseType).level_3?.yearly_issued_cards) < 1)
      level3Errors.yearly_issued_cards = 'Value must be > 0';

    if (Number((formValues as PCIDSSLevelResponseType).level_3?.yearly_transaction_count) < 1)
      level3Errors.yearly_transaction_count = 'Value must be > 0';

    if (Object.keys(level3Errors).length) (errors as PartialWithExtractedKeys<PCIDSSLevelResponseType>).level_3 = level3Errors;
  }

  if ((formValues as PCIDSSLevelResponseType).level_4) {
    const level4Errors: PartialWithExtractedKeys<PCIDSSLevelResponseType['level_4']> = {};
    if (isEmpty((formValues as PCIDSSLevelResponseType).level_4?.yearly_issued_cards)) level4Errors.yearly_issued_cards = 'Invalid value';

    if (isEmpty((formValues as PCIDSSLevelResponseType).level_4?.yearly_transaction_count))
      level4Errors.yearly_transaction_count = 'Invalid value';

    if (Number((formValues as PCIDSSLevelResponseType).level_4?.yearly_issued_cards) < 1)
      level4Errors.yearly_issued_cards = 'Value must be > 0';

    if (Number((formValues as PCIDSSLevelResponseType).level_4?.yearly_transaction_count) < 1)
      level4Errors.yearly_transaction_count = 'Value must be > 0';

    if (Object.keys(level4Errors).length) (errors as PartialWithExtractedKeys<PCIDSSLevelResponseType>).level_4 = level4Errors;
  }

  return errors;
};

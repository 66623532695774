import React from 'react';

interface IToggleDefaultConfig {
  selectedOption: 'custom' | 'default';
  onToggleConfigPreference: React.ChangeEventHandler<HTMLInputElement>;
  description: string;
}

const ToggleDefaultConfig = ({ selectedOption, onToggleConfigPreference, description }: IToggleDefaultConfig) => {
  return (
    <div className="limit-toggle-with-desc">
      <p className="font-weight-bold m-0" id="config_options">
        {description}
      </p>
      <fieldset aria-labelledby="config_options" className="limit-toggle-comp">
        <div className="controls">
          <div className="radio-control">
            <label htmlFor="default-config" className="m-0">
              Enable access to the default configuration
            </label>
            <input
              checked={selectedOption === 'default'}
              id="default-config"
              value="default"
              type="radio"
              onChange={onToggleConfigPreference}
            />
          </div>
          <div className="radio-control">
            <label htmlFor="custom-config" className="m-0">
              Enable access to custom configuration
            </label>
            <input
              checked={selectedOption === 'custom'}
              id="custom-config"
              value="custom"
              type="radio"
              onChange={onToggleConfigPreference}
            />
          </div>
        </div>
      </fieldset>
    </div>
  );
};

export default ToggleDefaultConfig;

import React from 'react';

import Modal from '+containers/Dashboard/Shared/Modal';

export default function ConfigurationConfirmationModal({
  handleClick,
  close,
  openSaveModal
}: {
  handleClick: () => void;
  close: () => void;
  openSaveModal: boolean;
}) {
  const confirmationModal = () => {
    const content = {
      heading: 'Confirm changes',
      description:
        'Please confirm that you want to save the changes you have made to this configuration. Saved changes will be applied immediately.',
      content: '',
      firstButtonText: 'Cancel',
      secondButtonText: `Yes, Confirm`,
      secondButtonAction: handleClick,
      equalFooterBtn: true,
      completedHeading: `Saved!`,
      completedDescription: `You have successfully saved changes to this configuration.`,
      secondaryCompletedModal: true,
      close: close,
      secondButtonActionIsTerminal: true
    };

    return content;
  };
  return openSaveModal && <Modal size="sm" {...confirmationModal()} />;
}

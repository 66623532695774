import React from 'react';

import Badge from '+containers/Dashboard/Shared/Badge';
import Typography from '+containers/Dashboard/Shared/Typography';
import { formatProductConfigStatus } from '+utils';

import './index.scss';

interface ISectionInfo {
  status: boolean;
  title: string;
  description: string;
}

const SectionInfo = ({ status, title, description }: ISectionInfo) => {
  const defaultDescription = `Here you can find the ${title} products for this merchant. You can modify their limits and payment channels configuration.`;

  return (
    <div className="section-info-comp">
      <div className="title-and-status">
        <Typography variant="h2">{title}</Typography>
        <Badge status={formatProductConfigStatus(String(status))} />
      </div>
      <Typography variant="subtitle2">{description ?? defaultDescription}</Typography>
    </div>
  );
};

export default SectionInfo;

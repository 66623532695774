export const riskLevels = [
  { label: 'Low risk', value: 'low_risk' },
  { label: 'Medium risk', value: 'medium_risk' },
  { label: 'High risk', value: 'high_risk' }
];

export const pciDSSLevels = [
  { label: 'Level 4', value: 'level_4' },
  { label: 'Level 3', value: 'level_3' },
  { label: 'Level 2', value: 'level_2' },
  { label: 'Level 1', value: 'level_1' },
  { label: 'Level 0', value: 'level_0' }
];

export const rvcPlans = [
  { label: 'Startup', value: 'startup' },
  { label: 'Growth', value: 'growth' },
  { label: 'Enterprise', value: 'enterprise' }
];

export const customerCardMonthlyPaymentValues = [
  { value: 'LessThan50K', label: '$50,000 and below' },
  { value: 'From50KTo100K', label: 'Between $50,000 - $100,000' },
  { value: 'From100KTo500K', label: 'Between $100,000 - $500,000' },
  { value: 'From500KTo1M', label: 'Between $500,000 - $1,000,000' },
  { value: 'Above1M', label: 'Above $1,000,000' }
] as const;

export const reservedCardMonthlyPaymentValues = [
  { value: 'LessThan1K', label: '$1,000 and below' },
  { value: 'From1KTo5K', label: 'Between $1,000 - $5,000' },
  { value: 'From5KTo10K', label: 'Between $5,000 - $1,0000' },
  { value: 'From10KTo50K', label: 'Between $10,000 - $50,000' },
  { value: 'Above50K', label: 'Above $50,000' }
] as const;

export const issuingPartners = {
  maplerad: 'Maplerad',
  sudo: 'Sudo',
  paytrade: 'Paytrade',
  passpoint: 'Passpoint'
} as const;

export const reasonsForPartnerSwitching = [
  { label: 'Frequent downtime', value: 'frequent_downtime' },
  { label: 'Regulatory compliance', value: 'regulatory_compliance' },
  { label: 'Feature limitations', value: 'feature_limitations' },
  { label: 'Others', value: 'others' }
] as const;

import React from 'react';

import FieldErrorMsg from '+containers/Dashboard/Shared/FormError';
import NumericInput from '+containers/Dashboard/Shared/NumericInput';
import { ILimitConfigFields, IMerchantInfo } from '+types';

import { riskLevels } from '../../../constants/common';

const FundingLimit = ({ getFieldProps, isDefault, currency, values, setFieldValue, touched, errors, handleBlur }: ILimitConfigFields) => {
  const isInvalid = (key: keyof IMerchantInfo) => touched[key] && errors[key];

  return (
    <fieldset className="fade-in mt-1">
      <div>
        <label htmlFor="risk-level">Risk level</label>
        <select className="form-control" id="risk-level" {...getFieldProps('risk_level')} disabled={isDefault}>
          {riskLevels.map(level => (
            <option value={level.value} key={level.value}>
              {level.label}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label htmlFor="max-daily-funding">Maximum daily funding ({currency})</label>
        <NumericInput
          name="max_daily_funding"
          id="max-daily-funding"
          value={values.max_daily_funding}
          onValueChange={values => setFieldValue('max_daily_funding', values.value)}
          onBlur={handleBlur}
          className="form-control"
          disabled={isDefault}
          data-state={isInvalid('max_daily_funding') ? 'invalid' : ''}
        />
        <FieldErrorMsg touched={Boolean(touched.max_daily_funding)} error={errors.max_daily_funding} id="max-daily-funding" />
      </div>

      <div>
        <label htmlFor="max-monthly-funding">Maximum monthly funding ({currency})</label>
        <NumericInput
          name="max_monthly_funding"
          id="max-monthly-funding"
          value={values.max_monthly_funding}
          onValueChange={values => setFieldValue('max_monthly_funding', values.value)}
          onBlur={handleBlur}
          disabled={isDefault}
          data-state={isInvalid('max_monthly_funding') ? 'invalid' : ''}
        />
        <FieldErrorMsg touched={Boolean(touched.max_monthly_funding)} error={errors.max_monthly_funding} id="max-monthly-funding" />
      </div>

      <div>
        <label htmlFor="max-quarterly-funding">Maximum quarterly funding ({currency})</label>
        <NumericInput
          name="max_quarterly_funding"
          id="max-quarterly-funding"
          value={values.max_quarterly_funding}
          onValueChange={values => setFieldValue('max_quarterly_funding', values.value)}
          onBlur={handleBlur}
          disabled={isDefault}
          data-state={isInvalid('max_quarterly_funding') ? 'invalid' : ''}
        />
        <FieldErrorMsg touched={Boolean(touched.max_quarterly_funding)} error={errors.max_quarterly_funding} id="max-quarterly-funding" />
      </div>
    </fieldset>
  );
};

export default FundingLimit;

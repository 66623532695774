/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { MutationOptions, useMutation } from 'react-query';
import { FormikErrors, FormikProps, FormikTouched, useFormik } from 'formik';

import { useFeedbackHandler, useSearchQuery } from '+hooks';
import APIRequest from '+services/api-services';
import Modal, { IModalProps } from '+shared/Modal';
import {
  CurrencyType,
  CustomizePCIDSSLimitsPayloadType,
  CustomizeRiskLevelLimitsPayloadType,
  EditMerchantLimitModalPayloadType,
  EditMerchantLimitsPayloadType,
  IEditMerchantLimitsModal,
  LimitType,
  MerchantFundingLimitsType,
  MerchantPCIDSSLimitsType,
  MerchantSpendingLimitsType,
  PCIDSSLevelType,
  RiskLevelType
} from '+types';
import { logError } from '+utils';

import CardFundingLimits from './CardFundingLimits';
import CardSpendingLimits from './CardSpendingLimits';
import { formValidationFn } from './editMerchantLimitsModalHelpers';
import LimitsByPCIDSS from './LimitsByPCIDSS';

type ConfirmSaveStepType = 'confirm_save_pci_dss' | 'confirm_save_spending' | 'confirm_save_funding';
type StepType = LimitType | ConfirmSaveStepType;

type WithExtras<T> = T & {
  riskLevel?: RiskLevelType;
  pciDssLevel?: PCIDSSLevelType;
  type: 'custom' | 'default';
};

type MutationSuccessResponse = {
  message: string;
};

type MutationErrorResponse = {
  response?: {
    data?: {
      message?: string;
    };
  };
};

const api = new APIRequest();

const getFormValuesType = (step: StepType, formValues: EditMerchantLimitModalPayloadType) => {
  if (step === 'confirm_save_funding') {
    return formValues as WithExtras<MerchantFundingLimitsType>;
  } else if (step === 'confirm_save_spending') {
    return formValues as WithExtras<MerchantSpendingLimitsType>;
  } else {
    return formValues as WithExtras<MerchantPCIDSSLimitsType>;
  }
};

const EditMerchantLimitsModal = ({
  merchantId,
  limitType,
  onClose,
  cardCategory,
  defaultValues,
  refetchLimits
}: IEditMerchantLimitsModal) => {
  const isCustomType = defaultValues.type === 'custom';
  const searchQuery = useSearchQuery<{ currency: CurrencyType }>();
  const [step, setStep] = useState<StepType>(limitType);
  const currency = searchQuery.value?.currency ?? 'USD';
  const { feedbackInit, closeFeedback } = useFeedbackHandler();
  const isReserved = cardCategory === 'reserved';

  const getMutationOptions = <T,>(
    componentLevel = false
  ): Omit<MutationOptions<MutationSuccessResponse, MutationErrorResponse, T>, 'mutationFn' | 'mutationKey'> => ({
    onSuccess: data => {
      feedbackInit({ message: data?.message, type: 'success', componentLevel });
      refetchLimits();
    },
    onError: error => {
      logError(error);
      feedbackInit({
        message: error.response?.data?.message || 'Action was unsuccessful',
        type: 'danger',
        componentLevel: true
      });
    },
    onSettled: () => {
      setTimeout(() => {
        closeFeedback();
      }, 5000);
    }
  });

  const { mutateAsync: mutateCustomizeRiskLevel } = useMutation(
    (payload: CustomizeRiskLevelLimitsPayloadType) => api.customizeMerchantRiskLevelLimits(merchantId, payload),
    getMutationOptions()
  );

  const { mutateAsync: mutateCustomizePCIDSSLevel } = useMutation(
    (payload: CustomizePCIDSSLimitsPayloadType) => api.customizeMerchantPCIDSSLevelLimits(merchantId, payload),
    getMutationOptions(true)
  );

  const editMerchantLimits = useMutation(
    (payload: EditMerchantLimitsPayloadType) => api.editMerchantLimits(merchantId, payload),
    getMutationOptions(true)
  );

  const formik = useFormik({
    initialValues: defaultValues,
    validate: formValidationFn,
    onSubmit: async formValues => {
      const typedFormValues = getFormValuesType(step, formValues);

      switch (step) {
        case 'funding':
          setStep('confirm_save_funding');
          break;
        case 'confirm_save_funding':
          if ('quarterly_max' in typedFormValues) {
            await handleModifyFundingLimits(typedFormValues);
          }
          break;
        case 'spending':
          setStep('confirm_save_spending');
          break;
        case 'confirm_save_spending':
          if ('per_transaction_max' in typedFormValues) {
            await handleModifySpendingLimits(typedFormValues);
          }
          break;
        case 'pci_dss':
          setStep('confirm_save_pci_dss');
          break;
        case 'confirm_save_pci_dss':
        default:
          await handleMutatePCIDSSLevel(typedFormValues as WithExtras<MerchantPCIDSSLimitsType>);
          break;
      }
    }
  });

  const goToPrev = () => {
    switch (step) {
      case 'confirm_save_spending':
        setStep('spending');
        break;
      case 'confirm_save_funding':
        setStep('funding');
        break;
      case 'confirm_save_pci_dss':
        setStep('pci_dss');
        break;
      default:
        onClose();
        break;
    }
  };

  const handleModifySpendingLimits = async (formValues: WithExtras<MerchantSpendingLimitsType>) => {
    if (isCustomType) {
      await editMerchantLimits.mutateAsync({
        type: 'risk_level',
        card_category: cardCategory,
        risk_level_limits: {
          spending_limit: {
            monthly_max: Number(formValues.monthly_max),
            per_transaction_max: Number(formValues.per_transaction_max),
            daily_max: Number(formValues.daily_max)
          }
        }
      });
    } else {
      await mutateCustomizeRiskLevel({
        currency,
        card_category: cardCategory,
        spending_limit: {
          per_transaction_max: Number(formValues.per_transaction_max),
          daily_max: Number(formValues.daily_max),
          monthly_max: Number(formValues.monthly_max)
        }
      });
    }
  };

  const handleModifyFundingLimits = async (formValues: WithExtras<MerchantFundingLimitsType>) => {
    if (isCustomType) {
      await editMerchantLimits.mutateAsync({
        type: 'risk_level',
        card_category: cardCategory,
        risk_level_limits: {
          funding_limit: {
            daily_max: Number(formValues.daily_max),
            monthly_max: Number(formValues.monthly_max),
            quarterly_max: Number(formValues.quarterly_max)
          }
        }
      });
    } else {
      await mutateCustomizeRiskLevel({
        currency,
        card_category: cardCategory,
        funding_limit: {
          daily_max: Number(formValues.daily_max),
          monthly_max: Number(formValues.monthly_max),
          quarterly_max: Number(formValues.quarterly_max)
        }
      });
    }
  };

  const handleMutatePCIDSSLevel = async (formValues: WithExtras<MerchantPCIDSSLimitsType>) => {
    if (isCustomType) {
      await editMerchantLimits.mutateAsync({
        type: 'pcidss_level',
        card_category: cardCategory,
        pcidss_level_limits: {
          yearly_issued_cards: Number(formValues.yearly_issued_cards),
          yearly_transaction_count:
            formValues.yearly_transaction_count === 'limitless' ? 'limitless' : Number(formValues.yearly_transaction_count)
        }
      });
    } else {
      await mutateCustomizePCIDSSLevel({
        card_category: cardCategory,
        currency,
        yearly_issued_cards: Number(formValues.yearly_issued_cards),
        yearly_transaction_count:
          formValues.yearly_transaction_count === 'limitless' ? 'limitless' : Number(formValues.yearly_transaction_count)
      });
    }
  };

  const getStepProps = (): Partial<IModalProps> => {
    let stepProps: Partial<IModalProps>;
    const sharedProps: Partial<IModalProps> = {
      close: onClose,
      completedAction: onClose,
      secondButtonText: 'Save',
      size: 'md',
      firstButtonAction: goToPrev,
      maxHeight: '700px',
      isScrollable: true,
      secondaryCompletedModal: true,
      completedDescription: 'You have successfully made changes to this configuration.',
      secondButtonAction: formik.submitForm,
      secondButtonActionIsTerminal: false,
      headerBottomBorder: true
    };

    switch (step) {
      case 'confirm_save_pci_dss':
      case 'confirm_save_spending':
      case 'confirm_save_funding':
        stepProps = {
          heading: 'Confirm modification',
          description: 'Kindly confirm that you want to apply the modification(s) to the configuration. They will take effect immediately.',
          size: 'sm',
          secondButtonText: 'Yes  Confirm',
          secondButtonActionIsTerminal: true,
          firstButtonText: 'Back'
        };
        break;
      case 'spending':
        stepProps = {
          heading: 'Card Spending Limits',
          description: `Set card spending limits across ${isReserved ? 'RVCs' : 'customer cards'} for this merchant.`,
          content: (
            <CardSpendingLimits
              currency={currency}
              onBlur={formik.handleBlur}
              errors={formik.errors as FormikErrors<MerchantSpendingLimitsType>}
              touched={formik.touched as FormikTouched<MerchantSpendingLimitsType>}
              setFieldValue={formik.setFieldValue as FormikProps<MerchantSpendingLimitsType>['setFieldValue']}
              values={formik.values as MerchantSpendingLimitsType}
              getFieldProps={formik.getFieldProps}
            />
          )
        };
        break;
      case 'funding':
        stepProps = {
          heading: 'Card Funding Limits',
          description: `Set card funding limits across ${isReserved ? 'RVCs' : 'customer cards'} for this merchant.`,
          content: (
            <CardFundingLimits
              currency={currency}
              onBlur={formik.handleBlur}
              errors={formik.errors as FormikErrors<MerchantFundingLimitsType>}
              touched={formik.touched as FormikTouched<MerchantFundingLimitsType>}
              setFieldValue={formik.setFieldValue as FormikProps<MerchantFundingLimitsType>['setFieldValue']}
              values={formik.values as MerchantFundingLimitsType}
              getFieldProps={formik.getFieldProps}
            />
          )
        };
        break;
      case 'pci_dss':
      default:
        stepProps = {
          heading: 'Limits based on PCI DSS',
          description: 'Configure limits based on merchant’s PCI DSS.',
          content: (
            <LimitsByPCIDSS
              getFieldProps={formik.getFieldProps}
              onBlur={formik.handleBlur}
              errors={formik.errors as FormikErrors<MerchantPCIDSSLimitsType>}
              touched={formik.touched as FormikTouched<MerchantPCIDSSLimitsType>}
              setFieldValue={formik.setFieldValue as FormikProps<MerchantPCIDSSLimitsType>['setFieldValue']}
              values={formik.values as MerchantPCIDSSLimitsType}
            />
          )
        };
        break;
    }

    return { ...sharedProps, ...stepProps };
  };

  return <Modal {...(getStepProps() as IModalProps)} />;
};

export default EditMerchantLimitsModal;

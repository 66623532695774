import React from 'react';
import classNames from 'classnames';

import './typography.scss';

const Typography = ({
  children,
  variant = 'h1',
  as,
  className = '',
  ...otherProps
}: {
  children: React.ReactNode;
  variant?: `h${1 | 2 | 3 | 4}` | `subtitle${1 | 2 | 3 | 4}`;
  as?: React.ElementType;
  className?: string;
  [key: string]: any;
}) => {
  const Component = as || (variant.startsWith('subtitle') ? 'p' : (variant as 'h1' | 'h2' | 'h3' | 'h4'));

  return (
    <Component className={classNames(`typography typography--${variant}`, className)} {...otherProps}>
      {children}
    </Component>
  );
};

export default Typography;

import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { useFeedbackHandler, useSearchQuery } from '+hooks';
import APIRequest from '+services/api-services';
import { logError } from '+utils';

const api = new APIRequest();

const useGetWhiteListeIP = () => {
  const { id } = useParams<{ id: string }>();
  const searchQuery = useSearchQuery();
  const keyword = searchQuery.get('keyword');
  const { feedbackInit } = useFeedbackHandler();

  const { data: ipData, isLoading } = useQuery(`WHITELISTED_IP_${id}`, () => api.getMerchantWhiteListedIp(id), {
    onError: e => {
      logError(e);
      feedbackInit({
        message: `There has been an error fetching WhitelistedIP address: ${id}.`,
        type: 'danger'
      });
    }
  });

  const data = ipData?.data?.ip_addresses?.filter((ip: { ip_address: string }) => ip?.ip_address.includes(keyword));
  return {
    isLoading,
    data: data ?? []
  };
};

export default useGetWhiteListeIP;

import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import TransactionDetails from '+containers/Dashboard/TransactionDetailsNew';
import { useFeedbackHandler } from '+hooks';
import APIRequest from '+services/api-services';
import { chargebackHistoryDataT, IChargeBackDetails } from '+types';
import { formatAmount, history, switchTrxnMessage } from '+utils';

import ChargebackActivityUpdate from './ChargebackActivityUpdate';
import ChargebackDetailsModal, { ModalStepT } from './ChargebackDetailsModal';
import {
  generateChargeBackDetailsSummary,
  generateHeaderSummary,
  generatePayerDetailsSummary,
  getActionButtons,
  mapToChargebackActivityUpdate
} from './ChargeBackHelper';

const api = new APIRequest();
export default function ChargeBackDetails() {
  const { id } = useParams<Record<string, string>>();
  const [chargebackModalState, setChargebackModalState] = useState({
    open: false,
    type: ''
  });
  const { feedbackInit } = useFeedbackHandler();

  const { data, refetch, isLoading } = useQuery<IChargeBackDetails>(
    [`CHARGE_BACK_TRANSACTION_DETAILS_${id}`],
    () => api.getSingleChargeback(id),
    {
      onError: () => {
        feedbackInit({
          message: `There has been an error fetching the details for the transaction: ${id.toUpperCase()}.`,
          type: 'danger',
          action: { name: 'retry', action: () => refetch() }
        });
        history.goBack();
      }
    }
  );

  const summaries = generateHeaderSummary(data as IChargeBackDetails);
  const actionButtons = getActionButtons({
    setChargebackModalState
  });
  const chargeBackDetails = generateChargeBackDetailsSummary(data as IChargeBackDetails);
  const payerDetails = generatePayerDetailsSummary(data as IChargeBackDetails);
  const chargeBackActivityProps = mapToChargebackActivityUpdate({
    chargebackDetails: data as IChargeBackDetails,
    resolveAction: () =>
      setChargebackModalState({
        open: true,
        type: 'resolve-chargeback'
      })
  });
  return (
    <>
      <TransactionDetails>
        <TransactionDetails.Header
          heading={formatAmount(data?.amount as number)}
          currency={data?.currency}
          status={String(
            switchTrxnMessage[data?.status as unknown as keyof typeof switchTrxnMessage]?.name || switchTrxnMessage?.processing?.name
          )}
          statusBg={String(switchTrxnMessage[data?.status as keyof typeof switchTrxnMessage]?.backgroundColor)}
          statusColor={String(switchTrxnMessage[data?.status as keyof typeof switchTrxnMessage]?.color)}
          isLoading={isLoading}
          summaries={summaries}
          actionButtons={actionButtons}
        />
        <TransactionDetails.Section isLoading={isLoading} heading="Chargeback Details" summaries={chargeBackDetails} />
        <TransactionDetails.Section isLoading={isLoading} heading="Payer's Information" summaries={payerDetails} />
        <TransactionDetails.Section isLoading={isLoading} heading="Activity Update">
          <ChargebackActivityUpdate resolveAction={chargeBackActivityProps.resolveAction} data={chargeBackActivityProps.data} />
        </TransactionDetails.Section>
      </TransactionDetails>
      {chargebackModalState.open && (
        <ChargebackDetailsModal
          close={() => setChargebackModalState({ open: false, type: '' })}
          type={chargebackModalState.type as ModalStepT}
          data={data?.status_history as chargebackHistoryDataT[]}
          reference={data?.reference as string}
          setModalState={value => setChargebackModalState({ open: true, type: value })}
        />
      )}
    </>
  );
}

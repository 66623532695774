import React from 'react';

import LoadingPlaceholder from '+dashboard/Shared/LoadingPlaceHolder';
import { useReducerState } from '+hooks';
import { IProductDetails, modalState, ProductDetailsTabKeyType, ProductDetailsTabType } from '+types';
import { capitalizeRemovedash, history, productMapping } from '+utils';

import SectionInfo from '../../components/SectionInfo';
import MerchantCurrencyModal from '../../MerchantsTable/components/MerchantCurrencyModal';
import MerchantsAccessTable from '../components/MerchantsAccessTable';
import DefaultConfiguration from './DefaultConfiguration';

const tabsList: ProductDetailsTabType = [
  { label: 'Default Configuration', key: 'default-config' },
  { label: 'Merchants with Access', key: 'merchants-with-access' }
];

type stateType = {
  activeTab: ProductDetailsTabKeyType;
  activeModal: modalState;
};

const DefaultProductDetails = ({ data, isLoading, currency, method, product }: IProductDetails) => {
  const [state, setState] = useReducerState<stateType>({
    activeTab: 'default-config',
    activeModal: ''
  });

  const methodStatus = data?.setting?.enabled;
  return (
    <div className="content-i">
      <div className="content-box">
        <div className="row">
          <button type="button" className="btn btn-link mb-2" onClick={() => history.goBack()}>
            <i className="os-icon os-icon-arrow-left7" />
            <span>Back to Product Config</span>
          </button>
        </div>
        <div className="payins-container">
          <div className="payins-container__first" style={{ border: 'none', paddingTop: '1rem' }}>
            <SectionInfo
              title={`${capitalizeRemovedash(method)} [${currency}]`}
              description="
                Here, you’ll find the general configuration of this product and other product methods under it. You can make modifications
                here."
              status={methodStatus}
            />

            <div className="controls method">
              <button
                type="button"
                className="btn btn-secondary --enable-btn"
                onClick={() => setState({ activeModal: methodStatus ? 'disable-channels' : 'enable-channels' })}
                title="more"
              >
                {`${methodStatus ? 'Disable' : 'Enable'} for all merchants`}
              </button>
              <MerchantCurrencyModal
                activeModal={state.activeModal}
                setActiveModal={() => setState({ activeModal: '' })}
                productType={productMapping[product]}
                currency={currency}
                method={method}
              />
            </div>
          </div>
        </div>
        <section className="os-tabs-w">
          <div className="os-tabs-controls os-tabs-complex">
            <ul className="nav nav-tabs" role="tablist">
              {tabsList.map(({ key, label }) => {
                return (
                  <li className="nav-item" key={key} role="tab">
                    <div
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        setState({ activeTab: key as ProductDetailsTabKeyType });
                      }}
                      onKeyDown={() => {
                        setState({ activeTab: key as ProductDetailsTabKeyType });
                      }}
                      className={`nav-link ${key === state.activeTab && 'active'}`}
                    >
                      {capitalizeRemovedash(label)}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </section>
        {isLoading ? (
          <LoadingPlaceholder type="text" background="#f5f6f6" />
        ) : (
          <>
            {state.activeTab === 'default-config' && <DefaultConfiguration />}
            {state.activeTab === 'merchants-with-access' && <MerchantsAccessTable />}
          </>
        )}
      </div>
    </div>
  );
};

export default DefaultProductDetails;

import React from 'react';

const FeesCurrencyInput = ({ onChange, currency }: { onChange: (e: string) => void; currency: string }) => (
  <div className="fees-currency-input">
    <label htmlFor="fees-currency-select">Fees Currency</label>
    <select id="fees-currency-select" value={currency} onChange={e => onChange(e.target.value)} className="icf__custom-select">
      <option value="USD">USD</option>
      <option value="NGN">NGN</option>
    </select>
  </div>
);

export default FeesCurrencyInput;

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import CurrencyPicker from '+shared/CurrencyPicker';

import ConversionsSettings from './conversionWidgets/ConversionsSettings';

import './conversions.scss';

export default function Conversions() {
  const { id } = useParams<{ id: string }>();

  const [activeCurrency, setActiveCurrency] = useState('NGN');

  return (
    <div className="nav-content active">
      <div className="element-box">
        <div className="element-box-heading conversions-header">
          <div className="heading-box-mmd">
            <h4 className="form-header">Manage Conversions</h4>
            <div className="form-desc no-underline mb-2">You can adjust the configuration of this merchant’s conversions here.</div>
          </div>
        </div>

        <CurrencyPicker
          options={['NGN', 'USD']}
          onChange={value => {
            setActiveCurrency(value);
          }}
          className="mt-2 mb-3"
          activeCurrency={activeCurrency}
          label={<strong>Showing configuration for:</strong>}
          id="merchant-settlements-settings__currency-switch"
        />

        <div className="os-tabs-controls os-tabs-complex mx-0">
          <ul className="nav nav-tabs px-0 mb-2">
            <li className="nav-item">
              <button type="button" className="nav-link active" data-toggle="tab">
                <span className="tab-label">Swap Settlement</span>
              </button>
            </li>
          </ul>
        </div>

        <ConversionsSettings id={id} activeCurrency={activeCurrency} />
      </div>
    </div>
  );
}

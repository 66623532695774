import React, { useState } from 'react';
import * as Flags from 'country-flag-icons/react/3x2';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import Icon from '+containers/Dashboard/Shared/Icons';
import LoadingPlaceholder from '+containers/Dashboard/Shared/LoadingPlaceHolder';
import APIRequest from '+services/api-services';
import { AccessDataType } from '+types/identity';

import { convertToObject, extractRegions } from '../../helpers/configurationHelper';
import ConfigurationFeeModal from '../ConfigurationFeeModal';
import ManageCountriesModal from '../ManageCountriesModal';

import './index.scss';

const api = new APIRequest(process.env.REACT_APP_MIDDLEWARE_API_BASE);

export default function RegionConfiguration({
  disabled,
  allMerchants,
  merchantId,
  externalRegions,
  refetch,
  merchantName
}: {
  disabled?: boolean;
  allMerchants?: boolean;
  merchantId?: string;
  externalRegions?: AccessDataType;
  refetch?: () => void;
  merchantName?: string;
}) {
  const [showConfigurationFeeModal, setShowConfigurationFeeModal] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<string>('');
  const [showManageCountriesModal, setShowManageCountriesModal] = useState(false);
  const {
    data: merchantFees,
    isFetching: isFetchingMerchantFees,
    refetch: refetchMerchantFees
  } = useQuery(`IDENTITY_DEFAULT_FEES`, () => api.getIdentityDefaultFees(), {});

  const defaultRegionsSetting = merchantFees?.data?.setting?.country;
  const regions = extractRegions(externalRegions || defaultRegionsSetting);

  const differentConfigsFunc = () => {
    if (externalRegions && merchantFees?.data?.setting?.country) {
      const countriesWithBilling = Object.keys(externalRegions).filter(countryCode => {
        const billing = (externalRegions[countryCode as keyof AccessDataType] as unknown as { billing: { kyc?: unknown; kyb?: unknown } })
          .billing;
        return (billing?.kyc && Object.keys(billing.kyc).length > 0) || (billing?.kyb && Object.keys(billing.kyb).length > 0);
      });
      return countriesWithBilling;
    }
    return [];
  };

  const differentConfigs = differentConfigsFunc();

  const isConfigDifferent = (regionCode: string) => differentConfigs.includes(regionCode);

  const getSelectedFees = (countryCode: string) => {
    if (externalRegions) {
      return externalRegions[countryCode as keyof AccessDataType];
    }
    return merchantFees?.data?.setting?.country[countryCode as keyof AccessDataType];
  };

  const getLink = (region: string) => {
    const configUrl = `/dashboard/identity/configuration`;
    if (allMerchants) {
      return `${configUrl}/general?tab=${region}`;
    }
    return `${configUrl}/individual/${merchantId}?region=${region}`;
  };

  const renderRegions = () => {
      if (isFetchingMerchantFees) {
        return <LoadingPlaceholder type="text" content={4} />;
      }
      if (!regions || regions.length === 0) {
        return <div className="irc__region unavailable">No regions available</div>;
      }
      return regions?.map(region => {
        const Flag = Flags[region.key.toUpperCase() as keyof typeof Flags];
        const showWarning = isConfigDifferent(region.key) && !allMerchants;
        return (
          <div key={region.key} className="irc__region">
            <div className="irc__region-name">
              <span className="region-flag">{Flag && <Flag />}</span>
              {region.name}
              <span className={`irc__region-status ${region.status.toLowerCase()}`}>{region.status}</span>
            </div>
            <div className="irc__region-right">
              {showWarning ? (
                <div className="irc__region-warning">
                  <Icon name="circledCaution" width={13} />
                  <span className="">This country has custom configurations for this merchant</span>
                </div>
              ) : null}
              <div className="irc__region-btns">
                <button
                  className="btn btn-link text-decoration-none"
                  onClick={() => {
                    setSelectedCountry(region.key);
                    setShowConfigurationFeeModal(true);
                  }}
                >
                  Configure fees
                </button>
                <Link className="btn btn-link" to={getLink(region.key)}>
                  {allMerchants ? 'Manage Service' : 'Configure Use Case'}
                </Link>
              </div>
            </div>
          </div>
        );
      })
  }
  return (
    <div className={`irc ${disabled ? 'disabled' : ''}`}>
      <div className="irc__header">
        <div>
          <h2 className="irc__title">Available regions for Identity</h2>
          <p className="irc__description">
            Identity services are available based on region, and the fees for each region can be configured below:{' '}
          </p>
        </div>
        <button className="irc__header-btn" onClick={() => setShowManageCountriesModal(true)}>
          Manage Country Verifications
        </button>
      </div>

      <div className="irc__content">
        <div className="irc__regions">
          {renderRegions()}
        </div>
      </div>

      {showConfigurationFeeModal && (
        <ConfigurationFeeModal
          close={() => setShowConfigurationFeeModal(false)}
          fees={getSelectedFees(selectedCountry)}
          region={selectedCountry}
          id={merchantId}
          refetch={refetch || refetchMerchantFees}
          defaultFee={defaultRegionsSetting[selectedCountry]}
          merchantName={merchantName}
          configType={allMerchants ? 'default' : 'merchant'}
          identityTitles={convertToObject(merchantFees?.data?.identities)}
        />
      )}
      {showManageCountriesModal && (
        <ManageCountriesModal
          externalRegions={externalRegions || defaultRegionsSetting}
          close={() => setShowManageCountriesModal(false)}
          merchantId={merchantId}
          refetch={refetch || refetchMerchantFees}
        />
      )}
    </div>
  );
}

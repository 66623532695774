import React from 'react';

import Icon from '+containers/Dashboard/Shared/Icons';

import './index.scss';

export default function Alert({
  text,
  customIcon,
  actionBtn,
  hideIcon,
  size
}: {
  text: string;
  customIcon?: React.ReactNode;
  actionBtn?: { text: string; action: () => void };
  hideIcon?: boolean;
  size?: 'sm';
}) {
  return (
    <div className={`id-alert d-flex justify-content-between ${size}`}>
      <div className="d-flex gap-1">
        {customIcon || (!hideIcon && <Icon name="caution" />)} <span>{text}</span>
      </div>
      {actionBtn && (
        <button className="btn btn-link" onClick={actionBtn.action}>
          {actionBtn.text}
        </button>
      )}
    </div>
  );
}

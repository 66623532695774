import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import Badge from '+containers/Dashboard/Shared/Badge';
import Typography from '+containers/Dashboard/Shared/Typography';
import { useFeedbackHandler, useSearchQuery, useSetUserAccess } from '+hooks';
import APIRequest from '+services/api-services';
import CurrencyPicker from '+shared/CurrencyPicker';
import LoadingPlaceholder from '+shared/LoadingPlaceHolder';
import { CurrencyType, modalState, productCategoriesType } from '+types';
import { availableProductCategories, capitalize, capitalizeRemovedash, getDate, getTime, history, isAllowed, productMapping } from '+utils';

import RiskIndicator from '../components/RiskIndicator';
import MerchantCurrencyModal from '../MerchantsTable/components/MerchantCurrencyModal';
import TierLevel from './components/TierLevel';
import Products from './Products';

import Office from '+assets/img/dashboard/office.svg';

import './index.scss';

const api = new APIRequest();

const MerchantCurrencyDetails = () => {
  const { feedbackInit } = useFeedbackHandler();
  const userAccess = useSetUserAccess();
  const searchQuery = useSearchQuery<{ product: productCategoriesType; currency: CurrencyType }>();
  const { currency, merchant_id } = useParams<{ currency: CurrencyType; merchant_id: string }>();
  const [activeModal, setActiveModal] = useState<modalState>(null);
  const productTab = searchQuery.value.product ?? 'pay-ins';
  const activeCurrency = searchQuery.value.currency ?? currency;

  const params = {
    account_id: merchant_id,
    currency: activeCurrency,
    payment_method: undefined
  };
  const {
    data: configData,
    refetch: refetchConfig,
    isLoading
  } = useQuery(
    [`${activeCurrency}_PRODUCT`, merchant_id, activeCurrency, productTab.key],
    () => api.getProductConfiguration(currency, params),
    {
      refetchOnMount: 'always',
      onError: () => {
        feedbackInit({
          message: `There has been an error in getting this merchant's details`,
          type: 'danger',
          action: {
            action: () => refetchConfig(),
            name: 'Try again'
          }
        });
      }
    }
  );

  const merchantData = configData?.data;
  const merchantsStatus = configData?.data?.setting?.enabled;
  const merchantCurrencyInfo = {
    [`Merchant's Email`]: merchantData?.account?.email,
    'Date Joined': `${getDate(merchantData?.account?.created_at)} ${getTime(merchantData?.account?.created_at)}`
  };
  const handleMerchantsStatus = (currentStatus: boolean) => setActiveModal(currentStatus ? 'disable-one' : 'enable-one');
  const getSelectedProduct = (product: productCategoriesType) => {
    const productConfig = merchantData?.setting?.transaction[productMapping[product]];
    if (product === 'pay-ins' && productConfig && productConfig?.virtual_bank_account && merchantData && merchantData?.vba) {
      productConfig.virtual_bank_account = {
        vba_count: merchantData?.vba,
        ...productConfig.virtual_bank_account
      };
    }
    return productConfig;
  };

  return (
    <>
      <div className="content-i">
        <div className="content-box">
          <div className="row">
            <button type="button" className="btn btn-link mb-2" onClick={() => history.goBack()}>
              <i className="os-icon os-icon-arrow-left7" />
              <span>{`Back to ${activeCurrency} Merchants`}</span>
            </button>
          </div>
          {isLoading ? (
            <LoadingPlaceholder type="text" background="#f5f6f6" content={3} section={1} />
          ) : (
            <>
              <div className="top-row__container">
                <div className="__left">
                  <div>
                    <span>
                      <img src={Office} alt="office" />
                    </span>
                    <p>Merchant</p>
                  </div>
                  <div>
                    <Typography variant="h1">{capitalize(merchantData?.account?.name)}</Typography>
                    <p>{activeCurrency}</p>
                    <Badge status={merchantsStatus ? 'enabled' : 'disabled'} />
                  </div>
                  <Typography as="div" variant="subtitle1" className="id-and-plan">
                    <p>{`ID: ${merchant_id}`}</p>
                    <span />
                    <p>Enterprise</p>
                  </Typography>
                </div>
                <div className="__right">
                  <CurrencyPicker
                    options={merchantData?.currencies}
                    onChange={value => {
                      searchQuery.setQuery({ currency: value as CurrencyType });
                    }}
                    className="business-settings__currency-switch"
                    activeCurrency={activeCurrency?.toUpperCase()}
                    label={null}
                  />
                  {isAllowed(userAccess, ['transaction_config_details.update']) && (
                    <span
                      role="button"
                      tabIndex={0}
                      onClick={() => handleMerchantsStatus(merchantsStatus)}
                      onKeyDown={() => handleMerchantsStatus(merchantsStatus)}
                    >
                      <p>{`${merchantsStatus ? 'Disable' : 'Enable'} this merchant’s ${activeCurrency} access`}</p>
                    </span>
                  )}
                </div>
              </div>
              <div className="detail-container">
                <article>
                  <ul>
                    {Object.entries(merchantCurrencyInfo).map(([key, value]) => (
                      <li key={key}>
                        <span>{capitalize(key)}</span>
                        <span>{capitalizeRemovedash(value)}</span>
                      </li>
                    ))}
                  </ul>
                </article>
                <div className="extra-details">
                  <span className="wrapper">
                    <p className="details-label">Risk Rating:</p>
                    <RiskIndicator riskLevel={merchantData?.account?.risk_level} />
                  </span>
                  <span className="wrapper">
                    <p className="details-label">Account Tier:</p>
                    {merchantData?.account?.tier_level_id ? <TierLevel tier={merchantData?.account?.tier_level_id} /> : 'N/A'}
                  </span>
                </div>
              </div>
            </>
          )}
          {/* Product section */}
          <div className="nav-content active">
            <section className="os-tabs-w">
              <div className="os-tabs-controls os-tabs-complex">
                <ul className="nav nav-tabs" role="tablist">
                  {Object.entries(availableProductCategories).map(([key, label]) => {
                    if (key === 'card-issuance') return null;
                    return (
                      <li
                        className="nav-item"
                        key={key}
                        role="tab"
                        onClick={() => {
                          searchQuery.setQuery({ product: key as productCategoriesType, currency: activeCurrency }, true);
                        }}
                        onKeyDown={() => {
                          searchQuery.setQuery({ product: key as productCategoriesType, currency: activeCurrency }, true);
                        }}
                        hidden={currency !== 'USD' && key === 'card-issuance'}
                      >
                        <div className={`nav-link ${key === productTab && 'active'}`}>{`${capitalizeRemovedash(label)} Product`}</div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </section>
          </div>
          {isLoading ? (
            <LoadingPlaceholder type="text" background="#f5f6f6" content={3} section={2} />
          ) : (
            <Products
              label={availableProductCategories[productTab]}
              type={productTab}
              config={getSelectedProduct(productTab)}
              merchantId={merchant_id}
              currency={activeCurrency}
              merchantsStatus={merchantsStatus}
            />
          )}
        </div>
      </div>
      {activeModal && (
        <MerchantCurrencyModal
          activeModal={activeModal}
          setActiveModal={setActiveModal}
          currency={activeCurrency}
          merchantId={merchant_id}
          hideButton
        />
      )}
    </>
  );
};

export default MerchantCurrencyDetails;

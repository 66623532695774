/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import Icon from '+containers/Dashboard/Shared/Icons';
import Modal, { IModalProps } from '+shared/Modal';
import { IAddMerchantModal, IConfirmSubmission } from '+types';

import { descriptionContext } from '../../helpers/addMerchantModalHelpers';
import LimitConfigWizard from './LimitConfigWizard';
import MerchantInfo from './MerchantInfo';
import SubscriptionPlans from './SubscriptionPlans';
import { useAddMerchantsToCardIssuance } from './useAddMerchantsToCardIssuance';

import './index.scss';

const AddMerchantModal = ({ category, onClose, merchantClass = 'new', defaultLimitsConfig, defaultPlansConfig }: IAddMerchantModal) => {
  const {
    handleConfirm,
    handleSkipSteps,
    handleStepChange,
    handleToggleConfigPreference,
    gotoPrev,
    formik,
    configPreference,
    confirmed,
    step,
    completedSteps,
    currency
  } = useAddMerchantsToCardIssuance({
    onClose,
    category,
    defaultPlansConfig,
    defaultLimitsConfig
  });

  const getStepProps = (): Partial<IModalProps> => {
    let stepProps: Partial<IModalProps>;
    const sharedProps: Partial<IModalProps> = {
      close: () => {
        onClose();
      },
      size: 'md',
      heading:
        merchantClass === 'existing'
          ? 'Enable access for merchant'
          : `Add merchant to ${category === 'issued-cards' ? 'Issued' : 'Reserved'} cards`,
      description: `Enter the details provided by the merchant below to ${
        merchantClass === 'existing' ? 'enable them access' : 'add them'
      } to USD ${descriptionContext.shared[category]}.`,
      secondButtonAction: formik.submitForm,
      firstButtonAction: gotoPrev,
      firstButtonText: 'Back',
      secondButtonText: 'Next',
      secondButtonDisable: !formik.isValid,
      secondButtonActionIsTerminal: false,
      secondaryCompletedModal: true,
      maxHeight: '700px',
      isScrollable: true
    };

    switch (step) {
      case 'init':
        stepProps = {
          content: <MerchantInfo currency={currency} category={category} {...formik} />,
          description: `Enter the details provided by the merchant below to ${
            merchantClass === 'existing' ? 'enable them access' : 'add them'
          } to USD ${descriptionContext.init[category]}.`
        };
        break;
      case 'set_spending_limit':
      case 'set_funding_limit':
      case 'set_pci_dss_limit':
      case 'set_count_limit':
        stepProps = {
          content: (
            <LimitConfigWizard
              category={category}
              step={step}
              onToggleConfigPreference={handleToggleConfigPreference}
              onStepChange={handleStepChange}
              configPreference={configPreference}
              completedSteps={completedSteps}
              {...formik}
            />
          ),
          showThirdButton: true,
          thirdButtonText: 'Skip limit configurations',
          thirdButtonAction: handleSkipSteps
        };
        break;
      case 'set_subscription_fees':
        stepProps = {
          content: (
            <SubscriptionPlans
              currency={currency}
              onToggleConfigPreference={handleToggleConfigPreference}
              configPreference={configPreference}
              defaultPlansConfig={defaultPlansConfig}
              {...formik}
            />
          ),
          showThirdButton: true,
          thirdButtonText: 'Skip plans configurations',
          thirdButtonAction: handleSkipSteps
        };
        break;
      case 'confirm_submission':
      default:
        stepProps = {
          content: <ConfirmSubmission category={category} onToggleConfirm={handleConfirm} confirmed={confirmed} />,
          description: `Please confirm that you want to enable ${descriptionContext.confirm_submission[category]} access for this merchant.`,
          size: 'sm',
          secondButtonText: 'Yes, Confirm',
          secondButtonActionIsTerminal: true,
          secondButtonDisable: !confirmed,
          completedDescription: `You have enabled this merchant access to ${
            category === 'issued-cards' ? 'card issuance' : 'RVC'
          } default configurations.`,
          completedHeading: 'Enabled'
        };
        break;
    }

    return {
      ...sharedProps,
      ...stepProps
    };
  };

  return <Modal {...(getStepProps() as IModalProps)} />;
};

export default AddMerchantModal;

const ConfirmSubmission = ({ category, onToggleConfirm, confirmed }: IConfirmSubmission) => {
  const contextString = category === 'issued-cards' ? 'virtual cards' : 'RVCs';

  return (
    <div>
      <div className="confirmation-disclaimer">
        <Icon width={32} height={32} fill="#2376f3" name="infoSolid" />
        <p className="copy">
          Important: This means that this merchant will be able to issue {contextString} under the default configuration.
        </p>
      </div>
      <div className="confirmation-check my-3">
        <label htmlFor="confirm-action">Yes, I understand the implications of this action</label>
        <input type="checkbox" checked={confirmed} id="confirm-action" onChange={onToggleConfirm} />
      </div>
    </div>
  );
};

import React from 'react';

import FieldErrorMsg from '+containers/Dashboard/Shared/FormError';
import { ILimitConfigFields, IMerchantInfo } from '+types';
import { formatWithCommas, stripNonNumeric } from '+utils';

import { pciDSSLevels } from '../../../constants/common';

const PCIDSSLimit = ({ values, handleBlur, setFieldValue, getFieldProps, isDefault, category, touched, errors }: ILimitConfigFields) => {
  const isInvalid = (key: keyof IMerchantInfo) => touched[key] && errors[key];

  return (
    <fieldset className="fade-in mt-1">
      {category === 'issued-cards' ? (
        <div>
          <label htmlFor="cert-level">PCI DSS certification level</label>
          <select className="form-control" id="cert-level" {...getFieldProps('pci_dss_level')} disabled={isDefault}>
            {pciDSSLevels.map(level => (
              <option value={level.value} key={level.value}>
                {level.label}
              </option>
            ))}
          </select>
        </div>
      ) : null}
      <div>
        <label htmlFor="trxn-count">Transaction Count</label>
        <input
          name="trxn_count"
          id="trxn-count"
          value={formatWithCommas(values.trxn_count)}
          onChange={e => setFieldValue('trxn_count', stripNonNumeric(e.target.value))}
          onBlur={handleBlur}
          className="form-control"
          disabled={isDefault}
          data-state={isInvalid('trxn_count') ? 'invalid' : ''}
        />
        <FieldErrorMsg touched={Boolean(touched.trxn_count)} error={errors.trxn_count} id="trxn-count" />
      </div>

      <div>
        <label htmlFor="yearly_issued_cards">Number of Issuable Cards</label>
        <input
          name="yearly_issued_cards"
          id="yearly_issued_cards"
          value={formatWithCommas(values.yearly_issued_cards)}
          onChange={e => setFieldValue('yearly_issued_cards', stripNonNumeric(e.target.value))}
          onBlur={handleBlur}
          className="form-control"
          disabled={isDefault}
          data-state={isInvalid('yearly_issued_cards') ? 'invalid' : ''}
        />
        <FieldErrorMsg touched={Boolean(touched.yearly_issued_cards)} error={errors.yearly_issued_cards} id="yearly_issued_cards" />
      </div>
    </fieldset>
  );
};

export default PCIDSSLimit;

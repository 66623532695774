import React, { useState } from 'react';
import { useQuery } from 'react-query';

import FieldErrorMsg from '+containers/Dashboard/Shared/FormError';
import NumericInput from '+containers/Dashboard/Shared/NumericInput';
import SearchInput from '+containers/Dashboard/Shared/SearchInput';
import { useFeedbackHandler } from '+hooks';
import APIRequest from '+services/api-services';
import { FilterMerchantType, IMerchantInfoForm } from '+types';

import {
  customerCardMonthlyPaymentValues,
  pciDSSLevels,
  reservedCardMonthlyPaymentValues,
  riskLevels,
  rvcPlans
} from '../../constants/common';

const api = new APIRequest(process.env.REACT_APP_MIDDLEWARE_API_BASE);

const MerchantInfo = ({
  currency = 'USD',
  category = 'reserved-cards',
  errors,
  values,
  setFieldValue,
  getFieldProps,
  handleBlur,
  touched
}: IMerchantInfoForm) => {
  const [debouncedSearchInput, setDebouncedSearchInput] = useState<string>('');
  const [shouldRunQuery, setShouldRunQuery] = useState(false);
  const { feedbackInit } = useFeedbackHandler();
  const monthlyPaymentValues = category === 'issued-cards' ? customerCardMonthlyPaymentValues : reservedCardMonthlyPaymentValues;

  const {
    data: merchantsData,
    isFetching: isFetchingMerchants,
    refetch: refetchMerchants
  } = useQuery(
    ['Merchants', debouncedSearchInput],
    () =>
      api.getMerchants(
        'verified',
        undefined,
        {
          merchantName: debouncedSearchInput
        },
        undefined
      ),
    {
      enabled: debouncedSearchInput?.length >= 3 && shouldRunQuery,
      select: data => data?.data?.data?.map((each: FilterMerchantType) => ({ name: each.name, kora_id: each.kora_id })),
      onError: () => {
        feedbackInit({
          message: 'There has been an error getting all merchants',
          type: 'danger',
          action: {
            action: () => refetchMerchants(),
            name: 'Try again'
          }
        });
      }
    }
  );

  return (
    <fieldset aria-labelledby="merchant_info" className="fade-in mt-0 merchant-info-field">
      <p className="sr-only" id="merchant_info">
        Retrieve Merchant Information
      </p>

      <div>
        <SearchInput
          label="Merchant Name"
          placeholder="Search for a merchant"
          id="merchant_name"
          name="merchant"
          value={values.merchant?.name}
          dropdownData={merchantsData}
          accessorKey={null}
          onChange={e => {
            setFieldValue('merchant', e as FilterMerchantType);
          }}
          setDebouncedSearchInput={setDebouncedSearchInput}
          setShouldRunQuery={setShouldRunQuery}
          isFetching={isFetchingMerchants}
          wrapperClassName="combobox"
          onBlur={handleBlur}
        />
        <div className="form-error mt-1" aria-live="polite" id="merchant_name">
          {touched.merchant && errors.merchant}
        </div>
      </div>

      <div>
        <label htmlFor="risk_level">Risk level</label>
        <select className="form-control" id="risk_level" {...getFieldProps('risk_level')}>
          {riskLevels.map(level => (
            <option value={level.value} key={level.value}>
              {level.label}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label htmlFor="monthly_payment_value">Monthly Payment Value</label>
        <select className="form-control" id="risk_level" {...getFieldProps('monthly_payment_value')}>
          {monthlyPaymentValues.map(level => (
            <option value={level.value} key={level.value}>
              {level.label}
            </option>
          ))}
        </select>
      </div>

      {category === 'issued-cards' ? (
        <div className="fade-in">
          <div>
            <label htmlFor="pci_dss_level">PCI DSS level</label>
            <select className="form-control" id="pci_dss_level" {...getFieldProps('pci_dss_level')}>
              {pciDSSLevels.map(level => (
                <option value={level.value} key={level.value}>
                  {level.label}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label htmlFor="tpv">Total Payment Value ({currency})</label>
            <NumericInput
              name="total_payment_value"
              data-state={touched.total_payment_value && errors.total_payment_value ? 'invalid' : ''}
              id="tpv"
              value={values.total_payment_value}
              onValueChange={values => setFieldValue('total_payment_value', values.value)}
              onBlur={handleBlur}
            />
            <FieldErrorMsg touched={Boolean(touched.total_payment_value)} error={errors.total_payment_value} id="merchant_name" />
          </div>
        </div>
      ) : null}

      {category === 'reserved-cards' ? (
        <div className="fade-in">
          <div>
            <label htmlFor="rvc_plans">RVCs Subscription plan</label>
            <select className="form-control" id="rvc_plans" {...getFieldProps('subscription_plan')}>
              {rvcPlans.map(level => (
                <option value={level.value} key={level.value}>
                  {level.label}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label htmlFor="issued_cards_plans">Issued cards Subscription plan</label>
            <select className="form-control" id="issued_cards_plans" {...getFieldProps('')} disabled>
              {rvcPlans.map(level => (
                <option value={level.value} key={level.value}>
                  {level.label}
                </option>
              ))}
            </select>
          </div>

          <div className="disclaimer mt-3">
            Note that any changes made to reserved cards subscription plan here will be implemented for issued cards and vice versa.
          </div>
        </div>
      ) : null}
    </fieldset>
  );
};

export default MerchantInfo;

import React from 'react';

import FieldErrorMsg from '+containers/Dashboard/Shared/FormError';
import InputWithSuffix from '+shared/InputWithSuffix';
import { CurrencyType, FeeType, IMerchantInfo, MerchantInfoFormikBagType, TransformedDefaultPlansConfigType } from '+types';
import { stripNonNumeric } from '+utils';

import ToggleDefaultConfig from '../ToggleDefaultConfig';

interface ISubscriptionFees extends MerchantInfoFormikBagType {
  currency: CurrencyType;
  onToggleConfigPreference: React.EventHandler<React.ChangeEvent>;
  configPreference: Record<'set_subscription_fees', 'custom' | 'default'>;
  defaultPlansConfig: TransformedDefaultPlansConfigType['customer'];
}

const percentOrCurrencySuffix = ({ feeType: valueType, currency }: { feeType?: FeeType; currency: string }) => {
  if (valueType === 'percentage') return '%';
  return currency;
};

const SubscriptionPlans = ({
  currency,
  onToggleConfigPreference,
  configPreference,
  defaultPlansConfig,
  values,
  errors,
  touched,
  setFieldValue,
  handleBlur,
  getFieldProps
}: ISubscriptionFees) => {
  const defaultIsSelected = configPreference.set_subscription_fees === 'default';

  const isInvalid = (key: keyof IMerchantInfo) => touched[key] && errors[key];

  return (
    <div className="fade-in">
      <ToggleDefaultConfig
        description="Select subscription plan configuration option"
        selectedOption={configPreference.set_subscription_fees}
        onToggleConfigPreference={onToggleConfigPreference}
      />

      <fieldset className="merchant-subscription-plan-form mt-1">
        <div>
          <label htmlFor="plan">Subscription Plan</label>
          <select id="plan" {...getFieldProps('subscription_plan')} className="form-control">
            {defaultPlansConfig.map(plan => (
              <option value={plan.name} key={plan.name}>
                {plan.name}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label htmlFor="monthly_card_limit">Number of cards issuable</label>
          <input
            name="monthly_card_limit"
            id="monthly_card_limit"
            value={values.monthly_card_limit}
            onChange={e => setFieldValue('monthly_card_limit', stripNonNumeric(e.target.value))}
            onBlur={handleBlur}
            className="form-control"
            disabled={defaultIsSelected}
            data-state={isInvalid('monthly_card_limit') ? 'invalid' : ''}
          />
          <FieldErrorMsg touched={Boolean(touched.monthly_card_limit)} error={errors.monthly_card_limit} id="monthly_card_limit" />
        </div>

        <div>
          <label htmlFor="subscription-fees">Subscription Fee</label>
          <InputWithSuffix
            name="subscription_fee"
            suffix={percentOrCurrencySuffix({ feeType: values.subscription_fee?.type, currency })}
            id="subscription-fees"
            touched={touched.subscription_fee}
            error={errors.subscription_fee}
            value={values.subscription_fee?.amount}
            onValueChange={values => setFieldValue('subscription_fee.amount', values.value)}
            onBlur={handleBlur}
            disabled={defaultIsSelected}
          />
        </div>

        <div>
          <label htmlFor="issuing-fee">Card Issuing Fee</label>
          <InputWithSuffix
            name="issuance_fee"
            suffix={percentOrCurrencySuffix({ feeType: values.issuance_fee?.type, currency })}
            id="issuing-fee"
            touched={touched.issuance_fee}
            value={values.issuance_fee?.amount}
            error={errors.issuance_fee}
            onBlur={handleBlur}
            onValueChange={values => setFieldValue('issuance_fee.amount', values.value)}
            disabled={defaultIsSelected}
          />
        </div>

        <div>
          <label htmlFor="chargeback-fee">Chargeback Fee</label>
          <InputWithSuffix
            name="chargeback_fee"
            suffix={percentOrCurrencySuffix({ feeType: values.chargeback_fee?.type, currency })}
            touched={touched.chargeback_fee}
            error={errors.chargeback_fee}
            value={values.chargeback_fee?.amount}
            onValueChange={values => setFieldValue('chargeback_fee.amount', values.value)}
            onBlur={handleBlur}
            id="chargeback-fee"
            disabled={defaultIsSelected}
          />
        </div>

        <div>
          <label htmlFor="cross-curr-fee">Cross-currency Fee</label>
          <div className="plan-fee-setter">
            <FeeTypeDropdown
              value={values.cross_currency_fee?.type as FeeType}
              onChange={e => setFieldValue('cross_currency_fee.type', e.target.value)}
              disabled={defaultIsSelected}
            />
            <InputWithSuffix
              name="cross_currency_fee"
              suffix={percentOrCurrencySuffix({ feeType: values.cross_currency_fee?.type, currency })}
              touched={touched.cross_currency_fee}
              error={errors.cross_currency_fee}
              value={values.cross_currency_fee?.amount}
              onValueChange={values => setFieldValue('cross_currency_fee.amount', values.value)}
              onBlur={handleBlur}
              id="cross-curr-fee"
              disabled={defaultIsSelected}
            />
          </div>
        </div>

        <div>
          <label htmlFor="funding-fee">Funding Fee</label>
          <div className="plan-fee-setter">
            <FeeTypeDropdown
              value={values.funding_fee?.type as FeeType}
              onChange={e => setFieldValue('funding_fee.type', e.target.value)}
              disabled={defaultIsSelected}
            />
            <InputWithSuffix
              name="funding_fee"
              suffix={percentOrCurrencySuffix({ feeType: values.funding_fee?.type, currency })}
              touched={touched.funding_fee}
              error={errors.funding_fee}
              value={values.funding_fee?.amount}
              onValueChange={values => setFieldValue('funding_fee.amount', values.value)}
              onBlur={handleBlur}
              id="funding-fee"
              disabled={defaultIsSelected}
            />
          </div>
        </div>

        <div>
          <label htmlFor="withdrawal-fee">Withdrawal Fee</label>
          <div className="plan-fee-setter">
            <FeeTypeDropdown
              value={values.withdrawal_fee?.type as FeeType}
              onChange={e => setFieldValue('withdrawal_fee.type', e.target.value)}
              disabled={defaultIsSelected}
            />
            <InputWithSuffix
              name="withdrawal_fee"
              suffix={percentOrCurrencySuffix({ feeType: values.withdrawal_fee?.type, currency })}
              touched={touched.withdrawal_fee}
              error={errors.withdrawal_fee}
              value={values.withdrawal_fee?.amount}
              onValueChange={values => setFieldValue('withdrawal_fee.amount', values.value)}
              onBlur={handleBlur}
              id="withdrawal-fee"
              disabled={defaultIsSelected}
            />
          </div>
        </div>
      </fieldset>
    </div>
  );
};

export default SubscriptionPlans;

interface IFeeTypeDropdown extends React.SelectHTMLAttributes<HTMLSelectElement> {
  value: 'flat' | 'percentage';
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
}

const FeeTypeDropdown = ({ value, onChange, ...otherProps }: IFeeTypeDropdown) => {
  return (
    <select className="form-control" value={value} onChange={onChange} {...otherProps}>
      <option value="flat">Flat Fee</option>
      <option value="percentage">Percentage Fee</option>
    </select>
  );
};

/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { ReactElement, useEffect, useReducer, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';

import ChargebackActivityUpdate from '+dashboard/Disputes/Chargeback/components/ChargebackActivityUpdate';
import ChargebackDetailsModal from '+dashboard/Disputes/Chargeback/components/ChargebackDetailsModal';
import { useFeedbackHandler, useSetUserAccess, useTransactionStatus } from '+hooks';
import APIRequest from '+services/api-services';
import { mapContentToAction, PausedPaymentsModalContent } from '+shared/data/PausedPayments';
import LoadingPlaceholder from '+shared/LoadingPlaceHolder';
import PausedPaymentsModal from '+shared/Modal';
import { ScrollToTopSection } from '+shared/ScrollToTop';
import ToolTip from '+shared/Tooltip';
import useBulkActionStore from '+store/zustand';
import { TConversionsTrxn, TRelatedTransactions, TTransactionDetails } from '+types';
import { capitalizeRemovedash, cardStatus, formatAmount, history, isAllowed, logError, switchTrxnMessage } from '+utils';

import { ReversePayoutModal } from '../PayOut/components/PayoutReversals';
import Icon from '../Shared/Icons';
import TransactionStatusModal from '../Shared/TransactionStatus';
import FailedPayoutReasons from './components/FailedPayoutsReasonsModal';
import ManageRefundsModal from './components/ManageRefundsModal';
import ReversalsInfoModal from './components/ReversalsInfoModal';
import ReversalTabs from './components/ReversalTabs';
import TrxBreakdownModal from './components/TrxBreakdownModal';
import VbaReprocessModal from './components/VbaReProcessModal/VbaReprocessModal';
import {
  additionalInfoOption,
  allActionButtonList,
  conversionsRelatedTrxns,
  getHighlightedAmount,
  paymentChannel,
  relatedTransactions,
  virtualAccount
} from './data';
import { moreDetails } from './details/more-details';
import { trxBreakdownOptions } from './details/trx-breakdown-options';

import InfoIcon from '+assets/img/dashboard/information-button.svg';

import './index.scss';

const api = new APIRequest();
const pageSource = {
  webhookPage: 'web-hook'
};

function TransactionDetails() {
  const { id } = useParams() as { id: string };
  const { pathname, search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const origin = queryParams.get('origin');
  const { feedbackInit } = useFeedbackHandler();
  const transactionType = pathname.split('/').slice(-2)[0];
  const queryClient = useQueryClient();
  const [modalOpen, setModalOpen] = useState(false);
  const [manageRefundsModalOpen, setManageRefundsModalOpen] = useState(false);
  const [showReversalInitiateModal, setShowReversalInitiateModal] = useState(false);
  const [trxBreakdownModalOpen, setTrxBreakdownModalOpen] = useState(false);
  const [isReasonForFailureModalOpen, setIsReasonForFailureModalOpen] = useState(false);

  const [activeDisputeTab, setActiveDisputeTab] = useState(transactionType === 'payouts' ? 'reversals' : 'refunds');
  const [reversalsControls, setReversalsControls] = useReducer((prev: object, next: object) => ({ ...prev, ...next }), {
    data: null,
    isReversalError: false
  });

  const [chargebackModalState, setChargebackModalState] = useState({
    open: false,
    type: ''
  });
  const bulkInfo = useBulkActionStore(state => state.bulkInfo);
  const savedBulkInfo = useRef(bulkInfo);
  const setBulkInfo = useBulkActionStore(state => state.setBulkInfo);

  const triggerReasonForFailureModal = () => {
    setIsReasonForFailureModalOpen(true);
  };

  const [managePausedPaymentsModal, setManagePausedPaymentsModal] = useState('');
  const modalContent = mapContentToAction[managePausedPaymentsModal as keyof typeof mapContentToAction] || {};

  const switchAPI = {
    'pay-ins': () => api.getSingleTransaction('payins', id),
    payouts: () => {
      if (origin && origin === pageSource.webhookPage) {
        return api.getSingleTransactionFromWebhookServices('payouts', id, 'reference');
      }
      return api.getSingleTransaction('payouts', id);
    },
    refunds: () => api.getSingleRefund(id),
    'virtual-card': () => api.fetchACardTransaction(id),
    chargebacks: () => api.getSingleChargeback(id),
    'currency-exchange': () => api.getSingleSwap(id)
  };

  const userAccess = useSetUserAccess() as { [key: string]: boolean };
  const hasAccess =
    (isAllowed(userAccess, ['pay_in_details.view']) && transactionType === 'pay-ins') ||
    (isAllowed(userAccess, ['payout_details.view']) && transactionType !== 'pay-ins') ||
    (isAllowed(userAccess, ['refund_details.view']) && transactionType === 'refunds');
  const hasPayoutReversalAccess = isAllowed(userAccess, ['payout_reversals.create']);
  const hasRefundsUpdateAccess = isAllowed(userAccess, ['refunds.update']);

  useEffect(() => {
    if (userAccess && !hasAccess) {
      history.push('/dashboard/access-denied', null);
    }
  }, [userAccess, transactionType]);

  const {
    data,
    isLoading,
    refetch: refetchTransaction
  } = useQuery(`TRANSACTION_DETAILS_${id}`, switchAPI[transactionType as keyof typeof switchAPI], {
    onError: e => {
      logError(e);
      feedbackInit({
        message: `There has been an error fetching the details for the transaction: ${id.toUpperCase()}.`,
        type: 'danger'
      });
      history.goBack();
    },
    enabled: !!hasAccess
  });

  useEffect(() => {
    const isTrxPaused = data?.status === 'paused';
    if (isTrxPaused) {
      setBulkInfo({ data: [data] });
      return () => setBulkInfo(savedBulkInfo);
    }
    return () => null;
  }, [data?.status]);

  const formatReversalsData = (reversalsData: { type: string }[]) => {
    const buffer = {
      refunds: reversalsData?.filter(({ type }: { type: string }) => type === 'refund'),
      chargebacks: reversalsData?.filter(({ type }: { type: string }) => type === 'chargeback'),
      reversals: payoutReversal
        ? [payoutReversal]
        : reversalsData?.filter(({ type }: { type: string }) => type === 'overpayment' || type === 'underpayment')
    };
    setReversalsControls({ data: buffer });
  };

  const { refetch: refetchDisputes, isLoading: loadingDisputes } = useQuery(
    ['DISPUTES_LIST', data?.reference, activeDisputeTab],
    () => api.getPayinReversals(data?.reference),
    {
      enabled: transactionType === 'pay-ins' && data !== undefined,
      onSuccess: disputesData => {
        formatReversalsData(disputesData.reversals);
      },
      onError: e => {
        logError(e);
        setReversalsControls({ isReversalError: true });
      }
    }
  );

  const { data: relatedTrxns } = useQuery(['RELATED_TRANSACTIONS', id], () => api.getRelatedTransactions(data?.payment?.unique_reference), {
    enabled: transactionType === 'pay-ins' && !!data,

    onError: e => {
      logError(e);
      feedbackInit({
        message: 'There was an error getting related transactions',
        type: 'danger',
        isClosable: true
      });
    }
  });

  const { mutateAsync } = useMutation(() => api.unpausePayment({ references: [id], action: managePausedPaymentsModal.split('_')[0] }), {
    onSuccess: () => {
      queryClient.invalidateQueries(`TRANSACTION_DETAILS_${id}`);
      queryClient.invalidateQueries('PAUSED_PAYMENTS');
    },
    onError: (e: { response: { data: { message: string } } }) => {
      feedbackInit({
        message: e?.response?.data?.message || 'An error occured! Could not process paused payment',
        type: 'danger'
      });
    }
  });

  const {
    reference,
    amount,
    amount_charged: amountCharged,
    payment_reversals: reversals,
    payout_reversal: payoutReversal,
    amount_paid: netAmount,
    amount_collected: amountCollected,
    payment_source_type: paymentSourceType,
    merchant_reference: merchantReference,
    currency,
    fee,
    vat,
    source,
    destination,
    payment,
    status,
    meta,
    account,
    status_history,
    card_acceptor_name,
    card_acceptor_country,
    source_amount,
    transaction_date,
    converted_amount,
    source_currency,
    destination_currency,
    exchange_rate,
    service_charge,
    channel,
    remittance_data
  } = (data || {}) as TTransactionDetails & TConversionsTrxn;

  const { state, updateTransactionStatusModalState, handleProcessingLoader } = useTransactionStatus();

  const isConversions = data?.type === 'wallet_conversion';
  const showViewBreakdownBtn = !isConversions && !Object.keys(cardStatus).includes(status);
  const relatedTrxnsWithoutCurrentTrxn = relatedTrxns?.data.filter((trx: TRelatedTransactions) => trx.reference !== data.reference);

  useEffect(() => {
    if (transactionType === 'payouts' || payoutReversal?.id) {
      formatReversalsData(reversals);
    }
  }, [data, payoutReversal]);

  const isVba = paymentSourceType === 'virtual_bank_account';

  useEffect(() => {
    queryClient.invalidateQueries(`TRANSACTION_DETAILS_${id}`);
    if (transactionType === 'pay-ins' && data?.reference !== undefined) {
      refetchDisputes();
    }
  }, [transactionType, data?.reference]);

  const paymentChannels = paymentChannel({ transactionType, payment, destination, source, meta });

  const virtualCardsBusinessInformation = {
    business_name: card_acceptor_name || 'Not Available',
    business_country: card_acceptor_country || 'Not Available',
    business_email: 'Not Available'
  };

  const moreDetailsOptions = moreDetails({
    data,
    transactionType,
    swapStatus: status,
    exchange_rate: Number(exchange_rate),
    source_currency,
    updateTransactionStatusModalState,
    state,
    userAccess,
    swapReference: reference,
    swapChannel: channel,
    destinationCurrency: destination_currency,
    status,
    triggerReasonForFailureModal
  });

  const morePaymentDetails = {
    ...moreDetailsOptions.shared,
    ...(moreDetailsOptions[transactionType as keyof typeof moreDetailsOptions] || moreDetailsOptions.common)
  };

  const merchantName = transactionType === 'payouts' ? account?.name : payment?.account?.name;

  const txBreakdownOptions = trxBreakdownOptions({
    data,
    merchantAccount: account,
    merchantName,
    history,
    source_currency,
    conversionFee: service_charge,
    source_amount,
    converted_amount,
    swapTransactionDate: transaction_date,
    destination_currency,
    swapReference: reference
  });

  const transactionBreakdown = txBreakdownOptions[transactionType as keyof typeof txBreakdownOptions] || txBreakdownOptions.common;

  useEffect(() => {
    if (state.clickedTransactionIds?.length) {
      refetchTransaction();
    }
  }, [state.clickedTransactionIds]);

  const additionalInfoOptions = additionalInfoOption({ data, transactionType, updateTransactionStatusModalState, state, userAccess });

  const allActionButtonOptions = allActionButtonList({
    hasPayoutReversal: !!payoutReversal?.id,
    setShowReversalInitiateModal,
    setManageRefundsModalOpen,
    setChargebackModalState,
    setManagePausedPaymentsModal,
    transactionType
  });

  const pausedActionButtons = [
    ...(status === 'paused' && allActionButtonOptions.managePausedPayments?.buttons
      ? allActionButtonOptions.managePausedPayments.buttons
      : [])
  ];

  const payoutActionButtons = [
    ...(transactionType === 'payouts' && hasPayoutReversalAccess && status === 'success' ? [allActionButtonOptions.payoutReversal] : [])
  ];

  const chargebackButtons = [
    ...(transactionType === 'chargebacks' && status_history && status_history.length > 0 ? [allActionButtonOptions.chargebacks] : [])
  ];

  const refundsActionButtons = [
    ...(transactionType === 'refunds' && hasRefundsUpdateAccess && ['failed', 'manual'].includes(status)
      ? [allActionButtonOptions.manageRefunds]
      : [])
  ];
  const actionButtons = [...payoutActionButtons, ...refundsActionButtons, ...chargebackButtons, ...pausedActionButtons] as {
    label: string;
    onClick: () => void;
    disabled: boolean;
    color: string;
    iconRight: ReactElement | null;
    iconLeft: ReactElement | null;
  }[];

  const highlightedAmount = getHighlightedAmount({ amountCollected, amount, sourceAmount: source_amount });

  return (
    <>
      <div className="content-i txn-detail">
        <div className="content-box">
          <div className="header-row">
            <div className="col-sm-12 pl-0 pr-0">
              {!isLoading && (
                <button type="button" className="btn btn-link" onClick={() => history.goBack()}>
                  <i className="os-icon os-icon-arrow-left7" />
                  <span>Go Back</span>
                </button>
              )}
              {!isLoading && (
                <section className="invoice-heading">
                  <div className="invoice-info">
                    <h3 className="amount-heading">
                      {['refunds'].includes(transactionType) ? (
                        merchantReference?.toUpperCase() || id?.toUpperCase?.()
                      ) : (
                        <>
                          {formatAmount(highlightedAmount)} <span>{[currency, source_currency]}</span>
                        </>
                      )}
                    </h3>

                    <div className="invoice-date">
                      {!['chargebacks'].includes(transactionType) && '|'}
                      {transactionType !== 'currency-exchange' && (
                        <span
                          style={{
                            color: switchTrxnMessage[status as unknown as keyof typeof switchTrxnMessage]?.color,
                            backgroundColor: switchTrxnMessage[status as unknown as keyof typeof switchTrxnMessage]?.backgroundColor
                          }}
                        >
                          {switchTrxnMessage[status as unknown as keyof typeof switchTrxnMessage]?.name ||
                            switchTrxnMessage?.processing?.name}
                        </span>
                      )}
                      {transactionType === 'currency-exchange' && (
                        <span
                          style={{
                            color: switchTrxnMessage[status as unknown as keyof typeof switchTrxnMessage]?.color,
                            backgroundColor: switchTrxnMessage[status as unknown as keyof typeof switchTrxnMessage]?.backgroundColor
                          }}
                        >
                          {switchTrxnMessage[status as unknown as keyof typeof switchTrxnMessage]?.name ||
                            switchTrxnMessage?.processing?.name}
                        </span>
                      )}
                    </div>
                  </div>

                  {!['chargebacks', 'refunds', 'payouts', 'pay-ins', 'virtual-card', 'currency-exchange'].includes(transactionType) && (
                    <div id="update-refund">
                      <p className="amount-heading --refund">
                        {formatAmount(amount || '0.00')}
                        <span>{[currency]}</span>
                      </p>
                    </div>
                  )}

                  {isVba && (
                    <VbaReprocessModal
                      accStatus={source?.virtual_bank_account.status}
                      trxStatus={status}
                      reference={reference}
                      currency={currency}
                      id={id}
                    />
                  )}

                  {actionButtons.length > 0 && (
                    <div className="action-btns-container">
                      {actionButtons.map(({ label, onClick, iconLeft = null, iconRight = null, color, ...btnProps }) => (
                        <button key={label} className={`btn btn-${color || 'primary'}`} type="button" onClick={onClick} {...btnProps}>
                          {iconLeft && <span className="mr-2">{iconLeft}</span>}
                          {label}
                          {iconRight && <span className="ml-2">{iconRight}</span>}
                        </button>
                      ))}
                    </div>
                  )}
                </section>
              )}
            </div>
          </div>
          <div className=" elemenet-box transaction-details-container mt-5">
            <section className="trxn-information">
              {isLoading ? (
                <LoadingPlaceholder type="text" content={4} />
              ) : (
                <article>
                  <ul className="trxn-breakdown-list">
                    {Object.keys(transactionBreakdown).map(item => {
                      if (
                        !['refunds'].includes(transactionType) &&
                        (!transactionBreakdown[item as keyof typeof transactionBreakdown] ||
                          transactionBreakdown[item as keyof typeof transactionBreakdown] === ' ')
                      )
                        return null;
                      if (item !== 'reason_for')
                        return (
                          <li key={Math.floor(1000 + Math.random() * 900)}>
                            <p>
                              {item === 'transaction_id' && 'Transaction ID'}
                              {item === 'settlement_id' && 'Settlement ID'}
                              {!['transaction_id', 'settlement_id'].includes(item) && capitalizeRemovedash(item)}
                              {item === 'transaction_fee' && '(VAT Inclusive)'}
                              {item === 'net' && <ToolTip type="net_amount" image={InfoIcon} message={<>This is the amount less fees</>} />}
                            </p>
                            <p style={{ maxWidth: '350px' }}>{transactionBreakdown[item as keyof typeof transactionBreakdown]}</p>
                          </li>
                        );
                      return null;
                    })}
                  </ul>
                </article>
              )}
            </section>
          </div>
          <div className="transaction-details__comp_2">
            <div className="transaction-details-container-2">
              <section className="customer-information">
                {isLoading ? (
                  <LoadingPlaceholder type="text" content={4} />
                ) : (
                  <article>
                    <ul>
                      {/* ===========More Transaction Details=========== */}
                      {!['chargebacks', 'refunds'].includes(transactionType) && (
                        <li>
                          <p
                            className="section-heading more-trxn-heading"
                            style={{
                              marginTop: '20px',
                              marginBottom: '20px'
                            }}
                          >
                            More Transaction Details
                            {showViewBreakdownBtn && (
                              <button
                                onClick={() => setTrxBreakdownModalOpen(true)}
                                className="view-breakdown"
                                type="button"
                                style={{ marginLeft: 'auto' }}
                              >
                                View Breakdown <Icon name="arrowUpRight" style={{ marginLeft: 6 }} />
                              </button>
                            )}
                          </p>
                          {Object.keys(morePaymentDetails).map(item => (
                            <p key={item}>
                              <span>
                                {item === 'rnn' && (
                                  <>
                                    RNN <ToolTip image={InfoIcon} message="Reference Retrieval Number" />
                                  </>
                                )}
                                {item === 'stan' && (
                                  <>
                                    STAN <ToolTip image={InfoIcon} message="System Trace Audit Number" />
                                  </>
                                )}
                                {item === 'cross-currency' && (
                                  <>
                                    Cross-Currency Status{' '}
                                    <ToolTip
                                      image={InfoIcon}
                                      message="This defines whether a transaction is performed in the issued virtual card currency or not"
                                    />
                                  </>
                                )}
                                {!['stan', 'rnn', 'cross-currency'].includes(item) && capitalizeRemovedash(item)}
                                {item === 'approval_code' && <ToolTip image={InfoIcon} message="Also known as ‘Acquirer code" />}
                              </span>
                              <span>{morePaymentDetails[item as keyof typeof morePaymentDetails]}</span>
                            </p>
                          ))}
                        </li>
                      )}
                      {/* ===========Remittance Merchants Transaction Details=========== */}
                      {remittance_data && (
                        <li>
                          <p className="section-heading" id="section-title">
                            {additionalInfoOptions(transactionType)?.remittanceHeading}
                          </p>
                          {Object.keys(additionalInfoOptions(transactionType)?.remittance || {}).map(item => (
                            <p key={item}>
                              {['bank_account', 'card', 'wallet', 'mobile_money'].includes(item) ? (
                                paymentChannels(item)
                              ) : (
                                <>
                                  <span>{capitalizeRemovedash(item)}</span>
                                  <span>
                                    {
                                      additionalInfoOptions(transactionType)?.remittance?.[
                                        item as keyof ReturnType<typeof additionalInfoOptions>
                                      ]
                                    }
                                  </span>
                                </>
                              )}
                            </p>
                          ))}
                        </li>
                      )}
                      {/* ===========Transaction Type Specific Details=========== */}
                      <li>
                        <p className="section-heading" id="section-title" hidden={!additionalInfoOptions(transactionType)?.title}>
                          {additionalInfoOptions(transactionType)?.title}
                        </p>
                        {Object.keys(additionalInfoOptions(transactionType)?.data || {}).map(item => (
                          <p key={item}>
                            {['bank_account', 'card', 'wallet', 'mobile_money'].includes(item) ? (
                              paymentChannels(item)
                            ) : (
                              <>
                                <span>{capitalizeRemovedash(item)}</span>
                                <span>
                                  {additionalInfoOptions(transactionType)?.data?.[item as keyof ReturnType<typeof additionalInfoOptions>]}
                                </span>
                              </>
                            )}
                          </p>
                        ))}
                      </li>
                      {['chargebacks', 'refunds'].includes(transactionType) && (
                        <li>
                          <p className="section-heading" id="section-title" hidden={!additionalInfoOptions('chargebacks-payins')?.title}>
                            {additionalInfoOptions('chargebacks-payins')?.title}
                          </p>
                          {Object.keys(additionalInfoOptions('chargebacks-payins')?.data || {}).map(item => (
                            <p key={item}>
                              {['bank_account', 'card', 'wallet', 'mobile_money'].includes(item) ? (
                                paymentChannels(item)
                              ) : (
                                <>
                                  <span>{capitalizeRemovedash(item)}</span>
                                  <span>
                                    {
                                      additionalInfoOptions('chargebacks-payins')?.data?.[
                                        item as keyof ReturnType<typeof additionalInfoOptions>
                                      ]
                                    }
                                  </span>
                                </>
                              )}
                            </p>
                          ))}
                        </li>
                      )}
                      {/* ===========Customer's Virtual Account Details=========== */}
                      {transactionType === 'pay-ins' && source?.virtual_bank_account && <li>{virtualAccount(source)}</li>}

                      {/* ===========Business Information (Virtual Cards)=========== */}
                      {transactionType === 'virtual-card' && (
                        <li>
                          <p className="section-heading mt-5" id="section-title">
                            Business Information
                          </p>
                          {Object.keys(virtualCardsBusinessInformation).map(item => (
                            <p key={item}>
                              <span>{capitalizeRemovedash(item)}</span>
                              <span>{virtualCardsBusinessInformation?.[item as keyof typeof virtualCardsBusinessInformation]}</span>
                            </p>
                          ))}
                        </li>
                      )}

                      {isConversions && data?.source && <li>{conversionsRelatedTrxns({ trxns: data })}</li>}

                      {!!relatedTrxnsWithoutCurrentTrxn?.length && (
                        <li>{relatedTransactions({ uniqueRef: payment.unique_reference, trxns: relatedTrxnsWithoutCurrentTrxn })}</li>
                      )}

                      {/* ============= Reversal, Refunds & Chargeback Tabs ============ */}
                      {(transactionType === 'pay-ins' || transactionType === 'payouts') && (
                        <ReversalTabs
                          activeDisputeTab={activeDisputeTab}
                          setModalOpen={setModalOpen}
                          reversalsControls={reversalsControls}
                          setActiveDisputeTab={setActiveDisputeTab}
                          currency={currency}
                          loadingDisputes={loadingDisputes}
                          refetchDisputes={refetchDisputes}
                          payoutReversal={payoutReversal}
                          refetchTransaction={refetchTransaction}
                          data
                        />
                      )}

                      {/* ===========Chargeback Activity Update=========== */}
                      {['chargebacks'].includes(transactionType) && (
                        <li>
                          <ChargebackActivityUpdate
                            resolveAction={() =>
                              setChargebackModalState({
                                open: true,
                                type: 'resolve-chargeback'
                              })
                            }
                            data={data}
                          />
                        </li>
                      )}
                    </ul>
                  </article>
                )}
              </section>
            </div>
          </div>
        </div>
        <ReversalsInfoModal close={() => setModalOpen(false)} visible={modalOpen} />
        {chargebackModalState.open && (
          <ChargebackDetailsModal
            close={() => setChargebackModalState({ open: false, type: '' })}
            type={chargebackModalState.type}
            data={status_history}
            reference={reference}
            setModalState={value => setChargebackModalState({ open: true, type: value })}
          />
        )}
        {showReversalInitiateModal && (
          <ReversePayoutModal
            transaction={data}
            onReversalInitiated={refetchTransaction}
            close={() => setShowReversalInitiateModal(false)}
          />
        )}
        {manageRefundsModalOpen && (
          <ManageRefundsModal
            close={() => setManageRefundsModalOpen(false)}
            visible={manageRefundsModalOpen}
            referenceId={id}
            currentStatus={status}
          />
        )}
        {managePausedPaymentsModal && (
          <PausedPaymentsModal
            size="sm"
            heading={modalContent.heading || 'Action required to proceed'}
            close={() => setManagePausedPaymentsModal('')}
            visible={!!managePausedPaymentsModal}
            secondButtonText={modalContent.secondButtonText || 'Confirm'}
            secondButtonColor={modalContent?.secondButtonColor}
            secondButtonAction={mutateAsync}
            content={<PausedPaymentsModalContent refs={[id]} stage={managePausedPaymentsModal} />}
            completedDescription={modalContent.completedDescription || 'Successfully initiated'}
            completedActionText="Dismiss"
            description={undefined}
            secondaryCompletedModal
            completedImage={modalContent.completedImage}
            completedHeading={modalContent.completedHeading || 'Success'}
          />
        )}
        {state.openTransactionStatusModal && (
          <TransactionStatusModal
            activeTransaction={state.activeTransaction}
            updateModalState={updateTransactionStatusModalState}
            triggerProcessingLoader={handleProcessingLoader}
            transactionType={transactionType === 'pay-ins' ? 'payins' : 'payout'}
          />
        )}
        {trxBreakdownModalOpen && (
          <TrxBreakdownModal
            trx={{
              currency,
              amount_charged: formatAmount(amountCharged),
              amount_paid: formatAmount(amount),
              fees: formatAmount(+fee + (+vat || 0)),
              ...(meta?.additional_fees?.stamp_duty && { stamp_duty_fee: meta?.additional_fees?.stamp_duty }),
              ...(payment?.sentinal_transaction
                ? {
                    tax: formatAmount(Number(payment.sentinal_transaction.vat) + Number(payment.sentinal_transaction.processing_fee)),
                    net_amount: formatAmount(
                      +netAmount - Number(payment.sentinal_transaction.vat) - Number(payment.sentinal_transaction.processing_fee)
                    )
                  }
                : { net_amount: netAmount })
            }}
            close={() => setTrxBreakdownModalOpen(false)}
          />
        )}
        {isReasonForFailureModalOpen && (
          <FailedPayoutReasons
            close={() => setIsReasonForFailureModalOpen(false)}
            visible={isReasonForFailureModalOpen}
            transactions={data}
          />
        )}
      </div>
      <section className="back-to-top">{ScrollToTopSection()}</section>
    </>
  );
}

export default TransactionDetails;

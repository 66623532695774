import React from 'react';

interface IFieldErrorMsg {
  touched: boolean;
  error: string;
  id: string;
}

const FieldErrorMsg = ({ touched, error, id }: IFieldErrorMsg) => {
  return (
    <div className="field-error" aria-live="polite" id={`err-${id}`}>
      {touched && error ? error : null}
    </div>
  );
};

export default FieldErrorMsg;

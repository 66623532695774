/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { useSearchQuery } from '+hooks';
import { AddMerchantModalStepType, CurrencyType, LimitStepType, MerchantInfoFormikBagType } from '+types';

import Stepper from '../../Stepper';
import ToggleDefaultConfig from '../../ToggleDefaultConfig';
import FundingLimit from './FundingLimit';
import PCIDSSLimit from './PCIDSSLimit';
import SpendingLimit from './SpendingLimit';

type DefaultLimitConfigType = Extract<
  AddMerchantModalStepType,
  'set_spending_limit' | 'set_funding_limit' | 'set_pci_dss_limit' | 'set_count_limit'
>;

interface ILimitConfigWizard extends MerchantInfoFormikBagType {
  category: 'reserved-cards' | 'issued-cards';
  step: LimitStepType;
  onStepChange: (arg: AddMerchantModalStepType) => void;
  onToggleConfigPreference: React.EventHandler<React.ChangeEvent>;
  configPreference: Record<DefaultLimitConfigType, 'custom' | 'default'>;
  completedSteps: Array<LimitStepType>;
}

const LimitConfigWizard = ({
  category,
  step,
  onStepChange,
  onToggleConfigPreference,
  configPreference,
  completedSteps,
  ...formik
}: ILimitConfigWizard) => {
  const searchQuery = useSearchQuery();
  const currency = (searchQuery.value?.currency as CurrencyType) ?? 'USD';

  const content: Record<LimitStepType, JSX.Element> = {
    set_spending_limit: <SpendingLimit isDefault={configPreference.set_spending_limit === 'default'} currency={currency} {...formik} />,
    set_funding_limit: <FundingLimit isDefault={configPreference.set_funding_limit === 'default'} currency={currency} {...formik} />,
    set_pci_dss_limit: (
      <PCIDSSLimit category={category} isDefault={configPreference.set_pci_dss_limit === 'default'} currency={currency} {...formik} />
    ),
    set_count_limit: (
      <PCIDSSLimit category={category} isDefault={configPreference.set_count_limit === 'default'} currency={currency} {...formik} />
    )
  };

  return (
    <div>
      <div className="limit-toggle-with-stepper">
        <ToggleDefaultConfig
          description="Select limits configuration option"
          selectedOption={configPreference[step]}
          onToggleConfigPreference={onToggleConfigPreference}
        />

        <div>
          <Stepper
            completedSteps={completedSteps}
            onStepChange={onStepChange}
            step={step}
            steps={['set_spending_limit', 'set_funding_limit', category === 'reserved-cards' ? 'set_count_limit' : 'set_pci_dss_limit']}
          />
        </div>
      </div>

      <div>{content[step]}</div>
    </div>
  );
};

export default LimitConfigWizard;

import React from 'react';

import Icon from '+containers/Dashboard/Shared/Icons';
import LoadingPlaceholder from '+containers/Dashboard/Shared/LoadingPlaceHolder';
import Modal from '+containers/Dashboard/Shared/Modal';
import { backwardAmountInput } from '+utils';

import useConversions from '../useConversions';

import '../conversions.scss';

export default function ConversionsSettings({ id, activeCurrency }: { [key in 'id' | 'activeCurrency']: string }) {
  const { modalDetails, disableSettlementBtn, toggleConversions, isFetching, setState, state } = useConversions(id, activeCurrency);
  return (
    <div className="conversions" data-testid="conversions">
      <section className="settlement-currency">
        <div className="settlement-currency__action">
          <div>
            <h5>Swap {activeCurrency} Settlements</h5>
            <p className="grey-text" data-testid="subtitle">
              A merchant may choose to have payments in one currency settled in another currency. This can be configured here
            </p>
          </div>
          {state.enabled && (
            <button
              data-testid="update-conversions-btn"
              className="btn btn-green"
              disabled={disableSettlementBtn()}
              type="button"
              onClick={() => setState({ activeModal: 'enableConversion' })}
            >
              <Icon name="save" height={20} width={20} stroke="currentColor" /> <span>Save Changes</span>
            </button>
          )}
        </div>
        <div className="toggle">
          <input
            aria-label="toggle-conversions-status"
            disabled={!state.currencyList.length}
            data-testid="conversions-checkbox"
            checked={state.enabled}
            type="checkbox"
            onChange={toggleConversions}
          />
          <span>Allow this merchant to settle {activeCurrency} payments in another currency</span>
        </div>{' '}
      </section>
      {state.enabled && (
        <section className="conversions-markup visible">
          {isFetching ? (
            <LoadingPlaceholder type="text" content={2} />
          ) : (
            <>
              <div className="conversions-markup__edit" data-testid="conversions-form">
                <div className="conversions-markup__edit-markup">
                  <strong>Select the settlement currency</strong>
                  <p>All transactions will be settled in the currency you chose for this merchant</p>
                </div>
                <div className="conversions-markup__edit-wrapper">
                  <select
                    data-testid="conversions-currency-select"
                    value={state.settlementCurrency}
                    onChange={e => setState({ settlementCurrency: e.target.value })}
                  >
                    <option disabled value="">
                      Select settlement currency
                    </option>
                    {state.currencyList.map(arg => (
                      <option key={arg} value={arg}>
                        {arg}
                      </option>
                    ))}
                  </select>
                  <Icon name="caretDown" stroke="currentColor" strokeWidth={1} />
                </div>
              </div>
              <div className="conversions-markup__edit">
                <div className="conversions-markup__edit-markup">
                  <strong>Internal markup</strong>
                  <p>Apply a markup to this merchant, which will be added to the current rate.</p>
                </div>
                <div className="conversions-markup__edit-wrapper">
                  <div className="select-wrapper">
                    <select defaultValue="%" disabled>
                      <option>%</option>
                    </select>
                    <Icon name="caretDown" stroke="#94a7b7" strokeWidth={1} />
                  </div>
                  <input
                    data-testid="rate-markup-input"
                    value={state.rateMarkup}
                    onChange={e => setState({ rateMarkup: String(backwardAmountInput(e.target.value)) })}
                    placeholder="0.00"
                  />
                </div>
              </div>
              <div className="conversions-markup__edit">
                <div className="conversions-markup__edit-markup">
                  <strong>Merchant Markup Limit</strong>
                  <p>Set the maximum markup rate that a merchant can add to the existing rate.</p>
                </div>
                <div className="conversions-markup__edit-wrapper">
                  <div className="select-wrapper">
                    <select defaultValue="%" disabled>
                      <option>%</option>
                    </select>
                    <Icon name="caretDown" stroke="#94a7b7" strokeWidth={1} />
                  </div>
                  <input
                    data-testid="markup-limit-input"
                    value={state.markupLimit}
                    onChange={e => {
                      setState({ markupLimit: String(backwardAmountInput(e.target.value)) });
                    }}
                    placeholder="0.00"
                  />
                </div>
              </div>
            </>
          )}
        </section>
      )}

      <Modal
        visible={!!state.activeModal}
        close={() => setState({ activeModal: '' })}
        heading={modalDetails?.heading}
        description={modalDetails?.description}
        content={modalDetails?.content}
        secondButtonText={modalDetails?.secondButtonText}
        size={modalDetails?.size}
        secondButtonColor={modalDetails?.secondButtonColor}
        secondButtonActionIsTerminal={modalDetails?.secondButtonActionIsTerminal || false}
        secondButtonAction={modalDetails?.secondButtonAction}
        showButtons={modalDetails?.showButtons}
        secondButtonDisable={modalDetails?.secondButtonDisable}
      />
    </div>
  );
}

import React from 'react';

import FieldErrorMsg from '+containers/Dashboard/Shared/FormError';
import NumericInput from '+containers/Dashboard/Shared/NumericInput';
import { ICardSpendingLimits } from '+types';

import { riskLevelMap } from '../../helpers/holderDetailsHelpers';
import ModificationDisclaimer from './ModificationDisclaimer';

const CardSpendingLimits = ({ currency, onBlur, errors, values, touched, setFieldValue, getFieldProps }: ICardSpendingLimits) => {
  return (
    <div className="fade-in">
      <fieldset className="mt-0">
        <div>
          <label className="mt-0" htmlFor="mrl">
            Merchant&apos;s Risk level
          </label>
          <select id="mrl" {...getFieldProps('riskLevel')} className="form-control" aria-labelledby="err-mrl" disabled>
            {Object.entries(riskLevelMap).map(([key, value]) => (
              <option key={key} value={key}>
                {value}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label htmlFor="maxlpt">Max. limit per transaction ({currency})</label>
          <NumericInput
            id="maxlpt"
            className="form-control"
            data-state={touched.per_transaction_max && errors.per_transaction_max ? 'invalid' : ''}
            aria-labelledby="err-maxlpt"
            name="per_transaction_max"
            value={values.per_transaction_max}
            onValueChange={values => setFieldValue('per_transaction_max', values.value)}
            onBlur={onBlur}
          />
          <FieldErrorMsg touched={Boolean(touched.per_transaction_max)} error={errors.per_transaction_max ?? ''} id="maxlpt" />
        </div>

        <div>
          <label htmlFor="dtc">Daily transaction cap ({currency})</label>
          <NumericInput
            id="dtc"
            className="form-control"
            data-state={touched.daily_max && errors.daily_max ? 'invalid' : ''}
            aria-labelledby="err-dtc"
            name="daily_max"
            value={values.daily_max}
            onValueChange={values => setFieldValue('daily_max', values.value)}
            onBlur={onBlur}
          />
          <FieldErrorMsg touched={Boolean(touched.daily_max)} error={errors.daily_max ?? ''} id="dtc" />
        </div>

        <div>
          <label htmlFor="mtc">Monthly transaction cap ({currency})</label>
          <NumericInput
            id="mtc"
            className="form-control"
            data-state={touched.monthly_max && errors.monthly_max ? 'invalid' : ''}
            aria-labelledby="err-mtc"
            name="monthly_max"
            value={values.monthly_max}
            onValueChange={values => setFieldValue('monthly_max', values.value)}
            onBlur={onBlur}
          />
          <FieldErrorMsg touched={Boolean(touched.monthly_max)} error={errors.monthly_max ?? ''} id="mtc" />
        </div>
      </fieldset>

      <ModificationDisclaimer />
    </div>
  );
};

export default CardSpendingLimits;

import React from 'react';
import { useQuery } from 'react-query';
import { NavLink, useParams } from 'react-router-dom';

import Badge from '+containers/Dashboard/Shared/Badge';
import Typography from '+containers/Dashboard/Shared/Typography';
import { useFeedbackHandler, useQueryStorage, useSearchQuery } from '+hooks';
import APIRequest from '+services/api-services';
import CurrencyPicker from '+shared/CurrencyPicker';
import useProductConfigCardIssuanceStore from '+store/productConfigCardIssuanceStore';
import { CurrencyType, productCategoriesType, ProductConfigSettingsType } from '+types';
import {
  availableProductCategories,
  capitalize,
  formatProductConfigStatus,
  history,
  storageDataKey,
  switchCurrencyWithoutSymbol
} from '+utils';

import Categories from './Categories';

import './index.scss';

const api = new APIRequest();

const ProductGroup = () => {
  const searchQuery = useSearchQuery<{ currency: CurrencyType; product: productCategoriesType }>();
  const { getQueryData } = useQueryStorage();
  const { feedbackInit } = useFeedbackHandler();
  const { currency } = useParams<{ currency: CurrencyType }>();
  const setAccessStatus = useProductConfigCardIssuanceStore(store => store.setAccessStatus);
  const availableCurrencies = getQueryData(storageDataKey.AVAILABLE_CURRENCIES);
  const activeCurrency = searchQuery.value.currency ?? currency;

  const {
    data: configData,
    refetch: refetchConfig,
    isLoading
  } = useQuery<unknown, unknown, { data: ProductConfigSettingsType }>(
    [`${currency}_ALL_PRODUCT_CONFIG_SETTING`, activeCurrency],
    () => api.getProductConfiguration(activeCurrency, {}),
    {
      refetchOnMount: 'always',
      onError: () => {
        feedbackInit({
          message: `There has been an error in getting this currency's config`,
          type: 'danger',
          action: {
            action: () => refetchConfig(),
            name: 'Try again'
          }
        });
      },
      onSuccess: data => setAccessStatus(data?.data)
    }
  );

  const currencyStatus = configData?.data?.setting?.enabled;

  return (
    <div className="content-i">
      <div className="content-box">
        <div className="row">
          <button type="button" className="btn btn-link mb-2" onClick={() => history.push('/dashboard/product-config')}>
            <i className="os-icon os-icon-arrow-left7" />
            <span>Back to Product Config</span>
          </button>
        </div>
        <div className="content">
          <div className="first-section">
            <div className="title-wrapper">
              <span>
                <Typography variant="h2">{`${switchCurrencyWithoutSymbol[activeCurrency]} Merchants [${activeCurrency}]`}</Typography>
                {!isLoading && <Badge status={formatProductConfigStatus(String(currencyStatus))} />}
              </span>
              <Typography variant="subtitle2">Here’s a comprehensive list of products under this currency.</Typography>
            </div>
            <div className="controls">
              <CurrencyPicker
                options={availableCurrencies as CurrencyType[]}
                onChange={value => {
                  searchQuery.setQuery({ currency: value as CurrencyType });
                }}
                className="business-settings__currency-switch"
                activeCurrency={activeCurrency.toUpperCase()}
                label={null}
              />
            </div>
          </div>
          <section className="os-tabs-w">
            <div className="os-tabs-controls os-tabs-complex">
              <ul className="nav nav-tabs" role="tablist">
                {Object.entries(availableProductCategories).map(([key, label]) => {
                  return (
                    <li className="nav-item" key={key} role="tab" hidden={currency !== 'USD' && key === 'card-issuance'}>
                      <NavLink className="nav-link" activeClassName="active" strict to={key}>
                        {capitalize(label)}
                      </NavLink>
                    </li>
                  );
                })}
              </ul>
            </div>
          </section>

          <Categories currency={activeCurrency} />
        </div>
      </div>
    </div>
  );
};

export default ProductGroup;

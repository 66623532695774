import React, { RefObject, useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import classNames from 'classnames';

import { useClickOutside } from '+hooks';
import { ISearchInput } from '+types';
import { FilterMerchantType } from '+types/identity';

import './index.scss';

type SearchInputStateT = {
  searchedResults: FilterMerchantType[];
  searchInputValue: string;
  cachedSearchInputValue: string;
  openDropdown: boolean;
};

export default function SearchInput({
  label,
  placeholder,
  id,
  value,
  onChange,
  dropdownData,
  setDebouncedSearchInput,
  setShouldRunQuery,
  isFetching,
  disabled,
  wrapperClassName = '',
  onBlur,
  accessorKey = 'kora_id',
  name
}: ISearchInput) {
  const [state, setState] = useState<SearchInputStateT>({
    searchedResults: [],
    searchInputValue: '',
    cachedSearchInputValue: '',
    openDropdown: false
  });

  const updateState = (newState: Partial<SearchInputStateT>) => {
    return setState(prevState => ({ ...prevState, ...newState }));
  };

  const wrapperRef = useClickOutside(() => {
    updateState({ openDropdown: false });
  });

  useDebounce(
    () => {
      setDebouncedSearchInput?.(state.searchInputValue);
      if (state.searchInputValue !== state.cachedSearchInputValue) setShouldRunQuery?.(true);
    },
    500,
    [state.searchInputValue]
  );

  useEffect(() => {
    if (!dropdownData) {
      updateState({ searchedResults: [] });
      return;
    }
    updateState({ searchedResults: dropdownData });
  }, [dropdownData]);

  useEffect(() => {
    if (value && !state.searchInputValue) updateState({ searchInputValue: value as string });
  }, [value]);

  const generateResult = (listItem: string, query: string) => {
    const queryIndex = listItem?.toLowerCase().indexOf(query?.toLowerCase().trim());
    if (queryIndex === 0) {
      return (
        <>
          <strong>{listItem.substr(queryIndex, query.length)}</strong>
          {listItem.substr(queryIndex + query.length)}
        </>
      );
    }
    if (queryIndex === listItem?.length - query.length) {
      return (
        <>
          {listItem?.substr(0, queryIndex)}
          <strong>{listItem?.substr(queryIndex, query.length)}</strong>
        </>
      );
    }
    if (queryIndex !== -1) {
      return (
        <>
          {listItem?.substr(0, queryIndex)}
          <strong>{listItem?.substr(queryIndex, query.length)}</strong>
          {listItem?.substr(queryIndex + query.length)}
        </>
      );
    }
    return listItem;
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateState({ searchInputValue: e.target.value, openDropdown: true });
  };

  const handleOnClick = (e: FilterMerchantType) => {
    onChange(accessorKey ? e[accessorKey] : e);

    updateState({
      searchInputValue: e.name,
      cachedSearchInputValue: e.name,
      searchedResults: [],
      openDropdown: false
    });
    setShouldRunQuery?.(false);
  };

  const renderDropdownContent = () => {
    if (state.searchInputValue.length < 3) {
      return <div>Search query must be at least 3 characters</div>;
    }
    if (isFetching) {
      return <div>Loading...</div>;
    }
    if (!isFetching && state.searchedResults?.length === 0) {
      return <div>No results found</div>;
    }
    return state.searchedResults.map(each => (
      <button
        data-testid={`search-dropdown-item`}
        type="button"
        key={each.kora_id}
        className="search-input__dropdown-item"
        onClick={() => handleOnClick(each)}
      >
        {generateResult(each.name, state.searchInputValue)}
      </button>
    ));
  };

  return (
    <div className={classNames('search-input', wrapperClassName)} ref={wrapperRef as RefObject<HTMLDivElement>}>
      <label htmlFor={id}>{label}</label>
      <input
        data-testid="search-input"
        type="text"
        id={id}
        placeholder={placeholder}
        value={state.searchInputValue}
        onChange={handleInputChange}
        disabled={disabled}
        onBlur={onBlur}
        name={name}
      />
      {state.openDropdown && (
        <div className="search-input__dropdown" data-testid="search-dropdown">
          {renderDropdownContent()}
        </div>
      )}
    </div>
  );
}

import React from 'react';

interface IFieldErrorMsg {
  touched: boolean;
  error?: string;
  id: string;
}

export default function FieldErrorMsg({ touched, error, id }: IFieldErrorMsg) {
  return (
    <div className="form-error mt-1" aria-live="polite" id={`err-${id}`}>
      {touched && error ? error : null}
    </div>
  );
}

import React from 'react';

import { getDefaultValue } from '+containers/Dashboard/Identity/helpers/configurationHelper';
import Accordion from '+containers/Dashboard/Shared/Accordion';
import CustomCheckbox from '+containers/Dashboard/Shared/CustomCheckbox';
import { KAASBillingFeesDataType, KAASFeesDataType, KAASFeeType } from '+types';

import ConfigurationFee from '../ConfigurationFee';
import ConfigurationFeeItem from '../ConfigurationFeeItem';

const ResetContent = ({
  feesData,
  defaultFee,
  setSelectedFees
}: {
  feesData: KAASBillingFeesDataType;
  defaultFee: KAASFeesDataType;
  setSelectedFees: (section: 'kyb' | 'kyc', e: KAASFeeType, add: boolean) => void;
}) => {
  const [resetAll, setResetAll] = React.useState(false);
  const selfie = feesData?.kyc.find(fee => fee.key === 'selfie_validation');
  return (
    <div className="id-fees-reset">
      <h3>Select the ID types you want to reset to default</h3>
      <CustomCheckbox
        className="identity-check reset-all"
        text="Reset all to default"
        checked={resetAll}
        onChange={e => setResetAll(e as boolean)}
      />
      <div>
        {feesData?.kyc?.length > 0 && (
          <Accordion
            title="Individuals (KYC)"
            className="icf__accordion"
            showToggle
            open
            content={
              <div>
                <ConfigurationFee title="ID Lookup" type="KYC" configType="reset">
                  {feesData?.kyc
                    ?.filter(fee => fee.key !== 'selfie_validation')
                    .map(fee => (
                      <ConfigurationFeeItem
                        key={fee.key}
                        title={fee.title}
                        currentFee={fee.currentFee}
                        newFee={getDefaultValue(fee.key, 'kyc', defaultFee)}
                        currency={fee.currency}
                        newCurrency={defaultFee.billing.currency}
                        modified={fee.modified}
                        configType="reset"
                        disabled={resetAll}
                        setChecked={e => {
                          setSelectedFees('kyc', fee, e);
                        }}
                        checked={resetAll ? true : undefined}
                 
                      />
                    ))}
                </ConfigurationFee>
                {selfie && (
                  <ConfigurationFee title="Facial Matching" type="KYC">
                    <ConfigurationFeeItem
                      key={selfie.key}
                      title={selfie.title}
                      currentFee={selfie.currentFee}
                      newFee={getDefaultValue('selfie_validation', 'kyc', defaultFee)}
                      currency={selfie.currency}
                      newCurrency={defaultFee.billing.currency}
                      modified={selfie.modified}
                      configType="reset"
                      disabled={resetAll}
                      setChecked={e => setSelectedFees('kyc', selfie, e)}
                      checked={resetAll ? true : undefined}
                  
                    />
                  </ConfigurationFee>
                )}
              </div>
            }
          />
        )}
        {feesData.kyb?.length > 0 && (
          <Accordion
            title="Businesses (KYB)"
            className="icf__accordion"
            showToggle
            content={
              <div>
                <ConfigurationFee title="Business Identity" type="KYB">
                  {feesData.kyb.map(fee => (
                    <ConfigurationFeeItem
                      key={fee.key}
                      title={fee.title}
                      currentFee={fee.currentFee}
                      newFee={fee.newFee}
                      currency={fee.currency}
                      newCurrency={defaultFee.billing.currency}
                      modified={fee.modified}
                      configType="reset"
                      disabled={resetAll}
                      setChecked={e => setSelectedFees('kyb', fee, e)}
                      checked={resetAll ? true : undefined}
                    />
                  ))}
                </ConfigurationFee>
              </div>
            }
          />
        )}
      </div>
    </div>
  );
};

export default ResetContent;

import { history } from '+utils';
import { useCallback, useEffect, useState } from 'react';

interface ILocation {
  pathname: string;
}


const useNavigationInterceptor = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [nextLocation, setNextLocation] = useState<Location | null>(null);
  const [isNavigating, setIsNavigating] = useState(false);


  const handleBlockedNavigation = useCallback(
    (nextLocation: Location): boolean => {
      if (!isNavigating) {
        setIsModalOpen(true);
        setNextLocation(nextLocation);
        return false;
      }
      return true;
    },
    [isNavigating]
  );

  useEffect(() => {
    if(isNavigating && nextLocation) {
      history.push(nextLocation.pathname);
    }
  }, [isNavigating, nextLocation])

  const handleConfirmNavigationClick = () => {
    setIsModalOpen(false);
    setIsNavigating(true);
  };

  const handleCancelNavigationClick = useCallback(() => {
    setIsModalOpen(false);
    setNextLocation(null);
  }, []);

  return {
    isModalOpen,
    handleBlockedNavigation,
    handleConfirmNavigationClick,
    handleCancelNavigationClick
  };
};

export default useNavigationInterceptor;

import { MerchantFundingLimitsType, MerchantPCIDSSLimitsType, MerchantSpendingLimitsType } from '+types';

type FormValuesType = MerchantSpendingLimitsType | MerchantFundingLimitsType | MerchantPCIDSSLimitsType;
type AllFormKeys = keyof MerchantSpendingLimitsType | keyof MerchantFundingLimitsType | keyof MerchantPCIDSSLimitsType;
type FormErrorType = Partial<Record<AllFormKeys, string>>;

const isEmpty = (value: unknown) => String(value).trim() === '';

export const formValidationFn = (formValues: FormValuesType): FormErrorType => {
  const errors: FormErrorType = {};

  const validateField = (field: AllFormKeys) => {
    if (field in formValues && isEmpty(formValues[field as keyof typeof formValues])) {
      errors[field] = 'Invalid value';
    }
  };

  validateField('daily_max');
  validateField('monthly_max');
  validateField('quarterly_max');

  validateField('daily_max');
  validateField('monthly_max');
  validateField('per_transaction_max');

  validateField('yearly_issued_cards');
  validateField('yearly_transaction_count');

  return errors;
};

/* eslint-disable react/no-array-index-key */
import React from 'react';

import './index.scss';

interface ILoadingPlaceholderProps {
  type: 'table' | 'text';
  content?: number;
  background?: string;
  section?: number;
  rows?: number;
}
export default function LoadingPlaceholder({ type, content = 1, background, section, rows = 3 }: ILoadingPlaceholderProps) {
  const rowsArray = Array(rows).fill(0);
  const contentArray = Array(content).fill(0);
  const sectionArray = Array(section).fill(0);

  const textboxWidth = () => {
    const percent = Math.floor(Math.random() * (90 / content));
    const width = window.innerWidth < 700 ? Math.floor(Math.random() * (window.innerWidth * 0.8)) : window.innerWidth * (percent / 100);
    return `${width}px`;
  };

  return (
    <div className="table-loader-container" data-testid="loading-placeholder">
      {type === 'table' && (
        <>
          {rowsArray.map((_, i) => (
            <div className="loader-row" key={i} style={{ backgroundColor: background }}>
              {contentArray.map((x, index) => (
                <div className="loader-content" key={index} style={{ width: `${Math.floor(Math.random() * (110 / content))}%` }} />
              ))}
            </div>
          ))}
        </>
      )}

      {type === 'text' &&
        sectionArray.map((_, i) => (
          <div className="textbox-loader-container" key={`section${i}`}>
            {contentArray.map((x, i) => (
              <div className="textbox-loader" key={i}>
                {rowsArray.map((_, index) => (
                  <div className="loader-content" key={index} style={{ width: `${textboxWidth()}` }} />
                ))}
              </div>
            ))}
          </div>
        ))}
    </div>
  );
}

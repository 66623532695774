import React from 'react';

import { AddMerchantModalStepType, LimitStepType } from '+types';

interface IStepper {
  steps: Array<LimitStepType>;
  step: AddMerchantModalStepType;
  onStepChange: (arg: LimitStepType) => void;
  completedSteps: Array<LimitStepType>;
}

const stageLabels: Record<LimitStepType, string> = {
  set_pci_dss_limit: 'PCI DSS level',
  set_spending_limit: 'Spending limit',
  set_funding_limit: 'Funding limit',
  set_count_limit: 'Count limit'
};

const Stepper = ({ steps, step, onStepChange, completedSteps }: IStepper) => {
  return (
    <div className="d-flex align-items-center stepper">
      {steps.map(stage => {
        const isActive = stage === step || completedSteps.includes(stage);
        return (
          <button type="button" onClick={() => onStepChange(stage)} className="d-flex align-items-center step" key={stage}>
            <span className="dashed-line" />
            <div className={`label-with-step-indicator ${isActive ? 'active' : ''}`}>
              <span className="step-indicator" />
              <span className={`${isActive ? 'font-weight-bold' : ''}`}>{stageLabels[stage]}</span>
            </div>
          </button>
        );
      })}
    </div>
  );
};

export default Stepper;

import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { useSetUserAccess } from '+hooks';
import { isAllowed } from '+utils';

const ProtectedRoute = ({
  component: Component,
  permissions,
  ...rest
}: { component: React.ComponentType<any>; permissions: string[] } & React.ComponentPropsWithoutRef<typeof Route>) => {
  const userAccess = useSetUserAccess();
  const hasAccess = isAllowed(userAccess, permissions);

  return <Route {...rest} render={props => (hasAccess ? <Component {...props} /> : <Redirect to="/dashboard/access-denied" />)} />;
};

export default ProtectedRoute;

import React from 'react';

import './index.scss';

type SidebarItemPropsType = {
  name: string;
  action: () => void;
  active?: boolean;
};

function SidebarItem({ name, action, active }: SidebarItemPropsType) {
  return (
    <button className={`id-sidebar-item ${active ? 'active' : ''}`} onClick={action}>
      {name}
    </button>
  );
}
export default function Sidebar({ items, activeItem, className = '' }: { items: Array<SidebarItemPropsType>; activeItem?: string; className?: string }) {
  return (
    <div className={`id-sidebar ${className}`}>
      {items?.map(item => {
        return <SidebarItem key={item.name} {...item} active={activeItem === item.name} />;
      })}
    </div>
  );
}

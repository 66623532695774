import {
  AccessDataType,
  BillingType,
  HasDifferencesWithInitialType,
  IdentityDocumentType,
  KAASBillingFeesDataType,
  KAASFeesDataType,
  KAASFeeType
} from '+types';
import { identityCountries, identityCountriesWithoutCode } from '+utils';

export const transformFeesData = (
  data: KAASFeesDataType,
  defaultFee: KAASFeesDataType,
  type: 'default' | 'merchant',
  identityTitles: { [key: string]: string },
  removeSelfieValidation?: boolean
): KAASBillingFeesDataType => {
  const { billing, access } = data || {};
  const { currency, kyc, kyb } = billing || {};
  const { billing: defaultBilling } = defaultFee || {};
  const { kyc: defaultKyc, kyb: defaultKyb } = defaultBilling || {};
  const { kyc: accessKyc, kyb: accessKyb } = access || {};

  const transformSection = (
    section: { [key: string]: number | null } | undefined,
    defaultSection: { [key: string]: number | null } | undefined,
    accessSection: { [key: string]: { enabled: boolean } },
    titles: { [key: string]: string }
  ) => {
    const transformed = Object.keys(accessSection || {})
      .filter(key => type === 'default' || accessSection[key].enabled)
      .map(key => {
        return {
          key,
          title: titles[key],
          currentFee: section?.[key] ?? defaultSection?.[key] ?? 0,
          newFee: undefined,
          currency: currency ?? defaultBilling?.currency,
          modified: type === 'default' ? false : section?.[key] !== undefined,
          selected: accessSection[key]?.enabled ?? accessSection[key]
        };
      })
      .filter(fee => fee.title !== undefined);
    if (!removeSelfieValidation && (section?.selfie_validation !== undefined || defaultSection?.selfie_validation !== undefined)) {
      transformed.push({
        key: 'selfie_validation',
        title: 'Surported ID',
        currentFee: section?.selfie_validation ?? defaultSection?.selfie_validation ?? 0,
        newFee: undefined,
        currency: currency ?? defaultBilling?.currency,
        modified: type === 'default' ? false : section?.selfie_validation !== undefined,
        selected: accessSection?.['selfie_validation']?.enabled ?? accessSection?.['selfie_validation']
      });
    }

    return transformed;
  };

  return {
    kyc: transformSection(kyc, defaultKyc, accessKyc, identityTitles),
    kyb: transformSection(kyb, defaultKyb, accessKyb, identityTitles)
  };
};

export const compareFees = (fees: BillingType, defaultFee: BillingType) => {
  const differences: {
    kyc: Array<{ key: string; fee: number | null; defaultFee: number | null }>;
    kyb: Array<{ key: string; fee: number | null; defaultFee: number | null }>;
  } = { kyc: [], kyb: [] };

  const compareSection = (
    section: { [key: string]: number | null } | undefined,
    defaultSection: { [key: string]: number | null } | undefined,
    sectionName: 'kyc' | 'kyb'
  ) => {
    Object.keys(section || {}).forEach(key => {
      if (section![key] !== defaultSection![key]) {
        differences[sectionName].push({ key, fee: section![key], defaultFee: defaultSection![key] });
      }
    });
  };

  compareSection(fees?.kyc, defaultFee?.kyc, 'kyc');
  compareSection(fees?.kyb, defaultFee?.kyb, 'kyb');

  return differences;
};

export const checkIfModified = (feesData: KAASBillingFeesDataType): boolean => {
  return feesData.kyc.some(fee => fee.modified) || feesData.kyb.some(fee => fee.modified);
};

export const hasDifferencesWithInitial = (
  initialFeesData: HasDifferencesWithInitialType,
  feesData: HasDifferencesWithInitialType
): boolean => {
  const compareSection = (section: KAASFeeType[], initialSection: KAASFeeType[]): boolean => {
    for (const fee of section) {
      const initialFee = initialSection.find(f => f.key === fee.key);
      if (initialFee && fee.newFee !== initialFee.newFee) {
        return true;
      }
    }
    return false;
  };

  const compareVat = (vat: boolean, initialVat: boolean) => {
    return vat !== initialVat;
  };

  const compareCurrency = (currency: string, initialCurrency: string) => {
    return currency !== initialCurrency;
  };

  return (
    compareSection(feesData.kyc, initialFeesData.kyc) ||
    compareSection(feesData.kyb, initialFeesData.kyb) ||
    compareVat(feesData.vat_inclusive, initialFeesData.vat_inclusive) ||
    compareCurrency(feesData.currency as string, initialFeesData.currency as string)
  );
};

export const extractRegions = (data: AccessDataType) => {
  if (!data) return [];

  return Object.keys(data).map(countryCode => {
    const countryData = data[countryCode as keyof Omit<AccessDataType, 'kora_id' | 'enable'>];
    return {
      key: countryCode,
      status: countryData?.enabled ? 'Enabled' : 'Disabled',
      name: identityCountries?.find(country => country.value === countryCode)?.label
    };
  });
};

export const compareConfigurations = (externalRegions: AccessDataType, merchantFees: AccessDataType): string[] => {
  const differentConfigs: string[] = [];

  Object.keys(externalRegions).forEach(countryCode => {
    const externalConfig = externalRegions[countryCode as keyof AccessDataType];
    const merchantConfig = merchantFees[countryCode as keyof AccessDataType];

    if (JSON.stringify(externalConfig) !== JSON.stringify(merchantConfig)) {
      differentConfigs.push(countryCode);
    }
  });

  return differentConfigs;
};

export const getDefaultValue = (key: string, section: 'kyc' | 'kyb', defaultFee: KAASFeesDataType) => {
  return defaultFee?.billing?.[section]?.[key] ?? 0;
};

export const transformFeesDataForReset = (resetFeesData: KAASBillingFeesDataType): BillingType => {
  const transformedData: BillingType = {
    kyc: {},
    kyb: {}
  };

  resetFeesData.kyc.forEach((fee: KAASFeeType) => {
    transformedData.kyc![fee.key] = null;
  });

  if (Object.keys(transformedData?.kyc || {}).length <= 0) {
    delete transformedData['kyc'];
  }

  resetFeesData.kyb.forEach((fee: KAASFeeType) => {
    transformedData.kyb![fee.key] = null;
  });

  if (Object.keys(transformedData?.kyb || {}).length <= 0) {
    delete transformedData['kyb'];
  }

  return transformedData;
};

export const transformFeesDataForSave = (
  feesData: KAASBillingFeesDataType,
  selectedCurrency: string | undefined,
  vatInclusive: boolean | undefined,
  type: 'merchant' | 'default'
): BillingType => {
  const transformedData: BillingType = {
    currency: selectedCurrency,
    vat_inclusive: vatInclusive,
    kyc: {},
    kyb: {}
  };

  feesData.kyc.forEach((fee: KAASFeeType) => {
    if (fee.newFee !== undefined) {
      transformedData.kyc![fee.key] = fee.newFee;
    }
  });

  if (Object.keys(transformedData?.kyc || {}).length <= 0) {
    delete transformedData['kyc'];
  }

  feesData.kyb.forEach((fee: KAASFeeType) => {
    if (fee.newFee !== undefined) {
      transformedData.kyb![fee.key] = fee.newFee;
    }
  });

  if (Object.keys(transformedData?.kyb || {}).length <= 0) {
    delete transformedData['kyb'];
  }

  if (type === 'merchant') {
    delete transformedData['vat_inclusive'];
    delete transformedData['currency'];
  }

  return transformedData;
};

export const getSelectedCountries = (regions: AccessDataType): string[] => {
  return Object.keys(regions).filter(country => {
    const region = regions[country as keyof AccessDataType];
    return typeof region === 'object' && 'enabled' in region && region.enabled;
  });
};

export const arraysEqual = (arr1: string[], arr2: string[]): boolean => {
  if (arr1.length !== arr2.length) return false;
  const sortedArr1 = [...arr1].sort();
  const sortedArr2 = [...arr2].sort();
  return sortedArr1.every((value, index) => value === sortedArr2[index]);
};

export const convertToObject = (arr: IdentityDocumentType[]) => {
  return arr?.reduce((acc, item) => {
    acc[item.type] = item.description;
    return acc;
  }, {} as { [key: string]: string });
};

export const convertUseCasesToObject = (useCases: KAASFeeType[], categoryState: { [key: string]: boolean }, category: 'kyb' | 'kyc') => {
  const result = useCases.reduce((acc: { [key: string]: boolean }, useCase) => {
    acc[useCase.key as string] = useCase.selected as boolean;
    return acc;
  }, {} as { [key: string]: boolean });
  return { [category]: { enable: categoryState[category], ...result } };
};

export const checkIfUseCasesChanged = (
  useCasesData: KAASBillingFeesDataType,
  useCasesState: KAASBillingFeesDataType,
  category: string,
  categoryState: {
    kyb: boolean;
    kyc: boolean;
  },
  categoryInitialState: {
    kyb: boolean;
    kyc: boolean;
  }
) => {
  const currentUseCases = useCasesData[category as keyof typeof useCasesData];
  const updatedUseCases = useCasesState[category as keyof typeof useCasesData];
  return (
    JSON.stringify(currentUseCases) !== JSON.stringify(updatedUseCases) ||
    categoryState[category as keyof typeof categoryState] !== categoryInitialState[category as keyof typeof categoryState]
  );
};

export const getCountry = (region: string) => {
  return identityCountriesWithoutCode?.find(country => country.value === region)?.label || '';
};

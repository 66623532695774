import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import AuthComponent from '+auth';
import DashboardComponent from '+dashboard';
import ExpiredExport from '+shared/ExpiredExport';
import { history } from '+utils';

import useStore from './zustandStore';

import './App.scss';

const App = () => {
  const { isAuthenticated } = useStore(state => state);

  return (
    <Router history={history}>
      <Switch>
        {isAuthenticated ? (
          [
            <Route path="/dashboard" component={DashboardComponent} key="auth01" />,
            <Route path="/expired-export" component={ExpiredExport} key="auth03" />,
            <Redirect path="/" key="auth02" to="/dashboard/home" />
          ]
        ) : (
          <AuthComponent />
        )}
      </Switch>
    </Router>
  );
};

export default function AppWrapper() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 1000 * 60 * 10,
        refetchOnWindowFocus: false,
        retry: (failedCount: number, error: any): boolean => {
          const maxRetryCount = 2;
          return failedCount < maxRetryCount && !(error?.response?.status >= 400 && error?.response?.status < 500);
        }
      }
    }
  });

  return (
    <QueryClientProvider client={queryClient}>
      <App />
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

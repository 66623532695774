import React from 'react';

import CustomCheckbox from '+containers/Dashboard/Shared/CustomCheckbox';
import { KAASFeeType } from '+types';

import { getCountry } from '../../helpers/configurationHelper';
import UseCase from './UseCase';

import './index.scss';

export default function UseCases({
  useCaseOptions,
  category,
  updateUseCases,
  updateSectionState,
  sectionState,
  region,
  type = 'general'
}: {
  updateSectionState: (e: boolean) => void;
  useCaseOptions: KAASFeeType[];
  category: 'kyc' | 'kyb';
  updateUseCases: (useCase: KAASFeeType, section: 'kyb' | 'kyc') => void;
  sectionState: boolean;
  region: string;
  type?: 'merchant' | 'general';
}) {
  const updateUseCase = (useCase: KAASFeeType) => {
    updateUseCases(useCase, category);
  };

  const checkIfModified = () => {
    return useCaseOptions?.some(option => option.modified);
  };

  return (
    <>
      <CustomCheckbox
        className="use-cases-switch"
        checked={!sectionState}
        text={`Turn off ${category?.toUpperCase()} verification in ${getCountry(region)} for ${
          type === 'merchant' ? 'this merchant' : 'all merchants'
        }`}
        onChange={e => {
          updateSectionState(!sectionState);
        }}
      />
      <div className="id-use-cases">
        <UseCase
          title={`${category === 'kyb' ? 'Business ' : ''}ID Verification`}
          description={`Choose whether merchants can verify ${
            category === 'kyb' ? 'business ' : ''
          }identity documents and/or compare the data submitted with data extracted from the documents`}
          disabled={!sectionState}
          indicateModified={checkIfModified()}
          options={useCaseOptions}
          updateUseCase={updateUseCase}
        />
      </div>
    </>
  );
}

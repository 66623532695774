import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useFormik } from 'formik';

import { useFeedbackHandler, useSearchQuery } from '+hooks';
import APIRequest from '+services/api-services';
import {
  AddMerchantModalStepType,
  AddMerchantRequestPayload,
  CurrencyType,
  IAddMerchantModal,
  IMerchantInfo,
  LimitStepType,
  PCIDSSLevelType,
  RiskLevelType
} from '+types';
import { capitalizeFirst, logError, stringToNumber } from '+utils';

import { formatAmountWithDelimiters, initializeValues, validatePerStep } from '../../helpers/addMerchantModalHelpers';

const api = new APIRequest();

export const useAddMerchantsToCardIssuance = ({
  category,
  defaultLimitsConfig,
  defaultPlansConfig,
  onClose
}: Omit<IAddMerchantModal, 'merchantClass'>) => {
  const { feedbackInit, closeFeedback } = useFeedbackHandler();
  const queryClient = useQueryClient();
  const searchQuery = useSearchQuery<{ currency: CurrencyType }>();
  const currency = searchQuery.value.currency ?? 'USD';
  const [step, setStep] = useState<AddMerchantModalStepType>('init');
  const [confirmed, setConfirmed] = useState(false);
  const [completedSteps, setCompletedSteps] = useState<Array<LimitStepType>>([]);
  const [configPreference, setConfigPreference] = useState({
    set_spending_limit: 'default',
    set_funding_limit: 'default',
    set_pci_dss_limit: 'default',
    set_count_limit: 'default',
    set_subscription_fees: 'default'
  } as const);

  const initialFormValues = initializeValues({ defaultLimitsConfig, defaultPlansConfig, category });

  const formik = useFormik({
    initialValues: initialFormValues,
    validate: values => {
      return validatePerStep({ values, stage: step, limitConfig: configPreference[step as keyof typeof configPreference] });
    },
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async formValues => {
      switch (step) {
        case 'init':
          setStep('set_spending_limit');
          break;
        case 'set_spending_limit':
          setStep('set_funding_limit');
          break;
        case 'set_funding_limit':
          if (category === 'reserved-cards') {
            setStep('set_count_limit');
          } else {
            setStep('set_pci_dss_limit');
          }
          break;
        case 'set_count_limit':
          setStep('confirm_submission');
          break;
        case 'set_pci_dss_limit':
          setStep('set_subscription_fees');
          break;
        case 'set_subscription_fees':
          setStep('confirm_submission');
          break;
        case 'confirm_submission':
          await handleAddMerchant(formValues);
          break;
        default:
          break;
      }
    }
  });

  const gotoPrev = () => {
    switch (step) {
      case 'confirm_submission':
        if (category === 'reserved-cards') {
          setStep(completedSteps[completedSteps.length - 1]);
        } else {
          setStep('set_subscription_fees');
        }
        break;
      case 'set_subscription_fees':
        setStep(completedSteps[completedSteps.length - 1]);
        break;
      case 'set_count_limit':
        setStep('set_funding_limit');
        break;
      case 'set_pci_dss_limit':
        setStep('set_funding_limit');
        break;
      case 'set_funding_limit':
        setStep('set_spending_limit');
        break;
      case 'set_spending_limit':
        setStep('init');
        break;
      case 'init':
      default:
        onClose();
        break;
    }
    formik.setErrors({});
  };

  const { mutateAsync: mutateAddMerchant } = useMutation((payload: AddMerchantRequestPayload) => api.addMerchantToCardIssuance(payload), {
    onError: error => {
      logError(error);
      feedbackInit({
        message: error?.response?.data?.message || 'An error occurred while adding a merchant. Please try again later.',
        type: 'danger',
        componentLevel: true
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['ISSUING_MERCHANTS']);
    },
    onSettled: () => {
      setTimeout(() => closeFeedback(), 5000);
    }
  });

  const handleAddMerchant = async (formValues: IMerchantInfo) => {
    try {
      await mutateAddMerchant({
        kora_id: formValues.merchant.kora_id,
        currency,
        card_type: 'virtual',
        risk_level: formValues.risk_level,
        pci_dss_level: formValues.pci_dss_level,
        subscription_plan: capitalizeFirst(formValues.subscription_plan),
        monthly_payment_value: formValues.monthly_payment_value,
        funding_limit: {
          data: {
            daily_max: stringToNumber(formValues.max_daily_funding),
            monthly_max: stringToNumber(formValues.max_monthly_funding),
            quarterly_max: stringToNumber(formValues.max_quarterly_funding)
          },
          type: configPreference.set_funding_limit
        },
        spending_limit: {
          data: {
            daily_max: stringToNumber(formValues.daily_trxn_cap),
            monthly_max: stringToNumber(formValues.monthly_trxn_cap),
            per_transaction_max: stringToNumber(formValues.max_limit_per_trxn)
          },
          type: configPreference.set_spending_limit
        },
        pcidss_level_limits: {
          type: configPreference.set_pci_dss_limit,
          data: {
            yearly_issued_cards: stringToNumber(formValues.yearly_issued_cards),
            yearly_transaction_count: stringToNumber(formValues.trxn_count)
          }
        },
        plan_config: {
          type: configPreference.set_subscription_fees === 'default' ? 'standard' : 'custom',
          monthly_card_limit: stringToNumber(formValues.monthly_card_limit),
          fee: {
            funding: formValues.funding_fee,
            issuance: formValues.issuance_fee,
            chargeback: formValues.chargeback_fee,
            withdrawal: formValues.withdrawal_fee,
            subscription: formValues.subscription_fee,
            cross_currency: formValues.cross_currency_fee
          }
        }
      });
    } catch (err) {
      throw new Error(err);
    }
  };

  const handleToggleConfigPreference = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfigPreference(prev => ({ ...prev, [step]: e.target.value }));
    e.target.value === 'default' && formik.resetForm({ values: { ...initialFormValues, merchant: formik.values.merchant } });
  };

  const handleAddCompletedStep = () => {
    const limitSteps = ['set_spending_limit', 'set_funding_limit', 'set_count_limit', 'set_pci_dss_limit'] satisfies LimitStepType[];

    if (!limitSteps.includes(step as LimitStepType)) return;

    const currentStepIndex = limitSteps.indexOf(step as LimitStepType);

    if (formik.isValid) {
      setCompletedSteps(prev => prev.filter((_, index) => index < currentStepIndex).concat(step as LimitStepType));
    } else {
      setCompletedSteps(prev => prev.filter((_, index) => index < currentStepIndex));
    }
  };

  const handleStepChange = (targetStep: AddMerchantModalStepType) => {
    const limitSteps: LimitStepType[] = ['set_spending_limit', 'set_funding_limit', 'set_count_limit', 'set_pci_dss_limit'];
    const currentStepIndex = limitSteps.indexOf(step as LimitStepType);
    const targetStepIndex = limitSteps.indexOf(targetStep as LimitStepType);

    const isAccessible =
      completedSteps.includes(targetStep as LimitStepType) || targetStepIndex === currentStepIndex - 1 || targetStep === step;

    if ((isAccessible && formik.isValid) || configPreference[step as LimitStepType] === 'default') {
      setStep(targetStep);
    }
  };

  const handleSkipSteps = () => {
    const isNotPlansConfigStep = step !== 'set_subscription_fees';

    if (isNotPlansConfigStep) {
      setStep(category === 'issued-cards' ? 'set_subscription_fees' : 'confirm_submission');
    } else {
      setStep('confirm_submission');
    }
  };

  const handleConfirm = (e: React.ChangeEvent<HTMLInputElement>) => setConfirmed(e.target.checked);

  useEffect(() => {
    handleAddCompletedStep();
  }, [step]);

  useEffect(() => {
    const pcidssLimit = defaultLimitsConfig.pcidss_level[formik.values.pci_dss_level as PCIDSSLevelType];

    if (formik.values.pci_dss_level) {
      formik.setFieldValue('trxn_count', String(pcidssLimit?.yearly_transaction_count));
      formik.setFieldValue('yearly_issued_cards', String(pcidssLimit?.yearly_issued_cards));
    }
  }, [formik.values.pci_dss_level]);

  useEffect(() => {
    const spendingLimit = defaultLimitsConfig.risk_level[formik.values.risk_level as RiskLevelType]?.spending_limit;
    const fundingLimit = defaultLimitsConfig.risk_level[formik.values.risk_level as RiskLevelType]?.funding_limit;

    if (formik.values.risk_level) {
      formik.setFieldValue('max_limit_per_trxn', formatAmountWithDelimiters(spendingLimit.per_transaction_max));
      formik.setFieldValue('daily_trxn_cap', formatAmountWithDelimiters(spendingLimit.daily_max));
      formik.setFieldValue('monthly_trxn_cap', formatAmountWithDelimiters(spendingLimit.monthly_max));
      formik.setFieldValue('max_daily_funding', formatAmountWithDelimiters(fundingLimit.daily_max));
      formik.setFieldValue('max_monthly_funding', formatAmountWithDelimiters(fundingLimit.monthly_max));
      formik.setFieldValue('max_quarterly_funding', formatAmountWithDelimiters(fundingLimit.quarterly_max));
    }
  }, [formik.values.risk_level]);

  useEffect(() => {
    const plan = defaultPlansConfig.find(planConfig => planConfig.name === formik.values.subscription_plan);
    const planFees = plan?.fee;

    if (formik.values.subscription_plan && planFees) {
      formik.setFieldValue('subscription_fee.amount', String(planFees?.subscription.amount));
      formik.setFieldValue('issuance_fee.amount', String(planFees?.issuance.amount));
      formik.setFieldValue('cross_currency_fee.amount', String(planFees?.cross_currency.amount));
      formik.setFieldValue('chargeback_fee.amount', String(planFees?.chargeback.amount));
      formik.setFieldValue('funding_fee.amount', String(planFees.funding.amount));
      formik.setFieldValue('monthly_card_limit', String(plan.cardLimit));
    }
  }, [formik.values.subscription_plan]);

  return {
    confirmed,
    configPreference,
    formik,
    gotoPrev,
    handleToggleConfigPreference,
    handleStepChange,
    handleSkipSteps,
    handleConfirm,
    step,
    completedSteps,
    currency
  };
};

import React from 'react';
import classNames from 'classnames';

import { useSearchQuery } from '+hooks';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '+shared/Tabs';

interface ISidebarLayout {
  tabs: Array<{ label: string; value: string; disabled?: boolean }>;
  pages: Record<string, React.ReactNode>;
}

const SidebarLayout = ({ tabs, pages }: ISidebarLayout) => {
  const searchQuery = useSearchQuery<{ tab: string }>();
  const activeTab = searchQuery.value.tab ?? tabs[0].value;
  const handleTabChange = (arg: string) => searchQuery.setQuery({ tab: arg });

  return (
    <div>
      <Tabs className="sidebar-comp" defaultValue={activeTab} onChange={handleTabChange} key={activeTab}>
        <div className="sidebar">
          <p id="tabs-heading">Products</p>

          <TabList aria-labelledby="tabs-heading" className="tabs-list">
            {tabs.map(tabItem => (
              <Tab
                key={tabItem.value}
                value={tabItem.value}
                className={classNames('tab', { active: tabItem.value === activeTab }, { disabled: tabItem.disabled })}
                disabled={tabItem.disabled}
              >
                {tabItem.label}
              </Tab>
            ))}
          </TabList>
        </div>

        <TabPanels className="not-sidebar mt-0">
          {tabs.map(tabItem => (
            <TabPanel key={tabItem.value} value={tabItem.value}>
              {pages[tabItem.value]}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </div>
  );
};
export default SidebarLayout;

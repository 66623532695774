import React from 'react';

import arrow from '+assets/img/dashboard/accordion-arrow.svg';

import './index.scss';

interface IAccordionProps {
  className?: string;
  showToggle?: boolean;
  title: React.ReactNode | string;
  content: React.ReactNode | string;
  open?: boolean;
  onClick?: React.MouseEventHandler<HTMLDetailsElement> | undefined;
}

const Accordion = ({ className, title, content, showToggle, open = false, onClick }: IAccordionProps) => {
  return (
    <details className={`accordion__comp ${className}`} open={open} onClick={onClick}>
      <summary>
        <section>{title}</section>
        {showToggle && <img className="toggler-arrow" src={arrow} alt="" />}
      </summary>
      <section className="accordion-content">{content}</section>
    </details>
  );
};

export default Accordion;

import React from "react";
import { identityCountries } from "+utils";
import { useEffect } from "react";
import CountrySwitch from "./CountrySwitch";

const CountrySwitchList = ({
  disabled,
  selected,
  setSelected
}: {
  disabled: boolean;
  selected: Array<string>;
  setSelected: (e: Array<string>) => void;
}) => {
  const checkIfSelected = (country: string) => selected.includes(country);
  const handleOnSelect = (country: string) => {
    if (selected.includes(country)) {
      setSelected(selected.filter(c => c !== country));
    } else {
      setSelected([...selected, country]);
    }
  };
  useEffect(() => {
    if (disabled) {
      setSelected([]);
    }
  }, [disabled]);
  return (
    <div>
      {identityCountries?.map(country => (
        <CountrySwitch
          key={country.value}
          country={country}
          value={checkIfSelected(country.value)}
          onChange={handleOnSelect}
          disabled={disabled}
        />
      ))}
    </div>
  );
};

export default CountrySwitchList;
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import Calendar from 'react-calendar';
import { useWindowSize } from 'react-use';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { useFeedbackHandler, useReducerState } from '+hooks';
import { IAllDateTimeProps, IDateRange, TableTypes } from '+types';
import { logError } from '+utils';

import CustomTimeRange from './CustomTimeRange';
import Modal from './Modal';

import 'react-calendar/dist/Calendar.css';
import './SecondaryFilter.scss';

dayjs.extend(customParseFormat);

interface ICustomDateRangeProps {
  visible: boolean;
  close: () => void;
  selectedDate: IDateRange;
  setSelectedDateRange: (date: IDateRange & { type: string; allData: IAllDateTimeProps }) => void;
  showTimeRange?: boolean;
  showMaxEndDate?: boolean;
  tableType?: TableTypes;
  isCleared?: boolean;
}

const formatDate = (date: string) => {
  if (!date) return dayjs().format('ddd, D MMM YYYY');
  return dayjs(dayjs(date)).format('ddd, D MMM YYYY');
};
// eslint-disable-next-line consistent-return
const formatDateTime = (date: string, time: string, meridiemStart: string | null, meridiemEnd: string | null) => {
  try {
    const meridiemStartLowerCase = meridiemStart?.toLowerCase();
    const meridiemEndLowerCase = meridiemEnd?.toLowerCase();
    if (!date && meridiemStart) {
      const todayDate = dayjs().format('ddd, D MMM YYYY');

      return `${dayjs(`${todayDate},${time}`).format('ddd, D MMM YYYY , hh:mm ')}${meridiemStartLowerCase}`;
    }
    if (!date && meridiemEnd) {
      const todayDate = dayjs().format('ddd, D MMM YYYY');

      return `${dayjs(`${todayDate},${time}`).format('ddd, D MMM YYYY , hh:mm ')}${meridiemEndLowerCase}`;
    }
    if (meridiemStart && meridiemEnd === null) {
      return `${dayjs(dayjs(`${date},${time}`)).format('ddd, D MMM YYYY , hh:mm ')}${meridiemStartLowerCase}`;
    }
    if (meridiemEnd && meridiemStart === null) {
      return `${dayjs(dayjs(`${date},${time}`)).format('ddd, D MMM YYYY , hh:mm ')}${meridiemEndLowerCase}`;
    }
  } catch (error) {
    logError(error);
  }
};

const componentToDisplayTimeBaseOnSameDayArr = ['pay-in'];

const CustomDateRangeCalendar = ({
  visible,
  close,
  selectedDate,
  setSelectedDateRange,
  showTimeRange = false,
  showMaxEndDate = true,
  tableType = '' as TableTypes,
  isCleared = false
}: ICustomDateRangeProps) => {
  const { closeFeedback, feedbackInit } = useFeedbackHandler();

  const { width } = useWindowSize();
  const [state, setState] = useReducerState({
    startDate: selectedDate.startDate,
    endDate: selectedDate.endDate,
    startTime: selectedDate?.startTime || '12:00',
    endTime: selectedDate?.endTime || '12:00',
    meridiemStart: 'am',
    meridiemEnd: 'am',
    isShowTimeRange: showTimeRange,
    isShowTimeInputOnSameDay: componentToDisplayTimeBaseOnSameDayArr.includes(tableType),
    isEndTimeBeforeStartTimeVal: false,
    clearInput: false,
    disableButton: false
  });

  const isValidTimeRange = (startTime: string, endTime: string): boolean => {
    const parseTime = (time: string): number => {
      const trimmedTime = time.trim();
      const [timePart, period] = trimmedTime.split(' ');
      const [h, m] = timePart.split(':').map(Number);
      let hours = h;
      const minutes = m;

      if (period.toUpperCase() === 'PM' && hours !== 12) {
        hours += 12;
      } else if (period.toUpperCase() === 'AM' && hours === 12) {
        hours = 0;
      }

      let totalMinutes = hours * 60 + minutes;

      if (time === '12:00 AM') {
        totalMinutes = 1440;
      }

      return totalMinutes;
    };

    const start = parseTime(startTime);
    const end = parseTime(endTime);

    return start <= end && state.startDate === state.endDate;
  };

  useEffect(() => {
    if (
      !isValidTimeRange(state.startTime + ' ' + state?.meridiemStart, state.endTime + ' ' + state?.meridiemEnd) &&
      state.startDate === state.endDate
    ) {
      setState({ ...state, disableButton: true });
      feedbackInit({
        message: 'Start time cannot be after end time. Please select a valid time range.',
        type: 'danger',
        componentLevel: true
      });
      return;
    } else {
      closeFeedback();
      setState({ ...state, disableButton: false });
    }
  }, [state.startTime, state.endTime, state.meridiemStart, state.meridiemEnd]);

  useEffect(() => {
    if (isCleared || state.clearInput) {
      setState({ startDate: '', endDate: '', startTime: '12:00', endTime: '12:00', meridiemStart: 'am', meridiemEnd: 'am' });
    }
  }, [isCleared, state.clearInput]);

  useEffect(() => {
    if (state.startDate === state.endDate && state.isShowTimeInputOnSameDay) {
      setState({ ...state, isShowTimeRange: true });
    }

    if (state.startDate !== state.endDate && state.isShowTimeInputOnSameDay) {
      setState({ ...state, isShowTimeRange: false });
    }
  }, [state.isShowTimeInputOnSameDay, state.startDate, state.endDate]);

  const content = {
    heading: state.isShowTimeRange ? 'Date & Time Range' : 'Custom Date Range',
    description: state.isShowTimeRange
      ? 'Select the start period and end period to get targeted report.'
      : 'Select the start and end date to limit your search query.',
    secondButtonText: state.isShowTimeRange ? 'Set Date & Time' : 'Set Date',
    secondButtonDisable: state.disableButton || !state.startDate || !state.endDate,
    content: (
      <form>
        <section className="secondary-datefilter--custom">
          <section>
            <label
              htmlFor="startDate"
              className="form-group filter-object filter-object-xxl flex-grow-1 w-auto --no-max-width --search-container"
            >
              <span>{state.isShowTimeRange ? 'From (Start Date & Time)' : 'From (Start Date)'}</span>
              <input
                title="startDate"
                aria-label="startDate"
                name="startDate"
                className="form-control"
                type="text"
                value={
                  state.isShowTimeRange
                    ? formatDateTime(state.startDate, state.startTime, state.meridiemStart, null)
                    : formatDate(state.startDate)
                }
              />
            </label>
            <div data-testid="start-calendar">
              <Calendar
                className="secondary-datefilter--custom-calendar"
                tileClassName="secondary-datefilter--custom-calendar-tile"
                next2Label={null}
                prev2Label={null}
                onChange={value => {
                  if (value) {
                    setState({ startDate: dayjs(new Date(String(value))).format('YYYY-MM-DD') });
                  }
                }}
                value={state.startDate}
                maxDate={new Date()}
              />
            </div>
          </section>
          <section>
            <label
              htmlFor="endDate"
              className="form-group filter-object filter-object-xxl flex-grow-1 w-auto --no-max-width --search-container"
            >
              <span>{state.isShowTimeRange ? 'To (End Date & Time)' : 'To (End Date)'}</span>
              <input
                title="endDate"
                aria-label="endDate"
                name="endDate"
                className="form-control"
                type="text"
                value={
                  state.isShowTimeRange ? formatDateTime(state.endDate, state.endTime, null, state.meridiemEnd) : formatDate(state.endDate)
                }
              />
            </label>
            <div data-testid="end-calendar">
              <Calendar
                className="secondary-datefilter--custom-calendar"
                tileClassName="secondary-datefilter--custom-calendar-tile"
                next2Label={null}
                prev2Label={null}
                onChange={value => {
                  if (value) {
                    setState({ endDate: dayjs(new Date(String(value))).format('YYYY-MM-DD') });
                  }
                }}
                value={state.endDate}
                minDate={state.startDate ? new Date(state.startDate) : undefined}
                maxDate={showMaxEndDate ? new Date() : undefined}
              />
            </div>
          </section>
        </section>
        {state.isShowTimeRange ? (
          <section className="timer-section">
            <CustomTimeRange
              setSelectedTimeRange={timeData => {
                setState({
                  startTime: timeData.startTime,
                  endTime: timeData.endTime,
                  meridiemStart: timeData.meridiemStart,
                  meridiemEnd: timeData.meridiemEnd
                });
              }}
            />
          </section>
        ) : null}
      </form>
    ),
    secondButtonAction: async () => {
      if (
        !isValidTimeRange(state.startTime + ' ' + state?.meridiemStart, state.endTime + ' ' + state?.meridiemEnd) &&
        state.startDate === state.endDate
      ) {
        feedbackInit({
          message: 'Start time cannot be after end time. Please select a valid time range.',
          type: 'danger',
          componentLevel: true
        });
        return;
      }
      setSelectedDateRange({
        startDate: state.startDate,
        endDate: state.endDate,
        type: `${formatDate(state.startDate)} - ${formatDate(state.endDate)}`,
        allData: state
      });
      close();
    },
    showCompletedModal: false
  };
  return <Modal visible={visible} close={close} {...content} size={width >= 1200 ? 'lg' : 'sm'} />;
};

export { CustomDateRangeCalendar, formatDate };

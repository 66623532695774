import React, { Dispatch, SetStateAction } from 'react';

import Copyable from '+shared/Copyable';
import { ActionButtonType, chargebackActivityUpdateT, chargebackHistoryDataT, IChargeBackDetails } from '+types';
import {
  capitalize,
  capitalizeRemovedash,
  chargebackActivityStatus,
  formatAmount,
  formatPAN,
  getDate,
  getTime,
  history,
  payinMethodMapping,
  switchBank,
  switchCard,
  truncateString
} from '+utils';

import cardIcon from '+assets/img/dashboard/card-icon.svg';
import walletIcon from '+assets/img/dashboard/wallet.svg';

export const getBankImageByName = (name: string): string => {
  return switchBank[name as keyof typeof switchBank] || switchBank.default;
};
export const generateHeaderSummary = (data: IChargeBackDetails) => {
  return [
    {
      label: 'Dispute Amount',
      value: data?.amount ? `${formatAmount(data?.amount)} ${data?.currency}` : 'Not Available'
    },
    {
      label: 'Accepted Amount',
      value: data?.approved_amount ? `${formatAmount(data?.approved_amount)} ${data?.currency}` : 'Not Available'
    },
    {
      label: 'Rejected Amount',
      value: `${formatAmount(data?.status === 'pending' ? 0 : +data?.amount - +data?.approved_amount)} ${data?.currency}`
    },
    {
      label: 'Date Contested',
      value: `${getDate(data?.created_at)}, ${getTime(data?.created_at)}`
    },
    {
      label: 'Merchant',
      value: `${data?.merchant}`
    },
    {
      label: 'Transaction ID',
      value: (
        <>
          <Copyable text={data?.payment_source_reference?.toUpperCase()} textModifier={text => truncateString(text, 18)} /> <br />
          <button type="button" onClick={() => history.push(`/dashboard/pay-ins/${data?.payment_source_reference}`)}>
            <span>View Initial Transaction</span>
            <i className="os-icon os-icon-arrow-up-right" />
          </button>
        </>
      )
    }
  ];
};
export function getActionButtons({
  setChargebackModalState
}: {
  setChargebackModalState: Dispatch<SetStateAction<{ open: boolean; type: string }>>;
}): Array<ActionButtonType> {
  return [
    {
      children: (
        <>
          <span>View History</span>
        </>
      ),
      variant: 'primary',
      onClick: () => setChargebackModalState({ type: 'feedback', open: true })
    }
  ];
}

export const generateChargeBackDetailsSummary = (data: IChargeBackDetails) => {
  return [
    {
      label: 'Currency Charged',
      value: data?.currency ? `${data?.currency}` : 'Not Available'
    },
    {
      label: 'Channel',
      value: payinMethodMapping[data?.source?.type as keyof typeof payinMethodMapping] || 'Not Available'
    },
    {
      label: 'Date Contested',
      value: `${getDate(data?.created_at)}, ${getTime(data?.created_at)}`
    },
    {
      label: 'Processor',
      value: `${capitalizeRemovedash(data?.processor) || 'Not available'}`
    },
    {
      label: 'Reason For Chargeback',
      value: capitalize(data?.reason) || 'Not Available'
    },
    {
      label: 'Deadline',
      value: `${getDate(data?.deadline)}, ${getTime(data?.deadline)}`
    }
  ];
};
export const generatePayerDetailsSummary = (data: IChargeBackDetails) => {
  return [
    {
      label: 'Payment Method',
      value:
        data?.payment_source?.payment_source_type === 'payment_reversal'
          ? 'Payment Reversal'
          : payinMethodMapping[data?.source?.type as keyof typeof payinMethodMapping] || 'Not Available'
    },
    data && data?.source?.type && ['bank_transfer', 'pay_with_bank'].includes(data?.source?.type || '')
      ? {
          label: 'Bank',
          value: (
            <>
              <img
                id="bank-icon"
                src={getBankImageByName(data?.source?.details?.bank_slug)}
                alt="bank icon"
                style={{ marginLeft: data?.source?.details?.bank_name ? 0 : '' }}
              />
              <span style={{ display: 'inline', marginLeft: 8, color: '#414f5f' }}>
                {capitalize(data?.source?.details?.bank_name || 'No Bank Information Available')}
              </span>
            </>
          )
        }
      : [],
    ...(data?.source?.type === 'wallet'
      ? [
          {
            label: 'Wallet',
            value: (
              <>
                <span>
                  <img id="wallet-icon" src={walletIcon} alt="wallet icon" />
                  <span style={{ display: 'inline', marginLeft: '0.5rem' }}>Korapay wallet</span>
                </span>
                <span>{data?.payment?.customer?.email || 'Not provided'}</span>
              </>
            )
          }
        ]
      : []),
    ...(data?.source?.type === 'card'
      ? [
          {
            label: (
              <>
                <span>
                  <img
                    src={switchCard[data?.source?.details?.card_type as keyof typeof switchCard]}
                    className="card-logo"
                    alt="card-logo"
                    style={{ width: '1.5rem', marginRight: '8px' }}
                  />
                  <span style={{ display: 'inline' }}>{capitalize(data?.source?.details?.card_type)}</span>
                </span>
              </>
            ),
            value: data?.source?.details?.masked_pan ? (
              <>
                <span>{formatPAN(data?.source.details.masked_pan)}</span>
              </>
            ) : (
              <span>
                <img src={cardIcon} className="card-logo" alt="card-icon" />
                <span style={{ display: 'inline' }}>No card information available</span>
              </span>
            )
          }
        ]
      : []),
    ...(['bank_transfer', 'pay_with_bank', 'wallet', 'card'].includes(data?.source?.type)
      ? [
          {
            label: 'Account Number',
            value: data?.source?.details?.account_number || 'Not Available'
          }
        ]
      : []),
    {
      label: 'Customer Name',
      value: (
        <>
          {data?.source?.type === 'bank_transfer' && <span>{capitalize(data?.source?.details?.account_name || 'Not Available')}</span>}
          {(['card', 'wallet', 'pay_with_bank', 'mobile_money', 'payment_reversal'].includes(data?.source?.type) ||
            (data?.payment_source && data?.payment_source.payment_source_type === 'payment_reversal')) && (
            <span>{capitalize(data?.payment?.customer?.name || 'Not Available')}</span>
          )}
        </>
      )
    },
    {
      label: 'Email',
      value: data?.merchant_email ?? 'Not provided'
    }
  ];
};

export function mapToChargebackActivityUpdate({
  chargebackDetails,
  resolveAction
}: {
  chargebackDetails: IChargeBackDetails;
  resolveAction: () => void;
}): chargebackActivityUpdateT {
  return {
    resolveAction,
    data: {
      merchant: chargebackDetails?.merchant,
      account: {
        name: chargebackDetails?.account?.name
      },
      status: chargebackDetails?.status as keyof typeof chargebackActivityStatus,
      status_history: chargebackDetails?.status_history as chargebackHistoryDataT[]
    }
  };
}

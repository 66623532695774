import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import SectionInfo from '+containers/Dashboard/ProductConfig/components/SectionInfo';
import { useFeedbackHandler, useSetUserAccess } from '+hooks';
import APIRequest from '+services/api-services';
import LoadingPlaceholder from '+shared/LoadingPlaceHolder';
import { CategoryType, CurrencyType, IssuingMerchantDetailsResponseType } from '+types';
import { history, isAllowed } from '+utils';

import MerchantId from '../../components/MerchantId';
import SidebarLayout from '../../components/SidebarLayout';
import LimitConfigPage from './components/LimitConfigPage';
import Summaries from './components/Summaries';
import { formatSummary } from './helpers/holderDetailsHelpers';
import MerchantAccessModal from './MerchantAccessModal';

const hasCurrencyAccess = true;

const features = [
  { label: 'Issued Cards (For Customers)', value: 'issued_cards' },
  { label: 'Reserved Virtual Cards', value: 'reserved_cards' },
  { label: 'Physical Cards', value: 'physical_cards', disabled: true }
];

const api = new APIRequest();

const HolderDetails = () => {
  const { feature, merchantId, currency } = useParams<{ feature: CategoryType; merchantId: string; currency: CurrencyType }>();
  const [modalIsVisible, setModalisVisible] = useState(false);
  const { feedbackInit } = useFeedbackHandler();
  const userAccess = useSetUserAccess();

  const handleModalVisibility = (arg: boolean) => setModalisVisible(arg);

  const { data: merchantDetails } = useQuery<IssuingMerchantDetailsResponseType>(
    ['MERCHANT_DETAILS', merchantId],
    () => api.fetchIssuingMerchantDetails(merchantId),
    {
      onError: () => {
        feedbackInit({
          message: 'There has been an error fetching the merchant details',
          type: 'danger'
        });
      }
    }
  );

  const hasCardIssuanceAccess = merchantDetails?.status === 'active';
  const hasCustomerCardAccess = merchantDetails?.config?.customer.status === 'active';
  const hasReservedCardAccess = merchantDetails?.config?.reserved.status === 'active';
  const cardCreationIsEnabled = !!isAllowed(userAccess, ['card_issuance_merchant_card_creation.update']);

  return (
    <>
      <div className="content-box">
        <div className="row">
          <button type="button" className="btn btn-link mb-2" onClick={() => history.goBack()}>
            <i className="os-icon os-icon-arrow-left7" />
            <span>Back to {feature === 'issued-cards' ? 'Virtual Cards' : 'Reserved Cards'} Merchants</span>
          </button>
        </div>

        {!merchantDetails ? (
          new Array(6).fill(0).map((_, index) => <LoadingPlaceholder key={index} type="text" content={4} background="#f556f6" />)
        ) : (
          <>
            <div className="top-row__container">
              <MerchantId
                name={merchantDetails?.name}
                id={merchantDetails?.kora_id}
                plan={merchantDetails?.plan.name}
                hasAccess={hasCardIssuanceAccess}
                currency="USD"
              />

              {!hasCurrencyAccess && (
                <button type="button" className="enable-access-btn" onClick={e => e}>
                  Enable USD Access
                </button>
              )}
            </div>

            <div className="mt-3">
              <Summaries data={formatSummary(merchantDetails)} />
            </div>

            <div className="d-flex justify-between align-items-center border-top pt-4 mt-4">
              <SectionInfo
                title="Card Issuance"
                description="These are the products that allow this merchant to issue payment cards to customers and manage issued cards. You can configure each product by modifying their access, limits and channels."
                status={hasCardIssuanceAccess}
              />

              <div>
                <button
                  onClick={() => handleModalVisibility(true)}
                  className="btn btn-danger font-weight-bold"
                  style={{ fontSize: '1.05rem' }}
                  type="button"
                  disabled={!hasCardIssuanceAccess}
                >
                  Disable Access to Card Issuance
                </button>
              </div>
            </div>

            <div className="border-top pt-4 mt-4">
              <SidebarLayout
                tabs={features}
                pages={{
                  issued_cards: (
                    <LimitConfigPage
                      key={String(merchantDetails?.config?.customer.card_creation_enabled)}
                      config={merchantDetails?.config?.customer}
                      merchantInfo={{
                        name: merchantDetails.name,
                        koraId: merchantDetails.kora_id,
                        requestRef: merchantDetails.access_request_reference?.customer,
                        merchantRef: merchantDetails.reference
                      }}
                      category="issued-cards"
                      pciDssLevel={merchantDetails.pci_dss_level}
                      riskLevel={merchantDetails.risk_level}
                      provider={merchantDetails.provider?.customer}
                      cardCreationToggleDisabled={!(cardCreationIsEnabled && hasCardIssuanceAccess && hasCustomerCardAccess)}
                      cardAccessEnabled={hasCustomerCardAccess}
                    />
                  ),
                  reserved_cards: (
                    <LimitConfigPage
                      key={String(merchantDetails?.config?.reserved.card_creation_enabled)}
                      merchantInfo={{
                        name: merchantDetails.name,
                        koraId: merchantDetails.kora_id,
                        requestRef: merchantDetails.access_request_reference?.reserved,
                        merchantRef: merchantDetails.reference
                      }}
                      config={merchantDetails.config?.reserved}
                      category="reserved-cards"
                      pciDssLevel={merchantDetails.pci_dss_level}
                      riskLevel={merchantDetails.risk_level}
                      provider={merchantDetails.provider?.reserved}
                      cardCreationToggleDisabled={!(cardCreationIsEnabled && hasCardIssuanceAccess && hasReservedCardAccess)}
                      cardAccessEnabled={hasReservedCardAccess}
                    />
                  ),
                  physicial_cards: null
                }}
              />
            </div>
          </>
        )}
      </div>
      {modalIsVisible ? (
        <MerchantAccessModal
          issuingMerchantDetails={merchantDetails}
          modalType="disableAccess"
          onClose={() => handleModalVisibility(false)}
          currency={currency}
          feature={feature}
        />
      ) : null}
    </>
  );
};

export default HolderDetails;

import React from 'react';
import { useQuery } from 'react-query';

import { useFeedbackHandler, useSearchQuery } from '+hooks';
import APIRequest from '+services/api-services';
import { FileFormatType } from '+types';
import { APIDownload, filteredOutObjectProperty, getDate, logError, queriesParams } from '+utils';

import HeroSection from '../components/HeroSection';
import RequestsTable from '../components/RequestTable';

const api = new APIRequest(process.env.REACT_APP_MIDDLEWARE_API_BASE);

export default function Requests() {
  const searchQuery = useSearchQuery();
  const { feedbackInit } = useFeedbackHandler();
  const page = searchQuery.value.page || 1;
  const limit = searchQuery.value.limit || 10;
  const valuesToBeRemoved = [
    queriesParams.tab,
    queriesParams.page,
    queriesParams.limit,
    queriesParams.dateCreatedFrom,
    queriesParams.dateCreatedTo,
    queriesParams.dateFrom,
    queriesParams.dateTo,
    queriesParams.status
  ];
  const dateFrom = searchQuery.value.dateFrom;
  const dateTo = searchQuery.value.dateTo;
  const status = searchQuery.value.status;
  const dateCreatedFrom = searchQuery.value.dateCreatedFrom;
  const dateCreatedTo = searchQuery.value.dateCreatedTo;

  const sortingParams = {
    status: typeof status === 'string' ? [status] : status,
    ...(dateFrom ? { startDate: dateFrom } : {}),
    ...(dateTo ? { endDate: dateTo } : {}),
    ...(dateCreatedFrom ? { startDate: dateCreatedFrom } : {}),
    ...(dateCreatedTo ? { endDate: dateCreatedTo } : {}),
    ...filteredOutObjectProperty(searchQuery.value, valuesToBeRemoved)
  };

  const {
    data: resolvedData,
    isFetching,
    refetch
  } = useQuery(['IDENTITY_REQUESTS', searchQuery.value], () => api.getIdentityAccessRequests({ page, limit, sortingParams }), {
    keepPreviousData: true,
    onError: () => {
      let message = 'There has been an error getting requests.';
      if (searchQuery.value?.sorterType)
        message = `There has been an error so we could not find any results for ${searchQuery.value?.sorterType}. `;
      feedbackInit({
        message,
        type: 'danger',
        action: {
          action: () => refetch(),
          name: 'Try again'
        },
        componentLevel: false
      });
    }
  });

  const exportRequests = async (format: FileFormatType, close: () => void, fieldsToExport: Array<string>) => {
    try {
      const res = await api.getIdentityAccessRequests({ sortingParams, format, fieldsToExport });

      const type = format === 'csv' ? 'csv' : 'excel';
      APIDownload(res, `Requests at ${getDate(Date.now())}`, type);
      feedbackInit({
        title: 'Export Successful',
        message: <> - Successfully exported</>,
        type: 'success'
      });
      close();
    } catch (error) {
      logError(error);
      feedbackInit({
        title: 'Export Failed',
        message: `There has been an error downloading your request list file`,
        type: 'danger',
        componentLevel: true
      });
    }
  };
  return (
    <div>
      <HeroSection
        title="Access requests to Identity"
        description="View merchants who have recently requested access. Review pending requests and associated documents."
      />
      <RequestsTable resolvedData={resolvedData} isFetching={isFetching} refetch={refetch} exportData={exportRequests} />
    </div>
  );
}

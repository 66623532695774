import React, { Dispatch, memo, SetStateAction, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useFormik } from 'formik';

import RiskIndicator from '+containers/Dashboard/ProductConfig/components/RiskIndicator';
import Accordion from '+containers/Dashboard/Shared/Accordion';
import Icon from '+containers/Dashboard/Shared/Icons';
import Modal, { IModalProps } from '+containers/Dashboard/Shared/Modal';
import { useFeedbackHandler } from '+hooks';
import APIRequest from '+services/api-services';
import useProductConfigCardIssuanceStore from '+store/productConfigCardIssuanceStore';
import {
  AllAccessRequestMerchantResponse,
  CategoryType,
  CurrencyType,
  IssuingMerchantDetailsResponseType,
  ModifyAccessPayloadType
} from '+types';
import { advanceCleanInput, capitalizeFirst, capitalizeRemovedash, formatWithCommas, logError } from '+utils';

import { fundingLimitMap, pcidsssMap, spendingLimitMap } from './helpers/holderDetailsHelpers';

type RenderFormType = {
  setMerchantName: Dispatch<SetStateAction<string>>;
  setReason?: Dispatch<SetStateAction<string>>;
  handleCheckboxChange: React.ChangeEventHandler<HTMLInputElement>;
};

interface IMerchantAccessModal {
  modalType: string;
  onClose: () => void;
  issuingMerchantDetails?: IssuingMerchantDetailsResponseType;
  requestingMerchantDetails?: AllAccessRequestMerchantResponse;
  monthlyPaymentValue?: string;
  currency: CurrencyType;
  feature: CategoryType;
}

const options = [
  { label: '--Select a reason--', value: '' },
  { label: 'Fraudulent activities', value: 'fraudulent activities' },
  { label: 'Other', value: 'other' }
];

const apiRequest = new APIRequest();

const MerchantAccessModal = ({
  modalType,
  onClose,
  issuingMerchantDetails,
  requestingMerchantDetails,
  monthlyPaymentValue,
  currency,
  feature
}: IMerchantAccessModal) => {
  const queryClient = useQueryClient();
  const [modal, setModal] = useState<{ modalType: string | null; modalInfo: Partial<IModalProps> }>({ modalType: '', modalInfo: {} });
  const defaultConfig = useProductConfigCardIssuanceStore(store => store.defaultConfig);
  const [isChecked, setChecked] = useState(false);
  const [merchantName, setMerchantName] = useState('');
  const [buttonState, setButtonState] = useState(false);
  const [reason, setReason] = useState('');
  const cardCategory = feature === 'issued-cards' ? 'issued' : 'reserved';
  const { feedbackInit, closeFeedback } = useFeedbackHandler();

  const { mutateAsync: mutateModifyAccess } = useMutation(
    (payload: ModifyAccessPayloadType) => apiRequest.modifyAccess({ reason, ...payload }),
    {
      onSuccess: data => {
        feedbackInit({ message: data?.message, type: 'success' });
        queryClient.invalidateQueries([`${currency}_ALL_PRODUCT_CONFIG_SETTING`]);
        queryClient.invalidateQueries(['REQUESTING_MERCHANT_DETAILS']);
        queryClient.invalidateQueries(['ISSUING_MERCHANT_PLANS']);
        queryClient.invalidateQueries(['MERCHANT_DETAILS']);
        queryClient.invalidateQueries(['ISSUING_MERCHANTS']);
        queryClient.invalidateQueries(['REQUESTING_MERCHANTS']);
      },
      onError: error => {
        logError(error);
        feedbackInit({
          message: error.response?.data?.message || 'Action was unsuccessful',
          type: 'danger',
          componentLevel: true
        });
      },
      onSettled: () => {
        setTimeout(() => {
          closeFeedback();
        }, 5000);
      }
    }
  );

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
  };

  useEffect(() => {
    setModalVisible(modalType);
  }, [modalType]);

  useEffect(() => {
    if ((isChecked && merchantName) || (reason && reason !== 'other') || modalType === 'enableAccessToIssuedCards') {
      setButtonState(true);
    }
  }, [isChecked, merchantName, reason]);

  function renderEnableForm({ setMerchantName, handleCheckboxChange }: RenderFormType) {
    const form = useFormik({
      initialValues: {
        merchantName: ''
      },
      onSubmit: () => undefined
    });
    return (
      <div className="form-group modal-content">
        <label htmlFor="merchant-name"> Merchant name </label>
        <input
          type="text"
          id="merchant-name"
          className="form-control"
          onChange={e => {
            const inputValue = advanceCleanInput(e.target.value);
            form.setFieldValue('merchantName', inputValue);
            setMerchantName(inputValue);
          }}
          value={form.values.merchantName}
        />
        <div className="info">
          <Icon name="info" fill="#2376F3" />
          <span>
            Important: This merchant will now be able to receive or send out USD payments and will have the default USD configuration for
            all merchants{' '}
          </span>
        </div>
        <div className="checkbox-container">
          <input type="checkbox" id="approve" onChange={handleCheckboxChange} />
          <label htmlFor="approve">Yes, I understand the implications of this action</label>
        </div>
      </div>
    );
  }

  function renderDisableForm({ setReason }: { setReason: Dispatch<SetStateAction<string>> }) {
    const form = useFormik({
      initialValues: {
        reason: ''
      },
      onSubmit: () => undefined
    });
    return (
      <div className="modal-content">
        <label htmlFor="reason"> Reason </label>
        <select
          name="reason"
          data-testid="select-reason"
          className="form-control"
          value={form.values.reason}
          onChange={e => {
            const { value } = e.target;
            form.setFieldValue('reason', value);
            setReason(value);
          }}
        >
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        {form.values.reason === 'other' && (
          <div className="form-group">
            <label htmlFor="reason"> Tell us why you want to disable access for this merchant </label>
            <input
              type="text"
              id="reason"
              className="form-control"
              onChange={e => {
                const inputValue = advanceCleanInput(e.target.value);
                setReason(inputValue);
              }}
            />
          </div>
        )}
      </div>
    );
  }

  function renderConfirmDisableForm({ handleCheckboxChange }: { handleCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void }) {
    return (
      <div className="form-group modal-content">
        <div className="info">
          <Icon name="infoSolid" fill="#F32345" />
          <span style={{ color: '#F32345' }}>
            Important: This means that this merchant won’t be able to issue any new cards and their {cardCategory} virtual cards will be
            suspended.
          </span>
        </div>
        <div className="checkbox-container">
          <input type="checkbox" id="approve" onChange={handleCheckboxChange} />
          <label htmlFor="approve">Yes, I understand the implications of this action</label>
        </div>
      </div>
    );
  }

  const riskLevelKeys = Object.keys(defaultConfig.customer.risk_level);
  const matchedRiskLevel: string | undefined = riskLevelKeys.includes(requestingMerchantDetails?.risk_level || '')
    ? requestingMerchantDetails?.risk_level
    : undefined;

  const merchantRiskLevel =
    matchedRiskLevel && defaultConfig?.customer?.risk_level[matchedRiskLevel as keyof typeof defaultConfig.customer.risk_level];

  const matchedPcidssLevel =
    defaultConfig && Object.keys(defaultConfig?.customer?.pcidss_level).find(e => e === requestingMerchantDetails?.pci_dss_level);
  const merchantPcidsLevel =
    matchedPcidssLevel && defaultConfig?.customer?.pcidss_level[matchedPcidssLevel as keyof typeof defaultConfig.customer.pcidss_level];

  const Merchant_request_detail = {
    'Merchant name': requestingMerchantDetails?.name,
    'Risk level': capitalizeFirst(requestingMerchantDetails?.risk_level?.split('_')[0]) || 'N/A',
    'Preferred plan': requestingMerchantDetails?.subscription_plan || 'N/A',
    'Monthly payment value': monthlyPaymentValue || 'N/A'
  };

  function renderEnableAccessToIssuedCards() {
    return (
      <div>
        <Accordion
          open={true}
          title={'Merchant Request Detail'}
          className="requesters__accordion"
          content={
            <div className="processor_message_container mt-2">
              {Merchant_request_detail &&
                Object.keys(Merchant_request_detail).map((e, index) => {
                  return (
                    <div className="ivr-modal__content__row" key={index}>
                      <div className="ivr-modal__content__row__label">
                        <div>{capitalizeFirst(e)}</div>
                      </div>
                      <div className="ivr-modal__content__row__value">
                        <div>{Merchant_request_detail[e as keyof typeof Merchant_request_detail]}</div>
                      </div>
                    </div>
                  );
                })}
            </div>
          }
        />

        <section className="mt-4">
          <h5>Limits based on risk level</h5>
          <p className="d-flex pt-2">
            <span className="mr-2" style={{ color: '#94a7b7' }}>
              Risk Level:
            </span>{' '}
            {requestingMerchantDetails?.risk_level ? <RiskIndicator riskLevel={requestingMerchantDetails?.risk_level} /> : 'N/A'}
          </p>
        </section>

        {Object.keys(merchantRiskLevel || {}).map(key => {
          const keyValue = merchantRiskLevel?.[key as keyof typeof merchantRiskLevel];
          if (!keyValue) {
            return null;
          }
          return (
            <Accordion
              open={true}
              title={capitalizeRemovedash(key)}
              className="requesters__accordion__limits"
              key={key}
              content={
                <div className="processor_message_container mt-2">
                  {Object.keys(keyValue).map((e, index) => {
                    return (
                      <div className="ivr-modal__content__row" key={index}>
                        <div className="ivr-modal__content__row__label">
                          <div>
                            {key === 'spending_limit'
                              ? spendingLimitMap[e as keyof typeof spendingLimitMap]
                              : fundingLimitMap[e as keyof typeof fundingLimitMap]}
                          </div>
                        </div>
                        <div className="ivr-modal__content__row__value">
                          <div>{formatWithCommas(Number(keyValue[e]).toFixed(2))}</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              }
            />
          );
        })}

        {merchantPcidsLevel && (
          <Accordion
            open={true}
            title={'Limits Based on PCI DSS Level'}
            className="requesters__accordion mt-4"
            content={
              <div className="processor_message_container mt-2">
                {Object.keys(merchantPcidsLevel || {}).map((e, index) => {
                  return (
                    <div className="ivr-modal__content__row" key={index}>
                      <div className="ivr-modal__content__row__label">
                        <div>{pcidsssMap[e as keyof typeof pcidsssMap]}</div>
                      </div>
                      <div className="ivr-modal__content__row__value">
                        <div>{formatWithCommas(merchantPcidsLevel?.[e as keyof typeof merchantPcidsLevel])}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            }
          />
        )}
      </div>
    );
  }

  function renderConfirmEnableAccessToIssuedCards({
    handleCheckboxChange
  }: {
    handleCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  }) {
    return (
      <div className="form-group modal-content">
        <div className="info">
          <Icon name="info" fill="#2376F3" />
          <span style={{ color: '#2376F3' }}>
            Important: This means that this merchant will be able to issue virtual cards under the default card issuance configuration.
          </span>
        </div>
        <div className="checkbox-container">
          <input type="checkbox" id="approve" onChange={handleCheckboxChange} />
          <label htmlFor="approve">Yes, I understand the implications of this action</label>
        </div>
      </div>
    );
  }

  const RenderEnableForm = memo(renderEnableForm);
  const RenderDisableForm = memo(renderDisableForm);
  const RenderConfirmDisableForm = memo(renderConfirmDisableForm);
  const RenderEnableAccessToIssuedCards = memo(renderEnableAccessToIssuedCards);
  const RenderConfirmEnableAccessToIssuedCards = memo(renderConfirmEnableAccessToIssuedCards);

  const modalDetails = {
    enableAccess: {
      heading: 'Enable USD Access for Merchant',
      description: 'Please confirm that you want to enable USD access for this merchant.',
      completedHeading: 'Enabled',
      completedDescription: 'You have enabled this merchant access to USD.',
      secondButtonText: 'Yes, Confirm',
      size: 'sm',
      content: <RenderEnableForm setMerchantName={setMerchantName} handleCheckboxChange={handleCheckboxChange} />,
      secondButtonActionIsTerminal: true,
      equalFooterBtn: true
    },
    disableAccess: {
      heading: 'Disable access for Merchant',
      description: `Provide the details below to disable merchant access to USD ${cardCategory} cards.`,
      secondButtonText: 'Next',
      content: <RenderDisableForm setReason={setReason} />,
      secondButtonColor: '#F32345',
      secondButtonAction: () => {
        setButtonState(false);
        setModalVisible('confirmDisableAccess');
      },
      secondButtonActionIsTerminal: false
    },
    confirmDisableAccess: {
      heading: 'Disable issued cards access for this merchant?',
      description: 'Please confirm that you want to disable card issuance access for this merchant. ',
      completedHeading: 'Disabled',
      content: <RenderConfirmDisableForm handleCheckboxChange={handleCheckboxChange} />,
      completedDescription: `You have disabled this merchant’s access to ${cardCategory} cards.`,
      secondButtonText: 'Yes, Confirm',
      size: 'sm',
      secondButtonActionIsTerminal: true,
      equalFooterBtn: true,
      secondButtonColor: '#F32345',
      secondButtonAction: async () => {
        await mutateModifyAccess({
          currency,
          kora_id: Number(issuingMerchantDetails?.kora_id),
          action: 'disable',
          card_type: 'virtual',
          access_type: 'service',
          reason: `disable ${cardCategory} card access`
        });
      }
    },
    enableAccessToIssuedCards: {
      heading: 'Enable access for merchant',
      description: `Review detail below to enable access to USD ${cardCategory} cards for this merchant.`,
      secondButtonText: 'Continue',
      size: 'md',
      content: <RenderEnableAccessToIssuedCards />,
      secondButtonActionIsTerminal: false,
      secondButtonAction: () => {
        setButtonState(false);
        setModalVisible('confirmEnableAccessToIssuedCards');
      },
      isScrollable: true,
      equalFooterBtn: true
    },
    confirmEnableAccessToIssuedCards: {
      heading: `Enable access to ${cardCategory} cards`,
      description: 'Please confirm that you want to enable card issuance access for this merchant.',
      content: <RenderConfirmEnableAccessToIssuedCards handleCheckboxChange={handleCheckboxChange} />,
      firstButtonText: 'Back',
      firstButtonAction: () => setModalVisible('enableAccessToIssuedCards'),
      secondButtonText: 'Enable',
      completedHeading: 'Success',
      completedDescription: `You have successfully enabled access to ${cardCategory} cards for this merchant.`,
      size: 'sm',
      secondButtonActionIsTerminal: true,
      equalFooterBtn: true,
      secondButtonAction: async () => {
        await mutateModifyAccess({
          currency,
          kora_id: Number(requestingMerchantDetails?.merchant_kora_id),
          action: 'enable',
          card_type: 'virtual',
          access_type: 'card',
          card_category: cardCategory === 'issued' ? 'customer' : 'reserved',
          access_request_reference: requestingMerchantDetails?.reference,
          reason: `enable ${cardCategory} card access`
        });
      }
    }
  } as unknown as Record<string, IModalProps>;

  const setModalVisible = (type: string) => {
    setModal({
      modalType: type,
      modalInfo: {
        heading: modalDetails[type]?.heading,
        description: modalDetails[type]?.description,
        completedHeading: modalDetails[type]?.completedHeading,
        completedDescription: modalDetails[type]?.completedDescription,
        content: modalDetails[type]?.content,
        firstButtonText: modalDetails[type]?.firstButtonText,
        secondButtonText: modalDetails[type]?.secondButtonText,
        secondButtonAction: modalDetails[type]?.secondButtonAction,
        secondButtonActionIsTerminal: modalDetails[type]?.secondButtonActionIsTerminal,
        size: modalDetails[type]?.size,
        secondButtonColor: modalDetails[type]?.secondButtonColor,
        equalFooterBtn: modalDetails[type]?.equalFooterBtn,
        isScrollable: modalDetails[type]?.isScrollable
      } as unknown as IModalProps
    });
  };

  return (
    <Modal
      visible={modal.modalType !== null}
      close={() => {
        onClose();
        setModal({ modalType: null, modalInfo: {} as IModalProps });
      }}
      heading={modal.modalInfo?.heading}
      description={modal.modalInfo?.description}
      content={modal.modalInfo?.content}
      equalFooterBtn={modal.modalInfo?.equalFooterBtn}
      size={modal.modalInfo?.size || 'md'}
      secondButtonText={modal.modalInfo?.secondButtonText}
      secondButtonAction={() => modal.modalInfo?.secondButtonAction?.()}
      secondButtonDisable={!buttonState}
      firstButtonText={modal.modalInfo?.firstButtonText}
      secondButtonActionIsTerminal={modal.modalInfo?.secondButtonActionIsTerminal}
      completedHeading={modal.modalInfo?.completedHeading}
      completedDescription={modal.modalInfo?.completedDescription}
      completedModalSize={modal.modalInfo?.completedModalSize}
      showButtons={modal.modalInfo?.showButtons}
      secondButtonColor={modal.modalInfo?.secondButtonColor}
      secondaryCompletedModal
      isScrollable={modal.modalInfo?.isScrollable}
    />
  );
};

export default MerchantAccessModal;

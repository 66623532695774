/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import classNames from 'classnames';

const NumericInput = ({ className, ...props }: NumericFormatProps) => {
  return (
    <NumericFormat
      thousandSeparator=","
      className={classNames('form-control', className)}
      displayType="input"
      decimalScale={2}
      fixedDecimalScale
      {...props}
    />
  );
};

export default NumericInput;

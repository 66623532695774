import React from 'react';

import Accordion from '+containers/Dashboard/Shared/Accordion';
import Icon from '+containers/Dashboard/Shared/Icons';
import { KAASFeeType } from '+types';
import UseCaseOptions from './UseCaseOptions';

export default function UseCase({
  title,
  description,
  disabled,
  indicateModified,
  options,
  updateUseCase
}: {
  title: string;
  description: string;
  disabled?: boolean;
  indicateModified?: boolean;
  options: KAASFeeType[];
  updateUseCase: (useCase: KAASFeeType) => void;
}) {
  const getTitle = () => {
    if (indicateModified) {
      return (
        <>
          {title} <Icon name="circledCaution" width={14} />
        </>
      );
    }
    return title;
  };
  return (
    <Accordion
      title={getTitle()}
      className="id-use-case"
      open
      onClick={(e: React.MouseEvent) => e.preventDefault()}
      content={
        <div>
          <p className="id-use-case__description">{description}</p>
          <UseCaseOptions disabled={disabled} options={options} updateUseCase={updateUseCase} />
        </div>
      }
    />
  );
}

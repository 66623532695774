import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import Icon from '+containers/Dashboard/Shared/Icons';
import Switch from '+containers/Dashboard/Shared/Switch';
import {
  CardStatusTogglePayloadType,
  CategoryType,
  CurrencyType,
  EditMerchantLimitModalPayloadType,
  IssuingPartnerType,
  LimitType,
  MerchantLimitsConfigType,
  PartnerSwitchingPayloadType,
  PCIDSSLevelType,
  RiskLevelType,
  ToggleCardCreationPayloadType
} from '+types';
import { formatWithCommas } from '+utils';

import { issuingPartners } from '../constants/common';
import {
  formatMerchantFundingLimits,
  formatMerchantPCIDSSLimits,
  formatMerchantSpendingLimits,
  riskLevelMap
} from '../helpers/holderDetailsHelpers';
import CardsStatusToggleModal from './CardsStatusToggleModal';
import EditMerchantLimitsModal from './EditMerchantLimitsModal';
import LimitsInfoPanel from './LimitsInfoPanel';
import PartnerSwitchingModal from './PartnerSwitchingModal';
import UpdateCardCreationModal from './UpdateCardCreationModal';

type ModalPurposeType = 'limits_config' | 'toggling_card_creation' | 'switching_partner' | 'toggling_cards_access' | null;

type ModalStateType<T> = {
  for: ModalPurposeType;
  action?: T | null;
  payload?:
    | EditMerchantLimitModalPayloadType
    | ToggleCardCreationPayloadType
    | CardStatusTogglePayloadType
    | PartnerSwitchingPayloadType
    | null;
};

type CardStatusToggleActionType = 'enable' | 'disable';

type ModalActionType = LimitType | CardStatusToggleActionType;

const initialModalState = {
  for: null,
  action: null,
  payload: null
} as const;

const LimitConfigPage = ({
  merchantInfo,
  category,
  config,
  pciDssLevel,
  riskLevel,
  provider,
  cardCreationToggleDisabled,
  cardAccessEnabled
}: {
  merchantInfo: { name: string; koraId: number; requestRef: string; merchantRef: string };
  category: CategoryType;
  config: MerchantLimitsConfigType;
  pciDssLevel: PCIDSSLevelType;
  riskLevel: RiskLevelType;
  provider: IssuingPartnerType;
  cardCreationToggleDisabled: boolean;
  cardAccessEnabled: boolean;
}) => {
  const queryClient = useQueryClient();
  const { merchantId, currency } = useParams<{ merchantId: string; currency: CurrencyType }>();
  const [modalState, setModalState] = useState<ModalStateType<ModalActionType>>(initialModalState);
  const [cardCreationDisabled, setCardCreationDisabled] = useState(!config.card_creation_enabled);
  const formattedCardCategory: 'customer' | 'reserved' = category === 'issued-cards' ? 'customer' : 'reserved';

  const handleChangeModalState = ({ action, for: isOpen, payload }: ModalStateType<ModalActionType>) => {
    setModalState(prev => ({ ...prev, for: isOpen, action, payload }));
  };

  const closeModal = () => handleChangeModalState(initialModalState);

  const toggleCardCreation = (e: React.ChangeEvent<HTMLInputElement>) => setCardCreationDisabled(e.target.checked);

  const invalidateMerchantData = () => {
    queryClient.invalidateQueries(['ISSUING_MERCHANT_PLANS']);
    queryClient.invalidateQueries(['MERCHANT_DETAILS']);
    queryClient.invalidateQueries(['ISSUING_MERCHANTS']);
    queryClient.invalidateQueries(['REQUESTING_MERCHANTS']);
  };

  return (
    <>
      <section className="cards-config-panel">
        <div className="cards-config-panel__header">
          <div className="header__section d-flex flex-wrap justify-content-between align-items-start">
            <div className="cards-config-panel__toggle-creation">
              <h5 className="font-weight-bolder">
                {category === 'issued-cards' ? 'Issued Cards (Customers)' : 'Reserved Cards'} Configuration
              </h5>
              <div className={`toggle-creation__check ${cardCreationToggleDisabled ? 'disabled' : ''}`}>
                <label htmlFor="disable_card_creation" className="font-weight-bold">
                  Turn off card creation for this merchant
                </label>
                <input
                  id="disable_card_creation"
                  type="checkbox"
                  checked={cardCreationDisabled}
                  onChange={toggleCardCreation}
                  disabled={cardCreationToggleDisabled}
                />
              </div>

              {config.card_creation_enabled === cardCreationDisabled && (
                <div className="toggle-creation__button-container fade-in d-flex align-items-center mt-4">
                  <button
                    type="button"
                    className="btn btn-success px-4"
                    onClick={() =>
                      handleChangeModalState({
                        for: 'toggling_card_creation',
                        payload: {
                          card_category: formattedCardCategory,
                          action: cardCreationDisabled ? 'disable' : 'enable',
                          reference: merchantId
                        }
                      })
                    }
                  >
                    Save
                  </button>
                  <span className="divider-x" />
                  <button type="button" className="btn btn-link font-weight-bold" onClick={() => setCardCreationDisabled(prev => !prev)}>
                    Cancel
                  </button>
                </div>
              )}
            </div>
            <div className="d-flex align-items-center">
              <Switch
                checked={cardAccessEnabled}
                onCheckedChange={() =>
                  handleChangeModalState({
                    for: 'toggling_cards_access',
                    action: cardAccessEnabled ? 'disable' : 'enable',
                    payload: {
                      currency,
                      kora_id: merchantInfo.koraId,
                      access_request_reference: merchantInfo.requestRef,
                      card_category: formattedCardCategory
                    }
                  })
                }
                id="enableCards"
              />
              <label htmlFor="enableCards" className="my-0 ml-2">
                Enable {formattedCardCategory} cards
              </label>
            </div>
          </div>

          {cardCreationToggleDisabled && (
            <div className="cards-config-panel__access-message d-flex align-items-center">
              <Icon name="info" fill="#2376F3" stroke="#fff" />
              <span>
                Access to {formattedCardCategory === 'reserved' ? 'Reserved Virtual Cards (RVCs)' : 'Customer Cards'} is disabled for this
                merchant.{' '}
              </span>
            </div>
          )}
        </div>
        <div className="cards-config-panel__main">
          <div className="cards-config-panel__change-partner">
            <p className="change-partner__header mb-0 font-weight-bolder" style={{ fontSize: '1rem' }}>
              Issuing Partner
            </p>

            <div className="change-partner__container d-flex justify-content-between channel-config__container">
              <div className="change-partner__section">
                <p className="change-partner__description">
                  This is the current partner managing card issuance for this merchant. All Reserved Virtual Cards are issued and maintained
                  under their system.
                </p>
                <p className="change-partner__current-partner">
                  Current issuing partner: <span>{issuingPartners[provider] ?? 'Not Available'}</span>
                </p>
              </div>

              <div className="change-partner__section">
                <button
                  className="text-decoration-none btn btn-link"
                  onClick={() =>
                    handleChangeModalState({
                      for: 'switching_partner',
                      payload: { merchantName: merchantInfo.name, merchantReference: merchantInfo.merchantRef }
                    })
                  }
                  style={{ fontWeight: 600 }}
                  type="button"
                  disabled={cardCreationToggleDisabled}
                >
                  Change Partner
                </button>
              </div>
            </div>
          </div>

          <div className="cards-config-panel__limits-section">
            <p className="limits-section__heading mb-0 font-weight-bolder">Limits</p>

            <div className="limits-section__info-panels-container">
              {category === 'issued-cards' ? (
                <LimitsInfoPanel
                  cta={config.pcidss_level_limit.type === 'custom' ? 'Edit' : 'Customize'}
                  columnwiseData={[
                    <strong style={{ color: '#414F5F' }} key={pciDssLevel}>
                      {pciDssLevel?.replace('level_', 'Level ')}
                    </strong>,
                    ...formatMerchantPCIDSSLimits(config.pcidss_level_limit.data)
                  ]}
                  onLimitEdit={() =>
                    handleChangeModalState({
                      for: 'limits_config',
                      action: 'pci_dss',
                      payload: { pciDssLevel: pciDssLevel, ...config.pcidss_level_limit.data, type: config.pcidss_level_limit.type }
                    })
                  }
                  rowHeaders={[null, 'Merchant PCI DSS Level', 'Transaction Count Limit', 'No. of Issuable Cards']}
                  subtitle="This is the pci dss level limit configuration for this merchant"
                  title="Limits based on PCI DSS Level"
                  disabled={cardCreationToggleDisabled}
                />
              ) : (
                <LimitsInfoPanel
                  cta={config.pcidss_level_limit.type === 'custom' ? 'Edit' : 'Customize'}
                  columnwiseData={[
                    formatWithCommas(config.pcidss_level_limit.data.yearly_transaction_count),
                    formatWithCommas(config.pcidss_level_limit.data.yearly_issued_cards)
                  ]}
                  onLimitEdit={() =>
                    handleChangeModalState({
                      for: 'limits_config',
                      action: 'pci_dss',
                      payload: { ...config.pcidss_level_limit.data, type: config.pcidss_level_limit.type }
                    })
                  }
                  rowHeaders={[null, 'Transaction Count Limit', 'No. of Issuable Cards']}
                  subtitle="These are the card and transaction count limit configuration for this merchant"
                  title="Card and Transaction Count Limits"
                  disabled={cardCreationToggleDisabled}
                />
              )}

              <LimitsInfoPanel
                cta={config.risk_level_limit.funding_limit.type === 'custom' ? 'Edit' : 'Customize'}
                columnwiseData={[
                  <strong style={{ color: '#414F5F' }} key={riskLevel}>
                    {riskLevelMap[riskLevel]}
                  </strong>,
                  ...formatMerchantFundingLimits(config.risk_level_limit.funding_limit.data)
                ]}
                onLimitEdit={() =>
                  handleChangeModalState({
                    for: 'limits_config',
                    action: 'funding',
                    payload: { riskLevel, ...config.risk_level_limit.funding_limit.data, type: config.risk_level_limit.funding_limit.type }
                  })
                }
                rowHeaders={[
                  null,
                  'Merchant Risk Level',
                  'Max. funding limit per day',
                  'Max. funding limit per month',
                  'Max. funding limit per quarter'
                ]}
                subtitle="These are the card funding limit configurations for this merchant"
                title="Card Funding Limits"
                disabled={cardCreationToggleDisabled}
              />

              <LimitsInfoPanel
                cta={config.risk_level_limit.spending_limit.type === 'custom' ? 'Edit' : 'Customize'}
                columnwiseData={[
                  <strong style={{ color: '#414F5F' }} key={riskLevel}>
                    {riskLevelMap[riskLevel]}
                  </strong>,
                  ...formatMerchantSpendingLimits(config.risk_level_limit.spending_limit.data)
                ]}
                onLimitEdit={() =>
                  handleChangeModalState({
                    for: 'limits_config',
                    action: 'spending',
                    payload: {
                      riskLevel,
                      ...config.risk_level_limit.spending_limit.data,
                      type: config.risk_level_limit.spending_limit.type
                    }
                  })
                }
                rowHeaders={[null, 'Merchant Risk Level', 'Max. limit per transaction', 'Daily transaction cap', 'Monthly transaction cap']}
                subtitle="These are the card spending limit configurations for this merchant"
                title="Card Spending Limits"
                disabled={cardCreationToggleDisabled}
              />
            </div>
          </div>
        </div>
      </section>

      {modalState.for === 'limits_config' && (
        <EditMerchantLimitsModal
          limitType={modalState.action as LimitType}
          cardCategory={formattedCardCategory}
          onClose={closeModal}
          refetchLimits={invalidateMerchantData}
          defaultValues={modalState.payload as EditMerchantLimitModalPayloadType}
          merchantId={merchantId}
        />
      )}

      {modalState.for === 'toggling_card_creation' && (
        <UpdateCardCreationModal
          onClose={closeModal}
          onToggleSuccess={invalidateMerchantData}
          payload={modalState.payload as ToggleCardCreationPayloadType}
        />
      )}

      {modalState.for === 'toggling_cards_access' && (
        <CardsStatusToggleModal
          action={modalState.action as CardStatusToggleActionType}
          onClose={closeModal}
          cardCategory={formattedCardCategory}
          payload={modalState.payload as CardStatusTogglePayloadType}
          onToggleSuccess={invalidateMerchantData}
        />
      )}

      {modalState.for === 'switching_partner' && (
        <PartnerSwitchingModal
          cardCategory={formattedCardCategory}
          currentPartner={provider}
          payload={modalState.payload as PartnerSwitchingPayloadType}
          onClose={closeModal}
          onToggleSuccess={invalidateMerchantData}
        />
      )}
    </>
  );
};

export default LimitConfigPage;

import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import pendingCircles from '+assets/img/dashboard/pending-circles.gif';
import { useFeedbackHandler } from '+hooks';
import APIRequest from '+services/api-services';
import {
  BankDetailsInfo,
  IApprovalData,
  IAttempt,
  IBank,
  IBankResponse,
  IDeclineData,
  IRefundStatus,
  IRetryData,
  IRetryDetail,
  IReversalModalContent,
  ISwitchReversalModal
} from '+types';
import { capitalize, capitalizeAllCharacters, getDate, getTime } from '+utils';

import Copyable from '../../Shared/Copyable';
import Modal from '../../Shared/Modal';

import Checkmark from '+assets/img/dashboard/check-green.svg';
import completed from '+assets/img/dashboard/completed-tick.svg';
import Failed from '+assets/img/dashboard/icon_failed.svg';

const api = new APIRequest();

const ReversalAttempts = ({
  item,
  attempts,
  index
}: {
  item: { status: string; reference: string; payment_reversal_payouts: Array<any> };
  attempts: { id: string; created_at: string; reference: string };
  index: number;
}) => {
  const queryCache = useQueryClient();
  const { feedbackInit } = useFeedbackHandler();
  const [loading, setLoading] = useState(false);
  const payinId = sessionStorage.getItem('payinId');

  const banks = queryCache.getQueryData<IBank[]>('BANKS');
  const [state, setState] = useState({
    modalVisible: false,
    type: ''
  });
  const [attemptsInfo, setAttemptsInfo] = useState<IAttempt>({
    id: '',
    created_at: '',
    reference: '',
    status: ''
  });
  const [attemptReference, setAttemptReference] = useState('');
  const [retryInfo, setRetryInfo] = useState({
    reference: '',
    account_number: '',
    bank_code: ''
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [reversalResponse, setReversalResponse] = useState({
    heading: '',
    description: ''
  });

  const [bankDetails, setBankDetails] = useState({
    account: '',
    bank: ''
  });

  const resolveBankInfomationlMutation = useMutation<IBankResponse, Error, BankDetailsInfo>(
    approvalData => api.resolveBankInformation(approvalData),
    {
      onError: error => {
        setRetryInfo(prevState => {
          return {
            ...prevState,
            bank_code: '',
            account_number: ''
          };
        });
        throw error;
      },
      onSuccess: bank => {
        setRetryInfo(prevState => {
          return {
            ...prevState,
            bank_code: bank?.bank_code,
            account_number: bank?.account_number
          };
        });
      }
    }
  );

  const getBankInformation = async (bankDetailsInfo: BankDetailsInfo): Promise<void> => {
    try {
      setLoading(true);
      await resolveBankInfomationlMutation.mutateAsync(bankDetailsInfo);
      setLoading(false);
    } catch (error) {
      feedbackInit({
        message: 'There has been an error resolving this account details.',
        type: 'danger',
        componentLevel: true
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (bankDetails?.bank && bankDetails?.account?.length === 10) {
      getBankInformation(bankDetails);
    }
  }, [bankDetails]);

  const lastReversalAttempt = item?.payment_reversal_payouts[item?.payment_reversal_payouts?.length - 1];

  const switchRefundStatus = (status: string): IRefundStatus => {
    switch (true) {
      case ['success', 'manual'].includes(status):
        return { name: `${capitalize(status)}`, color: '#24B314', icon: '✓' };
      case ['processing', 'pending_approval', 'pending'].includes(status):
        return {
          name: `${status === 'pending_approval' ? 'Pending Approval' : 'Processing...'}`,
          color: 'rgba(16,38,73,.4)',
          icon: <img src={pendingCircles} alt="" id="pending-circles" />
        };
      case status === 'failed':
        return { name: 'Failed', color: '#F32345', icon: '✗' };
      default:
        return { name: '', color: '', icon: '' };
    }
  };

  const getBankCode = (bankname: string): string | undefined => {
    const findBank = banks?.find((bank: IBank) => bank.name === bankname);
    return findBank?.code;
  };

  const modalContent = (attempt: IAttempt) => (
    <ul className="modal-list" style={{ padding: 0 }}>
      <li>
        <div className="attempt-modal modal-left-content">
          <h6>Status</h6>
          <div className="attempt-modal-wrap">
            <p
              style={{
                height: '6px',
                width: '6px',
                borderRadius: '50%',
                backgroundColor: switchRefundStatus(attempt?.status ?? '')?.color
              }}
            />
            <p className="attempt-modal-text">{capitalize(attempt.status ?? '')}</p>
          </div>
        </div>
        <div className="attempt-modal">
          <h6>Attempted at</h6>
          <p>{`${getDate(attempt.created_at)}, ${getTime(attempt.created_at)}`}</p>
        </div>
      </li>
      <li>
        <div className="attempt-modal modal-left-content">
          <h6>Bank</h6>
          <p>{attempt.bank_name ?? 'Not Available'}</p>
        </div>
        <div className="attempt-modal">
          <h6>Account number</h6>
          <p>{attempt.account_number ?? 'Not Available'}</p>
        </div>
      </li>
      <li>
        <div className="attempt-modal modal-left-content">
          <h6>Account Name</h6>
          <p>{attempt.account_name ?? 'Not Available'}</p>
        </div>
      </li>
      {attempt.status === 'failed' && (
        <li>
          <div className="attempt-modal modal-left-content">
            <h6>Reason for failure</h6>
            <p>{attempt.message ?? 'Not Available'}</p>
          </div>
        </li>
      )}
      <li>
        <div className="attempt-modal modal-left-content">
          <h6>Reversal for</h6>
          <p className="attempt-modal-blue">{capitalizeAllCharacters(attempt?.reference) || 'Not Available'}</p>
        </div>
      </li>
      {attempt.trace_id && (
        <li>
          <div className="attempt-modal modal-left-content">
            <h6>Session ID</h6>
            <p className="attempt-modal-blue">{capitalizeAllCharacters(attempt.trace_id)}</p>
          </div>
        </li>
      )}
    </ul>
  );

  const retryReversalMutation = useMutation<Response, Error, IRetryData>(reversaldata => api.retryReversals(reversaldata), {
    onError: error => {
      throw error;
    },
    onSuccess: () => {
      setState({ modalVisible: true, type: 'reversalSuccess' });
      setReversalResponse({
        heading: 'Reversal initiated',
        description: 'Transaction reversal has been initiated'
      });
      feedbackInit({
        message: 'Transaction Reversal has been initiated',
        type: 'success'
      });
    }
  });

  const handleRetry = async (retryData: IRetryData): Promise<void> => {
    try {
      await retryReversalMutation.mutateAsync(retryData);
      const payinId = localStorage.getItem('payinId');
      queryCache.invalidateQueries(`PAYIN_DETAILS_${payinId}`);
    } catch (error) {
      setErrorMessage('There has been an error initialising this reversal.');
      setState({ modalVisible: true, type: 'error' });
      feedbackInit({
        message: 'There has been an error initialising this reversal.',
        type: 'danger'
      });
      throw error;
    }
  };

  const handleDisableReversalButton = (retryDetail: IRetryDetail): boolean => {
    if (
      !retryDetail.account_number ||
      !retryDetail.bank_code ||
      !retryDetail.reference ||
      bankDetails.account.length !== 10 ||
      resolveBankInfomationlMutation.isLoading
    )
      return true;
    return false;
  };

  const manualUpdateMutation = useMutation<Response, Error, IApprovalData>((updateData: IApprovalData) => api.updateReversal(updateData), {
    onError: error => {
      throw error;
    },
    onSuccess: () => {
      setReversalResponse({
        heading: 'Reversal status updated',
        description: 'The status of the reversal for this transaction has been updated'
      });
      setState({ modalVisible: true, type: 'reversalSuccess' });
    }
  });

  const handleManualUpdate = async (approvalData: IApprovalData) => {
    try {
      await manualUpdateMutation.mutateAsync(approvalData);
      queryCache.invalidateQueries(`PAYIN_DETAILS_${payinId}`);
    } catch (error) {
      setErrorMessage('There has been an error manually updating this reversal.');
      setState({ modalVisible: true, type: 'error' });
      feedbackInit({
        message: 'There has been an error manually updating this reversal.',
        type: 'danger'
      });
      throw error;
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>): void => {
    e.persist();
    setBankDetails(prev => {
      return { ...prev, [e.target.name]: e?.target?.value };
    });
  };

  const approveReversalMutation = useMutation<Response, Error, IDeclineData>(approvalData => api.resolveReversal(approvalData), {
    onError: error => {
      throw error;
    }
  });

  const handleDecline = async (declineData: IDeclineData): Promise<void> => {
    try {
      await approveReversalMutation.mutateAsync(declineData);
      setState({ modalVisible: true, type: 'declined' });
      queryCache.invalidateQueries(`PAYIN_DETAILS_${payinId}`);
    } catch (error) {
      setErrorMessage('There has been an error declining this reversal.');
      setState({ modalVisible: true, type: 'error' });
      feedbackInit({
        message: 'There has been an error declining this reversal.',
        type: 'danger'
      });
      throw error;
    }
  };

  const handleApproval = async (approvalData: IApprovalData) => {
    try {
      await approveReversalMutation.mutateAsync(approvalData);
      setReversalResponse({
        heading: 'Reversal Approved',
        description: 'Transaction reversal has been approved'
      });
      setState({ modalVisible: true, type: 'reversalSuccess' });
      queryCache.invalidateQueries(`PAYIN_DETAILS_${payinId}`);
    } catch (error) {
      setErrorMessage('There has been an error approving this reversal.');
      setState({ modalVisible: true, type: 'error' });
      feedbackInit({
        message: 'There has been an error approving this reversal.',
        type: 'danger'
      });
      throw error;
    }
  };

  const switchReversalModal = (type: string): ISwitchReversalModal => {
    let content: IReversalModalContent | undefined;
    switch (type) {
      case 'manual':
        content = {
          size: 'sm',
          heading: 'Update Reversal Status',
          secondButtonText: 'Update',
          description: (
            <span style={{ fontStyle: 'normal', fontWeight: '400', fontSize: '0.863rem' }}>
              Please confirm that this reversal has been resolved offline. This action cannot be undone.
            </span>
          ),
          secondButtonAction: () => handleManualUpdate({ reference: attemptReference }),
          secondButtonActionIsTerminal: false,
          completedHeading: 'Reversal status updated',
          completedDescription: 'The status of the reversal for this transaction has been updated'
        };
        break;
      case 'retryWithNewDetails':
        content = {
          size: 'md',
          heading: 'Reversal Payout',
          firstButtonText: 'Cancel',
          secondButtonText: 'Send',
          secondButtonAction: () => handleRetry(retryInfo),
          secondButtonActionIsTerminal: false,
          secondButtonDisable: handleDisableReversalButton(retryInfo),
          content: (
            <>
              <p style={{ fontStyle: 'normal', fontWeight: '400', fontSize: '0.863rem' }}>
                This reversal failed due to invalid account details, enter new account details below to proceed
              </p>
              <div className="form-group filter-object filter-object-ssm w-auto --no-max-width">
                <h6>Bank Account</h6>
                <select
                  name="bank"
                  aria-label="method"
                  className="form-control"
                  onChange={e => handleChange(e)}
                  value={bankDetails?.bank}
                  style={{ minWidth: '200px' }}
                >
                  <option value="">Select bank</option>
                  {banks &&
                    banks.map(paymentMethod => (
                      <option key={paymentMethod.code} value={paymentMethod.code}>
                        {paymentMethod.name}
                      </option>
                    ))}
                </select>
              </div>
              <div>
                <h6>Enter account number</h6>
                <input
                  name="account"
                  className="form-control"
                  maxLength={10}
                  value={bankDetails?.account}
                  onChange={e => handleChange(e)}
                  type="text"
                />
              </div>
              {resolveBankInfomationlMutation?.data ? (
                <p className="account-name">
                  {resolveBankInfomationlMutation?.data?.account_name}
                  <img height={14} width={14} src={Checkmark} alt="check" />
                </p>
              ) : null}
              {loading && (
                <span className="spinner-border spinner-border-sm" style={{ marginRight: '0.5rem' }} role="status" aria-hidden="true" />
              )}
            </>
          )
        };
        break;
      case 'approval':
        content = {
          size: 'md',
          heading: 'Approve Reversal',
          firstButtonText: 'No, Decline',
          firstButtonAction: () => {
            handleDecline({ reference: attemptReference, action: 'decline' });
          },
          secondButtonText: 'Yes, Approve',
          description: (
            <span style={{ fontStyle: 'normal', fontWeight: '400', fontSize: '0.863rem' }}>
              This payout for reversal needs to be approved. Do you want to approve it?
            </span>
          ),
          secondButtonActionIsTerminal: false,
          secondButtonAction: () =>
            handleApproval({
              reference: attemptReference,
              action: 'approve'
            }),
          content: (
            <ul className="approve-modal-content" style={{ padding: '1rem' }}>
              <li className="approve-modal-list">
                <p>Bank Account</p>
                <p>{lastReversalAttempt?.bank_name || 'Not available'}</p>
              </li>
              <li className="approve-modal-list">
                <p>Account Number</p>
                <p>{lastReversalAttempt?.account_number || 'not available'}</p>
              </li>
              <li className="approve-modal-list">
                <p>Account Name</p>
                <p>{lastReversalAttempt?.account_name || 'Not available'}</p>
              </li>
            </ul>
          )
        };
        break;
      case 'declined':
        content = {
          size: 'sm',
          description: (
            <div
              style={{
                marginTop: '0px',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '10px'
              }}
              className="refund-heading"
            >
              <img width={80} height={80} src={Failed} alt="success" />
              <h5>Reversal Declined</h5>
              <p style={{ fontWeight: '400', textAlign: 'center', fontSize: '0.863rem' }}>Transaction reversal has been declined</p>
              <button type="button" onClick={() => setState({ ...state, modalVisible: false })}>
                Dismiss
              </button>
            </div>
          ),
          showButtons: false
        };
        break;
      case 'reversalSuccess':
        content = {
          size: 'sm',
          description: (
            <div
              style={{
                marginTop: '0px',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '10px'
              }}
              className="refund-heading"
            >
              <img width={80} height={80} src={completed} alt="success" />
              <h5>{reversalResponse?.heading}</h5>
              <p style={{ fontWeight: '400', textAlign: 'center', fontSize: '0.863rem' }}>{reversalResponse?.description}</p>
              <button type="button" onClick={() => setState({ ...state, modalVisible: false })}>
                Dismiss
              </button>
            </div>
          ),
          showButtons: false
        };
        break;
      case 'error':
        content = {
          size: 'sm',
          description: (
            <div
              style={{
                marginTop: '0px',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '10px'
              }}
              className="refund-heading"
            >
              <img width={80} height={80} src={Failed} alt="success" />
              <h5>Error</h5>
              <p style={{ fontWeight: '400', textAlign: 'center', fontSize: '0.863rem' }}>{errorMessage}</p>
              <button type="button" onClick={() => setState({ ...state, modalVisible: false })}>
                Dismiss
              </button>
            </div>
          ),
          showButtons: false
        };
        break;
      case 'attemptsInfo':
        content = {
          size: 'md',
          heading: capitalizeAllCharacters(item?.reference),
          content: modalContent(attemptsInfo),
          showButtons: false
        };
        break;
      case 'retry':
        content = {
          size: 'sm',
          showButtons: true,
          heading: 'Update Bank Information',
          firstButtonText: 'No',
          secondButtonText: 'Yes, I would',
          description: (
            <span style={{ fontStyle: 'normal', fontWeight: '400', fontSize: '0.863rem' }}>
              Would you like to retry this reversal with new bank information?
            </span>
          ),
          secondButtonActionIsTerminal: false,
          secondButtonAction: () => setState({ modalVisible: true, type: 'retryWithNewDetails' }),
          firstButtonAction: () => handleRetry(retryInfo)
        };
        break;
      default:
        return {};
    }
    return {
      visible: state.modalVisible,
      close: () => {
        setState({ ...state, modalVisible: false });
      },
      completedHeading: 'Success',
      ...content
    };
  };

  return (
    <>
      <div className="reversal-attempts" key={attempts.id}>
        <p className="attempt-date">
          <div className="attempt-date__label">Attempt {index + 1}</div>
          <div className="dot" />
          <span className="date-span attempt-date__value">{`${getDate(attempts.created_at)}, ${getTime(attempts.created_at)}`}</span>
        </p>
        <button
          onClick={() => {
            setAttemptsInfo(attempts);
            setState({
              modalVisible: true,
              type: 'attemptsInfo'
            });
          }}
          className="attempt-id"
          type="button"
        >
          <Copyable showOnHover text={capitalizeAllCharacters(attempts?.reference)} />
        </button>
      </div>
      {!['success', 'manual', 'pending'].includes(item?.status) && (
        <div className="button-wrapper">
          {item?.status === 'failed' ? (
            <button
              onClick={() => {
                if (item?.payment_reversal_payouts.length === 0 || lastReversalAttempt.account_number === null) {
                  setRetryInfo(prev => {
                    return {
                      ...prev,
                      reference: item?.reference
                    };
                  });
                  setState({
                    modalVisible: true,
                    type: 'retryWithNewDetails'
                  });
                } else {
                  setRetryInfo(prev => {
                    return {
                      ...prev,
                      reference: item?.reference,
                      account_number: lastReversalAttempt?.account_number,
                      bank_code: getBankCode(lastReversalAttempt?.bank_name) ?? ''
                    };
                  });
                  setState({ modalVisible: true, type: 'retry' });
                }
              }}
              className="retry-button"
              type="button"
            >
              Retry Reversal
            </button>
          ) : (
            <button
              onClick={() => {
                setAttemptReference(item?.reference);
                setState({
                  modalVisible: true,
                  type: 'approval'
                });
              }}
              className="retry-button"
              style={{ borderRadius: '4px' }}
              type="button"
              disabled={item?.status === 'processing'}
            >
              {item?.status === 'processing' ? 'Processing Reversal' : 'Approve Reversal'}
            </button>
          )}
          <button
            onClick={() => {
              setAttemptReference(item?.reference);
              setState({ modalVisible: true, type: 'manual' });
            }}
            disabled={item?.status === 'processing'}
            className="update-button"
            style={{ borderRadius: '4px' }}
            type="button"
          >
            ...
          </button>
        </div>
      )}
      {state.modalVisible && <Modal {...switchReversalModal(state.type)} />}
    </>
  );
};

export default ReversalAttempts;

import React, { useEffect, useState } from 'react';

import check from '+assets/img/dashboard/check-white.svg';

import './index.scss';

interface ICustomCheckboxProps {
  className?: string;
  checked?: boolean;
  disabled?: boolean;
  text: React.ReactNode | string;
  onChange: (value?: boolean) => void;
  dataTestId?: string;
}

const CustomCheckbox = ({ className, checked, text, onChange, disabled, dataTestId }: ICustomCheckboxProps) => {
  const [checkboxInput, setCheckboxInput] = useState(checked);

  useEffect(() => {
    setCheckboxInput(checked);
  }, [checked]);

  const handleCheckboxChange = () => {
    if (disabled) return;
    const newValue = !checkboxInput;
    setCheckboxInput(newValue);
    onChange(newValue);
  };

  return (
    <label
      className={`checkbox__custom ${className} ${disabled ? 'disabled' : ''}`}
      style={{ '--check-image': `url("${check}")` } as React.CSSProperties}
      data-testid={dataTestId}
    >
      <input
        type="checkbox"
        className={`${checkboxInput ? 'checked' : ''} ${disabled ? 'disabled' : ''}`}
        checked={checkboxInput}
        onChange={handleCheckboxChange}
      />
      <button
        aria-label="check"
        onClick={handleCheckboxChange}
        type="button"
      >
        {text}
      </button>
    </label>
  );
};

export default CustomCheckbox;
import React, { FC, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import APIRequest from '+services/api-services';
import Modal from '+shared/Modal';
import APIServiceError from '+services/error-services';
import useFeedbackHandler from '+hooks/useFeedbackHandler';
import './index.scss';

interface IInformationContentProps {
  state: string;
  setState: React.Dispatch<React.SetStateAction<string>>;
  currentStatus: string;
}
interface IManageRefundsModalProps {
  visible: boolean;
  close: () => void;
  referenceId: string;
  currentStatus: string;
}

const api = new APIRequest();

const refundsStatusLabel: Record<string, string> = {
  success: 'Success',
  processing: 'Processing',
  failed: 'Failed'
};

const InformationContent: FC<IInformationContentProps> = ({ state, setState, currentStatus }) => {
  return (
    <section className="reversals-info-modal__comp">
      <hr className="refund-modal__hr" />
      <p className="refunds-modal__p">
        You might need to enter more information in order to proceed, depending on the refund status you chose.
      </p>

      <h3>
        <b>Refund Status</b>
      </h3>
      <div className="refunds-radio_container">
        {['success', 'processing', 'failed']
          .filter((item: string) => (currentStatus === 'manual' ? item !== 'processing' : item !== currentStatus))
          .map((item: string) => {
            return (
              <label key={item}>
                <input checked={item === state} type="radio" onChange={() => setState(item)} data-testid="refund-type" />
                {refundsStatusLabel[item]}
              </label>
            );
          })}
      </div>
    </section>
  );
};

const ManageRefundsModal: FC<IManageRefundsModalProps> = ({ visible, close, referenceId, currentStatus }) => {
  const [refundsState, setRefundsState] = useState('');
  const [isConfirm, setIsConfirm] = useState(false);
  const { feedbackInit } = useFeedbackHandler();
  const queryClient = useQueryClient();
  const onProceed = () => {
    setIsConfirm(true);
  };

  const { mutateAsync: manageRefund } = useMutation(
    (variable: { refundReference: string; data: { status: string } }) => api.manageRefund(variable.refundReference, variable.data),
    {
      onError: (error: AxiosError) => {
        feedbackInit({ message: error.response.data?.message, componentLevel: true, type: 'danger' });
        if (error instanceof APIServiceError) {
          throw error;
        }
      },
      onSuccess: () => {
        queryClient.invalidateQueries(`TRANSACTION_DETAILS_${referenceId}`);
        queryClient.invalidateQueries(['PAYMENT_REFUNDS']);
      }
    }
  );
  const onClose = () => {
    if (!isConfirm) {
      close();
      return;
    }
    setIsConfirm(false);
  };
  const confirmDescription: Record<string, React.ReactNode> = {
    failed: (
      <>
        Proceed to change this refund status to <b>failed?</b>
      </>
    ),
    processing: (
      <>
        Proceed to change this refund status to <b>processing?</b>
      </>
    ),
    success: 'Update as successful only if this refund has been completed. This action can not be undone. '
  };
  const modalProps = () => {
    if (isConfirm) {
      return {
        heading: 'Update Status',
        description: confirmDescription[refundsState],
        firstButtonText: 'Go Back',
        secondButtonText: 'Yes, Proceed',
        firstButtonAction: onClose,
        secondButtonAction: async () => {
          await manageRefund({ refundReference: referenceId, data: { status: refundsState } });
        },
        size: 'sm',
        secondaryCompletedModal: true,
        completedDescription: 'Refund status update successful.',
        completedHeading: 'Status Updated!'
      };
    }
    return {
      heading: 'Manage Refund',
      description: 'Update the status and details of this refund below',
      firstButtonText: 'Cancel',
      secondButtonText: 'Proceed',
      firstButtonAction: onClose,
      secondButtonAction: onProceed,
      secondButtonDisable: !refundsState,
      secondButtonActionIsTerminal: false,
      size: 'md',
      content: <InformationContent currentStatus={currentStatus} state={refundsState} setState={setRefundsState} />
    };
  };

  return <Modal close={close} visible={visible} showButtons {...modalProps()} />;
};

export default ManageRefundsModal;

import React from 'react';
import classNames from 'classnames';

type SummaryType = {
  label: React.ReactNode;
  value: React.ReactNode;
  hidden?: boolean;
};

interface ISummaries {
  layout?: 'horizontal' | 'vertical';
  data: Array<SummaryType>;
  className?: string;
}

const Summaries = ({ layout = 'horizontal', data, className }: ISummaries) => {
  return (
    <ul className={classNames('summaries', { 'fade-in': layout === 'horizontal' }, className)} data-layout={layout}>
      {data.map((datum, index) => (
        <li className="summary" key={index}>
          <div className="summary-label" id={`${index}`}>
            {datum.label}
          </div>
          <div className="summary-value" aria-labelledby={`${index}`}>
            {datum.value}
          </div>
        </li>
      ))}
    </ul>
  );
};

export default Summaries;

import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import RiskIndicator from '+containers/Dashboard/ProductConfig/components/RiskIndicator';
import Badge from '+containers/Dashboard/Shared/Badge';
import Icon from '+containers/Dashboard/Shared/Icons';
import { useFeedbackHandler } from '+hooks';
import APIRequest from '+services/api-services';
import LoadingPlaceholder from '+shared/LoadingPlaceHolder';
import { AllAccessRequestMerchantResponse, CategoryType, CurrencyType } from '+types';
import { capitalize, capitalizeFirst, getDate, getTime, history } from '+utils';

import { customerCardMonthlyPaymentOptions, pciDSSLevelMap } from './helpers/holderDetailsHelpers';
import MerchantAccessModal from './MerchantAccessModal';

import './index.scss';

const api = new APIRequest();

const MerchantAccessDetails = () => {
  const { feedbackInit } = useFeedbackHandler();
  const { currency, merchantId, feature } = useParams<{
    currency: CurrencyType;
    merchantId: string;
    feature: CategoryType;
  }>();
  const featureTitle = feature === 'issued-cards' ? 'Issued Cards' : 'Reserved Cards';

  const [activeModal, setActiveModal] = useState<string>('');

  const {
    data: merchantData,
    refetch: refetchConfig,
    isLoading
  } = useQuery<AllAccessRequestMerchantResponse>(
    ['REQUESTING_MERCHANT_DETAILS', merchantId, currency],
    () => api.fetchAccessRequestMerchantsDetails(merchantId),
    {
      refetchOnMount: 'always',
      onError: () => {
        feedbackInit({
          message: `There has been an error in getting this merchant's details`,
          type: 'danger',
          action: {
            action: () => refetchConfig(),
            name: 'Try again'
          }
        });
      }
    }
  );

  const currencyAccessStatus = merchantData?.currency_access_status ?? 'disabled_access';

  const monthlyPaymentValue = customerCardMonthlyPaymentOptions.find(e => e.value === merchantData?.monthly_payment_value)?.label;

  const merchantAccessInfo = {
    'Request Reference': merchantId || 'N/A',
    'Monthly Payment Value': monthlyPaymentValue || 'N/A',
    'PCI DSS Level': pciDSSLevelMap[merchantData?.pci_dss_level as keyof typeof merchantData] || 'N/A',
    'Risk Rating': '',
    'Access Requested On': (
      <>
        <span>{getDate(merchantData?.request_date)}</span>
        <span className="annotation" style={{ marginLeft: '5px' }}>
          {getTime(merchantData?.request_date)}
        </span>
      </>
    )
  };

  const handleMerchantsStatus = () => {
    setActiveModal('enableAccessToIssuedCards');
  };

  return (
    <>
      <div className="content-i">
        <div className="content-box">
          <div className="row">
            <button type="button" className="btn btn-link mb-2" onClick={() => history.goBack()}>
              <i className="os-icon os-icon-arrow-left7" />
              <span>Back to Virtual Cards Merchants</span>
            </button>
          </div>
          {isLoading ? (
            <LoadingPlaceholder type="text" content={4} background="#f556f6" />
          ) : (
            <>
              <div className="top-row__container">
                <div className="__left">
                  <div>
                    <span>
                      <Icon name="office" />
                    </span>
                    <p>Merchant</p>
                  </div>
                  <div>
                    <h4>{capitalize(String(merchantData?.name ?? 'N/A'))}</h4>
                    <Badge status={merchantData?.currency_access_status ?? 'disabled_access'}>
                      {currency} {capitalizeFirst(currencyAccessStatus)}
                    </Badge>
                  </div>
                  <div>
                    <p>{`ID: ${merchantId}`}</p>
                    <span />
                    <p>Enterprise</p>
                  </div>
                </div>
              </div>
              <ul className="summaries" data-layout="horizontal">
                {Object.entries(merchantAccessInfo).map(([key, value]) => (
                  <li className="summary" key={key}>
                    <div className="summary-label">{key}</div>
                    <div className="summary-value">
                      {key === 'Risk Rating' ? (
                        merchantData?.risk_level ? (
                          <RiskIndicator riskLevel={merchantData?.risk_level} />
                        ) : (
                          'N/A'
                        )
                      ) : (
                        value
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </>
          )}
          <hr className="my-4" />
          {isLoading ? (
            <LoadingPlaceholder type="text" background="#f5f6f6" content={4} />
          ) : (
            <div className="card-issuance-product">
              <div className="card-issuance-product__header">
                <h4>{featureTitle}</h4>
                <Badge status={`card_${merchantData?.status}`} />
              </div>
              <div className="subtitle">
                <p>
                  This product allows this merchant to issue payment cards to customers and
                  <br />
                  manage them. You can configure this product by modifying access, limits and
                  <br />
                  channels.
                </p>
                <button type="button" onClick={() => handleMerchantsStatus()} disabled={merchantData?.status === 'active'}>
                  Enable
                </button>
              </div>
              <>
                <div className="card-issuance-product__details" role="region" aria-label="Merchant Card Details">
                  <div className="card-issuance-product__details__item" role="group" aria-labelledby="Details Labels">
                    <span id="merchant-email-label">Merchant Email</span>
                    <span id="product-use-case-label">Product Use Case</span>
                    <span id="subscription-plan">Subscription Plan</span>
                  </div>
                  <div className="card-issuance-product__details__item" role="group" aria-labelledby="Details Values">
                    <p aria-labelledby="merchant-email-label">{merchantData?.email || 'N/A'}</p>
                    <p aria-labelledby="product-use-case-label">{merchantData?.issuing || 'N/A'}</p>
                    <p aria-labelledby="subscription-plan">
                      {capitalize(merchantData?.subscription_plan as keyof typeof merchantData) || 'N/A'}
                    </p>
                  </div>
                </div>
              </>
            </div>
          )}
        </div>
      </div>
      {merchantData && activeModal && (
        <MerchantAccessModal
          onClose={() => setActiveModal('')}
          modalType={activeModal}
          requestingMerchantDetails={merchantData}
          monthlyPaymentValue={monthlyPaymentValue}
          currency={currency}
          feature={feature}
        />
      )}
    </>
  );
};

export default MerchantAccessDetails;

import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import Badge from '+containers/Dashboard/Shared/Badge';
import Typography from '+containers/Dashboard/Shared/Typography';
import LoadingPlaceholder from '+dashboard/Shared/LoadingPlaceHolder';
import { useFeedbackHandler, useReducerState, useSearchQuery } from '+hooks';
import APIRequest from '+services/api-services';
import useProductConfigCardIssuanceStore from '+store/productConfigCardIssuanceStore';
import { CurrencyType, modalState, productCategoriesType } from '+types';
import { capitalize, capitalizeRemovedash, formatProductConfigStatus, productMapping } from '+utils';

import MerchantCurrencyModal from '../../MerchantsTable/components/MerchantCurrencyModal';
import MerchantsAccessTable from '../components/MerchantsAccessTable';
import ProductCards from '../components/ProductCards';

import './index.scss';

const tabs = {
  default: 'Default Configuration',
  access: 'Merchants with Access'
} as const;

type TabKeyType = keyof typeof tabs;

const api = new APIRequest();
const Categories = ({ currency }: { currency: CurrencyType }) => {
  const { product } = useParams<{ product: productCategoriesType }>();
  const { feedbackInit } = useFeedbackHandler();
  const access = useProductConfigCardIssuanceStore(store => store.access);
  const searchQuery = useSearchQuery<{ tab: TabKeyType }>();
  const activeTab = searchQuery.value.tab ?? 'default';
  const [state, setState] = useReducerState<{ activeModal: modalState }>({
    activeModal: null
  });

  const params = {
    payment_type: productMapping[product]
  };

  const {
    data: configData,
    refetch: refetchConfig,
    isLoading
  } = useQuery([`${currency}_PRODUCTS`, currency, product], () => api.getProductConfiguration(currency, params), {
    onError: () => {
      feedbackInit({
        message: `There has been an error in getting this merchant's details`,
        type: 'danger',
        action: {
          action: () => refetchConfig(),
          name: 'Try again'
        }
      });
    },
    enabled: product !== 'card-issuance'
  });

  const productStatus = product === 'card-issuance' ? access.enabled : configData?.data?.setting?.enabled;

  const toggleEnableButtonIsHidden = ['card-issuance'].includes(product);

  return isLoading ? (
    <LoadingPlaceholder type="text" background="#f5f6f6" content={2} section={3} />
  ) : (
    <div className="categories-container">
      <div className="categories-container__first no-bottom-border">
        <div className="description">
          <span>
            <Typography variant="h3">{capitalize(product)}</Typography>
            <Badge status={formatProductConfigStatus(String(productStatus))} />
          </span>
          <Typography variant="subtitle3">
            {`Here you can find the ${capitalize(
              product
            )?.toLowerCase()} products for this currency. You can modify the limits and payment channels configuration.`}
          </Typography>
        </div>
        <div className="controls">
          <button
            type="button"
            className="btn btn-secondary --enable-btn"
            onClick={() =>
              setState({
                activeModal: productStatus ? 'disable-product-all' : 'enable-product-all'
              })
            }
            title={`${productStatus ? 'Disable' : 'Enable'} ${product} for all merchant`}
            hidden={toggleEnableButtonIsHidden}
          >
            {`${productStatus ? 'Disable' : 'Enable'} ${product} for all merchant`}
          </button>
          <MerchantCurrencyModal
            activeModal={state.activeModal}
            setActiveModal={() => setState({ activeModal: '' })}
            productType={productMapping[product]}
            currency={currency}
            hideButton={['card-issuance'].includes(product)}
          />
        </div>
      </div>
      <section className="os-tabs-w">
        <div className="os-tabs-controls os-tabs-complex">
          <ul className="nav nav-tabs" role="tablist">
            {Object.entries(tabs).map(([key, value]) => {
              return (
                <li className="nav-item" key={key} role="tab" hidden={key === 'access' && product === 'card-issuance'}>
                  <div
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      searchQuery.setQuery({ tab: key as TabKeyType });
                    }}
                    onKeyDown={() => {
                      searchQuery.setQuery({ tab: key as TabKeyType });
                    }}
                    className={`nav-link ${key === activeTab && 'active'}`}
                  >
                    {capitalizeRemovedash(value)}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </section>
      {activeTab === 'default' && configData && Object.entries(configData?.data?.setting).length > 0 && !isLoading && (
        <ProductCards currency={currency} config={configData?.data?.setting} product={product} />
      )}
      {product !== 'card-issuance' && activeTab === 'access' && <MerchantsAccessTable />}
      {product === 'card-issuance' && activeTab === 'default' ? (
        <ProductCards
          currency={currency}
          config={{ 'issued-cards': 'issued-cards', 'reserved-cards': 'reserved-cards' }}
          product={product}
        />
      ) : null}
    </div>
  );
};

export default Categories;

import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { AxiosError } from 'axios';

import Icon from '+containers/Dashboard/Shared/Icons';
import Modal from '+containers/Dashboard/Shared/Modal';
import SearchInput from '+containers/Dashboard/Shared/SearchInput';
import ToolTip from '+containers/Dashboard/Shared/Tooltip';
import { useFeedbackHandler } from '+hooks';
import APIRequest from '+services/api-services';
import { AccessDataType, FilterMerchantType, IAddMerchantsModal } from '+types';
import { identityCountries } from '+utils';

import CheckboxInput from './CheckInput';

import './index.scss';

const api = new APIRequest(process.env.REACT_APP_MIDDLEWARE_API_BASE);

export default function AddMerchantsModal({ close, refetchMerchant, merchantName, countries, koraId }: IAddMerchantsModal) {
  const checkboxesToShow = countries?.length ? identityCountries.filter(each => countries.includes(each.value)) : identityCountries;

  const { feedbackInit } = useFeedbackHandler();

  const [selectedMerchant, setSelectedMerchant] = useState<number>();
  const [selectedRegions, setSelectedRegions] = useState<string[]>([]);
  const [shouldRunQuery, setShouldRunQuery] = useState(false);
  const [debouncedSearchInput, setDebouncedSearchInput] = useState<string>('');
  const [disableAllCheckboxes, setDisableAllCheckboxes] = useState(true);

  const grantAccessMutation = useMutation((data: AccessDataType) => api.addIdentityMerchant(data), {
    onError: (error: AxiosError) => {
      feedbackInit({
        message: (error as { response: { data: { message: string } } }).response?.data.message,
        componentLevel: true,
        type: 'danger'
      });
    },
    onSuccess: data => {
      refetchMerchant();
      feedbackInit({ message: 'Merchant granted access successfully', componentLevel: true, type: 'success' });
    }
  });
  const {
    isFetching: isFetchingMerchants,
    refetch: refetchMerchants,
    data
  } = useQuery(
    ['MERCHANTS', debouncedSearchInput],
    () =>
      api.getMerchants(
        'verified',
        undefined,
        {
          merchantName: debouncedSearchInput
        },
        undefined
      ),
    {
      enabled: debouncedSearchInput?.length >= 3 && shouldRunQuery,
      select: data => data?.data?.data?.map((each: FilterMerchantType) => ({ name: each.name, kora_id: each.kora_id })),
      onError: () => {
        feedbackInit({
          message: 'There has been an error getting all merchants',
          type: 'danger',
          action: {
            action: () => refetchMerchants(),
            name: 'Try again'
          }
        });
      }
    }
  );

  const handleSubmit = async () => {
    if (!merchantName && (!selectedMerchant || !selectedRegions.length)) {
      feedbackInit({ message: 'Please select a merchant and at least one region', componentLevel: true, type: 'danger' });
      throw new Error('Please select a merchant and at least one region');
    }

    if (merchantName && !selectedRegions.length) {
      feedbackInit({ message: 'Please select at least one region', componentLevel: true, type: 'danger' });
      throw new Error('Please select at least one region');
    }

    const countryObjects = selectedRegions.reduce((obj, country) => {
      return {
        ...obj,
        [country]: {
          enable: true
        }
      };
    }, {});

    await grantAccessMutation.mutateAsync({ kora_id: selectedMerchant || koraId, ...countryObjects });
  };

  const isCheckboxDisabled = () => {
    if (merchantName) {
      return disableAllCheckboxes;
    } else return selectedMerchant === undefined || selectedMerchant === null;
  };

  useEffect(() => {
    if (countries?.length) {
      setSelectedRegions(countries);
    }
  }, [countries]);

  const renderContent = () => {
    return (
      <div className="iam-modal">
        <SearchInput
          label={merchantName ? 'Merchant' : 'Search merchant'}
          placeholder="Start typing"
          id="merchant"
          value={selectedMerchant || merchantName}
          onChange={e => setSelectedMerchant(e as number)}
          dropdownData={data}
          setDebouncedSearchInput={setDebouncedSearchInput}
          setShouldRunQuery={setShouldRunQuery}
          isFetching={isFetchingMerchants}
          disabled={!!merchantName}
        />
        <CheckboxInput
          label={
            countries?.length ? (
              <>
                Preferred verification regions/countries
                <ToolTip
                  message="These are the countries selected by this merchant during their access request."
                  className="iam-modal__form-check__tooltip"
                >
                  <Icon name="infoQuestionMark" width={15} height={15} />
                </ToolTip>
              </>
            ) : (
              'In what regions/countries should this merchant be able to verify customers?'
            )
          }
          data={checkboxesToShow}
          id="regions"
          value={selectedRegions}
          onChange={e => setSelectedRegions(e as string[])}
          setChecked={setSelectedRegions}
          borderTop={!merchantName}
          disabled={isCheckboxDisabled()}
          ableToDisableAllCheckboxes={!!merchantName}
          disableAllChecboxesLabel="Allow merchant to verify in all preferred countries"
          onChangeDisableAllCheckboxes={e => setDisableAllCheckboxes(e)}
        />

        {!disableAllCheckboxes && (
          <div className="iam-modal__warning">
            <span>
              <Icon name="warningOrange" />
            </span>
            <p>
              The merchant selected these countries during their access request. If you uncheck any of them, please ensure the merchant is
              informed of this change.
            </p>
          </div>
        )}
      </div>
    );
  };
  return (
    <Modal
      size="md"
      heading={merchantName ? 'Approve merchant access to Identity' : 'Add merchant to Identity service'}
      description={
        merchantName
          ? 'Grant this merchant access to our identity service. Once approved, they will be able to perform verification checks for the selected countries.'
          : 'You can find a merchant and add them to the Identity service. Once added, you can configure their fees and which regions they can perform verification checks.'
      }
      content={renderContent()}
      secondaryCompletedModal
      completedDescription="You have successfully added this merchant to the Identity service."
      completedHeading="Merchant Added!"
      completedActionText=""
      close={close}
      secondButtonText={merchantName ? 'Approve request' : 'Add Merchant'}
      secondButtonAction={handleSubmit}
    />
  );
}

import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { useFeedbackHandler } from '+hooks';
import APIRequest from '+services/api-services';
import { availableProductType, CurrencyType, productCategoriesType } from '+types';
import { productMapping } from '+utils';

import CardIssuanceProductDetails from './CardIssuance';
import DefaultProductDetails from './DefaultProductDetails';

const api = new APIRequest();

const ProductDetails = () => {
  const { feedbackInit } = useFeedbackHandler();
  const { currency, product, feature } = useParams<{
    currency: CurrencyType;
    product: productCategoriesType;
    feature: availableProductType;
  }>();

  const params = {
    payment_method: feature,
    payment_type: productMapping[product]
  };

  const {
    data: configData,
    refetch: refetchConfig,
    isLoading
  } = useQuery([`${currency}_PRODUCT_CONFIG`, currency, product], () => api.getProductConfiguration(currency, params), {
    refetchOnMount: 'always',
    onError: () => {
      feedbackInit({
        message: `There has been an error in getting this merchant's details`,
        type: 'danger',
        action: {
          action: () => refetchConfig(),
          name: 'Try again'
        }
      });
    },
    enabled: product !== 'card-issuance'
  });

  const switchProductDetailsPages = () => {
    switch (product) {
      case 'card-issuance':
        return <CardIssuanceProductDetails isLoading={false} currency={currency} method={feature} product={product} />;
      default:
        return (
          <DefaultProductDetails data={configData?.data} isLoading={isLoading} currency={currency} method={feature} product={product} />
        );
    }
  };

  return switchProductDetailsPages();
};

export default ProductDetails;

import React from 'react';
import classNames from 'classnames';

import './badge.scss';

const Badge = ({ children, status, 'aria-label': ariaLabel }: { children?: React.ReactNode; status: string; 'aria-label'?: string }) => {
  return (
    <span
      className={classNames(`badge-comp badge-comp--${status} text-capitalize`, !children && 'show-name')}
      role="status"
      aria-label={ariaLabel || `Status: ${status}`}
    >
      {children}
    </span>
  );
};

export default Badge;

import React, { useEffect } from 'react';

import CustomCheckbox from '+containers/Dashboard/Shared/CustomCheckbox';
import Icon from '+containers/Dashboard/Shared/Icons';
import ToolTip from '+containers/Dashboard/Shared/Tooltip';
import { IConfigFee } from '+types';
import { formatAmount } from '+utils';

import InfoIcon from '+assets/img/dashboard/question-mark-gray.svg';

const ConfigurationFeeItem = ({
  title,
  currentFee,
  newFee,
  currency,
  onChangeFee,
  newCurrency,
  tooltip,
  modified,
  configType = 'default',
  checked,
  disabled,
  setChecked
}: IConfigFee) => {
  useEffect(() => {
    if (configType === 'reset') {
      setChecked?.(disabled as boolean);
    }
  }, [disabled]);

  const handleFeeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === '') {
      onChangeFee?.(undefined);
    } else {
      const numericValue = parseFloat(value);
      if (numericValue > 0) {
        onChangeFee?.(numericValue);
      }
    }
  };

  return (
    <div className="icf__item">
      <div>
        {configType === 'reset' ? (
          <CustomCheckbox
            className="identity-check icf__item-checkbox"
            text={
              <span className="icf__item-title" title={title}>
                {title}
              </span>
            }
            checked={checked}
            onChange={e => {
              setChecked?.(e as boolean);
            }}
            disabled={disabled}
          />
        ) : (
          <span className="icf__item-title" title={title}>
            {title}
          </span>
        )}
        {tooltip && <ToolTip className="id-tooltip" image={InfoIcon} message={tooltip} />}
        {modified && configType === 'default' ? (
          <ToolTip className="id-tooltip" message="This value has been modified.">
            <Icon name="circledCaution" width={14} />
          </ToolTip>
        ) : null}
      </div>
      <div className="icf__item-current-fee">{`${currency?.toUpperCase() || ''} ${formatAmount(currentFee || 0)}`}</div>
      {configType === 'default' && (
        <div className="icf__item-currency">
          <input type="text" disabled value={newCurrency} aria-label="Currency" />
          <input type="number" value={newFee} onChange={handleFeeChange} placeholder="0.00" aria-label="New Fee" min={1} />
        </div>
      )}
      {configType === 'reset' && (
        <div className="icf__item-current-fee reset">{`${newCurrency?.toUpperCase() || ''} ${formatAmount(newFee || 0)}`}</div>
      )}
    </div>
  );
};
export default ConfigurationFeeItem;

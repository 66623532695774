import React from 'react';

import CustomCheckbox from '+containers/Dashboard/Shared/CustomCheckbox';

export default function UseCaseOption({
  title,
  price,
  currency,
  checked,
  setChecked,
  disabled
}: {
  title: string;
  price: number;
  currency: string;
  checked: boolean;
  setChecked: (checked: boolean) => void;
  disabled?: boolean;
}) {
  return (
    <div className="id-use-case__option">
      <CustomCheckbox
        className="identity-check"
        checked={checked}
        text={title}
        disabled={disabled}
        onChange={e => {
          setChecked(Boolean(e));
        }}
        dataTestId='use-case-option-checkbox'
      />
      <div className="id-use-case__price">
        <span className="currency">{`${currency?.toUpperCase()} ${price}`}</span> <span> / verification</span>
      </div>
    </div>
  );
}

import React from 'react';

const ConfirmationContent = ({ type }: { type: 'configuration' | 'reset' }) => (
  <div className={`irc__confirm-modal-content ${type === 'configuration' ? '' : 'warning'}`}>
    {
      <p>
        {type === 'configuration'
          ? 'Note: Ensure that merchants are capable of being charged in the currencies configured for these fees.'
          : 'Please confirm that you want to reset these fees back to default. This will override this merchant’s current fees with default system fees for the selected ID types.'}
      </p>
    }
  </div>
);

export default ConfirmationContent;

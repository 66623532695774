import React from 'react';

import { ITableData, TransactionType } from '+types';
import { capitalize, capitalizeRemovedash, formatAmount, getDate, getTime, switchStatus } from '+utils';

const transactions: ITableData<TransactionType> = {
  type: 'payouts',
  className: '--bulkpayouts-table',
  rowKey: 'unique_reference',
  rowURL: '/dashboard/bulk-payouts',
  emptyStateHeading: 'No records yet',
  emptyStateMessage: 'There are no bulk payouts yet.',
  annotations: 'transaction(s)',
  fields: each => ({
    data: {
      status: (
        <>
          <span
            className={`status-pill smaller ${
              each?.status === 'complete' ? 'green' : switchStatus(each?.status === 'requires_auth' ? 'pending' : each?.status)
            }`}
          />
          <span>{capitalizeRemovedash(each?.status || 'N/A')}</span>
        </>
      ),
      BulkPayout_id: <span className="trxn-id">{each?.batch_reference || 'N/A'}</span>,
      merchant: <span className="merch-name">{capitalize(each?.account?.name || 'Not Available')}</span>,
      amount: (
        <>
          <span>
            <strong>{formatAmount(each?.amount)}</strong>
          </span>
          <span className="annotation">{each?.currency}</span>
        </>
      ),
      'Date / Time': (
        <>
          <span>{getDate(each?.created_at)}</span>
          <span className="annotation">{getTime(each?.created_at)}</span>
        </>
      )
    }
  })
};

const BulkPayoutHelpers = {
  transactions
};

export default BulkPayoutHelpers;

import React from 'react';

import CustomCheckbox from '+containers/Dashboard/Shared/CustomCheckbox';
import { ICheckboxInput } from '+types';

export default function CheckboxInput({
  label,
  value,
  id,
  data,
  onChange,
  borderTop,
  setChecked,
  disabled = false,
  ableToDisableAllCheckboxes,
  disableAllChecboxesLabel,
  onChangeDisableAllCheckboxes
}: ICheckboxInput) {
  const isDisableAllCheckboxesDisabled = !data?.length || data?.length === 1;
  return (
    <div className={`iam-modal__form-group ${borderTop ? 'top-border' : ''}`}>
      {label && <label htmlFor={id}>{label}</label>}
      {ableToDisableAllCheckboxes && (
        <CustomCheckbox
          className="identity-check"
          checked={disabled}
          text={disableAllChecboxesLabel}
          disabled={isDisableAllCheckboxesDisabled}
          onChange={e => {
            onChangeDisableAllCheckboxes?.(Boolean(e));
          }}
        />
      )}
      {data?.map(each => (
        <CustomCheckbox
          className="identity-check"
          checked={(value as string[]).includes(each.value)}
          disabled={disabled}
          text={each.label}
          onChange={e => {
            if (e) {
              setChecked([...(value as string[]), each.value]);
            } else {
              setChecked((value as string[]).filter(value => value !== each.value));
            }
          }}
        />
      ))}
    </div>
  );
}

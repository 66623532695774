import React from "react";
import UseCaseOption from "./UseCaseOption";
import { KAASFeeType } from "+types";
export default function UseCaseOptions({ disabled, options, updateUseCase }: { disabled?: boolean; options: KAASFeeType[]; updateUseCase: (useCase: KAASFeeType) => void }) {
  return (
    <div className="id-use-case__options">
      {options?.map((option) => (
        <UseCaseOption
          key={option.key}
          title={option.title}
          price={option.currentFee}
          currency={option.currency as string}
          checked={Boolean(option.selected)}
          setChecked={() => {
            updateUseCase({ ...option, selected: !option.selected });
          }}
          disabled={disabled}
        />
      ))}
    </div>
  );
}

export const riskLevelHeadings = [
  { label: 'Low', value: 'low_risk' },
  { label: 'Medium', value: 'medium_risk' },
  { label: 'Semi-high', value: 'above_average_risk' },
  { label: 'High', value: 'high_risk' }
] as const;

export const pciDssLevelHeadings = [
  { label: '0', value: 'level_0' },
  { label: '1', value: 'level_1' },
  { label: '2', value: 'level_2' },
  { label: '3', value: 'level_3' },
  { label: '4', value: 'level_4' }
] as const;

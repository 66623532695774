import {
  ActionType,
  FeeCategoryType,
  IFeeProps,
  IPlan,
  PlansFormikBagType,
  PlansInitialValuesType,
  StepType,
  TPVCollectionType
} from '+types';

export const actionHeadings = {
  edit: 'Edit plan',
  add: 'Add new plan'
};

export const actionDescriptions = {
  edit: 'Provide the details below to make change to a new subscription plan.',
  add: 'Provide the details below to create a new subscription plan.'
};

export const confirmationHeadings = {
  edit: 'Confirm change(s) to plan',
  add: 'Confirm new plan'
};

export const confirmationDescriptions = {
  edit: 'Once you confirm, subscription plan will be updated and implemented for issuing merchants assigned under it.',
  add: 'Once you confirm, a new subscription plan will be created and issuing merchants can be assigned under it.'
};

export const feeTypes = [
  { label: '-- select fee type --', value: '' },
  { label: 'Flat fee', value: 'flat' },
  { label: 'Percentage fee', value: 'percentage' }
];

export const feeCategories: Array<{ fee: FeeCategoryType; prefix: string; subtitle: string }> = [
  { fee: 'subscription', prefix: 'Subscription', subtitle: 'This is the fee charged to the merchant to access this service.  ' },
  { fee: 'issuance', prefix: 'Card Issuing', subtitle: 'This is the fee charged to the merchant to create a new card.  ' },
  { fee: 'funding', prefix: 'Card Funding', subtitle: 'This is the fee charged to the merchant to fund a card.  ' },
  { fee: 'withdrawal', prefix: 'Withdrawal', subtitle: 'This is the fee charged to the merchant to withdraw from a card. ' },
  {
    fee: 'cross_currency',
    prefix: 'Cross-currency',
    subtitle: 'This is the fee charged to the merchant each time a card is used to transact in a foreign currency  '
  },
  {
    fee: 'chargeback',
    prefix: 'Chargeback',
    subtitle: 'This is the fee charged to the merchant when there’s a dispute to a charge on an issued card.'
  }
];

export const formSectionIsComplete = (sectionErrors: IFeeProps) => {
  if (sectionErrors?.max) return false;
  if (sectionErrors?.type) return false;
  if (sectionErrors?.amount) return false;
  if (sectionErrors?.vat_inclusive) return false;
  if (sectionErrors?.charge_interval) return false;
  return true;
};

// Initialize form values
export const initializeValues = (data: IPlan, action: ActionType) => {
  const {
    fee,
    max_payment_value,
    min_payment_value,
    reserved_card_max_payment_value,
    reserved_card_min_payment_value,
    monthly_card_limit,
    reserved_card_limit,
    ...otherProps
  } = (data || {}) as IPlan;

  switch (action) {
    case 'edit':
      return {
        reserved: {
          max_tpv: reserved_card_max_payment_value === null ? 'Infinity' : reserved_card_max_payment_value,
          min_tpv: reserved_card_min_payment_value,
          card_limit: reserved_card_limit,
          fee: { ...fee?.reserved, security_reserve: { type: 'flat', amount: '0', vat_inclusive: false, charge_interval: 'once' } }
        },
        customer: {
          max_tpv: max_payment_value === null ? 'Infinity' : max_payment_value,
          min_tpv: min_payment_value,
          card_limit: monthly_card_limit,
          fee: {
            ...fee?.customer
          }
        },
        ...otherProps
      };
    case 'add':
    default:
      return {
        card_type: 'virtual',
        currency: 'USD',
        name: '',
        reference: '',
        reserved: {
          fee: {
            funding: { active: false, type: 'flat', amount: '0', vat_inclusive: false, charge_interval: 'once' },
            issuance: { active: false, type: 'flat', amount: '0', vat_inclusive: false, charge_interval: 'once' },
            chargeback: { active: false, type: 'flat', amount: '0', vat_inclusive: false, charge_interval: 'once' },
            withdrawal: { active: false, type: 'flat', amount: '0', vat_inclusive: false, charge_interval: 'once' },
            subscription: { active: false, type: 'flat', amount: '0', vat_inclusive: false, charge_interval: 'once' },
            cross_currency: { active: false, type: 'flat', amount: '0', vat_inclusive: false, charge_interval: 'once' }
          },
          max_tpv: '0',
          min_tpv: '0',
          card_limit: '0'
        },
        customer: {
          fee: {
            funding: { active: false, type: 'flat', amount: '0', vat_inclusive: false, charge_interval: 'once' },
            issuance: { active: false, type: 'flat', amount: '0', vat_inclusive: false, charge_interval: 'once' },
            chargeback: { active: false, type: 'flat', amount: '0', vat_inclusive: false, charge_interval: 'once' },
            withdrawal: { active: false, type: 'flat', amount: '0', vat_inclusive: false, charge_interval: 'once' },
            subscription: { active: false, type: 'flat', amount: '0', vat_inclusive: false, charge_interval: 'once' },
            cross_currency: { active: false, type: 'flat', amount: '0', vat_inclusive: false, charge_interval: 'once' }
          },
          max_tpv: '0',
          min_tpv: '0',
          card_limit: '0'
        }
      } as PlansInitialValuesType;
  }
};

type PartialWithExtractedKeys<T> = Partial<Record<keyof T, unknown>>;
// eslint-disable-next-line consistent-return
export const validate = ({
  values,
  TPVCollection,
  step,
  planName
}: {
  values: PlansInitialValuesType;
  TPVCollection: TPVCollectionType;
  step: StepType;
  planName: string;
}) => {
  const errors: PartialWithExtractedKeys<PlansInitialValuesType> = {};
  const customerErrors: PartialWithExtractedKeys<PlansInitialValuesType['customer']> = {};
  const reservedErrors: PartialWithExtractedKeys<PlansInitialValuesType['reserved']> = {};
  const feeErrors: PartialWithExtractedKeys<PlansInitialValuesType['customer']['fee']> = {};
  const withdrawalErrors: PartialWithExtractedKeys<PlansInitialValuesType['customer']['fee']['withdrawal']> = {};
  const subscriptionErrors: PartialWithExtractedKeys<PlansInitialValuesType['customer']['fee']['subscription']> = {};
  const fundingErrors: PartialWithExtractedKeys<PlansInitialValuesType['customer']['fee']['funding']> = {};
  const issuanceErrors: PartialWithExtractedKeys<PlansInitialValuesType['customer']['fee']['issuance']> = {};
  const crossCurrencyErrors: PartialWithExtractedKeys<PlansInitialValuesType['customer']['fee']['cross_currency']> = {};
  const chargebackErrors: PartialWithExtractedKeys<PlansInitialValuesType['customer']['fee']['chargeback']> = {};

  const { customer, reserved } = TPVCollection;

  if (step === 'setIssuedCardFees') {
    if (!values.name) errors.name = 'Name is required';
    if (values.customer.card_limit === '') customerErrors.card_limit = 'Monthly card limit is required';

    // TPV validation
    if (!values.customer.max_tpv || values.customer.max_tpv === '0') customerErrors.max_tpv = 'Max TPV is required';
    if (!values.customer.min_tpv || values.customer.min_tpv === '0') customerErrors.min_tpv = 'Min TPV is required';
    if (values.customer.min_tpv === values.customer.max_tpv) customerErrors.min_tpv = 'TPV values cannot be equal';

    if (Number(values.customer.max_tpv) < Number(values.customer.min_tpv)) customerErrors.max_tpv = 'Max TPV cannot be less than min TPV';

    // check for plan tpv overlap
    for (let i = 0; i < customer.length; i++) {
      if (planName !== customer[i].plan) {
        if (
          (Number(values.customer.min_tpv) >= Number(customer[i].tpv[0]) &&
            Number(values.customer.max_tpv) <= Number(customer[i].tpv[1])) ||
          (Number(values.customer.max_tpv) >= Number(customer[i].tpv[0]) &&
            Number(values.customer.min_tpv) <= Number(customer[i].tpv[0])) ||
          (Number(values.customer.max_tpv) >= Number(customer[i].tpv[1]) && Number(values.customer.min_tpv) <= Number(customer[i].tpv[1]))
        ) {
          customerErrors.max_tpv = `TPV conflicts with '${customer[i].plan}' plan`;
          break;
        }
      }
    }
    if (Object.keys(customerErrors).length) errors.customer = customerErrors;

    if (values.customer.fee.subscription.amount === '') subscriptionErrors.amount = 'Fee is required';
    if (!values.customer.fee.subscription.type) subscriptionErrors.type = 'Fee type is required';
    if (values.customer.fee.subscription.max === '') subscriptionErrors.max = 'Max capped fee is required';
    if (Object.keys(subscriptionErrors).length) feeErrors.subscription = subscriptionErrors;

    if (values.customer.fee.withdrawal.amount === '') withdrawalErrors.amount = 'Fee is required';
    if (!values.customer.fee.withdrawal.type) withdrawalErrors.type = 'Fee type is required';
    if (values.customer.fee.withdrawal.max === '') withdrawalErrors.max = 'Max capped fee is required';
    if (Object.keys(withdrawalErrors).length) feeErrors.withdrawal = withdrawalErrors;

    if (values.customer.fee.funding.amount === '') fundingErrors.amount = 'Fee is required';
    if (!values.customer.fee.funding.type) fundingErrors.type = 'Fee type is required';
    if (values.customer.fee.funding.max === '') fundingErrors.max = 'Max capped fee is required';
    if (Object.keys(fundingErrors).length) feeErrors.funding = fundingErrors;

    if (values.customer.fee.issuance.amount === '') issuanceErrors.amount = 'Fee is required';
    if (!values.customer.fee.issuance.type) issuanceErrors.type = 'Fee type is required';
    if (values.customer.fee.issuance.max === '') issuanceErrors.max = 'Max capped fee is required';
    if (Object.keys(issuanceErrors).length) feeErrors.issuance = issuanceErrors;

    if (values.customer.fee.cross_currency.amount === '') crossCurrencyErrors.amount = 'Fee is required';
    if (!values.customer.fee.cross_currency.type) crossCurrencyErrors.type = 'Fee type is required';
    if (values.customer.fee.cross_currency.max === '') crossCurrencyErrors.max = 'Max capped fee is required';
    if (Object.keys(crossCurrencyErrors).length) feeErrors.cross_currency = crossCurrencyErrors;

    if (values.customer.fee.chargeback.amount === '') chargebackErrors.amount = 'Fee is required';
    if (!values.customer.fee.chargeback.type) chargebackErrors.type = 'fee type is required';
    if (values.customer.fee.chargeback.max === '') chargebackErrors.max = 'Max capped fee is required';
    if (Object.keys(chargebackErrors).length) feeErrors.cross_currency = chargebackErrors;

    if (Object.keys(feeErrors).length) customerErrors.fee = feeErrors;
    if (Object.keys(customerErrors).length) errors.customer = customerErrors;

    return errors;
  }

  if (step === 'setReservedCardFees') {
    // Reserved card fee validation
    if (values.reserved.card_limit === '') reservedErrors.card_limit = 'Monthly card limit is required';
    if (!values.reserved.max_tpv || values.reserved.max_tpv === '0') reservedErrors.max_tpv = 'Max TPV is required';
    if (!values.reserved.min_tpv || values.reserved.min_tpv === '0') reservedErrors.min_tpv = 'Min TPV is required';
    if (values.reserved.min_tpv === values.reserved.max_tpv) reservedErrors.min_tpv = 'TPV values cannot be equal';
    if (Number(values.reserved.max_tpv) < Number(values.reserved.min_tpv)) reservedErrors.max_tpv = 'Max TPV cannot be less than min TPV';

    // check for plan tpv overlap
    for (let i = 0; i < reserved.length; i++) {
      if (planName !== reserved[i].plan) {
        if (
          (Number(values.reserved.min_tpv) >= Number(reserved[i].tpv[0]) &&
            Number(values.reserved.max_tpv) <= Number(reserved[i].tpv[1])) ||
          (Number(values.reserved.max_tpv) >= Number(reserved[i].tpv[0]) &&
            Number(values.reserved.min_tpv) <= Number(reserved[i].tpv[0])) ||
          (Number(values.reserved.max_tpv) >= Number(reserved[i].tpv[1]) && Number(values.reserved.min_tpv) <= Number(reserved[i].tpv[1]))
        ) {
          reservedErrors.max_tpv = `TPV conflicts with '${reserved[i].plan}' plan`;
          break;
        }
      }
    }

    if (values.reserved.fee.subscription.amount === '') subscriptionErrors.amount = 'Fee is required';
    if (!values.reserved.fee.subscription.type) subscriptionErrors.type = 'Fee type is required';
    if (values.reserved.fee.subscription.max === '') subscriptionErrors.max = 'Max capped fee is required';
    if (Object.keys(subscriptionErrors).length) feeErrors.subscription = subscriptionErrors;

    if (values.reserved.fee.withdrawal.amount === '') withdrawalErrors.amount = 'Fee is required';
    if (!values.reserved.fee.withdrawal.type) withdrawalErrors.type = 'Fee type is required';
    if (values.reserved.fee.withdrawal.max === '') withdrawalErrors.max = 'Max capped fee is required';
    if (Object.keys(withdrawalErrors).length) feeErrors.withdrawal = withdrawalErrors;

    if (values.reserved.fee.funding.amount === '') fundingErrors.amount = 'Fee is required';
    if (!values.reserved.fee.funding.type) fundingErrors.type = 'Fee type is required';
    if (values.reserved.fee.funding.max === '') fundingErrors.max = 'Max capped fee is required';
    if (Object.keys(fundingErrors).length) feeErrors.funding = fundingErrors;

    if (values.reserved.fee.issuance.amount === '') issuanceErrors.amount = 'Fee is required';
    if (!values.reserved.fee.issuance.type) issuanceErrors.type = 'Fee type is required';
    if (values.reserved.fee.issuance.max === '') issuanceErrors.max = 'Max capped fee is required';
    if (Object.keys(issuanceErrors).length) feeErrors.issuance = issuanceErrors;

    if (values.reserved.fee.cross_currency.amount === '') crossCurrencyErrors.amount = 'Fee is required';
    if (!values.reserved.fee.cross_currency.type) crossCurrencyErrors.type = 'Fee type is required';
    if (values.reserved.fee.cross_currency.max === '') crossCurrencyErrors.max = 'Max capped fee is required';
    if (Object.keys(crossCurrencyErrors).length) feeErrors.cross_currency = crossCurrencyErrors;

    if (values.reserved.fee.chargeback.amount === '') chargebackErrors.amount = 'Fee is required';
    if (!values.reserved.fee.chargeback.type) chargebackErrors.type = 'fee type is required';
    if (values.reserved.fee.chargeback.max === '') chargebackErrors.max = 'Max capped fee is required';
    if (Object.keys(chargebackErrors).length) feeErrors.cross_currency = chargebackErrors;

    if (Object.keys(feeErrors).length) reservedErrors.fee = feeErrors;
    if (Object.keys(reservedErrors).length) errors.reserved = reservedErrors;

    return errors;
  }

  // Plan name validation and issuable cards validation
  if (!values.name) errors.name = 'Name is required';
  return errors;
};

export const getTPVCollection = (plans: IPlan[]) =>
  plans.reduce<TPVCollectionType>(
    (acc, next) => ({
      customer: [...acc.customer, { plan: next.name, tpv: [next.min_payment_value, next.max_payment_value] }],
      reserved: [...acc.reserved, { plan: next.name, tpv: [next.reserved_card_min_payment_value, next.reserved_card_max_payment_value] }]
    }),
    { customer: [], reserved: [] }
  );

import React from 'react';
import { Prompt } from 'react-router-dom';

import Modal from '+containers/Dashboard/Shared/Modal';

import useNavigationInterceptor from '../../hooks/useNavigationInterceptor';

export default function NavigationBlocker({ blockNavigation }: { blockNavigation: boolean }) {
  const { isModalOpen, handleBlockedNavigation, handleConfirmNavigationClick, handleCancelNavigationClick } =
    useNavigationInterceptor();
  return (
    <div>
      <Prompt when={blockNavigation} message={handleBlockedNavigation} />
      {isModalOpen && (
        <Modal
          heading="Discard changes?"
          description="Any unsaved changes you might have made will be discarded if you go back."
          firstButtonText="Cancel"
          secondButtonText="Discard Changes"
          size="md"
          secondButtonAction={() => handleConfirmNavigationClick()}
          close={handleCancelNavigationClick}
          firstButtonAction={handleCancelNavigationClick}
          content=""
          secondButtonActionIsTerminal={false}
        />
      )}
    </div>
  );
}

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';

import Icon from '+containers/Dashboard/Shared/Icons';
import { useFeedbackHandler } from '+hooks';
import APIRequest from '+services/api-services';
import Modal, { IModalProps } from '+shared/Modal';
import { CardStatusTogglePayloadType, IssuingMerchantDetailsResponseType, ModifyAccessPayloadType } from '+types';
import { logError } from '+utils';

type ActionType = 'enable' | 'disable';

const apiRequest = new APIRequest();

const CardsStatusToggleModal = ({
  onClose,
  action,
  cardCategory,
  payload,
  onToggleSuccess
}: {
  onClose: () => void;
  action: ActionType;
  cardCategory: 'reserved' | 'customer';
  payload: CardStatusTogglePayloadType;
  onToggleSuccess: () => void;
}) => {
  const isReserved = cardCategory === 'reserved';
  const { feedbackInit, closeFeedback } = useFeedbackHandler();
  const queryClient = useQueryClient();

  const modifyAccess = useMutation((payload: ModifyAccessPayloadType) => apiRequest.modifyAccess(payload), {
    onMutate: async () => {
      const queryKeyPrefix = 'MERCHANT_DETAILS';
      await queryClient.cancelQueries({
        predicate: query => Array.isArray(query.queryKey) && query.queryKey[0] === queryKeyPrefix
      });

      const queriesData = queryClient.getQueriesData([queryKeyPrefix]);
      const previousDataMap = new Map(queriesData);

      queriesData.forEach(([fullQueryKey, data]) => {
        if (data) {
          queryClient.setQueryData(
            fullQueryKey,
            (old: IssuingMerchantDetailsResponseType | undefined): IssuingMerchantDetailsResponseType => {
              const baseData = (old || data) as IssuingMerchantDetailsResponseType;

              if (!baseData?.config?.[isReserved ? 'reserved' : 'customer']) {
                return baseData;
              }

              return {
                ...baseData,
                config: {
                  ...baseData.config,
                  [isReserved ? 'reserved' : 'customer']: {
                    ...baseData.config[isReserved ? 'reserved' : 'customer'],
                    status: action === 'enable' ? 'active' : 'inactive'
                  }
                }
              };
            }
          );
        }
      });

      return { previousDataMap };
    },
    onSuccess: data => feedbackInit({ message: data?.message, type: 'success' }),
    onError: (error, _, context) => {
      if (context?.previousDataMap) {
        for (const [fullQueryKey, previousData] of context.previousDataMap.entries()) {
          queryClient.setQueryData(fullQueryKey, previousData);
        }
      }
      logError(error);
      feedbackInit({
        message: error.response?.data?.message || 'Action was unsuccessful',
        type: 'danger',
        componentLevel: true
      });
    },
    onSettled: () => {
      setTimeout(() => {
        closeFeedback();
      }, 5000);
    }
  });

  const handleToggleCardStatus = async () => {
    await modifyAccess.mutateAsync({ ...payload, access_type: 'card', action, card_type: 'virtual' });
  };

  const actionToProps: Record<ActionType, Partial<IModalProps>> = {
    enable: {
      heading: `Enable access to ${isReserved ? 'Reserved Virtual Cards (RVCs)' : 'Issued Cards'}`,
      description: (
        <div>
          You&apos;re about to enable access to {isReserved ? 'RVCs' : 'Issued cards (Customers)'} for this merchant
          <br />
          <br />
          Once enabled, the merchant will regain full access to manage {isReserved ? 'RVCs' : 'their Customer Cards'}.
        </div>
      ),
      secondButtonText: 'Enable',
      completedDescription: `You have successfully enabled access to ${isReserved ? 'Reserved Virtual' : 'Issued'} Cards for this merchant.`
    },
    disable: {
      heading: `Disable access to ${isReserved ? 'Reserved Virtual Cards (RVCs)' : 'Issued Cards (Customers)'}`,
      description: `Disabling ${isReserved ? 'RVCs' : 'Issued Cards (Customers)'} will revoke this merchant's access to manage their ${
        isReserved ? 'RVCs' : 'customer cards'
      }. All active cards will be suspended immediately.`,
      content: (
        <div className="info">
          <Icon name="infoSolid" fill="#2376F3" />
          <span style={{ color: '#2376F3' }}>
            Important: This means that this merchant won’t be able to issue any new cards and their {cardCategory} virtual cards will be
            suspended.
          </span>
        </div>
      ),
      secondButtonStyles: { backgroundColor: '#F32345' },
      secondButtonText: 'Disable',
      completedDescription: `You have successfully disabled access to ${
        isReserved ? 'Reserved Virtual' : 'Issued'
      } Cards for this merchant.`
    }
  };

  return (
    <Modal
      {...(actionToProps[action] as IModalProps)}
      firstButtonText="Back"
      size="sm"
      secondButtonActionIsTerminal
      close={() => {
        if (modifyAccess.isSuccess) onToggleSuccess();
        onClose();
      }}
      secondaryCompletedModal
      secondButtonAction={handleToggleCardStatus}
      equalFooterBtn
    />
  );
};

export default CardsStatusToggleModal;

import React from 'react';

import './index.scss';

interface ICustomSwitchProps {
  value: boolean;
  id: string;
  onChange: () => void;
  width?: number;
  backgroundColor?: string;
  height?: number;
  disabled?: boolean;
}

export default function CustomSwitch({ value, id, onChange, width = 40, height = 20, backgroundColor = '#24B314', disabled }: ICustomSwitchProps) {
  const sliderSize = height - 4; // Adjust the size of the slider circle

  return (
    <label className={`custom-switch ${disabled ? 'custom-switch--disabled' : ''}`} htmlFor={id} style={{ width, height }} data-testid={`custom-switch-${id}`}>
      <input type="checkbox" id={id} checked={value} onChange={onChange} disabled={disabled} aria-label="Custom Switch" name={id} />
      <span
        className="custom-switch-slider"
        style={{
          borderRadius: height / 2,
          ...{ '--slider-size': `${sliderSize}px` },
          ...{ '--translate-x': `${width - height}px` },
          ...{ '--slider-bg': backgroundColor }
        }}
      ></span>
    </label>
  );
}

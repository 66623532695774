import { CurrencyType } from '+types';

export interface IMerchantData {
  id: number;
  name: string;
  description: string;
  avatar: string | null;
  kora_id: number;
  kora_account_id: number;
  is_active: number;
  email: string;
  risk_level: string;
  type: number;
  kora_core_engine_id: number;
  payment_channel_type: string;
  can_go_live: boolean;
  env: string;
  configuration: string | null;
  display_support_email: boolean;
  status: string;
  kyc_status: string;
  country_id: number;
  sra: string;
  tier_level_id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  country: {
    name: string;
    iso2: string;
    is_active: boolean;
  };
  tier_level: {
    name: string;
    description: string | null;
  };
  default_currency: string;
  website: string;
  support_email: string | null;
  support_phone: string;
  reserved_bank_account: string | null;
  can_do_disbursements: boolean;
  can_do_card_tokenization: boolean;
  can_do_collections: boolean;
  fetching_status: string;
  settlements: {
    [code: string]: {
      destination: string;
      cycle: {
        [features: string]: string | number;
      };
      rolling_reserve: Record<string, Record<string, { [id: string]: number }>>;
      updated_at: string;
    };
  };
}
export type GenerateBalanceStatementModalType = 'generate_balance_statement' | 'custom_date_range';
export interface IGenerateBalanceStatementModalProps {
  isVisible: boolean;
  onClose: () => void;
  currency: CurrencyType;
  merchantId: string;
}
export const generateBalanceStatementModalStages = {
  generate_balance_statement: 'generate_balance_statement',
  custom_date_range: 'custom_date_range'
} as const satisfies Record<GenerateBalanceStatementModalType, GenerateBalanceStatementModalType>;

export const generateBalanceStatementPeriodOptions = [
  { value: 'last_7_days', label: 'Last 7 Days' },
  { value: 'last_1_month', label: 'Last 1 Month' },
  { value: 'last_3_months', label: 'Last 3 Months' },
  { value: 'custom_range', label: 'Custom Range' }
] as const;
export type GenerateBalanceStatementPeriodType = (typeof generateBalanceStatementPeriodOptions)[number]['value'];
export interface IGenerateBalanceStatementForm {
  periodSelected: string;
  isOpen: boolean;
  modal: GenerateBalanceStatementModalType;
  start_date: string;
  end_date: string;
  showCalendarIcon: boolean;
}

export type GenerateBalanceStatementPayLoadType = {
  dateFrom: string;
  dateTo: string;
  currency: CurrencyType;
};
export interface IMerchantBalanceSubActionTab {
  actions: () => void;
  label: string;
  classNames: string;
}

import React, { useEffect } from 'react';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';

import CustomCheckbox from '+containers/Dashboard/Shared/CustomCheckbox';
import Modal from '+containers/Dashboard/Shared/Modal';
import { useFeedbackHandler } from '+hooks';
import APIRequest from '+services/api-services';
import { AccessDataType, MerchantCountryAccessAPIPayloadType } from '+types';
import { identityCountries } from '+utils';

import { arraysEqual, getSelectedCountries } from '../../helpers/configurationHelper';
import CountrySwitchList from './CountrySwitchList';

import './index.scss';

type ManageStageType = 'manage' | 'confirmation';

const api = new APIRequest(process.env.REACT_APP_MIDDLEWARE_API_BASE);

export default function ManageCountriesModal({
  close,
  merchantId = 'default',
  externalRegions,
  refetch
}: {
  close: () => void;
  merchantId?: string;
  externalRegions?: AccessDataType;
  refetch?: () => void;
}) {
  const { feedbackInit } = useFeedbackHandler();
  const [manageStage, setManageStage] = React.useState<ManageStageType>('manage');
  const [disableIdentityVerification, setDisableIdentityVerification] = React.useState<boolean>(false);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [initialSelected, setInitialSelected] = React.useState<string[]>([]);
  useEffect(() => {
    if (externalRegions) {
      const selectedCountries = getSelectedCountries(externalRegions);
      setSelected(selectedCountries);
      setInitialSelected(selectedCountries);
    }
  }, []);
  const updatedCountries = arraysEqual(selected, initialSelected);
  const grantCountryAccessMutation = useMutation(
    (data: MerchantCountryAccessAPIPayloadType) => api.grantMerchantCountryAccess(merchantId as string, data),
    {
      onError: (error: AxiosError) => {
        feedbackInit({
          message: (error as { response: { data: { message: string } } }).response?.data.message,
          componentLevel: true,
          type: 'danger'
        });
      },
      onSuccess: () => {
        refetch?.();
      }
    }
  );
  const grantGlobalCountryAccessMutation = useMutation((data: MerchantCountryAccessAPIPayloadType) => api.grantGlobalCountryAccess(data), {
    onError: (error: AxiosError) => {
      feedbackInit({
        message: (error as { response: { data: { message: string } } }).response?.data.message,
        componentLevel: true,
        type: 'danger'
      });
    },
    onSuccess: () => {
      refetch?.();
    }
  });
  const renderContent = (stage: ManageStageType) => {
    if (stage === 'confirmation') {
      return (
        <div className="imc-modal__confirm-modal-content">
          <p>These changes will take effect immediately and may impact merchants' ability to verify identities in the affected regions.</p>
        </div>
      );
    }
    return (
      <div>
        <CustomCheckbox
          className="identity-check"
          checked={disableIdentityVerification}
          text={'Disable identity verification in all countries.'}
          onChange={() => setDisableIdentityVerification(!disableIdentityVerification)}
        />

        <CountrySwitchList disabled={disableIdentityVerification} selected={selected} setSelected={setSelected} />
      </div>
    );
  };

  const handleSecondButtonAction = () => {
    if (manageStage === 'confirmation') {
      return async () => {
        const newData = identityCountries.reduce((acc, country) => {
          acc[country.value as keyof Omit<AccessDataType, 'kora_id' | 'enable'>] = {
            enable: selected.includes(country.value)
          };
          return acc;
        }, {} as MerchantCountryAccessAPIPayloadType);
        if (merchantId === 'default') {
          await grantGlobalCountryAccessMutation.mutateAsync(newData as MerchantCountryAccessAPIPayloadType);
        } else {
          await grantCountryAccessMutation.mutateAsync(newData as MerchantCountryAccessAPIPayloadType);
        }
      };
    }
    return async () => setManageStage('confirmation');
  };

  const configurationModal = (type: ManageStageType) => {
    const content = {
      heading: type === 'manage' ? 'Manage country verifications' : 'Confirm changes to country verifications',
      description:
        type === 'manage'
          ? 'Select the countries where identity verification should be enabled. Use the toggles to turn verification on or off for each country. Changes take effect immediately after saving.'
          : 'You have updated the country identity verification settings.',
      content: renderContent(type),
      firstButtonText: 'Cancel',
      secondButtonText: type === 'manage' ? 'Save' : 'Confirm',
      secondButtonAction: handleSecondButtonAction(),
      headerBottomBorder: true,
      completedHeading: `Saved!`,
      completedDescription: `You have successfully updated the country identity verification settings.`,
      secondaryCompletedModal: type === 'confirmation',
      secondButtonActionIsTerminal: type === 'confirmation',
      secondButtonDisable: type === 'manage' ? updatedCountries : grantCountryAccessMutation.isLoading
    };

    return content;
  };

  return <Modal size="md" {...configurationModal(manageStage)} close={close} isScrollable />;
}

import React from 'react';

import { filterButton, FilterOption } from '+types';
import { capitalizeFirst, history } from '+utils';

const holderNameValidation = (name = '') => {
  const namesCount = name.trim().split(' ')?.length;
  if (namesCount < 2) {
    return 'Please enter both first name and last name';
  }
  if (namesCount > 2) {
    return 'Please enter only first name and last name';
  }
  return '';
};

const cardNumEndingValidation = num => {
  if (`${num}`.length < 4) {
    return 'Please enter a 4-digit number';
  }
  if (!`${num}`.match(/^[0-9]+$/)) {
    return 'Please enter a valid 4-digit number';
  }
};

export const options = {
  'pay-in': [
    { label: 'All Statuses', value: '' },
    { label: 'Success', value: 'success' },
    { label: 'Failed', value: 'failed' },
    { label: 'Processing', value: 'processing' },
    { label: 'Underpaid', value: 'underpaid' },
    { label: 'Overpaid', value: 'overpaid' },
    { label: 'Rejected', value: 'rejected' },
    { label: 'Expired', value: 'expired' },
    { label: 'Flagged Success', value: 'flagged_success' },
    { label: 'Flagged', value: 'flagged' },
    { value: 'pre_authorized', label: 'Pre-Authorized' },
    { value: 'void_authorization', label: 'Voided (Auth)' },
    { value: 'void_capture', label: 'Voided (Capture)' }
  ],
  'pay-outs': [
    { label: 'All Statuses', value: '' },
    { label: 'Success', value: 'success' },
    { label: 'Failed', value: 'failed' },
    { label: 'Pending', value: 'pending' },
    { label: 'Processing', value: 'processing' },
    { label: 'Requires Auth', value: 'requires_auth' },
    { label: 'Abandoned', value: 'abandoned' },
    { label: 'Paused', value: 'paused' },
    { label: 'Reversed', value: 'reversed' }
  ],
  'settlements-payouts': [
    { value: '', label: 'All' },
    { label: 'Success', value: 'success' },
    { label: 'Failed', value: 'failed' },
    { label: 'Processing', value: 'processing' },
    { label: 'Pending', value: 'pending' }
  ],
  reversals: [
    { value: '', label: 'All Statuses' },
    { label: 'Success', value: 'success' },
    { label: 'Failed', value: 'failed' },
    { label: 'Processing', value: 'processing' },
    { label: 'Pending', value: 'pending' },
    { label: 'Pending Approval', value: 'pending_approval' },
    { label: 'Manual', value: 'manual' }
  ],
  payout_reversals: [
    { value: '', label: 'All Statuses' },
    { label: 'Reversed', value: 'approved' },
    { label: 'Declined', value: 'declined' },
    { label: 'Pending Approval', value: 'pending_approval' }
  ],
  merchant: [
    { value: '', label: 'All' },
    { label: 'Active', value: 'active' },
    { label: 'Deactivated', value: 'deactivated' },
    { label: 'Suspended', value: 'suspended' },
    { label: 'Email Unverified', value: 'email_unverified' },
    { label: 'Unsupported', value: 'unsupported' }
  ],
  'payment-preferences': [
    { value: '', label: 'All' },
    { label: 'Approved', value: 'approved' },
    { label: 'Rejected', value: 'rejected' },
    { label: 'Pending', value: 'pending' }
  ],
  users: [
    { label: 'All', value: '' },
    { label: 'Active', value: 'active' },
    { label: 'Suspended', value: 'suspended' }
  ],
  invited_users: [
    { label: 'All', value: '' },
    { label: 'Pending', value: 'pending' },
    { label: 'Cancelled', value: 'cancelled' },
    { label: 'Expired', value: 'expired' }
  ],
  chargebacks: [
    { label: 'All', value: '' },
    { label: 'Accepted', value: 'accepted' },
    { label: 'Pending', value: 'pending' },
    { label: 'Declined', value: 'declined' },
    { label: 'Partial', value: 'partial' },
    { label: 'Auto Accepted', value: 'auto_accepted' }
  ],
  'issued-cards': [
    { label: 'All Statuses', value: '' },
    { label: 'Active', value: 'active' },
    { label: 'Suspended', value: 'suspended' },
    { label: 'Expired', value: 'expired' },
    { label: 'Terminated', value: 'terminated' },
    { label: 'Require Authentication', value: 'require_auth' }
  ],
  'cards-transactions': [
    { label: 'All Statuses', value: '' },
    { label: 'Processing', value: 'processing' },
    { label: 'Pending', value: 'pending' },
    { label: 'Success', value: 'success' },
    { label: 'Failed', value: 'failed' },
    { label: 'Declined', value: 'declined' },
    { label: 'Reversed', value: 'reversed' },
    { label: 'Abandoned', value: 'abandoned' },
    { label: 'Require Authentication', value: 'require_auth' }
  ],
  'card-details-transactions': [
    { label: 'All Statuses', value: '' },
    { label: 'Processing', value: 'processing' },
    { label: 'Pending', value: 'pending' },
    { label: 'Success', value: 'success' },
    { label: 'Failed', value: 'failed' },
    { label: 'Declined', value: 'declined' },
    { label: 'Reversed', value: 'reversed' }
  ],
  'issuance-chargebacks': [
    { label: 'All Statuses', value: '' },
    { label: 'Pending', value: 'pending' },
    { label: 'Processing', value: 'processing' },
    { label: 'Invalid', value: 'invalid' },
    { label: 'Declined', value: 'declined' },
    { label: 'Accepted', value: 'accepted' },
    { label: 'Accepted (Partially)', value: 'partially_accepted' },
    { label: 'Pre-Arb Pending', value: 'pending_pre_arbitration' },
    { label: 'Pre-Arb Processing', value: 'processing_pre_arbitration' },
    { label: 'Pre-Arb Invalid', value: 'invalid_pre_arbitration' },
    { label: 'Pre-Arb Declined', value: 'declined_pre_arbitration' },
    { label: 'Pre-Arb Accepted', value: 'accepted_pre_arbitration' },
    { label: 'Pre-Arb Accepted (Partially)', value: 'partially_accepted_pre_arbitration' }
  ],
  'issuing-merchants': [
    { label: 'All Statuses', value: '' },
    { label: 'Active', value: 'active' },
    { label: 'Inactive', value: 'inactive' }
  ],
  refunds: [
    { label: 'All', value: '' },
    { label: 'Pending', value: 'pending' },
    { label: 'Manual', value: 'manual' },
    { label: 'Processing', value: 'processing' },
    { label: 'Failed', value: 'failed' },
    { label: 'Success', value: 'success' }
  ],
  'fixed-virtual-accounts': [
    { label: 'All Statuses', value: '' },
    { label: 'Active', value: 'active' },
    { label: 'Suspended', value: 'suspended' },
    { label: 'Deactivated', value: 'deactivated' },
    { label: 'Pending', value: 'pending' },
    { label: 'Deleted', value: 'deleted' },
    { label: 'Active (Unverified)', value: 'kyc_pending' }
  ],
  'account-holders': [
    { label: 'All Statuses', value: '' },
    { label: 'Approved', value: 'approved' },
    { label: 'Suspended', value: 'suspended' },
    { label: 'Deactivated', value: 'deactivated' },
    { label: 'Pending', value: 'pending' },
    { label: 'Rejected', value: 'rejected' }
  ],
  'upgrade-requests': [
    { label: 'All', value: '' },
    { label: 'Pending', value: 'pending' },
    { label: 'Approved', value: 'approved' },
    { label: 'Declined', value: 'declined' }
  ],
  'vba-transactions': [
    { label: 'All Statuses', value: '' },
    { label: 'Success', value: 'success' },
    { label: 'Failed', value: 'failed' },
    { label: 'Processing', value: 'processing' },
    { label: 'Underpaid', value: 'underpaid' },
    { label: 'Overpaid', value: 'overpaid' },
    { label: 'Rejected', value: 'rejected' },
    { label: 'Expired', value: 'expired' },
    { label: 'Flagged Success', value: 'flagged_success' },
    { label: 'Flagged', value: 'flagged' }
  ],
  balance_funding: [
    { label: 'All Statuses', value: '' },
    { label: 'Approved', value: 'approved' },
    { label: 'Declined', value: 'declined' },
    { label: 'Pending', value: 'pending' }
  ],
  'bulk-payouts': [
    { label: 'All Statuses', value: '' },
    { label: 'Cancelled', value: 'cancelled' },
    { label: 'Complete', value: 'complete' },
    { label: 'Draft', value: 'draft' },
    { label: 'Failed', value: 'failed' },
    { label: 'Processing', value: 'processing' }
  ],
  'bulk-transactions': [
    { label: 'All Statuses', value: '' },
    { label: 'Success', value: 'success' },
    { label: 'Failed', value: 'failed' },
    { label: 'Processing', value: 'processing' },
    { label: 'Pending', value: 'pending' }
  ],
  billing_history: [
    { label: 'All Statuses', value: '' },
    { label: 'Pending', value: 'pending' },
    { label: 'PartiallyPaid', value: 'partially_paid' },
    { label: 'FullyPaid', value: 'fully_paid' }
  ],
  'paused-payments': [
    { label: 'All Payments', value: '' },
    { label: 'Payouts', value: 'payouts' }
  ],
  partners_balance: [
    { value: '', label: 'All' },
    { label: 'Success', value: 'success' },
    { label: 'Failed', value: 'failed' },
    { label: 'Processing', value: 'processing' },
    { label: 'Pending', value: 'pending' }
  ],
  'verification-events': [
    { label: 'All Statuses', value: '' },
    { label: 'Found', value: 'found' },
    { label: 'Not found', value: 'not_found' },
    { label: 'Pending', value: 'pending' },
    { label: 'Failed', value: 'failed' }
  ],
  'identity-merchants': [
    { label: 'All Statuses', value: '' },
    { label: 'Enabled', value: 'true' },
    { label: 'Disabled', value: 'false' }
  ],
  'identity-requests': [
    { label: 'All Statuses', value: '' },
    { label: 'Pending', value: 'pending' },
    { label: 'Approved', value: 'approved' },
    { label: 'Declined', value: 'declined' }
  ],
  'identity-billing': [
    { label: 'All Billing Type', value: '' },
    { label: 'ID Verification', value: 'id_verification' },
    { label: 'Facial Validation', value: 'facial_validation' }
  ]
};

export const rightOptions = {
  'product-config': [
    { label: 'All Statuses', value: '' },
    { label: 'Enabled', value: 'active' },
    { label: 'Disabled', value: 'suspended' }
  ]
};

export const configOptions = {
  'product-config': [
    { value: 'all', label: 'All Configurations' },
    { value: 'custom', label: 'Custom' },
    { value: 'default', label: 'Default' }
  ]
};

export const AccessStatusOptions = {
  'product-config-access-requesters': [
    { label: 'All Statuses', value: '' },
    { label: 'Enabled', value: 'enabled' },
    { label: 'Disabled', value: 'disabled' }
  ]
};

export const typeOptions = {
  'account-holders': {
    key: 'accountType',
    options: [
      { value: '', label: 'All Types' },
      { value: 'individual', label: 'Individual' }
    ]
  },
  'fixed-virtual-accounts': {
    key: 'tier',
    options: [
      { value: '', label: 'Tier' },
      { value: 1, label: 'Tier 1' },
      { value: 2, label: 'Tier 2' }
    ]
  }
};

export const paymentMethods = {
  NGN: {
    card: 'Card Payments',
    bank_transfer: 'Bank Transfer',
    virtual_bank_account: 'Virtual Bank Accounts',
    reserved_bank_account: 'Reserved Bank Account',
    pay_with_bank: 'Pay with Bank (Direct debit)'
  },
  USD: {
    card: 'Card Payments'
  },
  KES: {
    card: 'Card Payments',
    mobile_money: 'Mobile Money'
  },
  GHS: {
    mobile_money: 'Mobile Money'
  }
} as const;

export const pausedPayment = ['all_payments', 'payouts'];

export type FilterTypes = keyof typeof options | 'refunds' | 'settlements' | 'card-events' | 'paused-payments';
const convertOptionsToArray = (type: string) =>
  options[type as keyof typeof options]?.map(opt => opt.value !== '' && opt.value).filter(opt => opt);

export const getActionButton = (
  type: string,
  annotation: string,
  setState: React.Dispatch<any>,
  hasAdvancedFilter: boolean,
  showExport: boolean
): Record<string, any> => {
  const getExportText = () => {
    if (type === 'merchant') return 'Export Merchant List';
    if (type === 'merchant-balance-history') return 'Export History';
    if (type === 'balance_funding') return 'Export';
    if (type === 'partners_balance') return 'Export History';
    if (type === 'verification-events') return 'Export Events';
    if (type === 'identity-billing') return 'Export Invoices';
    if (type === 'permissions') return `Export ${annotation}`;
    if (
      [
        'users',
        'roles',
        'invited_users',
        'issuing-merchants',
        'issuing_history',
        'issued-cards',
        'card_transactions',
        'transactions',
        'balance_history',
        'events',
        'identity-merchants',
        'identity-requests'
      ].includes(type)
    )
      return 'Export';
    return `Export ${capitalizeFirst(annotation)} List`;
  };

  let firstButton: filterButton | null = {
    text: getExportText(),
    image: <i className="os-icon os-icon-arrow-up-right" />,
    action: () => setState({ openExport: true }),
    show: showExport,
    dataTestId: 'export-button'
  };

  let secondButton: filterButton = {
    text: 'Advanced Filter',
    image: (
      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" fill="none" viewBox="0 0 17 16">
        <g stroke="#2376F3" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" clipPath="url(#clip0_6_5)">
          <path d="M2.38 2.667h4.76M9.86 2.667h4.76M2.38 8H8.5M11.22 8h3.4M2.38 13.333h3.4M8.5 13.333h6.12M7.14.667v4M11.22 6v4M5.78 11.333v4" />
        </g>
        <defs>
          <clipPath id="clip0_6_5">
            <path fill="#fff" d="M0 0H17V16H0z" />
          </clipPath>
        </defs>
      </svg>
    ),
    action: () => setState({ openFilter: true }),
    show: hasAdvancedFilter && type !== 'merchant'
  };

  switch (type) {
    case 'users':
    case 'invited_users':
      firstButton = {
        text: 'Manage Roles',
        image: <i className="os-icon os-icon-settings" />,
        action: () => history.push(`/dashboard/users/roles`),
        permissionsArray: [
          'system_roles.view',
          'custom_roles.create',
          'my_custom_roles.view',
          'system_roles.update',
          'custom_roles.view',
          'custom_roles.update'
        ]
      };
      secondButton = {
        text: 'Invite New User',
        action: () => history.push(`/dashboard/users/invite`),
        image: <i className="os-icon os-icon-plus" />,
        permissionsArray: ['admin_user_invitations.create']
      };
      break;
    case 'roles':
      firstButton = {
        text: 'Create New Role',
        image: <i className="os-icon os-icon-plus" />,
        action: () => history.push('/dashboard/users/roles/new'),
        permissionsArray: ['custom_roles.create']
      };
      secondButton = null;
      break;
    case 'balance_funding':
      firstButton;
      secondButton = null;
      break;
    default:
      break;
  }

  return [...(firstButton ? [firstButton] : []), ...(secondButton ? [secondButton] : [])];
};

export const filterData: Record<FilterTypes, FilterOption[]> = {
  'pay-in': [
    {
      type: 'status',
      elementType: 'status',
      statusOptions: convertOptionsToArray('pay-in')
    },
    {
      type: 'amount',
      elementType: 'amount',
      amountRangeOptions: ['is_equal_to', 'is_greater_than', 'is_less_than']
    },
    {
      type: 'dateCompleted',
      elementType: 'date',
      processDate: true,
      dateRangeOptions: ['today', 'last_seven_days', 'last_thirty_days', 'completed_on', 'completed_before', 'completed_after'],
      datePickerPlaceholder: 'Select the completed date'
    },
    {
      type: 'uniqueReference',
      elementType: 'reference',
      referenceLabel: 'Unique Reference (Payin ID, Processor ID, etc.)',
      referencePlaceholder: 'Eg. KPY-C-8052042..'
    },
    {
      type: 'payment_method',
      elementType: 'select',
      selectOptions: [
        { name: 'Bank Transfer', value: 'bank_transfer' },
        { name: 'Card Payment', value: 'card' },
        { name: 'Mobile Money', value: 'mobile_money' },
        { name: 'Pay with Bank (Direct debit)', value: 'pay_with_bank' }
      ]
    },
    {
      type: 'type',
      elementType: 'select',
      selectOptions: [
        { name: 'Pay-in', value: 'payin' },
        { name: 'Funding', value: 'deposit' }
      ]
    },
    {
      type: 'channel',
      elementType: 'select',
      selectOptions: [
        { name: 'API', value: 'api' },
        { name: 'Dashboard', value: 'web' },
        { name: 'Checkout', value: 'modal' }
      ]
    },
    {
      type: 'processor',
      elementType: 'select'
    },
    {
      type: 'merchant',
      elementType: 'text',
      inputPlaceholder: 'Merchant name or ID'
    }
  ],
  'pay-outs': [
    {
      type: 'status',
      elementType: 'status',
      statusOptions: convertOptionsToArray('pay-outs')
    },
    {
      type: 'amount',
      elementType: 'amount',
      amountRangeOptions: ['is_equal_to', 'is_greater_than', 'is_less_than']
    },
    {
      type: 'dateCompleted',
      elementType: 'date',
      processDate: true,
      dateRangeOptions: ['today', 'last_seven_days', 'last_thirty_days', 'completed_on', 'completed_before', 'completed_after'],
      datePickerPlaceholder: 'Select the completed date'
    },
    {
      type: 'uniqueReference',
      elementType: 'reference',
      referenceLabel: 'Unique Reference (Payout ID, Processor ID, etc.)',
      referencePlaceholder: 'Eg. KPY-D-8052042..'
    },
    {
      type: 'channel',
      elementType: 'select',
      selectOptions: [
        { name: 'API', value: 'api' },
        { name: 'Dashboard', value: 'web' }
      ]
    },
    {
      type: 'processor',
      elementType: 'select'
    },
    {
      type: 'merchant',
      elementType: 'text',
      inputPlaceholder: 'Merchant name or ID'
    }
  ],
  refunds: [
    {
      type: 'amount',
      elementType: 'amount',
      amountRangeOptions: ['is_equal_to', 'is_greater_than', 'is_less_than']
    },
    {
      type: 'dateCompleted',
      elementType: 'date',
      processDate: true,
      dateRangeOptions: ['today', 'last_seven_days', 'last_thirty_days', 'completed_on', 'completed_before', 'completed_after'],
      datePickerPlaceholder: 'Select the completed date'
    },
    {
      type: 'processor',
      elementType: 'select'
    },
    {
      type: 'keyword',
      elementType: 'text',
      inputPlaceholder: 'Search anything...',
      inputPostScript: 'Keywords can include Customer Name, Customer Email, Narration, etc.'
    }
  ],
  'settlements-payouts': [
    {
      type: 'status',
      elementType: 'status',
      statusOptions: ['success', 'failed', 'processing', 'pending']
    },
    {
      type: 'amount',
      elementType: 'amount',
      amountRangeOptions: ['is_equal_to', 'is_greater_than', 'is_less_than']
    },
    {
      type: 'datePaid',
      elementType: 'date',
      processDate: true,
      dateRangeOptions: ['today', 'last_seven_days', 'last_thirty_days', 'completed_on', 'completed_before', 'completed_after'],
      datePickerPlaceholder: 'Select the paid date'
    },
    {
      type: 'uniqueReference',
      elementType: 'reference',
      referenceLabel: 'Unique Reference (Payout ID, Processor ID, etc.)',
      referencePlaceholder: 'Eg. KPY-D-8052042..'
    },
    {
      type: 'settledTo',
      elementType: 'select',
      selectOptions: [
        { name: 'Korapay balance', value: 'disbursement_wallet' },
        { name: 'Bank Account', value: 'settlement_account' }
      ]
    },
    {
      type: 'settlementCategory',
      elementType: 'select',
      selectOptions: [
        { name: 'Bank Transfer', value: 'bank_transfer' },
        { name: 'Card Payment', value: 'card' },
        { name: 'Mobile Money', value: 'mobile_money' },
        { name: 'Pay with Bank (Direct debit)', value: 'pay_with_bank' }
      ]
    },
    {
      type: 'processor',
      elementType: 'select'
    },
    {
      type: 'merchantName',
      elementType: 'text',
      inputPlaceholder: 'Merchant name or ID'
    },
    {
      type: 'keyword',
      elementType: 'text',
      inputPlaceholder: 'Search anything...',
      inputPostScript: 'Keywords can include Customer Name, Customer Email, Narration, etc.'
    }
  ],
  settlements: [
    {
      type: 'amountCollected',
      elementType: 'amount',
      amountRangeOptions: ['is_equal_to', 'is_greater_than', 'is_less_than']
    },
    {
      type: 'amountSettled',
      elementType: 'amount',
      amountRangeOptions: ['is_equal_to', 'is_greater_than', 'is_less_than']
    },
    {
      type: 'dateSettled',
      elementType: 'date',
      processDate: true,
      hide: (data): boolean => data.status !== 'settled',
      dateRangeOptions: ['today', 'last_seven_days', 'last_thirty_days', 'completed_on', 'completed_before', 'completed_after'],
      datePickerPlaceholder: 'Select the settlement date'
    },
    {
      type: 'expectedSettlementDate',
      elementType: 'date',
      processDate: true,
      hide: (data): boolean => data.status === 'settled',
      dateRangeOptions: ['today', 'last_seven_days', 'last_thirty_days', 'completed_on', 'completed_before', 'completed_after'],
      datePickerPlaceholder: 'Select the expected settlment date'
    },
    {
      type: 'uniqueReference',
      elementType: 'reference',
      referenceLabel: 'Unique Reference (Payin ID, Processor ID, etc.)',
      referencePlaceholder: 'Eg. KPY-C-8052042..'
    },
    {
      type: 'settlementMethod',
      elementType: 'select',
      selectOptions: [
        { name: 'Instant', value: 'instant' },
        { name: 'Next Day', value: 'next_day' }
      ]
    },
    {
      type: 'settledTo',
      elementType: 'select',
      selectOptions: [
        { name: 'Korapay balance', value: 'disbursement_wallet' },
        { name: 'Bank Account', value: 'settlement_account' }
      ]
    },
    {
      type: 'settlementCategory',
      elementType: 'select',
      selectOptions: [
        { name: 'Bank Transfer', value: 'bank_transfer' },
        { name: 'Card Payment', value: 'card' },
        { name: 'Mobile Money', value: 'mobile_money' },
        { name: 'Pay with Bank (Direct debit)', value: 'pay_with_bank' }
      ]
    },
    {
      type: 'processor',
      elementType: 'select'
    },
    {
      type: 'merchantName',
      elementType: 'text',
      inputPlaceholder: 'Merchant name or ID'
    },
    {
      type: 'keyword',
      elementType: 'text',
      inputPlaceholder: 'Search anything...',
      inputPostScript: 'Keywords can include Customer Name, Customer Email, Narration, etc.'
    }
  ],
  'issued-cards': [
    {
      type: 'status',
      elementType: 'status',
      statusOptions: convertOptionsToArray('issued-cards')
    },
    {
      type: 'cardId',
      label: 'Card ID',
      elementType: 'text',
      inputPlaceholder: 'Eg. KPY-VC-8052042..',
      inputPostScript: 'Please input at least 3 characters in this field',
      minLength: 3,
      maxLength: 50
    },
    {
      type: 'cardHolderName',
      label: "Cardholder's Name",
      inputPlaceholder: 'FirstName LastName',
      elementType: 'text',
      minLength: 3,
      maxLength: 30,
      validate: holderNameValidation,
      inputPostScript: 'For names with prefixes, use the Keywords filter'
    },
    {
      type: 'cardBrand',
      elementType: 'select',
      selectOptions: [
        { name: 'Mastercard', value: 'mastercard' },
        { name: 'Visa', value: 'visa' }
      ]
    },
    {
      type: 'cardNumberEnding',
      elementType: 'text',
      inputPlaceholder: 'Eg. 1101',
      minLength: 4,
      maxLength: 4,
      validate: cardNumEndingValidation
    },
    {
      type: 'dateCreated',
      elementType: 'date',
      processDate: true,
      dateRangeOptions: ['today', 'last_seven_days', 'last_thirty_days', 'created_on', 'created_before', 'created_after'],
      datePickerPlaceholder: 'Select the created date'
    },
    {
      type: 'cardExpiryDate',
      label: 'Card Expiry Date',
      elementType: 'date',
      processDate: true,
      dateRangeOptions: ['today', 'last_seven_days', 'last_thirty_days', 'expired_on', 'expired_before', 'expired_after'],
      datePickerPlaceholder: 'Select the expiry date'
    },
    {
      type: 'keyword',
      elementType: 'text',
      inputPlaceholder: 'Search anything...',
      inputPostScript: 'Keywords can include Customer Name, Customer Email, Card Information, etc.'
    }
  ],
  'card-balance-history': [
    {
      type: 'reference',
      elementType: 'text',
      inputPlaceholder: 'Eg. KPY-VC-8052042..',
      inputPostScript: 'Unique Reference (Transaction ID)',
      minLength: 3,
      maxLength: 50
    },
    {
      type: 'type',
      elementType: 'select',
      selectOptions: [
        { name: 'Creation', value: 'creation' },
        { name: 'Funding', value: 'funding' },
        { name: 'Expense', value: 'expense' },
        { name: 'Refund', value: 'refund' }
      ]
    },
    {
      type: 'dateCreated',
      elementType: 'date',
      processDate: true,
      dateRangeOptions: ['today', 'last_seven_days', 'last_thirty_days', 'created_on', 'created_before', 'created_after'],
      datePickerPlaceholder: 'Select the created date'
    },
    {
      type: 'amount',
      elementType: 'amount',
      amountRangeOptions: ['is_equal_to', 'is_greater_than', 'is_less_than']
    },
    {
      type: 'keyword',
      elementType: 'text',
      inputPlaceholder: 'Search anything...',
      inputPostScript: 'Keywords can include Customer Name, Customer Email, References, Card Information etc.'
    }
  ],
  'card-details-transactions': [
    {
      type: 'status',
      elementType: 'status',
      statusOptions: convertOptionsToArray('card-details-transactions')
    },
    {
      type: 'transactionId',
      label: 'Transaction ID',
      elementType: 'text',
      inputPlaceholder: 'Eg. KPY-VC-8052042..',
      minLength: 3,
      maxLength: 50
    },
    {
      type: 'type',
      elementType: 'select',
      selectOptions: [
        { name: 'Card Funding', value: 'card_funding' },
        { name: 'Card Transaction', value: 'card_transaction' },
        { name: 'Chargeback', value: 'chargeback' },
        { name: 'Refund', value: 'refund' }
      ]
    },
    {
      type: 'dateCreated',
      elementType: 'date',
      processDate: true,
      dateRangeOptions: ['today', 'last_seven_days', 'last_thirty_days', 'created_on', 'created_before', 'created_after'],
      datePickerPlaceholder: 'Select the created date'
    },
    {
      type: 'amount',
      elementType: 'amount',
      amountRangeOptions: ['lessThan', 'equalTo', 'greaterThan'],
      subFilterKey: 'amountFilterOption',
      convertFromCamelCase: true
    },
    {
      type: 'keyword',
      elementType: 'text',
      inputPlaceholder: 'Search anything...',
      inputPostScript: 'Keywords can include Customer Name, Customer Email, Narration, etc.'
    }
  ],
  'cards-transactions': [
    {
      type: 'status',
      elementType: 'status',
      statusOptions: convertOptionsToArray('cards-transactions')
    },
    {
      type: 'transactionId',
      label: 'Transaction ID',
      elementType: 'text',
      inputPlaceholder: 'Eg. KPY-VC-8052042..',
      minLength: 3,
      maxLength: 50
    },
    {
      type: 'cardHolderName',
      label: "Cardholder's Name",
      elementType: 'text',
      inputPlaceholder: 'FirstName LastName',
      minLength: 3,
      maxLength: 30,
      validate: holderNameValidation,
      inputPostScript: 'For names with prefixes, use the Keywords filter'
    },
    {
      type: 'cardNumberEnding',
      elementType: 'text',
      inputPlaceholder: 'Eg. 1101',
      minLength: 4,
      maxLength: 4,
      validate: cardNumEndingValidation
    },
    {
      type: 'type',
      elementType: 'select',
      selectOptions: [
        { name: 'Card Funding', value: 'card_funding' },
        { name: 'Card Transaction', value: 'card_transaction' },
        { name: 'Card Creation', value: 'card_creation' },
        { name: 'Chargeback', value: 'chargeback' },
        { name: 'Refund', value: 'refund' }
      ]
    },
    {
      type: 'dateCreated',
      elementType: 'date',
      processDate: true,
      dateRangeOptions: ['today', 'last_seven_days', 'last_thirty_days', 'created_on', 'created_before', 'created_after'],
      datePickerPlaceholder: 'Select the created date'
    },
    {
      type: 'amount',
      elementType: 'amount',
      amountRangeOptions: ['lessThan', 'equalTo', 'greaterThan'],
      subFilterKey: 'amountFilterOption',
      convertFromCamelCase: true
    },
    {
      type: 'keyword',
      elementType: 'text',
      inputPlaceholder: 'Search anything...',
      inputPostScript: 'Keywords can include Customer Name, Customer Email, Narration, etc.'
    }
  ],
  'issuance-chargebacks': [
    {
      type: 'status',
      elementType: 'status',
      statusOptions: convertOptionsToArray('issuance-chargebacks')
    },
    {
      type: 'chargebackId',
      referenceLabel: 'Chargeback ID',
      elementType: 'text',
      inputPlaceholder: 'E.g, chb-4pu...',
      minLength: 3,
      maxLength: 50
    },
    {
      type: 'amount',
      elementType: 'amount',
      amountRangeOptions: ['lessThan', 'equalTo', 'greaterThan'],
      subFilterKey: 'amountFilterOption',
      convertFromCamelCase: true
    },
    {
      type: 'dateCreated',
      label: 'Escalated At',
      elementType: 'date',
      processDate: true,
      dateRangeOptions: ['today', 'last_seven_days', 'last_thirty_days', 'created_on', 'created_before', 'created_after'],
      datePickerPlaceholder: 'Select the Escalation date'
    },
    {
      type: 'cardHolderName',
      label: "Cardholder's Name",
      elementType: 'text',
      inputPlaceholder: 'FirstName LastName',
      minLength: 3,
      maxLength: 30,
      validate: holderNameValidation,
      inputPostScript: 'For names with prefixes, use the Keywords filter'
    },
    {
      type: 'cardAcceptorName',
      label: "Vendor's Name",
      elementType: 'text',
      inputPlaceholder: 'FirstName LastName',
      minLength: 3,
      maxLength: 50,
      validate: holderNameValidation,
      inputPostScript: 'For names with prefixes, use the Keywords filter'
    },
    {
      type: 'cardNumberEnding',
      elementType: 'text',
      inputPlaceholder: 'Eg. 1101',
      minLength: 4,
      maxLength: 4,
      validate: cardNumEndingValidation
    },
    {
      type: 'transactionType',
      label: 'Transaction Type',
      elementType: 'select',
      selectOptions: [{ name: 'Card Transaction', value: 'card_transaction' }]
    },
    {
      type: 'keyword',
      elementType: 'text',
      inputPlaceholder: 'Search anything...',
      inputPostScript: 'Keywords can include transaction description, Balance...'
    }
  ],
  'issuing-merchants': [
    {
      type: 'dateCreated',
      label: 'Started Issuing At',
      elementType: 'date',
      processDate: true,
      dateRangeOptions: ['today', 'last_seven_days', 'last_thirty_days', 'created_on', 'created_before', 'created_after'],
      datePickerPlaceholder: 'Select the Issuing date'
    },
    {
      type: 'keyword',
      elementType: 'text',
      inputPlaceholder: 'Search anything...',
      inputPostScript: 'Keywords can include Merchant Name,  Balance...'
    }
  ],
  issuing_history: [
    {
      type: 'keyword',
      elementType: 'text',
      inputPlaceholder: 'Search anything...',
      inputPostScript: 'Keywords can include Transaction Detail, Reference  Balance...'
    },
    {
      type: 'amount',
      elementType: 'amount',
      amountRangeOptions: ['is_less_than', 'is_equal_to', 'is_greater_than'],
      subFilterKey: 'amountSubfilter'
    }
  ],
  'bulk-payouts': [
    {
      type: 'status',
      elementType: 'status',
      statusOptions: convertOptionsToArray('bulk-payouts')
    },
    {
      type: 'amount',
      elementType: 'amount',
      amountRangeOptions: ['is_equal_to', 'is_greater_than', 'is_less_than']
    },
    {
      type: 'dateCreated',
      elementType: 'date',
      processDate: true,
      dateRangeOptions: ['today', 'last_seven_days', 'last_thirty_days', 'created_on', 'created_before', 'created_after'],
      datePickerPlaceholder: 'Select the created date'
    },
    {
      type: 'dateCompleted',
      elementType: 'date',
      processDate: true,
      dateRangeOptions: ['today', 'last_seven_days', 'last_thirty_days', 'completed_on', 'completed_before', 'completed_after'],
      datePickerPlaceholder: 'Select the completed date'
    },
    {
      type: 'uniqueReference',
      elementType: 'reference',
      referenceLabel: 'Unique Reference (Payout ID, Processor ID, etc.)',
      referencePlaceholder: 'Eg. KPY-D-8052042..'
    },
    {
      type: 'channel',
      elementType: 'select',
      selectOptions: [
        { name: 'API', value: 'api' },
        { name: 'Dashboard', value: 'web' }
      ]
    },
    {
      type: 'merchant',
      elementType: 'text',
      inputPlaceholder: 'Merchant name or ID'
    },
    {
      type: 'keyword',
      elementType: 'text',
      inputPlaceholder: 'Search anything...',
      inputPostScript: 'Keywords can include Customer Name, Customer Email, Narration, etc.'
    }
  ],
  'bulk-transactions': [
    {
      type: 'status',
      elementType: 'status',
      statusOptions: convertOptionsToArray('bulk-transactions')
    },
    {
      type: 'amount',
      elementType: 'amount',
      amountRangeOptions: ['is_equal_to', 'is_greater_than', 'is_less_than']
    },
    {
      type: 'dateCreated',
      elementType: 'date',
      processDate: true,
      dateRangeOptions: ['today', 'last_seven_days', 'last_thirty_days', 'created_on', 'created_before', 'created_after'],
      datePickerPlaceholder: 'Select the created date'
    },
    {
      type: 'dateCompleted',
      elementType: 'date',
      processDate: true,
      dateRangeOptions: ['today', 'last_seven_days', 'last_thirty_days', 'completed_on', 'completed_before', 'completed_after'],
      datePickerPlaceholder: 'Select the completed date'
    },
    {
      type: 'uniqueReference',
      elementType: 'reference',
      referenceLabel: 'Unique Reference (Payout ID, Processor ID, etc.)',
      referencePlaceholder: 'Eg. KPY-D-8052042..'
    },
    {
      type: 'channel',
      elementType: 'select',
      selectOptions: [
        { name: 'API', value: 'api' },
        { name: 'Dashboard', value: 'web' }
      ]
    },
    {
      type: 'merchant',
      elementType: 'text',
      inputPlaceholder: 'Merchant name or ID'
    },
    {
      type: 'keyword',
      elementType: 'text',
      inputPlaceholder: 'Search anything...',
      inputPostScript: 'Keywords can include Customer Name, Customer Email, Narration, etc.'
    }
  ],
  'paused-payments': [
    {
      type: 'amount',
      elementType: 'amount',
      amountRangeOptions: ['is_equal_to', 'is_greater_than', 'is_less_than']
    },
    {
      type: 'uniqueReference',
      elementType: 'reference',
      referenceLabel: 'Unique Reference (Payout ID, Processor ID, etc.)',
      referencePlaceholder: 'Eg. KPY-D-8052042..'
    },
    {
      type: 'channel',
      elementType: 'select',
      selectOptions: [
        { name: 'API', value: 'api' },
        { name: 'Dashboard', value: 'web' }
      ]
    },
    {
      type: 'merchant',
      elementType: 'text',
      inputPlaceholder: 'Merchant name or ID'
    }
  ],
  'identity-merchants': [
    {
      type: 'dateCreated',
      elementType: 'date',
      processDate: true,
      dateRangeOptions: ['today', 'last_seven_days', 'last_thirty_days', 'created_on', 'created_before', 'created_after'],
      datePickerPlaceholder: 'Select the created date'
    },
    {
      type: 'keyword',
      elementType: 'text',
      inputPlaceholder: 'Search anything...',
      inputPostScript: 'Keywords can include Merchant Name,  Balance...'
    }
  ],
  'identity-requests': [
    {
      type: 'status',
      elementType: 'status',
      statusOptions: convertOptionsToArray('identity-requests')
    },
    {
      type: 'dateCreated',
      elementType: 'date',
      processDate: true,
      dateRangeOptions: ['today', 'last_seven_days', 'last_thirty_days', 'created_on', 'created_before', 'created_after'],
      datePickerPlaceholder: 'Select the created date'
    },
    {
      type: 'keyword',
      elementType: 'text',
      inputPlaceholder: 'Search anything...',
      inputPostScript: 'Keywords can include Merchant Name,  Balance...'
    }
  ]
};

export const getFilterParams = (filterState: Record<string, unknown>, type: string) => ({
  ...filterState,
  ...(!filterState.amount && { amount: filterState.queryAmount || filterState.queryAmountColleted }),
  amountSubfilter: filterState.amountRange || filterState.amountCollectedRange || filterState.amountSubfilter,
  type: filterState.payInType || filterState.paymentType || filterState.type
});

import React from 'react';

import Icon from '+containers/Dashboard/Shared/Icons';
import Copyable from '+shared/Copyable';
import EmptyState from '+shared/EmptyState';
import ViewAndDownload from '+shared/ViewAndDownload';
import { capitalizeAllCharacters, capitalizeRemovedash } from '+utils';

import FileIcon from '+assets/img/dashboard/file.svg';

import './index.scss';

interface IMerchantDocument {
  [key: string]: string;
}

interface IMerchantDocumentsProps {
  documents?: IMerchantDocument[] | null;
  businessType?: string;
  profile?: Record<string, any>;
  validation?: Record<string, any>;
}

export default function MerchantDocuments({ documents = null, businessType, profile, validation }: IMerchantDocumentsProps) {
  const validationError = values => {
    if (values?.is_valid === false) return true;
    if (
      (businessType === 'individual' && (!values?.dob?.match || !values?.name?.match)) ||
      (businessType !== 'individual' && (!values?.country?.match || !values?.name?.match))
    ) {
      return true;
    }
  };

  const renderValidationMessage = documentValidation => {
    const dobMatch = documentValidation?.dob?.match;
    const nameMatch = documentValidation?.name?.match;
    const countryMatch = documentValidation?.country?.match;

    if (documentValidation.is_valid === false) {
      return (
        <div className="mt-2">
          <Icon name="close" stroke="#FC4343" />
          <span className="red-text ml-1 text-sm">Invalid</span>
        </div>
      );
    }

    if (businessType === 'individual') {
      if (!dobMatch && !nameMatch) {
        return (
          <div className="mt-2">
            <Icon name="close" stroke="#FC4343" />
            <span className="red-text ml-1 text-sm">Name and DOB does not match</span>
          </div>
        );
      }
      if (!dobMatch) {
        return (
          <div className="mt-2">
            <Icon name="close" stroke="#FC4343" />
            <span className="red-text ml-1 text-sm">DOB does not match</span>
          </div>
        );
      }
      if (!nameMatch) {
        return (
          <div className="mt-2">
            <Icon name="close" stroke="#FC4343" />
            <span className="red-text ml-1 text-sm">Name does not match</span>
          </div>
        );
      }
    } else {
      if (!nameMatch && !countryMatch) {
        return (
          <div className="mt-2">
            <Icon name="close" stroke="#FC4343" />
            <span className="red-text ml-1 text-sm">Business name and country does not match</span>
          </div>
        );
      }
      return (
        <>
          {!nameMatch && (
            <div className="mt-2">
              <Icon name="close" stroke="#FC4343" />
              <span className="red-text ml-1 text-sm">Business name does not match</span>
            </div>
          )}

          {!countryMatch && (
            <div className="mt-2">
              <Icon name="close" stroke="#FC4343" />
              <span className="red-text ml-1 text-sm">Business country does not match</span>
            </div>
          )}
        </>
      );
    }

    return null;
  };
  return (
    <>
      {documents ? (
        <div className={`details-wrapper ${profile?.kyc_status?.toLowerCase() === 'rejected' ? 'is-disabled' : ''}`}>
          {documents
            .filter(doc => doc?.is_additional_document === false)
            .map(document => (
              <div className="mb-3">
                <div
                  className={`entry entry-doc mb-0 ${
                    validation?.[document?.type] && (validation?.[document?.type]?.is_valid === false ? 'entry-invalid' : 'entry-valid')
                  }`}
                  key={document.type}
                >
                  <div className="entry-doc__left">
                    <img alt="" src={FileIcon} />
                    <span className="bk-text">{capitalizeRemovedash(document.type)}</span>
                    {document.id && (
                      <>
                        <span className={`${(validation?.[document?.type]?.is_valid === false && 'red-text') || 'gn-text'}`}>
                          {capitalizeRemovedash(document.id)}
                        </span>

                        {validationError(validation?.[document?.type]) ? (
                          <Icon name="close" stroke="#FC4343" />
                        ) : (
                          <Icon name="check" stroke="#24B314" />
                        )}
                      </>
                    )}
                  </div>
                  <div className="entry-doc__right">
                    {document.file ? (
                      <>
                        <span className="gy-text">{document.file?.description}</span>
                        <span className="divider-sm" />
                        <ViewAndDownload
                          url={document.file?.url}
                          info={document.type}
                          type={document.file?.name?.split('.').pop()}
                          filename={document.file?.description?.split('.')[0]}
                          disableView={profile?.kyc_status?.toLowerCase() === 'rejected'}
                          disableDownload={profile?.kyc_status?.toLowerCase() === 'rejected'}
                        />
                      </>
                    ) : (
                      <>
                        <span className="gy-text">
                          {!document.text
                            ? `Merchant does not have ${capitalizeRemovedash(document.type)}`
                            : `${capitalizeAllCharacters(document.text?.name)}`}
                        </span>
                        <span className="vnin-text">{document.text && `${document.text?.value}`}</span>
                        <span className="divider-sm" />
                        <Copyable text="Copy" copyColor="#2376F3" spanClassName="copy-text" isBlueIcon textToCopy={document.text?.value} />
                      </>
                    )}
                  </div>
                </div>
                {validation?.[document?.type] &&
                  validationError(validation?.[document?.type]) &&
                  renderValidationMessage(validation?.[document?.type])}

                {validation?.[document?.type] && !validationError(validation?.[document?.type]) && (
                  <div className="mt-2">
                    <Icon name="check" stroke="#24B314" />
                    <span className="gn-text ml-1 text-sm">Valid</span>
                  </div>
                )}
              </div>
            ))}
          <div className="add-doc">Additional Documents</div>
          {documents
            .filter(doc => doc?.is_additional_document === true)
            .map(document => (
              <div className="entry entry-doc" key={document.type}>
                <div className="entry-doc__left">
                  <img alt="" src={FileIcon} />
                  <span className="bk-text">{capitalizeAllCharacters(document.type)}</span>
                </div>
                <div className="entry-doc__right">
                  {document.file ? (
                    <>
                      <span className="gy-text">{document.file?.description}</span>
                      <span className="divider-sm" />
                      <ViewAndDownload
                        url={document.file?.url}
                        info={document.type}
                        type={document.file?.name?.split('.').pop()}
                        filename={document.file?.description?.split('.')[0]}
                        disableView={profile?.kyc_status?.toLowerCase() === 'rejected'}
                        disableDownload={profile?.kyc_status?.toLowerCase() === 'rejected'}
                      />
                    </>
                  ) : (
                    <span className="gy-text">{`Merchant does not have ${capitalizeRemovedash(document.type)}`}</span>
                  )}
                </div>
              </div>
            ))}
        </div>
      ) : (
        <EmptyState message="No document information available." />
      )}

      {documents && Object.keys(documents || {}).length === 0 && <EmptyState message="No document information available." />}
    </>
  );
}

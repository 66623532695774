/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useMutation } from 'react-query';

import Modal, { IModalProps } from '+containers/Dashboard/Shared/Modal';
import { useFeedbackHandler } from '+hooks';
import APIRequest from '+services/api-services';
import { ToggleCardCreationPayloadType } from '+types';
import { logError } from '+utils';

const api = new APIRequest();

const UpdateCardCreationModal = ({
  onClose,
  onToggleSuccess,
  payload
}: {
  onClose: () => void;
  onToggleSuccess: () => void;
  payload: ToggleCardCreationPayloadType;
}) => {
  const { feedbackInit, closeFeedback } = useFeedbackHandler();
  const isReserved = payload.card_category === 'reserved';

  const { mutateAsync: mutateTogglelCardCreation, isSuccess: hasUpdatedSuccessfully } = useMutation(
    () => api.toggleCardCreation(payload.reference, { card_category: payload.card_category, action: payload.action }),
    {
      onSuccess: data => {
        feedbackInit({ message: data?.message, type: 'success' });
      },
      onError: error => {
        logError(error);
        feedbackInit({
          message: error.response?.data?.message || 'Action was unsuccessful',
          type: 'danger',
          componentLevel: true
        });
      },
      onSettled: () => {
        setTimeout(() => {
          closeFeedback();
        }, 5000);
      }
    }
  );

  const handleMutateToggleCardCreation = async () => {
    try {
      await mutateTogglelCardCreation();
    } catch (err) {
      throw new Error(err);
    }
  };

  const actionToProps: Record<'disable' | 'enable', Partial<IModalProps>> = {
    disable: {
      heading: 'Confirm changes?',
      description: (
        <div>
          Please confirm if you want to turn off {isReserved ? 'RVCs' : 'Issued cards (Customers)'} card creation for this merchant.
          <br />
          <br />
          Once you check the box, they will no longer be able to create {isReserved ? 'RVCs' : 'cards for their customers'} unless the box
          is unchecked to re-enable it.
        </div>
      ),
      secondButtonText: 'Yes, Confirm'
    },
    enable: {
      heading: 'Undo changes?',
      description: (
        <div>
          Please confirm if you want to enable {isReserved ? 'RVCs' : 'Issued cards (Customers)'} card creation for this merchant.
          <br />
          <br />
          Once you uncheck the box, they will be able to create {isReserved ? 'RVCs' : 'cards for their customers'} again.
        </div>
      ),
      secondButtonText: 'Yes, Undo'
    }
  };

  return (
    <Modal
      size="sm"
      secondButtonAction={handleMutateToggleCardCreation}
      secondButtonActionIsTerminal={true}
      secondaryCompletedModal={true}
      completedDescription="You have successfully made changes to this configuration."
      close={() => {
        if (hasUpdatedSuccessfully) onToggleSuccess();
        onClose();
      }}
      {...actionToProps[payload.action]}
    />
  );
};

export default UpdateCardCreationModal;

import React from 'react';
import { useClickOutside } from '+hooks';
import './index.scss';

type DropdownListT = Array<{ name: string; action: () => void; disabled?: boolean }>;

function PageBackDropdown({ dropdownList, activeMenu }: { dropdownList: DropdownListT; activeMenu: string }) {
  const [openDropdown, setOpenDropdown] = React.useState(false);
  const ref = useClickOutside(() => setOpenDropdown(false));

  return (
    <div className="page-back-dropdown" ref={ref as React.RefObject<HTMLDivElement>}>
      <button
        className="btn page-back-dropdown__toggle"
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        onClick={() => setOpenDropdown(!openDropdown)}
      >
        <span>{activeMenu || dropdownList[0]?.name}</span> <i className="os-icon os-icon-chevron-down" />
      </button>
      {openDropdown && (
        <div className="page-back-dropdown__menu" aria-labelledby="dropdownMenuButton">
          {dropdownList.map((item, index) => (
            <button
              key={index}
              className={`page-back-dropdown__item ${activeMenu === item.name ? 'active' : ''}`}
              onClick={() => {
                item.action();
                setOpenDropdown(false);
              }}
              disabled={item.disabled}
            >
              <span>{item.name}</span>
              {activeMenu === item.name && <span className="os-icon os-icon-check" role="status" />}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

export default function PageBackButton({
  onClick,
  label,
  dropdownList,
  activeMenu
}: {
  onClick: () => void;
  label: string;
  dropdownList?: DropdownListT;
  activeMenu?: string;
}) {
  return (
    <div className="page-back-button">
      <button type="button" className="btn btn-link identity__back-btn" onClick={onClick}>
        <i className="os-icon os-icon-arrow-left7" />
        <span>{label}</span>
      </button>

      {dropdownList && (
        <>
          <span className="id-divider-sm" />
          <PageBackDropdown dropdownList={dropdownList} activeMenu={activeMenu as string} />
        </>
      )}
    </div>
  );
}

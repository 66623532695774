import React from 'react';

import './index.scss';

export default function Tabs({
  activeTab,
  tabs,
  searchQuery,
  isTabDisabled,
  tabsCount
}: {
  activeTab: string;
  tabs: { name: string; key: string }[];
  searchQuery: { setQuery: (query: Partial<Record<string, string | null>>, arg1: boolean) => void };
  isTabDisabled?: (tab: string) => boolean;
  tabsCount?: { key: string; count: number }[];
}) {
  return (
    <div className="os-tabs-w">
      <div className="os-tabs-controls os-tabs-complex">
        <ul className="nav nav-tabs">
          {tabs.map(tab => {
            const tabCount = tabsCount?.find(t => t.key === tab.key);
            return (
              <li className="nav-item" key={tab.name}>
                <button
                  aria-expanded="false"
                  className={`nav-link details--custom--button identity-tab ${activeTab === tab.key ? ' active' : ''}`}
                  data-toggle="tab"
                  onClick={() => searchQuery.setQuery({ tab: tab.key }, true)}
                  onKeyDown={() => searchQuery.setQuery({ tab: tab.key }, true)}
                  disabled={isTabDisabled?.(tab.key)}
                >
                  <span className="tab-label">{tab.name}</span>{' '}
                  {tabCount && tabCount.count ? <span className="identity-tab-count">{tabCount?.count}</span> : null}
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

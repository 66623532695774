/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import RiskIndicator from '+containers/Dashboard/ProductConfig/components/RiskIndicator';
import { useFeedbackHandler, useSearchQuery, useSetUserAccess } from '+hooks';
import APIRequest from '+services/api-services';
import Table from '+shared/Table';
import { AllAccessRequestMerchantsResponseType, CategoryType, CurrencyType } from '+types';
import {
  APIDownload,
  capitalize,
  capitalizeRemovedash,
  filteredOutObjectProperty,
  getDate,
  getTime,
  isAllowed,
  logError,
  queriesParams,
  renameObjectKeys,
  switchStatus
} from '+utils';

const api = new APIRequest();
const RequestersTable = ({ feature }: { feature: CategoryType }) => {
  const { currency } = useParams<{ currency: CurrencyType }>();
  const cardType = feature === 'issued-cards' ? 'customer' : 'reserved';
  const { feedbackInit } = useFeedbackHandler();
  const searchQuery = useSearchQuery();
  const page = searchQuery.value.page || 1;
  const limit = searchQuery.value.limit || 10;
  const keyword = searchQuery.value.keyword || undefined;
  const currencyAccessStatus = searchQuery.value.currencyAccessStatus || undefined;
  const filteredSortingParams = filteredOutObjectProperty(searchQuery.value, [
    queriesParams.tab,
    queriesParams.page,
    queriesParams.limit,
    queriesParams.keyword,
    queriesParams.currencyAccessStatus
  ]);
  const userAccess = useSetUserAccess();

  const sortingParams = {
    limit,
    page,
    keyword: keyword,
    ...(currencyAccessStatus
      ? { currencyAccessStatus: typeof currencyAccessStatus === 'string' ? [currencyAccessStatus] : currencyAccessStatus }
      : {}),
    ...{ status: ['pending'] },
    ...renameObjectKeys(filteredSortingParams, { dateFrom: 'dateCreatedFrom', dateTo: 'dateCreatedTo' })
  };

  const { data, refetch, isLoading } = useQuery<{
    data: AllAccessRequestMerchantsResponseType;
    paging: { page_size: number; total_items: number };
  }>(
    ['REQUESTING_MERCHANTS', searchQuery.value],
    () => api.fetchAllAccessRequestMerchants(currency, 'virtual', cardType, page, limit, sortingParams),
    {
      onError: () => {
        feedbackInit({
          message: 'There has been an error fetching merchants requesting access',
          type: 'danger',
          action: {
            action: () => refetch(),
            name: 'Try again'
          }
        });
      },
      enabled: !!isAllowed(userAccess, ['card_issuance_access_request.view'])
    }
  );

  const exportAllAccessRequestMerchants = async (exportFormat: 'csv' | 'xlsx', close: () => void, exportFields: Array<string>) => {
    try {
      const res = await api.fetchAllAccessRequestMerchants(
        currency,
        'virtual',
        cardType,
        page,
        limit,
        sortingParams,
        true,
        exportFormat,
        exportFields
      );
      const type = exportFormat === 'csv' ? 'csv' : 'xlsx';
      APIDownload(res, `All Access Request Merchants at ${getDate(Date.now())}`, type);
      feedbackInit({
        title: 'Export Successful',
        message: <> - Successfully exported</>,
        type: 'success'
      });
      close();
    } catch (error) {
      logError(error);
      feedbackInit({
        title: 'Export Failed',
        message: `There has been an error downloading your Access Request Merchants list file`,
        type: 'danger',
        componentLevel: true
      });
    }
  };

  const tableProps = {
    className: 'issuance-merchants-access-table',
    emptyStateHeading: 'No Merchants yet',
    annotations: 'Merchant(s)',
    data: data?.data || [],
    emptyStateMessage: 'There are no Merchants',
    rowUrl: `/dashboard/product-config/${currency}/card-issuance/${feature}/requesters`,
    rowKey: 'reference',
    fields: (each: AllAccessRequestMerchantsResponseType[number]) => ({
      data: {
        Merchant: <span id="merchant-name">{capitalize(each?.name)}</span>,
        Risk_Level: each.risk_level ? <RiskIndicator riskLevel={each.risk_level} /> : 'N/A',
        Usd_Access: (
          <span>
            {each?.currency_access_status ? (
              <>
                <span className={`status-pill smaller ${switchStatus(each.currency_access_status)}`} />
                <span className="lighter">{capitalizeRemovedash(each?.currency_access_status)}</span>
              </>
            ) : (
              'N/A'
            )}
          </span>
        ),
        Subscription_Plan: <span className="lighter">{capitalize(each?.subscription_plan) || 'N/A'}</span>,
        Date_Requested: (
          <>
            <span>{getDate(each?.request_date)}</span>
            <span className="annotation" style={{ marginLeft: '5px' }}>
              {getTime(each?.request_date)}
            </span>
          </>
        )
      }
    })
  };

  const tableDataKeys = Object.keys(tableProps.fields({} as AllAccessRequestMerchantsResponseType[number]).data).map(key =>
    capitalizeRemovedash(key)
  );
  return (
    <div className="table-section ">
      <Table
        annotation={tableProps.annotations}
        className={tableProps.className}
        data={tableProps?.data || []}
        tableHeadings={tableDataKeys}
        loading={isLoading}
        isRowClickable
        renderFields
        hasPagination
        rowKey={tableProps?.rowKey}
        rowURL={tableProps.rowUrl}
        pageSize={data?.paging?.page_size}
        totalItems={data?.paging?.total_items}
        current={parseInt(page + '', 10)}
        hideTable={tableProps?.data?.length === 0}
        filterKeywordPlaceholder="Search merchant..."
        tableWrapperClassName="table-responsive table-wrapper"
        emptyStateHeading={tableProps?.emptyStateHeading || ''}
        emptyStateMessage={tableProps.emptyStateMessage || ''}
        actionFn={currentPage => searchQuery.setQuery({ page: String(currentPage) })}
        limitAction={currentLimit => searchQuery.setQuery({ limit: String(currentLimit) })}
        filterHasAdvancedFilter={false}
        filterName={`merchants are requesting access to ${currency} ${feature === 'issued-cards' ? 'Issued' : 'Reserved'} Cards`}
        type="product-config-access-requesters"
        filterExportAction={exportAllAccessRequestMerchants}
        filterShowExport
      >
        {tableProps.fields}
      </Table>
    </div>
  );
};

export default RequestersTable;

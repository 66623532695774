import React, { ButtonHTMLAttributes, useRef, useState } from 'react';
import classNames from 'classnames';

import './switch.scss';

interface ISwitchProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: 'sm' | 'md' | 'lg';
  checked?: boolean;
  onCheckedChange?: (value: boolean) => void;
}

function Switch({
  size = 'md',
  checked: propsChecked,
  onCheckedChange,
  'aria-label': ariaLabel = 'Toggle switch',
  id,
  className,
  ...props
}: ISwitchProps) {
  const [internalChecked, setInternalChecked] = useState(false);
  const switchRef = useRef<HTMLButtonElement>(null);
  const isControlled = typeof propsChecked !== 'undefined';
  const checked = isControlled ? propsChecked : internalChecked;

  function handleToggle() {
    if (!isControlled) setInternalChecked(!checked);
    onCheckedChange?.(!checked);
    switchRef.current?.focus();
  }

  return (
    <button
      id={id}
      aria-checked={checked}
      aria-label={ariaLabel}
      className={classNames(`switch switch--${size}`, checked && 'is-checked', className)}
      onClick={handleToggle}
      onKeyDown={e => {
        if (e.key === ' ' || e.key === 'Enter') {
          e.preventDefault();
          handleToggle();
        }
      }}
      {...props}
      role="switch"
      ref={switchRef}
    >
      <span className="switch__track">
        <span className="switch__thumb" />
      </span>
    </button>
  );
}

export default Switch;

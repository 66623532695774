import React from 'react';

import HeroSection from '../components/HeroSection';
import RegionConfiguration from '../components/RegionConfiguration';

export default function Configuration() {
  return (
    <div>
      <HeroSection
        title="Configuration for Identity"
        description="This is where the configuration for the Identity service lives. This is the default configuration that applies to merchant when they are granted access to the service."
      />
      <RegionConfiguration allMerchants />
    </div>
  );
}

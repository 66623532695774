import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import ProtectedRoute from './components/ProtectedRoute';
import CurrenciesMenu from './CurrenciesMenu';
import MerchantCurrencyDetails from './MerchantCurrencyDetails';
import MerchantsTable from './MerchantsTable';
import ProductGroup from './ProductGroup';
import ProductDetails from './ProductGroup/ProductDetails';
import HolderDetails from './ProductGroup/ProductDetails/CardIssuance/HolderDetails';
import MerchantAccessDetails from './ProductGroup/ProductDetails/CardIssuance/MerchantAccessDetails';

export default function ProductConfig() {
  return (
    <Switch>
      <Route path="/dashboard/product-config" exact component={CurrenciesMenu} />

      <Route path="/dashboard/product-config/:currency/merchants" exact component={MerchantsTable} />

      <Route path="/dashboard/product-config/:currency/merchants/:merchant_id" exact component={MerchantCurrencyDetails} />

      <Route exact path="/dashboard/product-config/:currency/:product" component={ProductGroup} />

      <Redirect exact from="/dashboard/product-config/:currency/" to="/dashboard/product-config/:currency/pay-ins" />

      <Route path="/dashboard/product-config/:currency/:product/:feature" exact component={ProductDetails} />

      <ProtectedRoute
        permissions={['card_issuance_access_request_details.view']}
        path="/dashboard/product-config/:currency/card-issuance/:feature/holders/:merchantId"
        component={HolderDetails}
      />

      <ProtectedRoute
        permissions={['card_issuance_access_request_details.view']}
        path="/dashboard/product-config/:currency/card-issuance/:feature/requesters/:merchantId"
        component={MerchantAccessDetails}
      />
    </Switch>
  );
}
